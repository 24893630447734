import { Box, Checkbox, FormControl, HStack } from '@chakra-ui/react'
import RowActionButtons from 'components/RowActionButtons'
import { FC } from 'react'
import SimpleInput from 'shared/components/SimpleInput'
import { IAnswer, ITestOption } from 'shared/types'
import isString from 'lodash/isString'

type Props = {
  option: ITestOption
  testAnswer?: IAnswer
  setAnswer: (optId: string) => void
  updateOptionValue: (optId: string, v: string) => void
  deleteOption: (optId: string) => void
  isFirst: boolean
  isLast: boolean
  onMove: (isUp: boolean) => void
}

const EditableOption: FC<Props> = ({
  option,
  isFirst,
  isLast,
  testAnswer,
  setAnswer,
  updateOptionValue,
  deleteOption,
  onMove
}) => {
  return (
    <HStack key={option.id} w='full' position={'relative'} role='group'>
      <Checkbox
        size='sm'
        isChecked={isString(testAnswer) && testAnswer === option.id}
        onChange={e => setAnswer(option.id)}
      />
      <FormControl>
        <SimpleInput
          variant={'flushed'}
          size='sm'
          value={option.value}
          onChange={(v: string) => updateOptionValue(option.id, v)}
        />
      </FormControl>
      <Box position={'absolute'} right={-4} bottom={1}>
        <RowActionButtons
          onDelete={() => deleteOption(option.id)}
          isFirst={isFirst}
          isLast={isLast}
          onMove={(isUp: boolean) => onMove(isUp)}
        />
      </Box>
    </HStack>
  )
}
export default EditableOption
