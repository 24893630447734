import { Box, HStack, IconButton } from '@chakra-ui/react'
import EditableReachField from 'components/EditableReachField'
import { FC } from 'react'
import { ISyncTransalteParagraph } from 'shared/types'
import { ReactComponent as IconDelete } from 'shared/assets/bsTrash.svg'

type Props = {
  p: ISyncTransalteParagraph
  onChange: (p: ISyncTransalteParagraph) => void
  onDelete: () => void
}

const SyncTranslateParagraph: FC<Props> = ({ p, onChange, onDelete }) => {
  const onLeftSideChange = (v: string) => {
    onChange({ ...p, left: v })
  }

  const onRightSideChange = (v: string) => {
    onChange({ ...p, right: v })
  }

  return (
    <HStack w='full' align='flex-start'>
      <Box flex={1} rounded={'xs'} borderColor='wood.200' borderWidth={1}>
        <EditableReachField
          v={p.left}
          onChange={(v: string) => onLeftSideChange(v)}
        />
      </Box>
      <Box flex={1} rounded={'xs'} borderColor='wood.200' borderWidth={1}>
        <EditableReachField
          v={p.right}
          onChange={(v: string) => onRightSideChange(v)}
        />
      </Box>
      <IconButton
        size='sm'
        colorScheme='red'
        aria-label='delete'
        variant={'ghost'}
        icon={<IconDelete width={16} height={16} />}
        onClick={onDelete}
      />
    </HStack>
  )
}

export default SyncTranslateParagraph
