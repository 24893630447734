import { FC } from 'react'
import { ISectionCard, ISectionColumns, IStyledText } from 'shared/types'
import {
  Box,
  VStack,
  Grid,
  GridItem,
  Divider,
  Circle,
  HStack,
  Text
} from '@chakra-ui/react'
import map from 'lodash/map'
import SectionTextContent from 'shared/components/SectionTextContent'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import CustomButtonRight from 'shared/components/sections/carousel/CustomButtonRight'
import CustomButtonLeft from 'shared/components/sections/carousel/CustomButtonLeft'

type Props = {
  s: ISectionColumns
}

const SectionColumns: FC<Props> = ({ s }) => {
  const renderCard = (c: ISectionCard, i: number) => {
    return (
      <VStack bg='wood.50' p={6} h='full' spacing={6} rounded={'xs'}>
        {s.showNumbers && (
          <HStack w='full' justify={s.numbersAlignment || 'center'}>
            <Circle size={8} borderWidth={1} borderColor='black.950'>
              <Text textStyle={'body'}>{i + 1}</Text>
            </Circle>
          </HStack>
        )}
        {!c.hideTitle && <SectionTextContent r={c.title} key={i} />}
        <VStack w='full' divider={<Divider />} spacing={2}>
          {map(c.data, (r: IStyledText, i: number) => {
            return <SectionTextContent r={r} key={i} />
          })}
        </VStack>
      </VStack>
    )
  }

  const renderCards = () => {
    let amountPerRow = s.columnsAmount || 4
    if (s.columns.length < amountPerRow) amountPerRow = s.columns.length
    return (
      <Grid
        w='full'
        templateColumns={{
          base: `repeat(1, 1fr)`,
          lg: `repeat(${amountPerRow}, 1fr)`
        }}
        rowGap={6}
        columnGap={6}
        justifyItems={'center'}
      >
        {map(s.columns, (c, i) => {
          return (
            <GridItem key={i} h='full' maxW={'600px'} w='full'>
              {renderCard(c, i)}
            </GridItem>
          )
        })}
      </Grid>
    )
  }

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: s.columnsAmount || 3,
      partialVisibilityGutter: 40
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: s.columnsAmount || 3,
      partialVisibilityGutter: 40
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 20
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 30
    }
  }

  const renderCarousel = () => {
    return (
      <Box
        w='full'
        sx={{
          cursor: 'grab !important',
          li: { 'list-style-type': 'none' },
          'li::before': { content: 'none' }
        }}
      >
        <Carousel
          partialVisible
          key={s.columnsAmount + '_' + (s.columns && s.columns.length)}
          responsive={responsive}
          additionalTransfrom={0}
          centerMode={false}
          infinite
          keyBoardControl
          minimumTouchDrag={80}
          slidesToSlide={1}
          swipeable
          customRightArrow={<CustomButtonRight />}
          customLeftArrow={<CustomButtonLeft />}
        >
          {map(s.columns, (c, i) => {
            return (
              <Box key={i} pr={6} h='full'>
                {renderCard(c, i)}
              </Box>
            )
          })}
        </Carousel>
      </Box>
    )
  }

  return (
    <VStack
      minH={10}
      // align={s.alignment}
      w='full'
      spacing={2}
    >
      {s.showCarousel ? renderCarousel() : renderCards()}
    </VStack>
  )
}

export default SectionColumns
