import { VStack, Text, HStack, FormControl } from '@chakra-ui/react'
import { generateId } from 'controllers/db'
import { FC } from 'react'
import SimpleInput from 'shared/components/SimpleInput'
import { ITestContent, ITestOption } from 'shared/types'
import { arrayMove } from 'shared/utils/array'
import map from 'lodash/map'
import EditableOption from 'components/contentSettingsCard/EditableOption'
import findIndex from 'lodash/findIndex'
import size from 'lodash/size'

type Props = {
  test: ITestContent
  onChange: (v: ITestContent) => void
}

const EditTestButtons: FC<Props> = ({ test, onChange }) => {
  const setAnswer = (optionId: string) => {
    onChange({ ...test, answer: optionId })
  }

  const deleteOption = (id: string) => {
    const options = test.options || []
    const newOptions = options.filter(v => v.id !== id)
    onChange({ ...test, options: newOptions })
  }

  const onMove = (i: number, isUp: boolean) => {
    const options = test.options || []
    const newOptions = arrayMove(options, i, isUp ? i - 1 : i + 1)
    onChange({ ...test, options: newOptions })
  }

  const updateOptionValue = (optId: string, v: string) => {
    const options = test.options || []
    const i = findIndex(options, o => o.id === optId)
    const newOptions = [...options]
    newOptions[i].value = v
    onChange({ ...test, options: newOptions })
  }

  const renderOption = (option: ITestOption, i: number) => {
    return (
      <EditableOption
        key={option.id}
        option={option}
        isFirst={i === 0}
        isLast={i === size(test.options) - 1}
        testAnswer={test.answer}
        setAnswer={setAnswer}
        updateOptionValue={updateOptionValue}
        deleteOption={deleteOption}
        onMove={(isUp: boolean) => onMove(i, isUp)}
      />
    )
  }

  const addOption = (v: string) => {
    if (v !== '') {
      const id = generateId()
      const options = test.options || []
      const opt = {
        id,
        value: v
      }
      onChange({ ...test, options: [...options, opt] })
    }
  }

  const renderNewOption = () => {
    return (
      <HStack w='full'>
        <FormControl ml={5}>
          <SimpleInput
            variant={'flushed'}
            placeholder='Добавить вариант'
            size='sm'
            onChange={addOption}
            clearOnApply
          />
        </FormControl>
      </HStack>
    )
  }

  return (
    <VStack w='full' align={'flex-start'} spacing={4}>
      <Text textStyle={'body'}>Выберите верный ответ:</Text>
      <VStack w='full'>{map(test.options, renderOption)}</VStack>
      {renderNewOption()}
    </VStack>
  )
}

export default EditTestButtons
