import {
  HStack,
  Text,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  useDisclosure,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  IconButton,
  FormControl,
  FormLabel,
  Input,
  Slider,
  SliderMark,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Tag,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Switch
} from '@chakra-ui/react'
import AddTextField from 'components/AddTextField'
import { generateId } from 'controllers/db'
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { IDay, IEntityType, ILessonType, IWeek } from 'shared/types'
import _ from 'lodash'
import RowActionButtons from 'components/RowActionButtons'
import { ReactComponent as IconChevronRight } from 'shared/assets/bsChevronRight.svg'
import { arrayMove } from 'shared/utils/array'
import { ReactComponent as IconEdit } from 'shared/assets/bsEdit.svg'
import DeleteConfirmation, {
  IDeleteConfirmationModal
} from 'modals/DeleteConfirmation'
import { COMPLETION_PERC } from 'shared/constants'
import LessonsContext from 'contexts/LessonsContext'
import { percToAmount } from 'shared/utils/tests'
import { dbDuplicateDayTo, duplicateDay } from 'controllers/entity'
import { ReactComponent as IconMove } from 'shared/assets/bsBoxArrowLeft.svg'
import EntitySelectModal, {
  IEntitySelectModal,
  IItem
} from 'modals/EntitySelectModal'
import { useSelector } from 'model/hooks'

type Props = {
  week: IWeek
  updateWeek: (w: IWeek) => void
  selectedDay?: string
  setSelectedDay: (dayId: string) => void
  entityId: string
  entityType: IEntityType
  content: IWeek[]
  moveDay: (dayId: string, toWeekId: string) => void
}

type IEntityBase = {
  id: string
  title: string
  content?: IWeek[]
  createdAt: number
}

const EntityContentWeek: FC<Props> = ({
  week,
  updateWeek,
  selectedDay,
  setSelectedDay,
  entityId,
  entityType,
  content,
  moveDay
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [title, setTitle] = useState(week.title)
  const { lessons } = useContext(LessonsContext)
  const [perc, setPerc] = useState(week.perc || COMPLETION_PERC)
  const deleteModalRef = useRef<IDeleteConfirmationModal>(null)
  const entities = useSelector(state => _.get(state, entityType)) as Record<
    string,
    IEntityBase
  > | null
  const selectModalRef = useRef<IEntitySelectModal>(null)

  const conf = useMemo(() => {
    const sortedEntities = _.orderBy(entities, 'createdAt', 'desc')
    const availableEntities = _.filter(sortedEntities, e => e.id !== entityId)
    return _.map(availableEntities, e => {
      const weeks = _.map(e.content, w => {
        return {
          id: w.id,
          label: w.title
        } as IItem
      })
      return {
        id: e.id,
        label: e.title,
        items: weeks
      } as IItem
    })
  }, [entities, entityId])

  useEffect(() => {
    setTitle(week.title)
  }, [week.title])

  useEffect(() => {
    setPerc(week.perc || COMPLETION_PERC)
  }, [week.perc])

  const updateWeekDays = (days: IDay[]) => {
    const newWeek = { ...week, days }
    updateWeek(newWeek)
  }

  const addDay = (title: string) => {
    const id = generateId()
    const d: IDay = {
      id,
      title,
      lessons: [],
      disabled: true
    }
    updateWeekDays([...week.days, d])
  }

  const onDelete = (id?: string) => {
    if (id) {
      const newDays = _.filter(week.days, d => d.id !== id)
      updateWeekDays(newDays)
    }
  }

  const onMove = (i: number, isUp: boolean) => {
    const newDays = arrayMove(week.days, i, isUp ? i - 1 : i + 1)
    updateWeekDays(newDays)
  }

  const onDuplicate = (dId: string) => {
    duplicateDay(entityId, entityType, week.id, dId)
  }

  const renderMoveMenuList = (dId: string) => {
    return (
      <MenuList textStyle={'small'}>
        {content.map((w, wNum) => {
          return (
            <MenuItem
              key={w.id}
              isDisabled={w.id === week.id}
              onClick={() => moveDay(dId, w.id)}
            >
              {w.title}
            </MenuItem>
          )
        })}
      </MenuList>
    )
  }

  const onToggleDisabled = (dayId: string) => {
    const newDays = _.map(week.days, (d, index) => {
      if (d.id === dayId) {
        return { ...d, disabled: !d.disabled }
      } else {
        return d
      }
    })
    updateWeekDays(newDays)
  }

  const renderDay = (d: IDay, i: number) => {
    return (
      <HStack
        key={d.id}
        _hover={{ bg: 'wood.100' }}
        role='group'
        w='full'
        rounded={'4px'}
        pl={2}
        pr={1}
        py={1}
        bg={selectedDay === d.id ? 'wood.100' : undefined}
        as='button'
        onClick={() =>
          selectedDay !== d.id ? setSelectedDay(d.id) : undefined
        }
      >
        <VStack w='full' spacing={1} align='flex-start'>
          <Text textStyle={'small'} textAlign={'start'}>
            {d.title}
          </Text>
          <HStack spacing={4}>
            <Text textStyle={'tag'} color='darkGray'>
              Лекций:{' '}
              {_.size(
                _.filter(
                  d.lessons,
                  lId => _.get(lessons, [lId, 'type']) !== ILessonType.TEST
                )
              )}
            </Text>
            <Text textStyle={'tag'} color='darkGray'>
              Tecтов:{' '}
              {_.size(
                _.filter(
                  d.lessons,
                  lId => _.get(lessons, [lId, 'type']) === ILessonType.TEST
                )
              )}
            </Text>
          </HStack>
        </VStack>
        <Switch
          size='sm'
          colorScheme='wood'
          isChecked={_.get(d, 'disabled', true)}
          onChange={() => onToggleDisabled(d.id)}
        />
        <RowActionButtons
          isFirst={i === 0}
          isLast={i === week.days.length - 1}
          onMove={(isUp: boolean) => onMove(i, isUp)}
          onDuplicate={() => onDuplicate(d.id)}
          onDelete={() => {
            console.log('on Delete click')
            deleteModalRef.current?.open(d.id)
          }}
        >
          <Menu placement='auto-start'>
            <MenuButton w='full'>
              <MenuItem
                textStyle={'small'}
                icon={<IconMove width={14} />}
                w='full'
              >
                Перенести
              </MenuItem>
            </MenuButton>
            {renderMoveMenuList(d.id)}
          </Menu>
          <MenuItem
            textStyle={'small'}
            icon={<IconMove width={14} />}
            w='full'
            onClick={() => {
              console.log('on move to item click')
              selectModalRef.current?.open(d.id)
            }}
          >
            Перенести в
          </MenuItem>
        </RowActionButtons>
        <IconChevronRight />
      </HStack>
    )
  }

  const renderDays = () => {
    return (
      <VStack spacing={1} w='full' pt={2}>
        {_.map(week.days, renderDay)}
      </VStack>
    )
  }

  const renderHeader = () => {
    console.log('perc', perc)
    const l = _.size(week.days)
    return (
      <HStack w='full' justify={'space-between'}>
        <VStack align={'flex-start'} spacing={1}>
          <Text textStyle='small' fontWeight={700} textAlign={'start'}>
            {week.title}
          </Text>
          <Tag colorScheme='teal' size='sm'>
            {l === 0 ? '0 / 0' : `${percToAmount(l, perc)} / ${l}`}
          </Tag>
        </VStack>
        <IconButton
          aria-label='edit'
          size='xs'
          icon={<IconEdit width='14' />}
          onClick={() => {
            setTitle(week.title)
            onOpen()
          }}
        />
      </HStack>
    )
  }

  const apply = () => {
    onClose()
    updateWeek({ ...week, title, perc })
  }

  const onMoveTo = async (dayId: string, pth: string[]) => {
    console.log('onMoveTo', pth)
    await dbDuplicateDayTo(pth[0], entityType, pth[1], entityId, week.id, dayId)
  }

  const renderEditModal = () => {
    const labelStyles = {
      mt: '2',
      ml: '-2.5',
      fontSize: 'sm'
    }
    const l = _.size(week.days)
    return (
      <Modal isOpen={isOpen} onClose={onClose} size='xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{'Настройки недели'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={8}>
              <FormControl>
                <FormLabel size='sm'>Название</FormLabel>
                <Input
                  size='sm'
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                  variant={'flushed'}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Процент выполнения</FormLabel>
                <Slider
                  aria-label='slider-ex-6'
                  value={perc}
                  onChange={val => setPerc(val)}
                  mt={8}
                >
                  <SliderMark value={25} {...labelStyles}>
                    25%
                  </SliderMark>
                  <SliderMark value={50} {...labelStyles}>
                    50%
                  </SliderMark>
                  <SliderMark value={75} {...labelStyles}>
                    75%
                  </SliderMark>
                  <SliderMark
                    value={perc}
                    textAlign='center'
                    textStyle={'small'}
                    bg='blue.500'
                    color='white'
                    mt='-10'
                    ml='-5'
                    w='24'
                  >
                    {l === 0 ? '0 / 0' : `${percToAmount(l, perc)} / ${l}`} -{' '}
                    {perc}%
                  </SliderMark>
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter pt={12}>
            <Button
              variant='solid'
              size='sm'
              colorScheme='teal'
              onClick={apply}
            >
              Сохранить
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }

  return (
    <VStack flex={1} p={4} align={'flex-start'}>
      {renderHeader()}
      {renderDays()}
      <VStack w='full' pt={4} align='flex-start'>
        <AddTextField
          buttonTitle='+ Добавить урок'
          placeholder='Название урока'
          onSubmit={addDay}
        />
      </VStack>
      <DeleteConfirmation
        ref={deleteModalRef}
        title='Удаление урока'
        description={`Вы собираетесь удалить урок и все ее содержимое. Это действие нельзя отменить.`}
        onDelete={onDelete}
      />
      {renderEditModal()}
      <EntitySelectModal conf={conf} onApply={onMoveTo} ref={selectModalRef} />
    </VStack>
  )
}

export default EntityContentWeek
