import { FormControl, FormLabel, Input } from '@chakra-ui/react'
import { FC } from 'react'

type Props = {
  label: string
  value: string
  onChange: (v: string) => void
}

const InputField: FC<Props> = ({ label, value, onChange }) => {
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <Input
        variant={'flushed'}
        size='sm'
        value={value}
        onChange={e => onChange(e.target.value)}
      />
    </FormControl>
  )
}

export default InputField
