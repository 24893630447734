import { Unsubscribe } from 'firebase/firestore'
import get from 'lodash/get'
import isFunction from 'lodash/isFunction'
import isNil from 'lodash/isNil'
import has from 'lodash/has'

// import { UserT } from 'shared/types/model'

interface Listeners {
  [key: string]: Unsubscribe
}

let listeners: Listeners = {}

// export let existingUser: UserT | null = null

// export const setExistingUser = (user: UserT | null) => {
//   existingUser = user
// }

export function addListener (key: string, l: Unsubscribe): void {
  const existingUnsubscribe = get(listeners, key)
  if (isFunction(existingUnsubscribe)) existingUnsubscribe()
  listeners[key] = l
}

export function hasListener (key: string): boolean {
  return has(listeners, key)
}

export function clearListeners (): void {
  console.log('clear listeners:')
  // setExistingUser(undefined)
  for (const key in listeners) {
    console.log('clear listener', key)
    const l = get(listeners, key)
    // console.log('off:', l)
    !isNil(l) && l()
  }

  listeners = {}
}

export function clearListenersExceptUser (): void {
  console.log('clear listeners except user:')
  // setExistingUser(undefined)
  for (const key in listeners) {
    console.log('clear listener', key)
    if (key !== 'user') {
      const l = get(listeners, key)
      // console.log('off:', l)
      !isNil(l) && l()
    }
  }

  listeners = {}
}

export function offListener (key: string): void {
  const l = get(listeners, key)
  if (isFunction(l)) {
    // l.off()
    l()
    delete listeners[key]
  }
}
