import {
  HStack,
  Text,
  VStack,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  useDisclosure,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  IconButton,
  FormControl,
  FormLabel,
  Input,
  Slider,
  SliderMark,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Tag,
  MenuItem,
  Menu,
  MenuButton,
  MenuList,
  MenuGroup,
  Divider
} from '@chakra-ui/react'
import { generateId } from 'controllers/db'
import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { IDay, IEntityType, ILesson, IWeek, ILessonType } from 'shared/types'
import _ from 'lodash'
import RowActionButtons from 'components/RowActionButtons'
import { arrayMove } from 'shared/utils/array'
import { ReactComponent as IconEdit } from 'shared/assets/bsEdit.svg'
import DeleteConfirmation, {
  IDeleteConfirmationModal
} from 'modals/DeleteConfirmation'
import { COMPLETION_PERC } from 'shared/constants'
import { dbCreateLesson, dbDeleteLesson } from 'controllers/lessons'
import LessonsContext from 'contexts/LessonsContext'
import { useNavigate } from 'react-router-dom'
import { percToAmount } from 'shared/utils/tests'
import { dbDuplicateLessonTo, duplicateLesson } from 'controllers/entity'
import { ReactComponent as IconMove } from 'shared/assets/bsBoxArrowLeft.svg'
import { ReactComponent as IconChevronDown } from 'shared/assets/bsChevronDown.svg'
import { defaultExaminationSettings } from 'shared/constants/examination'
import get from 'lodash/get'
import map from 'lodash/map'
import EntitySelectModal, {
  IEntitySelectModal,
  IItem
} from 'modals/EntitySelectModal'
import { useSelector } from 'model/hooks'

type Props = {
  entityId: string
  entityType: IEntityType
  weekId: string
  day: IDay
  updateDay: (d: IDay) => void
  content: IWeek[]
  moveLesson: (lessonId: string, toWeekId: string, toDayId: string) => void
}

type IEntityBase = {
  id: string
  title: string
  content?: IWeek[]
  createdAt: number
}

const EntityContentDay: FC<Props> = ({
  entityType,
  entityId,
  weekId,
  day,
  updateDay,
  content,
  moveLesson
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [title, setTitle] = useState(day.title)
  const [perc, setPerc] = useState(day.perc || COMPLETION_PERC)
  const deleteModalRef = useRef<IDeleteConfirmationModal>(null)
  const selectModalRef = useRef<IEntitySelectModal>(null)
  const { lessons } = useContext(LessonsContext)
  const entities = useSelector(state => get(state, entityType)) as Record<
    string,
    IEntityBase
  > | null
  const navigate = useNavigate()

  useEffect(() => {
    setTitle(day.title)
  }, [day.title])

  useEffect(() => {
    setPerc(day.perc || COMPLETION_PERC)
  }, [day.perc])

  const conf = useMemo(() => {
    const sortedEntities = _.orderBy(entities, 'createdAt', 'desc')
    const availableEntities = _.filter(sortedEntities, e => e.id !== entityId)
    return map(availableEntities, e => {
      const weeks = map(e.content, w => {
        const days = map(w.days, d => {
          return {
            id: d.id,
            label: d.title
          } as IItem
        })
        return {
          id: w.id,
          label: w.title,
          items: days
        } as IItem
      })
      return {
        id: e.id,
        label: e.title,
        items: weeks
      } as IItem
    })
  }, [entities, entityId])

  const updateDayLessons = (lessons: string[]) => {
    const newDay = { ...day, lessons }
    updateDay(newDay)
  }

  const addLesson = (name: string, lessonType: ILessonType) => {
    const id = generateId()
    const newL: ILesson = {
      id,
      name,
      entityId,
      type: lessonType
    }
    if (lessonType === ILessonType.EXAMINATION) {
      newL.examination = { ...defaultExaminationSettings }
    }
    dbCreateLesson(id, newL)
    updateDayLessons([...day.lessons, id])
  }

  const onDelete = (lessonId?: string) => {
    if (lessonId) {
      const newLessons = _.filter(day.lessons, lId => lId !== lessonId)
      updateDayLessons(newLessons)
      dbDeleteLesson(lessonId)
    }
  }

  const onMove = (i: number, isUp: boolean) => {
    const newLessons = arrayMove(day.lessons, i, isUp ? i - 1 : i + 1)
    updateDayLessons(newLessons)
  }

  const onDuplicate = (lId: string) => {
    duplicateLesson(entityId, entityType, weekId, day.id, lId)
  }

  const renderMoveMenuList = (lId: string) => {
    return (
      <MenuList textStyle={'small'} overflowY='auto' maxH='lg'>
        {content.map((w, wNum) => {
          return (
            <MenuGroup title={w.title} key={w.id} ml={2}>
              {w.days &&
                w.days.map((d, dayNum) => {
                  return (
                    <MenuItem
                      pl={4}
                      key={d.id}
                      isDisabled={d.id === day.id}
                      onClick={() => moveLesson(lId, w.id, d.id)}
                    >
                      {d.title}
                    </MenuItem>
                  )
                })}
            </MenuGroup>
          )
        })}
      </MenuList>
    )
  }

  const lessonColor = (l: ILesson) => {
    switch (l.type) {
      case ILessonType.LECTURE:
        return 'gray.700'
      case ILessonType.TEST:
        return 'wood.600'
      case ILessonType.EXAMINATION:
        return 'red.800'
    }
  }

  const renderLesson = (lId: string, i: number) => {
    const l = _.get(lessons, lId)
    if (l) {
      const c = lessonColor(l)
      return (
        <HStack key={lId} role='group' w='full' pl={0} pr={1}>
          <Flex
            py={1}
            w='full'
            _hover={{
              cursor: 'pointer',
              bg: 'wood.100'
            }}
            justify={'space-between'}
            // mr={16}
            as='button'
            rounded={'4px'}
            pl={2}
            pr={1}
            onClick={() => navigate(`/${entityType}/${entityId}/${lId}`)}
          >
            <Text textStyle={'small'} color={c}>
              {l.name}
            </Text>
            <Tag size='sm' color={c}>
              {l.type}
            </Tag>
          </Flex>
          <RowActionButtons
            isFirst={i === 0}
            isLast={i === day.lessons.length - 1}
            onMove={(isUp: boolean) => onMove(i, isUp)}
            onDuplicate={() => onDuplicate(l.id)}
            onDelete={() => {
              console.log('on Delete click')
              deleteModalRef.current?.open(lId)
            }}
          >
            <Menu placement='auto-start'>
              <MenuButton w='full'>
                <MenuItem
                  textStyle={'small'}
                  icon={<IconMove width={14} />}
                  w='full'
                >
                  Перенести
                </MenuItem>
              </MenuButton>
              {renderMoveMenuList(lId)}
            </Menu>

            <MenuItem
              textStyle={'small'}
              icon={<IconMove width={14} />}
              w='full'
              onClick={() => {
                console.log('on move to item click')
                selectModalRef.current?.open(lId)
              }}
            >
              Перенести в
            </MenuItem>
          </RowActionButtons>
        </HStack>
      )
    }
  }

  const renderLessons = () => {
    return (
      <VStack spacing={1} w='full' pt={2}>
        {_.map(day.lessons, renderLesson)}
      </VStack>
    )
  }

  const renderHeader = () => {
    const l = _.size(
      _.filter(
        day.lessons,
        lId => _.get(lessons, [lId, 'type']) === ILessonType.TEST
      )
    )
    return (
      <HStack w='full' justify={'space-between'}>
        <VStack align={'flex-start'} spacing={1}>
          <Text textStyle='small' fontWeight={700} textAlign={'start'}>
            {day.title}
          </Text>
          <Tag colorScheme='teal' size='sm'>
            {l === 0 ? '0 / 0' : `${percToAmount(l, perc)} / ${l}`}
          </Tag>
        </VStack>
        <IconButton
          aria-label='edit'
          size='xs'
          icon={<IconEdit width='14' />}
          onClick={() => {
            setTitle(day.title)
            onOpen()
          }}
        />
      </HStack>
    )
  }

  const onMoveTo = async (lessonId: string, pth: string[]) => {
    console.log('onMoveTo', pth)
    const success = await dbDuplicateLessonTo(
      pth[0],
      entityType,
      pth[1],
      pth[2],
      entityId,
      weekId,
      day.id,
      lessonId
    )
    console.log('onMoveTo success', success)
  }

  const apply = () => {
    onClose()
    updateDay({ ...day, title, perc })
  }

  const renderEditModal = () => {
    const labelStyles = {
      mt: '2',
      ml: '-2.5',
      fontSize: 'sm'
    }
    const l = _.size(
      _.filter(
        day.lessons,
        lId => _.get(lessons, [lId, 'type']) === ILessonType.TEST
      )
    )
    return (
      <Modal isOpen={isOpen} onClose={onClose} size='xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{'Настройки урока'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={8}>
              <FormControl>
                <FormLabel size='sm'>Название</FormLabel>
                <Input
                  size='sm'
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                  variant={'flushed'}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Процент выполнения</FormLabel>
                <Slider
                  aria-label='slider-ex-6'
                  value={perc}
                  onChange={val => setPerc(val)}
                  mt={8}
                >
                  <SliderMark value={25} {...labelStyles}>
                    25%
                  </SliderMark>
                  <SliderMark value={50} {...labelStyles}>
                    50%
                  </SliderMark>
                  <SliderMark value={75} {...labelStyles}>
                    75%
                  </SliderMark>
                  <SliderMark
                    value={perc}
                    textAlign='center'
                    textStyle={'small'}
                    bg='blue.500'
                    color='white'
                    mt='-10'
                    ml='-5'
                    w='24'
                  >
                    {l === 0 ? '0 / 0' : `${percToAmount(l, perc)} / ${l}`} -{' '}
                    {perc}%
                  </SliderMark>
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter pt={12}>
            <Button
              variant='solid'
              size='sm'
              colorScheme='teal'
              onClick={apply}
            >
              Сохранить
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }

  const renderAddLessonMenu = () => {
    return (
      <Menu>
        <MenuButton
          as={Button}
          size='sm'
          variant={'ghost'}
          colorScheme='black'
          rightIcon={<IconChevronDown width={12} />}
          px={3}
        >
          Add
        </MenuButton>
        <MenuList textStyle={'small'}>
          <MenuItem onClick={() => addLesson('Лекция', ILessonType.LECTURE)}>
            Lecture
          </MenuItem>
          <MenuItem onClick={() => addLesson('Тест', ILessonType.TEST)}>
            Test
          </MenuItem>
          <MenuItem
            onClick={() => addLesson('Экзамен', ILessonType.EXAMINATION)}
          >
            Examination
          </MenuItem>
          <MenuItem
            onClick={() => addLesson('Словарь', ILessonType.DICTIONARY)}
          >
            Dictionary
          </MenuItem>
        </MenuList>
      </Menu>
    )
  }

  return (
    <VStack flex={1} p={4} align={'flex-start'}>
      {renderHeader()}
      {renderLessons()}
      <Divider />
      {renderAddLessonMenu()}
      <DeleteConfirmation
        ref={deleteModalRef}
        title='Удаление урока'
        description={`Вы собираетесь удалить урок и все ее содержимое. Это действие нельзя отменить.`}
        onDelete={onDelete}
      />
      {renderEditModal()}
      <EntitySelectModal conf={conf} onApply={onMoveTo} ref={selectModalRef} />
    </VStack>
  )
}

export default EntityContentDay
