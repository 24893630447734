import { generateId } from 'controllers/db'
import {
  SectionType,
  ISection,
  ISectionHeading,
  ISectionTextList,
  ISectionColumns,
  ISectionColumnsVideo,
  IVideo,
  ISectionColumnsImage,
  ISectionButton,
  ISectionTeachers,
  ISectionSyncTranslate
} from 'shared/types'

export const createSection = (sType: SectionType) => {
  const s = {
    id: generateId(),
    type: sType,
    widthCol: 12
  } as ISection
  switch (s.type) {
    case SectionType.SYNC_TRANSLATE: {
      return {
        ...s,
        paragraphs: [],
        widthCol: 12
      } as ISectionSyncTranslate
    }
    case SectionType.TEACHERS: {
      return {
        ...s,
        teachers: [],
        widthCol: 12
      } as ISectionTeachers
    }
    case SectionType.BUTTON: {
      return {
        ...s,
        button: {
          title: 'Кнопка',
          alignment: 'center'
        },

        widthCol: 12
      } as ISectionButton
    }
    case SectionType.HEADING: {
      return {
        ...s,
        data: [
          {
            text: 'Заголовок',
            textStyle: 'h1'
          }
        ],
        alignment: 'center',
        widthCol: 12
      } as ISectionHeading
    }
    case SectionType.TEXT_LIST: {
      return {
        ...s,
        title: {
          text: 'Заголовок',
          textStyle: 'h3'
        },
        data: [
          {
            text: 'Строка 1',
            textStyle: 'body'
          }
        ],
        alignment: 'start',
        widthCol: 10
      } as ISectionTextList
    }
    case SectionType.COLUMNS: {
      return {
        ...s,
        columns: [
          {
            title: {
              text: 'Заголовок',
              textStyle: 'h3'
            },
            data: [
              {
                text: 'Строка',
                textStyle: 'body'
              }
            ]
          }
        ]
      } as ISectionColumns
    }
    case SectionType.COLUMNS_VIDEO: {
      return {
        ...s,
        columns: [] as IVideo[]
      } as ISectionColumnsVideo
    }
    case SectionType.COLUMNS_IMAGE: {
      return {
        ...s,
        columns: [] as string[]
      } as ISectionColumnsImage
    }
    case SectionType.TWO_COLUMNS_TEXT: {
      s.title = 'Заголовок'
      break
    }
    case SectionType.TWO_COLUMNS_VIMEO: {
      s.title = 'Заголовок'
      break
    }
    case SectionType.TEST_ONE_OPTION: {
      s.test = {
        options: []
      }
      break
    }
    case SectionType.TEST_SYNC_TRANSLATE: {
      s.test = {
        syncTranslate: []
      }
      break
    }
  }
  return s
}
