import ReachEditor from 'components/contentSettingsCard/ReachEditor'
import { FC } from 'react'
import { HStack, Select, Text, VStack } from '@chakra-ui/react'
import { ITextStyle } from 'shared/types'

type Props = {
  v: string
  onChange: (v: string) => void
  textStyle?: ITextStyle
  onTextStyleChange?: (v: ITextStyle) => void
  label?: string
}

const EditableReachField: FC<Props> = ({
  onChange,
  v,
  onTextStyleChange,
  textStyle = 'body',
  label
}) => {
  return (
    <VStack w='full' spacing={1} align={'start'}>
      {label && (
        <Text textStyle='small' color='black.950'>
          {label}
        </Text>
      )}
      <HStack
        w='full'
        py={1}
        minH={8}
        align={'end'}
        spacing={1}
        color='black.950'
        bg='wood.50'
      >
        <ReachEditor value={v} onChange={(v: string) => onChange(v)} />
        {onTextStyleChange && (
          <HStack flexShrink={0}>
            <Select
              size='xs'
              colorScheme='gray'
              value={textStyle}
              onChange={e => onTextStyleChange(e.target.value as ITextStyle)}
              variant={'filled'}
              alignItems={'center'}
              fontWeight={700}
              fontSize={'10px'}
            >
              <option value='h1'>H1</option>
              <option value='h2'>H2</option>
              <option value='h3'>H3</option>
              <option value='body'>body</option>
              <option value='small'>small</option>
              <option value='tag'>tag</option>
            </Select>
          </HStack>
        )}
      </HStack>
    </VStack>
  )
}

export default EditableReachField
