import { useState, useEffect } from 'react'
import {
  HStack,
  VStack,
  Text,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Button
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import NavBar from 'components/NavBar'
import { useSelector } from 'model/hooks'
import isEmpty from 'lodash/isEmpty'
import { IEntityType, IUserPayment } from 'shared/types'
import dayjs, { Dayjs } from 'dayjs'
import get from 'lodash/get'
import MonthPicker from 'components/MonthPicker'
import { dbGetPaymentsByDate } from 'controllers/userPayments'
import { getEntities } from 'model/selectors/entities'

const Payments = () => {
  const navigate = useNavigate()
  const [startTime, setStartTime] = useState(+dayjs())
  const [payments, setPayments] = useState<IUserPayment[]>([])
  const entities = useSelector(getEntities)

  useEffect(() => {
    setStartTime(+dayjs().date(1).hour(0).minute(0).second(0).millisecond(0))
  }, [])

  useEffect(() => {
    const run = async () => {
      const monthPayments = await dbGetPaymentsByDate(
        startTime,
        +dayjs(startTime).add(1, 'month')
      )
      setPayments(monthPayments)
    }
    run()
  }, [startTime])

  const stringEntityType = (t: IEntityType) => {
    switch (t) {
      case IEntityType.CONFERENCES:
        return 'Конференция'
      case IEntityType.COURSES:
        return 'Курс'
      case IEntityType.LIBRARY:
        return 'Курс библиотеки'
      case IEntityType.SEMINARS:
        return 'Семинар'
      case IEntityType.WEBINARS:
        return 'Вебинар'
    }
  }

  const renderPayments = () => {
    if (isEmpty(payments)) {
      return (
        <HStack w='full' justify={'center'} pt='20'>
          <Text>За выбраный месяц нет платежей</Text>
        </HStack>
      )
    } else {
      return (
        <TableContainer
          w='full'
          bg='white'
          rounded={'md'}
          textStyle={'body'}
          overflowY='auto'
        >
          <Table size={'sm'}>
            <Thead position={'sticky'} top={0} zIndex={2} bg='white'>
              <Tr>
                <Th>Имя</Th>
                <Th>Email</Th>
                <Th>Дата</Th>
                <Th>Название</Th>
                <Th>Цена</Th>
                <Th>ID</Th>
              </Tr>
            </Thead>
            <Tbody>
              {payments.map(p => {
                return (
                  <Tr>
                    <Td>
                      <Button
                        size='sm'
                        variant='link'
                        onClick={() => navigate(`/users/${p.userId}`)}
                      >
                        <Text textStyle={'small'} color='black'>
                          {p.name}
                        </Text>
                      </Button>
                    </Td>
                    <Td>
                      <Button
                        size='sm'
                        variant='link'
                        onClick={() => navigate(`/users/${p.userId}`)}
                      >
                        <Text textStyle={'small'} color='black'>
                          {p.email}
                        </Text>
                      </Button>
                    </Td>
                    <Td>{dayjs(p.createdAt).format('D MMMM, HH:mm')}</Td>

                    <Td>
                      <Button
                        size='sm'
                        variant='link'
                        onClick={() => navigate(`/${p.type}/${p.entityId}`)}
                      >
                        <Text color='darkGray' as='span' textStyle={'small'}>
                          {stringEntityType(p.type as IEntityType)}:
                        </Text>
                        <Text
                          color='black'
                          as='span'
                          textStyle={'small'}
                          pl={2}
                        >
                          {get(entities, [p.entityId, 'title'], '')}
                        </Text>
                      </Button>
                    </Td>
                    <Td>{p.price}</Td>
                    <Td textStyle='tag' fontSize={'xs'} color='darkGray'>
                      {p.id}
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )
    }
  }

  const navBarActions = (
    <HStack>
      <MonthPicker
        value={dayjs(startTime)}
        onSelect={(v: Dayjs) => setStartTime(+v)}
      />
    </HStack>
  )

  return (
    <VStack w='full' h='full' spacing={0} overflowY={'hidden'}>
      <NavBar hideBackButton title='Покупки'>
        {navBarActions}
      </NavBar>
      <VStack align={'flex-start'} w='full' h='full' p={4} overflowY={'auto'}>
        {renderPayments()}
      </VStack>
    </VStack>
  )
}

export default Payments
