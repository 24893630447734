import { Box, Center, Spinner, Text, VStack } from '@chakra-ui/react'
import { useAudioRecorder, RECORD_STATUS } from '@sarafhbk/react-audio-recorder'
import { FC, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { FileT, StorageFileT, StorageT } from 'shared/types'
import ceil from 'lodash/ceil'

type Props = {
  onComplete: (files: FileT[]) => void
  storagePath: string
  userId: string
  storage: StorageT
  generateId: () => string
  addPrefixToFilename?: boolean
  isLoading?: boolean
}

const Recorder: FC<Props> = ({
  onComplete,
  storagePath,
  userId,
  storage,
  generateId,
  isLoading
}) => {
  const {
    audioResult,
    timer,
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    status,
    errorMessage
  } = useAudioRecorder()

  const [progress, setProgress] = useState<number | undefined>()

  const processResult = async (blobUrl: string) => {
    const id = generateId()
    const fname = `${id}.wav`
    const p = `${storagePath}/${fname}`
    const onProgress = (v: number) => {
      console.log('onProgress', v)
      setProgress(ceil(v * 100))
    }
    const fileInfo: StorageFileT | undefined = await storage.saveFile(
      p,
      blobUrl,
      onProgress
    )
    const pFile: FileT = {
      id,
      createdAt: +dayjs(),
      name: fname,
      createdBy: userId,
      ...(fileInfo || {})
    }
    setProgress(undefined)
    if (pFile) {
      onComplete([pFile])
    }
  }

  useEffect(() => {
    console.log('audioResult', audioResult)
    if (status === RECORD_STATUS.IDLE && audioResult) {
      processResult(audioResult)
    }
  }, [status])

  const renderButton = () => {
    const loading = isLoading || progress !== undefined
    const color = loading ? 'gray.300' : 'red.700'
    if (status === RECORD_STATUS.IDLE) {
      return (
        <Center
          w={10}
          h={10}
          borderColor={color}
          borderWidth={1}
          rounded={'full'}
          as='button'
          onClick={startRecording}
        >
          {loading ? (
            <Spinner color={color} size='md' />
          ) : (
            <Box bg={color} rounded='full' w={8} h={8} />
          )}
        </Center>
      )
    } else if (status === RECORD_STATUS.RECORDING) {
      return (
        <Center
          w={10}
          h={10}
          borderColor={color}
          borderWidth={1}
          rounded={'full'}
          as='button'
          onClick={stopRecording}
        >
          <Box bg={color} rounded='2px' w={4} h={4} />
        </Center>
      )
    }
  }

  return (
    <VStack spacing={2}>
      <Text textStyle={'small'} fontWeight={'semibold'} color='gray.600'>
        Record
      </Text>
      <VStack spacing={1}>
        {renderButton()}
        <Text
          textStyle={'small'}
          color={status === RECORD_STATUS.RECORDING ? 'black' : 'gray.400'}
        >
          {dayjs(timer * 1000).format('mm:ss')}
        </Text>
      </VStack>
    </VStack>
  )
}

export default Recorder
