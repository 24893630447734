import { FC, useState } from 'react'
import { Box, HStack, Text, VStack } from '@chakra-ui/react'
import { FileT, IPadding, ISectionAudio } from 'shared/types'
import EditableReachField from 'components/EditableReachField'
import PaddingSelect from 'components/PaddingSelect'
import Label from 'shared/components/Label'
import FilesPickerButton from 'components/FilesPickerButton'
import { generateId, storageRef } from 'controllers/db'
import AudioPlayer from 'shared/components/AudioPlayer'
import storage from 'controllers/storage'
import { dbProcessAudioFile } from 'controllers/files'
import Recorder from 'components/Recorder'

type Props = {
  file?: FileT
  onChange: (f: FileT) => void
}

const EditCardAudio: FC<Props> = ({ file, onChange }) => {
  const [loading, setLoading] = useState(false)

  const onAudioFileUploaded = async (files: FileT[]) => {
    console.log('on audio file uploaded', files)
    if (files.length > 0) {
      const fRaw = files[0]
      setLoading(true)
      const f = await dbProcessAudioFile(fRaw)
      setLoading(false)
      console.log('the file after processing', f)
      onChange(f)
    }
  }

  const renderAudioInput = () => {
    return (
      <HStack w='full' h='156px' spacing={6}>
        <VStack align={'center'} h='full' justify={'space-between'}>
          <Recorder
            onComplete={onAudioFileUploaded}
            storagePath={'/audio/cards'}
            userId=''
            storage={storage}
            generateId={generateId}
            addPrefixToFilename
            isLoading={loading}
          />
          <Text textStyle={'tag'} color='gray.400'>
            or
          </Text>
          <FilesPickerButton
            onComplete={onAudioFileUploaded}
            storagePath={'/audio/cards'}
            userId=''
            buttonTitle='Загрузить аудио файл'
            storage={storage}
            generateId={generateId}
            options={{
              maxFiles: 1,
              accept: { 'audio/*': ['.mp3', '.ogg', '.wav'] }
            }}
            isPrivate={false}
            addPrefixToFilename
            isLoading={loading}
          />
        </VStack>

        {file && file.url && <AudioPlayer key={file.url} url={file.url} />}
      </HStack>
    )
  }

  return (
    <VStack w='full' spacing={6} align={'start'}>
      <Label>Audio</Label>
      {renderAudioInput()}
    </VStack>
  )
}

export default EditCardAudio
