import { db, dbOmit } from 'controllers/db'
import {
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where
} from 'firebase/firestore'
import _ from 'lodash'
import { appendWordCard, receiveWordCards } from 'model/actions'
import store from 'model/store'
import { IWordCard } from 'shared/types'

export const dbGetWordCards = async (cardIds: string[]) => {
  try {
    const chunks = _.chunk(cardIds, 10)
    const promises = _.map(chunks, async ids => {
      const q = query(collection(db, 'cards'), where(documentId(), 'in', ids))
      const sn = await getDocs(q)
      return sn.docs
    })
    const resAr = await Promise.all(promises)
    const docs = _.flatten(resAr)
    const cards = _.keyBy(
      _.map(docs, doc => ({ ...doc.data(), id: doc.id } as IWordCard)),
      'id'
    )
    store.dispatch(receiveWordCards(cards))
  } catch (e) {
    console.error('dbGetLessonsById error', e)
    return null
  }
}

export const dbSubscribeToRecentlyAdded = (
  lang: string,
  callback: (cards: IWordCard[]) => void
) => {
  try {
    const q = query(
      collection(db, 'cards'),
      where('lang', '==', lang),
      orderBy('createdAt', 'desc'),
      limit(20)
    )
    const unsubscribe = onSnapshot(
      q,
      sn => {
        callback(_.map(sn.docs, doc => doc.data() as IWordCard))
      },
      err => {
        console.log(`dbSubscribeToRecentlyAdded error: ${err.message}`)
      }
    )
    return unsubscribe
  } catch (e) {
    console.error('dbSubscribeToRecentlyAdded error', e)
    return null
  }
}

export const dbAppendWordCard = async (cardId: string) => {
  try {
    const cardSN = await getDoc(doc(collection(db, 'cards'), cardId))
    const card = cardSN.data() as IWordCard | undefined
    if (card) {
      store.dispatch(appendWordCard(card))
    } else {
      console.warn(`card ${cardId} is not found`)
    }
  } catch (e) {
    console.error('dbAppendWordCard error', e)
    return null
  }
}

export const dbFetchWordCard = async (cardId: string) => {
  try {
    const cardSN = await getDoc(doc(collection(db, 'cards'), cardId))
    const card = cardSN.data() as IWordCard | undefined
    return card
  } catch (e) {
    console.error('dbAppendWordCard error', e)
    return null
  }
}

export const dbSubscribeToCard = (
  cardId: string,
  callback: (card?: IWordCard) => void
) => {
  try {
    const ref = doc(db, 'cards', cardId)
    const unsubscribe = onSnapshot(
      ref,
      sn => {
        callback(sn.data() as IWordCard | undefined)
      },
      err => {
        console.log(`dbSubscribeToCard error: ${err.message}`)
      }
    )
    return unsubscribe
  } catch (e) {
    console.error('dbSubscribeToCard error', e)
    return null
  }
}

export const dbCreateCard = async (card: IWordCard) => {
  try {
    const ref = doc(collection(db, 'cards'), card.id)
    await setDoc(ref, dbOmit(card))
    return true
  } catch (e) {
    console.log('dbCreateCard error', e)
    return false
  }
}

export const dbUpdateCard = async (cardId: string, upd: Partial<IWordCard>) => {
  try {
    console.log('dbUpdateCard', cardId, upd)
    const ref = doc(collection(db, 'cards'), cardId)
    await updateDoc(ref, dbOmit(upd))
  } catch (e) {
    console.log('dbUpdateCard error', e)
  }
}
