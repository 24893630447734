import { VStack } from '@chakra-ui/react'
import { DatePickerInput } from 'chakra-datetime-picker'
import dayjs from 'dayjs'
import { FC } from 'react'
import Label from 'shared/components/Label'

type Props = {
  label: string
  value?: number
  onChange: (v?: number) => void
}

const DateField: FC<Props> = ({ label, value, onChange }) => {
  return (
    <VStack w={'full'} spacing={1} align={'flex-start'}>
      <Label>{label}</Label>
      <DatePickerInput
        currentLangKey='en'
        colorScheme='gray'
        format='YYYY-MM-DD'
        size='sm'
        wrapPortal={false}
        placement='left-start'
        inputProps={{ variant: 'flushed', sx: { div: { zIndex: 54321 } } }}
        disableTimestampBefore={0}
        value={value ? dayjs(value) : undefined}
        onChange={(str, d) => {
          onChange(d ? +d : undefined)
        }}
      />
    </VStack>
  )
}

export default DateField
