import {
  onSnapshot,
  query,
  collection,
  doc,
  updateDoc,
  setDoc,
  writeBatch,
  deleteDoc
} from 'firebase/firestore'
import { db, dbOmit, generateId } from 'src/controllers/db'
import {
  ICourse,
  ICoursesPack,
  IDay,
  IEntityType,
  IFree,
  ILesson,
  IWeek
} from 'shared/types'
import { receiveCoursesPacks } from 'src/model/actions'
import store from 'src/model/store'
import { addListener } from 'controllers/listeners'
import dayjs from 'dayjs'
import forEach from 'lodash/forEach'

export const dbFetchCoursesPacks = async () => {
  try {
    const q = query(collection(db, 'coursesPacks'))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: Record<string, ICoursesPack> = {}
        sn.forEach(doc => {
          const p = doc.data() as ICoursesPack
          res[doc.id] = p
        })
        store.dispatch(receiveCoursesPacks(res))
      },
      err => {
        console.log(`dbFetchCoursesPacks error: ${err.message}`)
      }
    )
    addListener('coursesPacks', unsubscribe)
  } catch (e) {
    console.error('dbFetchCoursesPacks error', e)
  }
}

export const dbUpdateCoursesPack = async (
  packId: string,
  upd: Partial<ICoursesPack>
) => {
  try {
    console.log('dbUpdateCoursesPack', packId, upd)
    const ref = doc(collection(db, 'coursesPacks'), packId)
    await updateDoc(ref, dbOmit(upd))
  } catch (e) {
    console.log('dbUpdateCoursesPack error', e)
  }
}

export const dbCreateCoursesPack = async () => {
  const id = generateId()
  const c: ICoursesPack = {
    id,
    title: 'Новый тариф',
    invisible: true,
    closed: true,
    sections: {},
    sectionsOrder: [],
    createdAt: +dayjs(),
    plans: []
  }
  const ref = doc(collection(db, 'coursesPacks'), id)
  await setDoc(ref, dbOmit(c))
  return id
}

export const dbDeleteCoursesPack = async (c: ICoursesPack) => {
  const ref = doc(collection(db, 'coursesPacks'), c.id)
  await deleteDoc(ref)
}

export const dbDuplicateCoursesPack = async (c: ICoursesPack) => {
  const courseId = generateId()
  const newCoursePack: ICoursesPack = {
    ...c,
    id: courseId,
    closed: true,
    invisible: true,
    createdAt: +dayjs(),
    title: c.title + ' (copy)'
  }
  // console.log('new course', newCourse)
  const ref = doc(collection(db, 'coursesPacks'), courseId)
  await setDoc(ref, newCoursePack)
  return courseId
}

export const dbDuplicateCourseFree = async (
  c: ICourse,
  lessons: Record<string, ILesson>
) => {
  const batch = writeBatch(db)
  const courseId = generateId()
  const newCourse: IFree = {
    ...c,
    id: courseId,
    lessons: [],
    closed: true,
    invisible: true,
    createdAt: +dayjs(),
    copyOf: {
      entityId: c.id,
      entityType: IEntityType.COURSES
    }
  }
  if (c.lessons) {
    for (const lId of c.lessons) {
      const l = lessons[lId]
      if (l) {
        const newL: ILesson = {
          ...l,
          id: generateId(),
          entityId: courseId
        }
        newCourse.lessons.push(newL.id)
        batch.set(doc(collection(db, 'lessons'), newL.id), newL)
      }
    }
  }
  if (c.content) {
    const newContent: IWeek[] = []
    forEach(c.content, w => {
      const newWeek: IWeek = { ...w, days: [], enabled: false }
      forEach(w.days, d => {
        const newDay: IDay = { ...d, lessons: [] }
        forEach(d.lessons, lId => {
          const l = lessons[lId]
          if (l) {
            const newL: ILesson = {
              ...l,
              id: generateId(),
              entityId: courseId
            }
            newDay.lessons.push(newL.id)
            // console.log('add new lesson to batch', newL)
            batch.set(doc(collection(db, 'lessons'), newL.id), newL)
          }
        })
        newWeek.days.push(newDay)
      })
      newContent.push(newWeek)
    })
    newCourse.content = newContent
  }

  // console.log('new course', newCourse)

  batch.set(doc(collection(db, 'free'), courseId), newCourse)
  await batch.commit()
  return courseId
}
