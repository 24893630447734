import { FC } from 'react'
import { VStack } from '@chakra-ui/react'
import { IPadding, ISectionText } from 'shared/types'
import EditableReachField from 'components/EditableReachField'
import SectionWidthSelect from 'components/SectionWidthSelect'
import PaddingSelect from 'components/PaddingSelect'

type Props = {
  s: ISectionText
  onChange: (s: ISectionText) => void
}

const EditSectionTestList: FC<Props> = ({ s, onChange }) => {
  const onChangeSectionWidth = (v: number) => {
    onChange({ ...s, widthCol: v })
  }

  const renderSectionWidth = () => {
    return (
      <SectionWidthSelect value={s.widthCol} onChange={onChangeSectionWidth} />
    )
  }

  const renderPadding = () => {
    return (
      <PaddingSelect
        value={s.paddingTop}
        onChange={(v: IPadding) => onChange({ ...s, paddingTop: v })}
      />
    )
  }

  const renderContent = () => {
    return (
      <EditableReachField
        v={s.htmlString || ''}
        onChange={(v: string) => onChange({ ...s, htmlString: v })}
        textStyle={s.textStyle}
        onTextStyleChange={v => onChange({ ...s, textStyle: v })}
      />
    )
  }

  return (
    <VStack w='full' spacing={6}>
      {renderSectionWidth()}
      {renderPadding()}
      {renderContent()}
    </VStack>
  )
}

export default EditSectionTestList
