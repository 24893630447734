import {
  BoxProps,
  Flex,
  useColorModeValue,
  Text,
  CloseButton,
  HStack,
  VStack,
  IconButton,
  Icon,
  Button
} from '@chakra-ui/react'
import { ReactComponent as Logo } from 'shared/assets/crrown.svg'
import { ReactComponent as IconChevronLeft } from 'shared/assets/bsChevronLeft.svg'
import { ReactComponent as IconMenu } from 'shared/assets/bsMenu.svg'

import NavItem from 'components/sidebar/NavItem'
import { dbSignOut } from 'controllers/auth'
import { useNavigate } from 'react-router-dom'
import { SettingsIcon } from '@chakra-ui/icons'
import { IoMdLogOut } from 'react-icons/io'

interface LinkItemProps {
  name: string
  path: string
  isDisabled?: boolean
}
const LinkItems: Array<LinkItemProps> = [
  { name: 'Курсы', path: '/courses' },
  { name: 'Тарифы', path: '/tariffs' },
  { name: 'Вебинары', path: '/webinars' },
  { name: 'Библиотeка', path: '/library' },
  { name: 'Конференции', path: '/conferences' },
  { name: 'Семинары', path: '/seminars' },
  { name: 'Бесплатно', path: '/free' },
  { name: 'Преподаватели', path: '/teachers' },
  // { name: 'Группы', path: '/groups', isDisabled: true },
  { name: 'Пользователи', path: '/users' },
  // { name: 'Подписки', path: '/subscriptions', isDisabled: true },
  { name: 'Покупки', path: '/payments' },
  { name: 'Платежные ссылки', path: '/payment_links' },
  { name: 'Словарь', path: '/cards' },
  { name: 'Отзывы', path: '/feedback' }
]

interface SidebarProps extends BoxProps {
  onClose: () => void
}

const SidebarContent = ({ onClose }: SidebarProps) => {
  const navigate = useNavigate()

  return (
    <VStack
      bg={useColorModeValue('white', 'gray.900')}
      borderRight='1px'
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      flex={1}
      w='full'
      h='full'
      overflow={'hidden'}
      flexShrink={0}
      align={'flex-start'}
    >
      <Flex
        h='16'
        w='full'
        alignItems='center'
        px='2'
        justifyContent='space-between'
      >
        <HStack spacing={2}>
          <HStack
            spacing={0}
            as={Button}
            variant={'unstyled'}
            onClick={onClose}
          >
            <IconChevronLeft width={16} height={16} />
            <IconMenu width={24} height={24} />
          </HStack>
          {/* <Icon */}
          <Text fontSize='lg' fontWeight='bold'>
            thequeenenglish
          </Text>
        </HStack>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      <VStack
        flex='1'
        h='full'
        align='flex-start'
        overflowY={'auto'}
        spacing={0}
        pl={4}
      >
        {LinkItems.map(link => (
          <NavItem
            key={link.name}
            name={link.name}
            path={link.path}
            isDisabled={link.isDisabled}
            onClick={() => {
              onClose()
              navigate(link.path)
            }}
          />
        ))}
      </VStack>
      <HStack
        py={4}
        align='flex-start'
        justify={'space-between'}
        px={4}
        w='full'
      >
        <NavItem
          key={'settings'}
          name={'Настройки'}
          path={'/settings'}
          buttonProps={{
            leftIcon: <SettingsIcon />,
            variant: 'outline',
            size: 'sm'
          }}
          onClick={() => {
            onClose()
            navigate('/settings')
          }}
        />
        <IconButton
          aria-label='logout'
          icon={<Icon as={IoMdLogOut} />}
          size='sm'
          onClick={() => dbSignOut(navigate)}
        />
      </HStack>
    </VStack>
  )
}

export default SidebarContent
