import { useMemo } from 'react'
import { Button, HStack, VStack, Wrap, WrapItem } from '@chakra-ui/react'
import NavBar from 'components/NavBar'
import { useSelector } from 'model/hooks'
import map from 'lodash/map'
import { IFree } from 'shared/types'
import imageDefault from 'shared/assets/course-default.png'
import { AddIcon } from '@chakra-ui/icons'
import { dbCreateFree } from 'controllers/frees'
import { useNavigate } from 'react-router-dom'
import orderBy from 'lodash/orderBy'
import EntityCard from 'components/EntityCard'
import size from 'lodash/size'
import { createLabel } from 'shared/utils/format'

const Frees = () => {
  const navigate = useNavigate()
  const frees = useSelector(state => state.frees)

  const sortedFrees = useMemo(() => {
    return orderBy(frees, 'createdAt', 'desc')
  }, [frees])

  const renderCard = (c: IFree) => {
    return (
      <WrapItem key={c.id}>
        <EntityCard
          url={`/free/${c.id}`}
          title={c.title}
          imageUrl={c.imageUrl || imageDefault}
          desc={[]}
          lessonsAmount={c.content ? size(c.content) : size(c.lessons)}
          lessonsAmountDesc={
            c.content
              ? createLabel(size(c.content), ['Неделя', 'Недели', 'Недель'])
              : createLabel(size(c.lessons), ['Урок', 'Урока', 'Уроков'])
          }
          closed={c.closed}
          invisible={c.invisible}
        />
      </WrapItem>
    )
  }

  const onCreateClick = async () => {
    const courseId = await dbCreateFree()
    navigate(`/free/${courseId}`)
  }

  const navBarActions = (
    <HStack>
      <Button colorScheme='teal' leftIcon={<AddIcon />} onClick={onCreateClick}>
        Создать курс
      </Button>
    </HStack>
  )

  return (
    <VStack w='full' h='full' spacing={0}>
      <NavBar hideBackButton title='Бесплатно'>
        {navBarActions}
      </NavBar>
      <VStack align={'flex-start'} w='full' h='full' p={4} overflowY={'auto'}>
        <Wrap spacing={4}>{map(sortedFrees, renderCard)}</Wrap>
      </VStack>
    </VStack>
  )
}

export default Frees
