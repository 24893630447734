import { FC } from 'react'
import { Button } from '@chakra-ui/react'
import { ReactComponent as IconEdit } from 'shared/assets/bsEdit.svg'

type Props = {
  onClick?: () => void
}

const EditSectionButton: FC<Props> = ({ onClick }) => {
  if (!onClick) return null
  return (
    <Button
      leftIcon={<IconEdit width={12} height={12} />}
      position='absolute'
      display='none'
      _groupHover={{ display: 'block', cursor: 'pointer' }}
      right={4}
      top={2}
      onClick={onClick}
      variant={'outline'}
      size='sm'
      // _hover={{ cursor: 'pointer' }}
    >
      Edit
    </Button>
  )
}

export default EditSectionButton
