import { FC, useState, useMemo } from 'react'
import { VStack, BoxProps, Flex, Box } from '@chakra-ui/react'
import {
  ILessonType,
  IPartition,
  ISection,
  ISessionAnswer,
  ITeacher,
  IWordCard,
  IWordCardTag,
  SectionType
} from 'shared/types'
import Section from 'shared/components/Section'
import { StorageReference } from 'firebase/storage'
import get from 'lodash/get'
import map from 'lodash/map'
import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import { isTestSection } from 'shared/utils/sectionName'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from 'react-beautiful-dnd'
import { ReactComponent as IconList } from 'shared/assets/bsList.svg'
import AddSectionButton from 'shared/components/admin/AddSectionButton'
import EditSectionButton from 'shared/components/EditSectionButton'

type Props = {
  sections: Record<string, ISection>
  sectionsOrder: string[]
  containerProps?: BoxProps
  entityId: string
  storageRef: (refer: string) => StorageReference
  onAnswer?: (sectionId: string, answer: ISessionAnswer) => void
  sessionAnswers?: Record<string, ISessionAnswer>
  showUserAnswer?: boolean
  isExamination?: boolean
  lt?: ILessonType
  admin?: {
    onAddSection: (sType: SectionType, i: number) => void
    onMoveSection: (fromIndex: number, toIndex: number) => void
    onEditSection: (sid: string) => void
  }
  teachers: Record<string, ITeacher> | null
  partitions: Record<string, IPartition> | null
  wordCards: Record<string, IWordCard>
  cardTags: Record<string, IWordCardTag> | null
  cardStatuses?: Record<string, boolean>
}

const PageContent: FC<Props> = ({
  sectionsOrder,
  sections,
  containerProps,
  entityId,
  storageRef,
  onAnswer,
  sessionAnswers,
  showUserAnswer,
  isExamination,
  admin,
  lt,
  teachers,
  partitions,
  wordCards,
  cardTags,
  cardStatuses
}) => {
  const [isDragging, setIsDragging] = useState(false)

  const renderSection = (s: ISection, i: number, testNum?: number) => {
    const isTest = isTestSection(s.type)
    return (
      <Section
        key={s.id}
        s={s}
        containerProps={containerProps}
        entityId={entityId}
        storageRef={storageRef}
        onAnswer={onAnswer}
        answer={get(sessionAnswers, s.id, null)}
        testNum={isTest ? testNum + 1 : undefined}
        showUserAnswer={showUserAnswer}
        isExamination={isExamination}
        teachers={teachers || {}}
        partitions={partitions || {}}
        wordCards={wordCards}
        cardTags={cardTags}
        cardStatuses={cardStatuses}
      />
    )
  }

  const testNumBySectionId = useMemo(() => {
    const testSections = filter(sectionsOrder, sId => {
      const s = sections[sId]
      return s && isTestSection(s.type)
    })
    const res = {}
    forEach(testSections, (sId, i) => {
      res[sId] = i
    })
    return res
  }, [sections, sectionsOrder])

  const renderSections = () => {
    return map(sectionsOrder, (sId, i) => {
      const s = sections[sId]
      if (s) {
        if (admin) {
          return (
            <Draggable key={i} draggableId={s.id + i} index={i}>
              {(provided, snapshot) => (
                <Flex
                  w='full'
                  role='group'
                  position='relative'
                  _hover={admin ? { bgColor: 'gray.50' } : undefined}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  justify={'center'}
                >
                  {renderSection(s, i, testNumBySectionId[s.id])}
                  {!isDragging && (
                    <>
                      <Box
                        position={'absolute'}
                        left={1}
                        top={1}
                        display={'none'}
                        {...provided.dragHandleProps}
                        _groupHover={{ display: 'block' }}
                      >
                        <IconList width='14' height='14' />
                      </Box>
                      <AddSectionButton
                        lt={lt}
                        addSection={(sType: SectionType) =>
                          admin.onAddSection(sType, i + 1)
                        }
                      />
                      <EditSectionButton
                        onClick={() => admin.onEditSection(s.id)}
                      />
                    </>
                  )}
                </Flex>
              )}
            </Draggable>
          )
        } else {
          return (
            <Flex w='full' align='center' justify={'center'} key={s.id}>
              {renderSection(s, i, testNumBySectionId[s.id])}
            </Flex>
          )
        }
      }
    })
  }

  const onDragEnd = (result: DropResult) => {
    setIsDragging(false)
    admin &&
      result.destination &&
      result.destination.index >= 0 &&
      admin.onMoveSection(result.source.index, result.destination.index)
  }

  const renderClone = (i: number) => {
    const sId = sectionsOrder[i]
    const s = sections[sId]
    return (
      <Flex w='full' bg='gray.50' position='relative'>
        <Box position={'absolute'} left={4} top={'calc(50% - 7px)'}>
          <IconList width='14' height='14' />
        </Box>
        {renderSection(s, i, 0)}
      </Flex>
    )
  }

  if (admin) {
    return (
      <DragDropContext
        onDragEnd={onDragEnd}
        onDragStart={() => setIsDragging(true)}
      >
        <Droppable
          droppableId='sections'
          renderClone={(provided, snapshot, rubric) => {
            return (
              <div ref={provided.innerRef} {...provided.draggableProps}>
                {renderClone(rubric.source.index)}
              </div>
            )
          }}
        >
          {(provided, snapshot) => (
            <VStack
              w='full'
              align='center'
              justify={'center'}
              spacing={0}
              {...provided.droppableProps}
              ref={provided.innerRef}
              pb={{ base: 12, lg: 20 }}
            >
              {renderSections()}
              {provided.placeholder}
            </VStack>
          )}
        </Droppable>
      </DragDropContext>
    )
  } else {
    return (
      <VStack w='full' align='center' spacing={0} pb={{ base: 12, lg: 20 }}>
        {renderSections()}
      </VStack>
    )
  }
}

export default PageContent
