import { FC, ReactNode } from 'react'
import { VStack } from '@chakra-ui/react'

type Props = {
  children: ReactNode
}

const PageContentWrapper: FC<Props> = ({ children }) => {
  return (
    <VStack w='full' spacing={0} justify={'flex-start'} minH='full'>
      {children}
    </VStack>
  )
}

export default PageContentWrapper
