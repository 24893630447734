import {
  query,
  collection,
  where,
  onSnapshot,
  orderBy,
  doc,
  deleteDoc,
  setDoc,
  arrayUnion,
  getDocs,
  limit
} from 'firebase/firestore'
import { db, generateId } from 'controllers/db'
import { IPaymentLink, IUserPayment } from 'shared/types'
import dayjs from 'dayjs'
import store from 'model/store'
import { receivePaymentLinks } from 'model/actions'
import { addListener } from 'controllers/listeners'

export const dbSubscribeToUserPayments = (
  entityId: string,
  callback: (payments: IUserPayment[]) => void
) => {
  try {
    const q = query(
      collection(db, 'userPayments'),
      where('entityId', '==', entityId),
      orderBy('createdAt', 'desc')
    )
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: IUserPayment[] = []
        sn.forEach(doc => {
          const p = doc.data() as IUserPayment
          res.push({ ...p, id: doc.id })
        })
        callback(res)
      },
      err => {
        console.log(`dbSubscribeToUserPayments error: ${err.message}`)
      }
    )
    return unsubscribe
  } catch (e) {
    console.error('dbSubscribeToUserPayments error', e)
    return null
  }
}

export const dbSubscribeToPayments = (
  userId: string,
  callback: (payments: IUserPayment[]) => void
) => {
  try {
    const q = query(
      collection(db, 'userPayments'),
      where('userId', '==', userId),
      orderBy('createdAt', 'desc')
    )
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: IUserPayment[] = []
        sn.forEach(doc => {
          const p = doc.data() as IUserPayment
          res.push({ ...p, id: doc.id })
        })
        callback(res)
      },
      err => {
        console.log(`dbSubscribeToPayments error: ${err.message}`)
      }
    )
    return unsubscribe
  } catch (e) {
    console.error('dbSubscribeToPayments error', e)
    return null
  }
}

export const dbDeleteUserPayment = async (id: string) => {
  try {
    console.log('dbDeleteUserPayment', id)
    const ref = doc(collection(db, 'userPayments'), id)
    await deleteDoc(ref)
  } catch (e) {
    console.log('dbDeleteUserPayment error', e)
  }
}

export const dbCreateUserPayment = async (up: IUserPayment) => {
  try {
    const ref = doc(collection(db, 'userPayments'), up.id)
    await setDoc(ref, up)
    const accessRef = doc(db, 'users', up.userId, 'access', 'entities')
    await setDoc(accessRef, { list: arrayUnion(up.entityId) }, { merge: true })
  } catch (e) {
    console.log('dbCreateUserPayment error', e)
  }
}

export const dbGetPaymentsByDate = async (
  startDate: number,
  endDate: number
) => {
  const q = query(
    collection(db, 'userPayments'),
    where('createdAt', '>=', startDate),
    where('createdAt', '<', endDate),
    orderBy('createdAt')
  )
  const sn = await getDocs(q)
  return sn.docs.map(doc => doc.data() as IUserPayment)
}

export const dbCreatePaymentLink = async (title: string, price: number) => {
  const pl: IPaymentLink = {
    id: generateId(),
    title,
    price,
    createdAt: +dayjs()
  }
  const ref = doc(collection(db, 'paymentLinks'), pl.id)
  await setDoc(ref, pl)
}

export const dbFetchPaymentLinks = async () => {
  try {
    const q = query(
      collection(db, 'paymentLinks'),
      orderBy('createdAt', 'desc'),
      limit(200)
    )
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: IPaymentLink[] = []
        sn.forEach(doc => {
          const p = doc.data() as IPaymentLink
          res.push(p)
        })
        store.dispatch(receivePaymentLinks(res))
      },
      err => {
        console.log(`dbFetchPaymentLinks error: ${err.message}`)
      }
    )
    addListener('paymentLinks', unsubscribe)
  } catch (e) {
    console.error('dbFetchPaymentLinks error', e)
  }
}
