import { useRef } from 'react'
import { AddIcon } from '@chakra-ui/icons'
import { Button, HStack, VStack, Flex, Box } from '@chakra-ui/react'
import NavBar from 'components/NavBar'
import AddTeacherModal, { IAddTeacherModal } from 'modals/AddTeacherModal'
import { useSelector } from 'model/hooks'
import TeacherCard from 'shared/components/TeacherCard'
import _ from 'lodash'
import RowActionButtons from 'components/RowActionButtons'

const Teachers = () => {
  const teacherModalRef = useRef<IAddTeacherModal>(null)
  const teachers = useSelector(state => state.teachers)
  const partitions = useSelector(state => state.partitions)

  const renderContent = () => {
    return (
      <VStack w='full' h='full' spacing={6} py={6} overflowY={'auto'}>
        {_.map(teachers, t => {
          return (
            <Flex key={t.id} role='group' w='full' position={'relative'}>
              <TeacherCard t={t} partitions={partitions || {}} />
              <Box
                position='absolute'
                top={2}
                right={2}
                bg='wood.50'
                rounded='xs'
              >
                <RowActionButtons
                  onEdit={() => teacherModalRef.current?.open(t)}
                />
              </Box>
            </Flex>
          )
        })}
      </VStack>
    )
  }

  const onCreateTeacherClick = () => {
    teacherModalRef.current?.open()
  }

  const navBarActions = (
    <HStack>
      <Button
        colorScheme='teal'
        leftIcon={<AddIcon />}
        onClick={onCreateTeacherClick}
      >
        Добавить
      </Button>
    </HStack>
  )

  return (
    <VStack
      w='full'
      h='full'
      spacing={0}
      overflow={'hidden'}
      maxH={'full'}
      bg='brand.50'
    >
      <NavBar hideBackButton title={'Преподаватели'}>
        {navBarActions}
      </NavBar>
      <VStack overflow={'hidden'} w='full' maxW='7xl'>
        {renderContent()}
      </VStack>

      <AddTeacherModal ref={teacherModalRef} />
    </VStack>
  )
}

export default Teachers
