import {
  Ref,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useState,
  useMemo,
  useRef
} from 'react'
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagCloseButton,
  TagLabel,
  VStack,
  useDisclosure
} from '@chakra-ui/react'

import { FileT, ITeacher, IVideo, IVideoPlatform } from 'shared/types'
import dayjs from 'dayjs'
import { dbCreateTeacher, dbUpdateTeacher } from 'controllers/teachers'
import FilesPickerButton from 'components/FilesPickerButton'
import { generateId } from 'controllers/db'
import storage from 'controllers/storage'
import Resizer from 'react-image-file-resizer'
import FilePlaceholder from 'components/filesPicker/FilePlaceholder'
import Vimeo from '@u-wave/react-vimeo'
import { useSelector } from 'model/hooks'
import _ from 'lodash'
import { AddIcon, EditIcon } from '@chakra-ui/icons'
import { useNavigate } from 'react-router-dom'
import ReachEditor from 'components/contentSettingsCard/ReachEditor'
import ImageField from 'components/formFields/ImageField'
import Label from 'shared/components/Label'
import VideoPlatformSelect from 'components/VideoPlatformSelect'
import VideoField from 'components/formFields/VideoField'

export interface IAddTeacherModal {
  open: (t?: ITeacher) => void
}

type Props = {}

const AddTeacherModal: ForwardRefRenderFunction<IAddTeacherModal, Props> = (
  _props,
  ref: Ref<unknown>
) => {
  const teachers = useSelector(state => state.teachers)
  const partitions = useSelector(state => state.partitions)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [id, setId] = useState(generateId())
  const [name, setName] = useState('')
  const [avatarUrl, setAvatarUrl] = useState<string>()
  const [partitionsIds, setPartitionsIds] = useState<string[]>([])
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [video, setVideo] = useState<IVideo>()
  const [image, setImage] = useState<string>()
  const navigate = useNavigate()

  const isNew = useMemo(() => {
    return !_.has(teachers, id)
  }, [teachers, id])

  useImperativeHandle(ref, () => ({
    open: (t?: ITeacher) => {
      setId(_.get(t, 'id', generateId()))
      setName(_.get(t, 'name', ''))
      setTitle(_.get(t, 'title', ''))
      setAvatarUrl(_.get(t, 'avatarUrl'))
      setPartitionsIds(_.get(t, 'partitions', []))
      setDescription(_.get(t, 'description', ''))
      setVideo(_.get(t, 'video'))
      setImage(_.get(t, 'image'))
      onOpen()
    }
  }))

  const partitionsIdsLeft = useMemo(() => {
    if (partitions) {
      const allIds = _.keys(partitions)
      return _.difference(allIds, partitionsIds)
    } else {
      return []
    }
  }, [partitions, partitionsIds])

  // console.log('user', user)

  const apply = async () => {
    if (isNew) {
      const t: ITeacher = {
        id,
        createdAt: +dayjs(),
        name,
        title,
        partitions: partitionsIds,
        avatarUrl,
        description,
        video,
        image
      }
      dbCreateTeacher(t)
    } else {
      const upd: Partial<ITeacher> = {
        name,
        title,
        partitions: partitionsIds,
        avatarUrl,
        description,
        video,
        image
      }
      dbUpdateTeacher(id, upd)
    }
    onClose()
  }

  const nameInput = (
    <FormControl>
      <FormLabel>Имя</FormLabel>
      <HStack w='full'>
        <Input
          variant={'flushed'}
          size='sm'
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </HStack>
    </FormControl>
  )

  const titleInput = (
    <FormControl>
      <FormLabel>Специальность</FormLabel>
      <HStack w='full'>
        <Input
          variant={'flushed'}
          size='sm'
          value={title}
          placeholder='Фонетист'
          onChange={e => setTitle(e.target.value)}
        />
      </HStack>
    </FormControl>
  )

  const resizeFile = async (file: File, size: number = 300): Promise<File> =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        size,
        size,
        'PNG',
        100,
        0,
        f => resolve(f as File),
        'file'
      )
    })

  const onImageUploaded = (files: FileT[]) => {
    if (files.length > 0) {
      const imageUrl = files[0].url
      setAvatarUrl(imageUrl)
    }
  }

  const avatarUploading = (
    <FormControl>
      <FormLabel pb={2}>Аватар</FormLabel>
      <FilesPickerButton
        generateId={() => generateId() + '.png'}
        userId=''
        storage={storage}
        storagePath={`/teachers/${id}`}
        onComplete={onImageUploaded}
        fileProcessor={resizeFile}
        options={{
          accept: {
            'image/*': ['.png', '.jpeg', '.jpg']
          },
          multiple: false
        }}
      >
        {avatarUrl ? (
          <Box w='full'>
            <Avatar size='2xl' name={name} src={avatarUrl} />
          </Box>
        ) : (
          <FilePlaceholder buttonTitle='PNG or JPG file' />
        )}
      </FilesPickerButton>
    </FormControl>
  )

  const onAddPartition = (pId: string) => {
    setPartitionsIds(ids => _.uniq([...ids, pId]))
  }

  const onRemovePartition = (pId: string) => {
    setPartitionsIds(ids => _.filter(ids, id => id !== pId))
  }

  const partitionsSelect = () => {
    if (partitions) {
      return (
        <FormControl>
          <HStack spacing={0} pb={2}>
            <FormLabel mr={0}>Разделы</FormLabel>
            <IconButton
              aria-label='edit partitions'
              icon={<EditIcon />}
              variant={'unstyled'}
              size='xs'
              onClick={() => navigate('/settings', { state: { tabIndex: 2 } })}
            />
          </HStack>
          <HStack>
            {_.map(partitionsIds, pId => {
              const p = partitions[pId]
              if (p) {
                return (
                  <Tag key={pId}>
                    <TagLabel>{p.name}</TagLabel>
                    <TagCloseButton onClick={() => onRemovePartition(p.id)} />
                  </Tag>
                )
              }
            })}
            {!_.isEmpty(partitionsIdsLeft) && (
              <Menu>
                <MenuButton as={IconButton} icon={<AddIcon />} size='xs' />
                <MenuList>
                  {_.map(partitionsIdsLeft, pId => {
                    const p = partitions[pId]
                    if (p) {
                      return (
                        <MenuItem key={pId} onClick={() => onAddPartition(pId)}>
                          {p.name}
                        </MenuItem>
                      )
                    }
                  })}
                </MenuList>
              </Menu>
            )}
          </HStack>
        </FormControl>
      )
    }
  }

  const descriptionInput = (
    <FormControl>
      <FormLabel>Информация о преподавателе</FormLabel>
      <ReachEditor
        value={description}
        onChange={(v: string) => setDescription(v)}
      />
    </FormControl>
  )

  const onChangeVideoUrl = (v: string) => {
    setVideo({ platform: video?.platform || IVideoPlatform.VIMEO, url: v })
  }

  const onChangeVideoPlatform = (v: IVideoPlatform) => {
    setVideo({ platform: v, url: '' })
  }

  const videoInput = (
    <VStack w='full' spacing={1}>
      <VideoPlatformSelect
        value={video?.platform || IVideoPlatform.VIMEO}
        onChange={(v: IVideoPlatform) => onChangeVideoPlatform(v)}
      />
      <VideoField
        label=''
        platform={video?.platform || IVideoPlatform.VIMEO}
        value={video?.url || ''}
        onChange={(v: string) => onChangeVideoUrl(v)}
      />
    </VStack>
  )

  const imageInput = (
    <VStack w='full' align='flex-start'>
      <Label>Фото преподавателя</Label>
      <ImageField
        value={image}
        onChange={(v: string) => setImage(v)}
        storagePath={`/teachers/${id}`}
      />
    </VStack>
  )

  const renderBody = () => {
    return (
      <VStack pt={8} w='full' spacing={6}>
        {nameInput}
        {titleInput}
        {avatarUploading}
        {partitionsSelect()}
        {descriptionInput}
        {videoInput}
        {imageInput}
      </VStack>
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {isNew ? 'Добавление преподавателя' : 'Редактирование преподавателя'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>{renderBody()}</ModalBody>
        <ModalFooter pt={12}>
          <Button
            variant='solid'
            size='sm'
            colorScheme='teal'
            isDisabled={name === ''}
            onClick={apply}
          >
            {isNew ? 'Добавить' : 'Сохранить'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(AddTeacherModal)
