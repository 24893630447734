import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import isNil from 'lodash/isNil'

export const arrayMoveMutate = <T>(array: T[], from: number, to: number) => {
  const startIndex = from < 0 ? array.length + from : from

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = to < 0 ? array.length + to : to

    const [item] = array.splice(from, 1)
    array.splice(endIndex, 0, item)
  }
}

export const arrayMove = <T>(array: T[], from: number, to: number) => {
  array = [...array]
  arrayMoveMutate(array, from, to)
  return array
}

export const roundRobinGet = <T>(array: T[], index: number) => {
  if (isEmpty(array) || isNil(index) || index < 0) return undefined
  const l = size(array)
  const i = index % l
  return array[i]
}

export const arrayInsertAt = <T>(arr: T[], index: number, newItem: any) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index)
]
