import {
  onSnapshot,
  query,
  collection,
  doc,
  deleteDoc,
  setDoc
} from 'firebase/firestore'
import { db } from 'src/controllers/db'
import { IAdmin } from 'shared/types'
import { receiveAdmins } from 'src/model/actions'
import store from 'src/model/store'
import { addListener } from 'controllers/listeners'

export const dbFetchAdmins = async () => {
  try {
    const q = query(collection(db, 'admins'))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: Record<string, IAdmin> = {}
        sn.forEach(doc => {
          const p = doc.data() as IAdmin
          res[doc.id] = {
            ...p,
            id: doc.id
          }
        })
        store.dispatch(receiveAdmins(res))
      },
      err => {
        console.log(`dbFetchAdmins error: ${err.message}`)
      }
    )
    addListener('admins', unsubscribe)
  } catch (e) {
    console.error('dbFetchAdmins error', e)
  }
}

export const dbDeleteAdmin = async (id: string) => {
  const ref = doc(db, 'admins', id)
  await deleteDoc(ref)
}

export const dbAddAdmin = async (id: string, email: string) => {
  const ref = doc(db, 'admins', id)
  await setDoc(ref, { id, email })
}
