import { VStack, Grid } from '@chakra-ui/react'
import { FC, ReactNode } from 'react'
import EditSectionButton from 'shared/components/EditSectionButton'

type Props = {
  amount: number
  children: ReactNode
  onEditPlans?: () => void
}

const CoursesPackPlansContainer: FC<Props> = ({
  amount,
  children,
  onEditPlans
}) => {
  return (
    <VStack w='full' pt={8} pb={8} spacing={0}>
      <EditSectionButton onClick={onEditPlans} />
      <Grid
        w='full'
        templateColumns={{
          base: `repeat(1, 1fr)`,
          lg: `repeat(${amount}, 1fr)`
        }}
        rowGap={6}
        columnGap={6}
        maxW={{ base: undefined, lg: `${amount * 389}px` }}
      >
        {children}
      </Grid>
    </VStack>
  )
}

export default CoursesPackPlansContainer
