import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  IconButton,
  useDisclosure,
  Text,
  VStack,
  Box
} from '@chakra-ui/react'
import {
  Ref,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useState
} from 'react'
import get from 'lodash/get'
import map from 'lodash/map'
import { ILessonType, SectionType } from 'shared/types'
import sectionsByPageType from 'shared/constants/sectionsByPageType'
// import { dbUpdateCoursesPack } from 'controllers/coursesPacks'
// import { useSelector } from 'model/hooks'
import { sectionName } from 'shared/utils/sectionName'

export interface IAddSectionDrawer {
  open: (lt?: ILessonType) => void
}
type Props = {
  addSection: (sType: SectionType) => void
}

const CoursesPackPlanDrawer: ForwardRefRenderFunction<
  IAddSectionDrawer,
  Props
> = ({ addSection }, ref: Ref<unknown>) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [lt, setLt] = useState<ILessonType>()

  useImperativeHandle(ref, () => ({
    open: (lt: ILessonType) => {
      setLt(lt)
      onOpen()
    }
  }))

  const renderContent = () => {
    const sTypes = sectionsByPageType(lt)
    return (
      <VStack>
        {map(sTypes, st => (
          <Box
            w='full'
            key={st}
            as={Button}
            colorScheme={'wood'}
            variant={'ghost'}
            onClick={() => {
              onClose()
              addSection(st)
            }}
          >
            <Text>{sectionName[st]}</Text>
          </Box>
        ))}
      </VStack>
    )
  }

  return (
    <Drawer isOpen={isOpen} placement='right' onClose={onClose} size='sm'>
      <DrawerOverlay bg='transparent' />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader color={'wood.950'}>
          <Text textStyle='small' fontWeight={700}>
            Добавление блока
          </Text>
        </DrawerHeader>

        <DrawerBody>{renderContent()}</DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default forwardRef(CoursesPackPlanDrawer)
