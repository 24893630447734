import { FC } from 'react'
import { ISection, ISectionTwoColumnsVimeo } from 'shared/types'
import { Box, Text, Stack } from '@chakra-ui/react'
import Vimeo from '@u-wave/react-vimeo'

type Props = {
  s: ISectionTwoColumnsVimeo
}

const SectionTwoColumnsVimeo: FC<Props> = ({ s }) => {
  return (
    <Stack direction={{ base: 'column', lg: 'row' }} w='full' spacing={6}>
      <Box flex={1}>
        <Text textStyle={'h2'} fontWeight={700}>
          {s.title || ''}
        </Text>
      </Box>
      <Box flex={1} w='full' sx={{ iframe: { borderRadius: 'xs' } }}>
        {s.vimeoVideoId && (
          <Vimeo
            id='vimeo_video'
            video={s.vimeoVideoId}
            responsive
            showPortrait={false}
            onError={e => console.log('vimeo video error:', e)}
          />
        )}
      </Box>
    </Stack>
  )
}

export default SectionTwoColumnsVimeo
