import { FC } from 'react'
import { ISectionText } from 'shared/types'
import SectionTextContent from 'shared/components/SectionTextContent'

type Props = {
  s: ISectionText
}

const SectionText: FC<Props> = ({ s }) => {
  if (s.htmlString) {
    return (
      <SectionTextContent r={{ text: s.htmlString, textStyle: s.textStyle }} />
    )
  }
}

export default SectionText
