import { FC } from 'react'
import {
  Box,
  FormControl,
  HStack,
  VStack,
  Flex,
  FormLabel
} from '@chakra-ui/react'
import { IExaminationMessage } from 'shared/types'
import map from 'lodash/map'
import set from 'lodash/set'
// import SimpleTextarea from 'shared/components/SimpleTextarea'
import RowActionButtons from 'components/RowActionButtons'
import PercSlider from 'components/PercSlider'
import { arrayMove } from 'shared/utils/array'
import SimpleReachTextarea from 'components/contentSettingsCard/SimpleReachTextarea'

type Props = {
  messages: IExaminationMessage[]
  setMessages: (m: IExaminationMessage[]) => void
  tasksAmount: number
}

const ExaminationResultMessages: FC<Props> = ({
  messages = [],
  setMessages,
  tasksAmount
}) => {
  const updateMessage = (i: number, v: string) => {
    const newMessages = [...messages]
    set(newMessages, [i, 'msg'], v)
    setMessages(newMessages)
  }

  const updatePerc = (i: number, v: number) => {
    const newMessages = [...messages]
    set(newMessages, [i, 'perc'], v)
    setMessages(newMessages)
  }

  const deleteVariant = (i: number) => {
    const newMessages = [...messages]
    newMessages.splice(i, 1)
    setMessages(newMessages)
  }

  const addVariant = (v: string) => {
    if (v !== '') {
      const m: IExaminationMessage = { perc: 100, msg: v }
      const newMessages = [...messages, m]
      setMessages(newMessages)
    }
  }

  const onMove = (i: number, isUp: boolean) => {
    const newMessages = arrayMove(messages, i, isUp ? i - 1 : i + 1)
    setMessages(newMessages)
  }

  const renderMessage = (m: IExaminationMessage, i: number) => {
    return (
      <HStack key={i} w='full' position={'relative'} role='group' spacing={8}>
        <VStack spacing={4} w='full'>
          <PercSlider
            perc={m.perc}
            onChange={v => updatePerc(i, v)}
            tasksAmount={tasksAmount}
            size='sm'
          />
          <FormControl>
            <SimpleReachTextarea
              value={m.msg}
              onApply={(v: string) => updateMessage(i, v)}
              clearOnApply
            />
            {/* <SimpleTextarea
              // variant={'flushed'}
              size='sm'
              value={m.msg}
              onChange={(v: string) => updateMessage(i, v)}
            /> */}
          </FormControl>
        </VStack>
        <HStack>
          <Box position={'absolute'} right={-4} bottom={1}>
            <RowActionButtons
              onDelete={() => deleteVariant(i)}
              isFirst={i === 0}
              isLast={i === messages.length - 1}
              onMove={(isUp: boolean) => onMove(i, isUp)}
            />
          </Box>
        </HStack>
      </HStack>
    )
  }

  const renderNewOption = () => {
    return (
      <HStack w='full' pt={6}>
        <FormControl>
          <FormLabel>Добавить вариант</FormLabel>
          <SimpleReachTextarea
            placeholder='Добавить вариант'
            onApply={addVariant}
            clearOnApply
          />
        </FormControl>
      </HStack>
    )
  }

  return (
    <VStack w='full' spacing={6}>
      {map(messages, renderMessage)} {renderNewOption()}
    </VStack>
  )
}

export default ExaminationResultMessages
