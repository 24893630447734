import { COMPLETION_PERC } from 'shared/constants'
import { ILesson } from 'shared/types'
import reduce from 'lodash/reduce'
import { isTestSection } from 'shared/utils/sectionName'
import words from 'lodash/words'
import toLower from 'lodash/toLower'
import map from 'lodash/map'
import get from 'lodash/get'
import isNumber from 'lodash/isNumber'
import { SectionType } from 'shared/types'

export const percToAmount = (testsAmount: number, perc?: number) => {
  const v = isNumber(perc) ? perc : COMPLETION_PERC
  const res = Math.ceil((testsAmount / 100) * v)
  // console.log(
  //   'percToAmount, testsAmount',
  //   testsAmount,
  //   'perc',
  //   perc,
  //   'res',
  //   res
  // )
  return res
}

export const getTestQuestionsAmount = (l: ILesson) => {
  return reduce(
    l.sections,
    (res, s) => {
      return isTestSection(s.type) ? res + 1 : res
    },
    0
  )
}

export const cleanupString = (s: string) => {
  const wrds = words(toLower(s))
  const strAr = map(wrds, w =>
    w.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  )
  return strAr.join(' ')
}

export const testTitles = {
  [SectionType.TEST_ONE_OPTION]:
    'Выберите один правильный варианта перевода фразы',
  [SectionType.TEST_TRUE_FALSE]: 'Верно ли утверждение',
  [SectionType.TEST_TEXT_ANSWER]: 'Напишите верный перевод предложения',
  [SectionType.TEST_INPUT_ANSWER]: 'Напишите верный перевод слова',
  [SectionType.TEST_BUTTONS]:
    'Выберите один правильный варианта перевода слова',
  [SectionType.TEST_ADD_WORDS]: 'Заполните пропуски в тексте',
  [SectionType.TEST_ADD_LETTERS]: 'Вставьте пропущенные буквы',
  [SectionType.TEST_SYNC_TRANSLATE]: 'Заполните пропуски в тексте'
}

export const getTestTitle = (t: SectionType) => {
  return get(testTitles, t, '')
}
