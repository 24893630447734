import { FC } from 'react'
import { ISection, ISectionTestOneOption, ISessionAnswer } from 'shared/types'
import { VStack, Text, Box, HStack } from '@chakra-ui/react'
import isObject from 'lodash/isObject'
import TestTask from 'shared/components/sections/tests/TestTask'
import RadioIcon from 'shared/components/RadioIcon'
import map from 'lodash/map'
import get from 'lodash/get'
import { getTestTitle } from 'shared/utils/tests'
import TestCard from 'shared/components/sections/tests/TestCard'
import SectionTextContent from 'shared/components/SectionTextContent'

type Props = {
  s: ISectionTestOneOption
  onAnswer?: (sectionId: string, answer: ISessionAnswer) => void
  answer?: ISessionAnswer
  testNum?: number
  showUserAnswer?: boolean
  isExamination: boolean
}

const SectionTestOneOption: FC<Props> = ({
  s,
  onAnswer,
  answer,
  testNum,
  showUserAnswer,
  isExamination
}) => {
  const renderQuestion = () => {
    if (s.htmlString) {
      return (
        <SectionTextContent
          r={{ text: s.htmlString, textStyle: s.textStyle }}
        />
      )
    }
  }

  const answered = isObject(answer)
  const correctAnswer = get(s, 'test.answer')

  const checkAnswer = (a: string) => {
    const sa: ISessionAnswer = {
      answer: a,
      isCorrect: a === correctAnswer
    }
    if (onAnswer) {
      onAnswer(s.id, sa)
    }
  }

  if (s.test) {
    return (
      <TestCard showUserAnswer={showUserAnswer} answer={answer}>
        <TestTask
          title={s.test?.title || getTestTitle(s.type)}
          answer={showUserAnswer ? undefined : answer}
          testNum={testNum}
        />
        {renderQuestion()}
        <Box w='full'>
          <VStack spacing={4} align='flex-start' w='full' py={0}>
            {map(s.test.options, opt => {
              let color = 'black.950'
              if (showUserAnswer) {
                color = 'black.950'
              } else if (answer && answer.answer === opt.id) {
                color = answer.isCorrect ? 'green.800' : 'red.700'
              } else if (answer && correctAnswer === opt.id) {
                color = get(s.test, 'highlightCorrectAnswer', false)
                  ? 'green.800'
                  : 'black.950'
              }

              let variant: 'default' | 'success' | 'error' | 'selected' =
                'default'
              if (showUserAnswer && answer) {
                variant = answer.answer === opt.id ? 'selected' : 'default'
              } else if (answer && answer.answer === opt.id) {
                variant = answer.isCorrect ? 'success' : 'error'
              } else if (answer && correctAnswer === opt.id) {
                variant = get(s.test, 'highlightCorrectAnswer', false)
                  ? 'success'
                  : 'default'
              }

              return (
                <HStack
                  w='full'
                  key={opt.id}
                  textStyle={'body'}
                  align='start'
                  as={'button'}
                  onClick={
                    onAnswer && (!answered || showUserAnswer)
                      ? v => checkAnswer(opt.id)
                      : undefined
                  }
                >
                  <RadioIcon variant={variant} mt={{ base: 0.5, lg: 1 }} />
                  <Text textStyle={'body'} color={color} textAlign={'start'}>
                    {opt.value}
                  </Text>
                </HStack>
              )
            })}
          </VStack>
        </Box>
      </TestCard>
    )
  } else {
    return null
  }
}

export default SectionTestOneOption
