import { FC } from 'react'
import { Flex, BoxProps } from '@chakra-ui/react'
import {
  IPadding,
  IPartition,
  ISection,
  ISectionAudio,
  ISectionButton,
  ISectionColumns,
  ISectionColumnsImage,
  ISectionColumnsVideo,
  ISectionHeading,
  ISectionSyncTranslate,
  ISectionTeachers,
  ISectionTestAddLetters,
  ISectionTestAddWords,
  ISectionTestButtons,
  ISectionTestInputAnswer,
  ISectionTestOneOption,
  ISectionTestSyncTranslate,
  ISectionTestTextAnswer,
  ISectionTestTrueFalse,
  ISectionText,
  ISectionTextList,
  ISectionTwoColumnsText,
  ISectionTwoColumnsVimeo,
  ISectionVimeo,
  ISectionWordCard,
  ISectionYoutube,
  ISessionAnswer,
  ITeacher,
  IWordCard,
  IWordCardTag,
  SectionType
} from 'shared/types'
import get from 'lodash/get'
import SectionText from 'shared/components/sections/SectionText'
import SectionVimeo from 'shared/components/sections/SectionVimeo'
import SectionTwoColumnsText from 'shared/components/sections/SectionTwoColumsText'
import SectionDivider from 'shared/components/sections/SectionDivider'
import SectionTwoColumnsVimeo from 'shared/components/sections/SectionTwoColumnsVimeo'
import SectionButton from 'shared/components/sections/SectionButton'
import SectionAudio from 'shared/components/sections/SectionAudio'
import { StorageReference } from 'firebase/storage'
import SectionTestOneOption from 'shared/components/sections/SectionTestOneOption'
import SectionTestTrueFalse from 'shared/components/sections/SectionTestTrueFalse'
import SectionTestButtons from 'shared/components/sections/SectionTestButtons'
import SectionTestTextAnswer from 'shared/components/sections/SectionTestTextAnswer'
import SectionTestAddWords from 'shared/components/sections/SectionTestAddWords'
import SectionYoutube from 'shared/components/sections/SectionYoutube'
import SectionTestInputAnswer from 'shared/components/sections/SectionTestInputAnswer'
import SectionTestAddLetters from 'shared/components/sections/SectionTestAddLetters'
import SectionTestSyncTranslate from 'shared/components/sections/SectionTestSyncTranslate'
import SectionHeading from 'shared/components/sections/SectionHeading'
import SectionTextList from 'shared/components/sections/SectionTextList'
import SectionColumns from 'shared/components/sections/SectionColumns'
import SectionColumnsVideo from 'shared/components/sections/SectionColumnsVideo'
import SectionColumnsImage from 'shared/components/sections/SectionColumnsImage'
import SectionTeachers from 'shared/components/sections/SectionTeachers'
import SectionSyncTranslate from 'shared/components/sections/SectionSyncTranslate'
import SectionWordCard from 'shared/components/sections/SectionWordCard'

type Props = {
  s: ISection
  containerProps?: BoxProps
  storageRef: (refer: string) => StorageReference
  entityId: string
  onAnswer?: (sectionId: string, answer: ISessionAnswer) => void
  answer?: ISessionAnswer
  testNum?: number
  showUserAnswer?: boolean
  isExamination: boolean
  teachers: Record<string, ITeacher>
  partitions: Record<string, IPartition>
  wordCards: Record<string, IWordCard>
  cardTags: Record<string, IWordCardTag> | null
  cardStatuses?: Record<string, boolean>
}

const Section: FC<Props> = ({
  s,
  containerProps,
  storageRef,
  entityId,
  onAnswer,
  answer,
  testNum,
  showUserAnswer,
  isExamination,
  teachers,
  partitions,
  wordCards,
  cardTags,
  cardStatuses
}) => {
  // console.log('section: showUserAnswer', showUserAnswer)
  const renderContent = () => {
    switch (s.type) {
      case SectionType.TEACHERS:
        return (
          <SectionTeachers
            s={s as ISectionTeachers}
            teachers={teachers}
            partitions={partitions}
          />
        )
      case SectionType.SYNC_TRANSLATE:
        return <SectionSyncTranslate s={s as ISectionSyncTranslate} />
      case SectionType.HEADING:
        return <SectionHeading s={s as ISectionHeading} />
      case SectionType.TEXT_LIST:
        return <SectionTextList s={s as ISectionTextList} />
      case SectionType.COLUMNS:
        return <SectionColumns s={s as ISectionColumns} />
      case SectionType.COLUMNS_VIDEO:
        return <SectionColumnsVideo s={s as ISectionColumnsVideo} />
      case SectionType.COLUMNS_IMAGE:
        return <SectionColumnsImage s={s as ISectionColumnsImage} />
      case SectionType.TEXT:
        return <SectionText s={s as ISectionText} />
      case SectionType.TWO_COLUMNS_TEXT:
        return <SectionTwoColumnsText s={s as ISectionTwoColumnsText} />
      case SectionType.VIMEO:
        return <SectionVimeo s={s as ISectionVimeo} />
      case SectionType.YOUTUBE:
        return <SectionYoutube s={s as ISectionYoutube} />
      case SectionType.DIVIDER:
        return <SectionDivider />
      case SectionType.TWO_COLUMNS_VIMEO:
        return <SectionTwoColumnsVimeo s={s as ISectionTwoColumnsVimeo} />
      case SectionType.BUTTON:
        return <SectionButton s={s as ISectionButton} />
      case SectionType.AUDIO:
        return (
          <SectionAudio
            s={s as ISectionAudio}
            storageRef={storageRef}
            entityId={entityId}
          />
        )
      case SectionType.TEST_ONE_OPTION:
        return (
          <SectionTestOneOption
            s={s as ISectionTestOneOption}
            onAnswer={onAnswer}
            answer={answer}
            testNum={testNum}
            showUserAnswer={showUserAnswer}
            isExamination={isExamination}
          />
        )
      case SectionType.TEST_TRUE_FALSE:
        return (
          <SectionTestTrueFalse
            s={s as ISectionTestTrueFalse}
            onAnswer={onAnswer}
            answer={answer}
            testNum={testNum}
            showUserAnswer={showUserAnswer}
            isExamination={isExamination}
          />
        )
      case SectionType.TEST_BUTTONS:
        return (
          <SectionTestButtons
            s={s as ISectionTestButtons}
            onAnswer={onAnswer}
            answer={answer}
            testNum={testNum}
            showUserAnswer={showUserAnswer}
            isExamination={isExamination}
          />
        )
      case SectionType.TEST_TEXT_ANSWER:
        return (
          <SectionTestTextAnswer
            s={s as ISectionTestTextAnswer}
            onAnswer={onAnswer}
            answer={answer}
            testNum={testNum}
            showUserAnswer={showUserAnswer}
            isExamination={isExamination}
          />
        )
      case SectionType.TEST_INPUT_ANSWER:
        return (
          <SectionTestInputAnswer
            s={s as ISectionTestInputAnswer}
            onAnswer={onAnswer}
            answer={answer}
            testNum={testNum}
            showUserAnswer={showUserAnswer}
            isExamination={isExamination}
          />
        )
      case SectionType.TEST_ADD_WORDS:
        return (
          <SectionTestAddWords
            s={s as ISectionTestAddWords}
            onAnswer={onAnswer}
            answer={answer}
            testNum={testNum}
            showUserAnswer={showUserAnswer}
            isExamination={isExamination}
          />
        )
      case SectionType.TEST_SYNC_TRANSLATE:
        return (
          <SectionTestSyncTranslate
            s={s as ISectionTestSyncTranslate}
            onAnswer={onAnswer}
            answer={answer}
            testNum={testNum}
            showUserAnswer={showUserAnswer}
            isExamination={isExamination}
          />
        )
      case SectionType.TEST_ADD_LETTERS:
        return (
          <SectionTestAddLetters
            s={s as ISectionTestAddLetters}
            onAnswer={onAnswer}
            answer={answer}
            testNum={testNum}
            showUserAnswer={showUserAnswer}
            isExamination={isExamination}
          />
        )
      case SectionType.WORD_CARD:
        const card = get(wordCards, s.cardId)
        if (card) {
          return (
            <SectionWordCard
              s={s as ISectionWordCard}
              card={card}
              cardTags={cardTags}
              cardStatuses={cardStatuses}
            />
          )
        }

      default:
        return null
    }
  }

  const getPaddingTop = () => {
    const p = get(s, 'paddingTop', IPadding.SMALL) as IPadding
    switch (p) {
      case IPadding.SMALL:
        return { base: 4, lg: 6 }
      case IPadding.MEDIUM:
        return { base: 8, lg: 12 }
      case IPadding.LARGE:
        return { base: 12, lg: 20 }
    }
  }

  return (
    <Flex
      id={s.id}
      w='full'
      h='fit-content'
      justify={'center'}
      className='ql-editor'
      overflow={'visible'}
      p={0}
      sx={{ a: { color: '#A3906C', textDecoration: 'underline' } }}
      {...containerProps}
    >
      <Flex
        w={'full'}
        pt={getPaddingTop()}
        maxW={{
          base: undefined,
          lg:
            s.type === SectionType.HEADING ||
            s.type === SectionType.TEXT_LIST ||
            s.type === SectionType.VIMEO ||
            s.type === SectionType.SYNC_TRANSLATE
              ? `${(100 / 12) * (s as ISectionHeading).widthCol}%`
              : undefined
        }}
      >
        {renderContent()}
      </Flex>
    </Flex>
  )
}

export default Section
