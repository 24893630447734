import { useSelector } from 'model/hooks'
import {
  Avatar,
  AvatarBadge,
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Text,
  VStack,
  useDisclosure
} from '@chakra-ui/react'
import { ReactComponent as ChatIcon } from 'shared/assets/bsChat.svg'
import _ from 'lodash'
import { useMemo, useRef } from 'react'
import { IChannel, IEntityType } from 'shared/types'
import { Index } from 'firebase/firestore'
import { getEntities } from 'model/selectors/entities'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'

const ChatDrawer = () => {
  const channels = useSelector(state => state.channels)
  const entities = useSelector(getEntities)
  const conferences = useSelector(state => state.conferences)
  const webinars = useSelector(state => state.webinars)
  const seminars = useSelector(state => state.seminars)
  const library = useSelector(state => state.library)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef(null)
  const navigate = useNavigate()

  const sortedChannels = useMemo(() => {
    return _.orderBy(channels, 'lastMessage.createdAt', 'desc')
  }, [channels])

  const entityType = (entityId: string) => {
    if (_.has(conferences, entityId)) {
      return IEntityType.CONFERENCES
    } else if (_.has(webinars, entityId)) {
      return IEntityType.WEBINARS
    } else if (_.has(seminars, entityId)) {
      return IEntityType.SEMINARS
    } else if (_.has(library, entityId)) {
      return IEntityType.LIBRARY
    } else {
      return IEntityType.COURSES
    }
  }

  const onRowClick = (ch: IChannel) => {
    onClose()
    navigate(
      `${entityType(ch.entityId)}/${ch.entityId}/${ch.lessonId}?tab=2&userId=${
        ch.userId
      }`
    )
  }

  const renderChannel = (ch: IChannel, i: Index) => {
    const entity = _.get(entities, ch.entityId)
    if (entity) {
      return (
        <VStack
          key={ch.id}
          w='full'
          align='flex-start'
          spacing={1}
          py={2}
          px={2}
          _hover={{ cursor: 'pointer', bg: 'lightGray' }}
          onClick={() => onRowClick(ch)}
        >
          <Text textStyle={'small'} fontWeight={700}>
            {entity.title} (урок #{_.indexOf(entity.lessons, ch.lessonId) + 1}){' '}
          </Text>
          <HStack justify={'space-between'} w='full' pt={1}>
            <Text textStyle={'small'} color='darkGray'>
              {ch.lastMessage.senderName}
            </Text>
            <Text textStyle={'small'} color='darkGray'>
              {dayjs(ch.lastMessage.createdAt).format('DD MMMM, hh:mm')}
            </Text>
          </HStack>
          <Text textStyle={'tag'} color='black' pt={0}>
            {ch.lastMessage.content.text}
          </Text>
        </VStack>
      )
    } else {
      console.warn('entity is not found', ch.entityId)
    }
  }

  return (
    <>
      <Box
        ref={btnRef}
        position={'fixed'}
        right='4'
        bottom={4}
        as='button'
        _hover={{ cursor: 'pointer' }}
        onClick={onOpen}
      >
        <Avatar icon={<ChatIcon width={32} height={32} />}>
          <AvatarBadge borderColor='papayawhip' bg='green' boxSize='1.5em'>
            <Text textStyle={'tag'}>
              {_.size(channels) > 99 ? '99+' : _.size(channels)}
            </Text>
          </AvatarBadge>
        </Avatar>
      </Box>

      <Drawer
        size='sm'
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader textStyle='h3' borderBottomWidth={1}>
            Непрочитанные сообщения
          </DrawerHeader>

          <DrawerBody px={4}>
            <VStack divider={<Divider />} spacing={0}>
              {_.map(sortedChannels, renderChannel)}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default ChatDrawer
