import { VStack, TabPanel, BoxProps } from '@chakra-ui/react'
import { FC, ReactNode } from 'react'

type Props = BoxProps & {
  children: ReactNode
}

const CustomTabPanel: FC<Props> = ({ children, ...rest }) => {
  return (
    <TabPanel w='full' h='full' overflow={'hidden'} p={0}>
      <VStack
        align={'center'}
        w='full'
        h='full'
        p={0}
        overflowY={'auto'}
        {...rest}
      >
        {children}
      </VStack>
    </TabPanel>
  )
}

export default CustomTabPanel
