import {
  onSnapshot,
  query,
  collection,
  doc,
  setDoc,
  updateDoc,
  where,
  writeBatch,
  getDocs,
  deleteField
} from 'firebase/firestore'
import { db, generateId } from 'src/controllers/db'
import { IPartition } from 'shared/types'
import { receivePartitions } from 'src/model/actions'
import store from 'src/model/store'
import { addListener } from 'controllers/listeners'

export const dbFetchPartitions = async () => {
  try {
    const q = query(collection(db, 'partitions'))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: Record<string, IPartition> = {}
        sn.forEach(doc => {
          const p = doc.data() as IPartition
          res[doc.id] = p
        })
        store.dispatch(receivePartitions(res))
      },
      err => {
        console.log(`dbFetchPartitions error: ${err.message}`)
      }
    )
    addListener('partitions', unsubscribe)
  } catch (e) {
    console.error('dbFetchPartitions error', e)
  }
}

export const dbCreatePartition = async () => {
  const id = generateId()
  const p: IPartition = {
    id,
    name: 'Новый раздел'
  }
  const r = doc(db, 'partitions', id)
  await setDoc(r, p)
  return id
}

export const dbUpdatePartition = async (id: string, name: string) => {
  const r = doc(db, 'partitions', id)
  await updateDoc(r, { name })
}

export const dbDeletePartition = async (id: string) => {
  const q = query(collection(db, 'library'), where('partitionId', '==', id))
  const coursesSN = await getDocs(q)
  const batch = writeBatch(db)
  coursesSN.forEach(courseDoc =>
    batch.update(doc(db, 'library', courseDoc.id), {
      partitionId: deleteField()
    })
  )
  batch.delete(doc(db, 'partitions', id))
  await batch.commit()
}
