import { FC } from 'react'
import { ISectionYoutube } from 'shared/types'
import { Box, Text, VStack, HStack } from '@chakra-ui/react'
import YoutubePlayer from 'shared/components/YoutubePlayer'

type Props = {
  s: ISectionYoutube
}

const SectionYoutube: FC<Props> = ({ s }) => {
  return (
    <VStack
      w='full'
      h='fit-content'
      maxH='full'
      position='relative'
      spacing={2}
    >
      {s.title && s.title !== '' && (
        <HStack w='full' justify={s.titleAlignment || 'flex-start'}>
          <Text textStyle={'body'} fontWeight={700}>
            {s.title}
          </Text>
        </HStack>
      )}
      <Box
        w='full'
        sx={{
          iframe: { borderRadius: 'xs' }
        }}
      >
        {s.youtubeVideoId && <YoutubePlayer videoId={s.youtubeVideoId} />}
      </Box>
    </VStack>
  )
}

export default SectionYoutube
