import { ReactNode, FC, useEffect, useRef } from 'react'
import { VStack } from '@chakra-ui/react'
import { appInitialized } from 'src/controllers/init'
import { useNavigate } from 'react-router-dom'

const NavWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate()
  const appInitializedRef = useRef<boolean>(false)

  useEffect(() => {
    if (!appInitializedRef.current) {
      appInitialized(navigate)
      appInitializedRef.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <VStack
      w='full'
      h='full'
      justify={'center'}
      bg='rgb(229, 229, 229);'
      spacing={0}
      overflowX={'hidden'}
    >
      {children}
    </VStack>
  )
}

export default NavWrapper
