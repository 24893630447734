import { FormControl, Text, HStack, Switch } from '@chakra-ui/react'
import { FC } from 'react'

type Props = {
  label: string
  value: boolean
  onChange: (v: boolean) => void
}

const SwitchField: FC<Props> = ({ label, value, onChange }) => {
  return (
    <FormControl>
      <HStack w='full' justify={'space-between'}>
        <Text textStyle='small' color='black.950'>
          {label}
        </Text>
        <Switch
          colorScheme='wood'
          isChecked={value}
          onChange={e => onChange(e.target.checked)}
        />
      </HStack>
    </FormControl>
  )
}

export default SwitchField
