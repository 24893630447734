import { HStack, Text } from '@chakra-ui/react'
import { ReactComponent as ChevronLeftIcon } from 'shared/assets/bsChevronLeft.svg'
import { FC } from 'react'

type Props = {
  onClick: () => void
}

const BackButtonSimple: FC<Props> = ({ onClick }) => {
  return (
    <HStack
      color='black.500'
      spacing={1}
      pb={{ lg: 4, base: 2 }}
      as='button'
      onClick={onClick}
      _hover={{ cursor: 'pointer' }}
    >
      <ChevronLeftIcon width={'12px'} height='12px' />
      <Text textStyle={'small'} color='black.500'>
        назад
      </Text>
    </HStack>
  )
}

export default BackButtonSimple
