import { createReducer } from '@reduxjs/toolkit'
import { IWordCardTag } from 'shared/types'
import { receiveCardTags } from 'src/model/actions'

const initialState = null as Record<string, IWordCardTag> | null

const reducer = createReducer(initialState, builder => {
  builder.addCase(receiveCardTags, (state, action) => action.payload)
})

export default reducer
