import { FC } from 'react'
import { VStack } from '@chakra-ui/react'
import { IPadding, ISectionVimeo, IVideoPlatform } from 'shared/types'
import EditableReachField from 'components/EditableReachField'
import SectionWidthSelect from 'components/SectionWidthSelect'
import PaddingSelect from 'components/PaddingSelect'
import Label from 'shared/components/Label'
import VideoField from 'components/formFields/VideoField'

type Props = {
  s: ISectionVimeo
  onChange: (s: ISectionVimeo) => void
}

const EditSectionVimeo: FC<Props> = ({ s, onChange }) => {
  const onChangeSectionWidth = (v: number) => {
    onChange({ ...s, widthCol: v })
  }

  const renderTitle = () => {
    return (
      <VStack w='full' align='start'>
        <Label>Заголовок</Label>
        <VStack
          w='full'
          spacing={2}
          borderWidth={1}
          borderColor='wood.600'
          px={2}
          rounded='4px'
          flexShrink={0}
          align='center'
          justify={'space-between'}
          color='wood.600'
        >
          <EditableReachField
            v={s.title || ''}
            onChange={(v: string) => onChange({ ...s, title: v })}
            textStyle={s.textStyle}
            onTextStyleChange={v => onChange({ ...s, textStyle: v })}
          />
        </VStack>
      </VStack>
    )
  }

  const renderSectionWidth = () => {
    return (
      <SectionWidthSelect value={s.widthCol} onChange={onChangeSectionWidth} />
    )
  }

  const renderPadding = () => {
    return (
      <PaddingSelect
        value={s.paddingTop}
        onChange={(v: IPadding) => onChange({ ...s, paddingTop: v })}
      />
    )
  }

  const renderVideoInput = () => {
    return (
      <VideoField
        label=''
        platform={IVideoPlatform.VIMEO}
        value={s.vimeoVideoId || ''}
        onChange={(v: string) => onChange({ ...s, vimeoVideoId: v })}
      />
    )
  }

  return (
    <VStack w='full' spacing={6}>
      {renderSectionWidth()}
      {renderPadding()}
      {renderTitle()}
      {renderVideoInput()}
    </VStack>
  )
}

export default EditSectionVimeo
