import { FC, MouseEvent } from 'react'
import { Center } from '@chakra-ui/react'
import { ReactComponent as IconRight } from 'shared/assets/bsChevronRight.svg'

type Props = {
  onClick?: () => void
}

const CustomButtonRight: FC<Props> = ({ onClick }) => {
  return (
    <Center
      rounded={'full'}
      as='button'
      onClick={(e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        e.stopPropagation()
        onClick()
      }}
      bg='white'
      width={7}
      height={7}
      position={'absolute'}
      right='1'
      boxShadow={'shadow1'}
      color='black.200'
      zIndex={2000}
    >
      <IconRight width='16' height='16' />
    </Center>
  )
}

export default CustomButtonRight
