import { createReducer } from '@reduxjs/toolkit'
import { ILevel } from 'shared/types'
import { receiveLevels } from 'src/model/actions'

const initialState = null as Record<string, ILevel> | null

const reducer = createReducer(initialState, builder => {
  builder.addCase(receiveLevels, (state, action) => action.payload)
})

export default reducer
