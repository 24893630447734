import { FC, useRef, useEffect, useState } from 'react'
import { Input } from '@chakra-ui/react'
import last from 'lodash/last'

type Props = {
  isReadonly: boolean
  isFocused: boolean
  index: number
  v: string
  onChange: (v: string, i: number) => void
  toPrev: (i: number) => void
  toNext: (i: number) => void
  status: 'transparent' | 'gray' | 'success' | 'error' | 'warn' | string
}

const LetterInput: FC<Props> = ({
  isReadonly,
  isFocused,
  index,
  v,
  onChange,
  toPrev,
  toNext,
  status
}) => {
  const inputRef = useRef<HTMLInputElement>()
  const [value, setValue] = useState('')

  useEffect(() => {
    setValue(v)
  }, [v])

  useEffect(() => {
    if (isFocused && !isReadonly) {
      inputRef.current?.focus()
      inputRef.current?.setSelectionRange(-1, -1)
      // console.log('on focus, value', value)
    }
  }, [isFocused])

  const _onChange = (v: string) => {
    // console.log('onChange', v)
    const diacReg = /\p{Lm}|\p{Sk}/gu
    const isDiac = diacReg.test(v.normalize('NFD'))
    if (isDiac && v.length === 1) {
      // console.log('diac symb found', v)
      setValue(v)
      return
    } else if (v !== '') {
      // inputRef.current?.blur()
      onChange(last(v), index)
    }
  }

  const onBackspace = () => {
    // console.log('onBackspace', value)
    // if (v === '') {
    //   toPrev(index)
    // } else {
    setValue('')
    onChange('', index)
    setTimeout(() => toPrev(index))
    // }
  }

  // const diacReg = /\p{Lm}|\p{Sk}/gu
  // const isDiac = diacReg.test(value.normalize('NFD'))
  // console.log('isDiac', value, isDiac)

  return (
    <Input
      type='text'
      ref={inputRef}
      variant={'unstyled'}
      value={value}
      onChange={e => _onChange(e.target.value)}
      w='26px'
      color={
        status === 'success'
          ? 'green.800'
          : status === 'error'
          ? 'red.700'
          : status === 'warn'
          ? 'black.300'
          : 'black.950'
      }
      maxLength={2}
      textAlign={'center'}
      fontSize={{ base: '21px', lg: '28px' }}
      fontWeight={{ base: '450', lg: '450' }}
      letterSpacing={{ base: '-0.21px', lg: '-0.56px' }}
      lineHeight={{ base: '24px', lg: '32px' }}
      isReadOnly={isReadonly}
      onKeyDown={e => {
        // console.log(
        //   'selectionStart',
        //   inputRef.current.selectionStart,
        //   'vlength',
        //   v.length
        // )
        if (e.code === 'Backspace') {
          onBackspace()
        } else if (
          e.code === 'ArrowRight' &&
          inputRef.current.selectionStart >= v.length
        ) {
          toNext(index)
        } else if (
          e.code === 'ArrowLeft' &&
          inputRef.current.selectionStart === 0
        ) {
          toPrev(index)
        }
      }}
    />
  )
}

export default LetterInput
