import { FC, useState, useEffect } from 'react'
// import { TextareaProps } from '@chakra-ui/react'
import ReachEditor from 'components/contentSettingsCard/ReachEditor'
import { ReactQuillProps } from 'react-quill'

type Props = ReactQuillProps & {
  onApply: (v: string) => void
  clearOnApply?: boolean
}

const SimpleReachTextarea: FC<Props> = ({
  onApply,
  value,
  clearOnApply,
  ...rest
}) => {
  // const ref = useRef<HTMLInputElement>(null)
  const [v, setV] = useState<string>(value ? value.toString() : '')

  useEffect(() => {
    setV(value ? value.toString() : '')
  }, [value])

  const apply = () => {
    console.log('onBlur, apply', v)
    onApply(v)
    if (clearOnApply) {
      setV('')
    }
  }

  return (
    <ReachEditor value={v} {...rest} onChange={v => setV(v)} onBlur={apply} />
  )
}

export default SimpleReachTextarea
