import { FC, useRef, ChangeEvent, useState, useEffect } from 'react'
import {
  Slider,
  SliderTrack,
  Box,
  SliderThumb,
  SliderFilledTrack,
  HStack,
  VStack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Flex,
  Show
} from '@chakra-ui/react'
import { getBlob, StorageReference } from 'firebase/storage'
import { ReactComponent as IconPlay } from 'shared/assets/bsPlay.svg'
import { ReactComponent as IconPause } from 'shared/assets/bsPause.svg'
import { ReactComponent as IconVolumeUp } from 'shared/assets/bsVolumeFillUp.svg'
import { ReactComponent as IconCounterLeft } from 'shared/assets/bsArrowCounterLeft.svg'
import { ReactComponent as IconCounterRight } from 'shared/assets/bsArrowCounterRight.svg'
import { ReactComponent as IconChevronDown } from 'shared/assets/bsChevronDown.svg'
import dayjs from 'dayjs'
import split from 'lodash/split'

type Props = {
  url: string
}

const AudioPlayer: FC<Props> = ({ url }) => {
  const audioRef = useRef<HTMLAudioElement>(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [pos, setPos] = useState(0)
  const [volume, setVolume] = useState(1)
  const [rate, setRate] = useState(1)
  const resumeAfterChange = useRef(false)

  const timeToString = (t: number) => {
    const d = dayjs(t * 1000)
    const timeFormat = t > 3600 ? 'h:mm:ss' : 'mm:ss'
    return d.format(timeFormat)
  }

  useEffect(() => {
    console.log('url changed', url)
  }, [url])

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.playbackRate = rate
    }
  }, [rate])

  const onTimeUpdate = (e: ChangeEvent<HTMLAudioElement>) => {
    // console.log('onTimeUpdate', e)
    setPos(e.target.currentTime)
  }

  const togglePlay = (e?: React.SyntheticEvent): void => {
    e && e.stopPropagation()
    const audio = audioRef.current
    if ((audio.paused || audio.ended) && audio.src) {
      playAudioPromise()
    } else if (!audio.paused) {
      audio.pause()
    }
  }

  const playAudioPromise = () => {
    const playPromise = audioRef.current.play()
    // playPromise is null in IE 11
    if (playPromise) {
      playPromise.then(null).catch(err => {
        // const { onPlayError } = this.props
        // onPlayError && onPlayError(new Error(err))
      })
    } else {
      // Remove forceUpdate when stop supporting IE 11
      // this.forceUpdate()
    }
  }

  const onDurationChange = (e: ChangeEvent<HTMLAudioElement>) => {
    console.log('on duration change')
    setDuration(e.target.duration)
  }

  const onChangeStart = () => {
    resumeAfterChange.current = isPlaying
    audioRef.current?.pause()
  }

  const onChangeEnd = (v: number) => {
    const newPos = (duration * v) / 100
    audioRef.current.currentTime = newPos
    // setPos(newPos)
    // if (resumeAfterChange.current) {
    //   togglePlay()
    // }
  }

  const onChange = (v: number) => {
    const newPos = (duration * v) / 100
    // audioRef.current.currentTime = newPos
    setPos(newPos)
  }

  const renderVolume = () => {
    return (
      <Show above='lg'>
        <HStack role='group' spacing={2}>
          <IconVolumeUp width={24} height={24} />
          <Slider
            defaultValue={0}
            min={0}
            max={1}
            step={0.01}
            w={'16'}
            value={volume}
            autoFocus={false}
            onChange={(v: number) => {
              audioRef.current.volume = v
              setVolume(v)
            }}
          >
            <SliderTrack bg='lightGray' h='1px'>
              <SliderFilledTrack bg='black' />
            </SliderTrack>
            <SliderThumb boxSize={3} bg={'black'} />
          </Slider>
        </HStack>
      </Show>
    )
  }

  const onCanPlay = () => {
    console.log('onCanPlay')
    if (audioRef.current) {
      setVolume(audioRef.current.volume)
    }
  }

  const renderSpeedSwitch = () => {
    const rates = [0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.5, 2]
    return (
      <Flex flex={1} justify='flex-end'>
        <Menu matchWidth>
          <MenuButton
            as={Button}
            size='xs'
            variant={'outline'}
            colorScheme='black'
          >
            <HStack spacing={1}>
              <Text textStyle={'tag'} color='black'>
                x{rate}
              </Text>
              <IconChevronDown width={12} height={12} />
            </HStack>
          </MenuButton>
          <MenuList minWidth={'12'} textStyle={'small'}>
            {rates.map(r => (
              <MenuItem w={12} key={r} onClick={() => setRate(r)}>
                <Text textAlign={'center'} w='full'>
                  {r}
                </Text>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Flex>
    )
  }

  const renderTime = () => {
    return (
      <HStack w='full' justify={'space-between'} textStyle='small'>
        <Text>{timeToString(pos)}</Text>
        <Text>{timeToString(duration)}</Text>
      </HStack>
    )
  }

  const onShiftLeft = () => {
    let newPos = pos - 15
    if (newPos < 0) {
      newPos = 0
    }
    if (audioRef.current) {
      audioRef.current.currentTime = newPos
    }
  }

  const onShiftRight = () => {
    let newPos = pos + 15
    if (newPos > duration) {
      newPos = duration
    }
    if (audioRef.current) {
      audioRef.current.currentTime = newPos
    }
  }

  const renderShiftButtons = () => {
    return (
      <HStack spacing={2} flex={1} justify='center'>
        <Box as='button' _hover={{ cursor: 'pointer' }} onClick={onShiftLeft}>
          <IconCounterLeft width={24} height={24} />
        </Box>
        <Box as='button' _hover={{ cursor: 'pointer' }} onClick={onShiftRight}>
          <IconCounterRight width={24} height={24} />
        </Box>
      </HStack>
    )
  }

  return (
    <>
      <audio
        key={url}
        src={url}
        controls={false}
        loop={false}
        autoPlay={false}
        preload={'auto'}
        ref={audioRef}
        onTimeUpdate={onTimeUpdate}
        onDurationChange={onDurationChange}
        onPause={() => setIsPlaying(false)}
        onPlay={() => setIsPlaying(true)}
        onCanPlay={() => onCanPlay()}
      />
      <VStack
        w='full'
        spacing={2}
        // borderWidth={1}
        rounded={'xs'}
        // borderColor='black'
        boxShadow={'shadow1'}
        p={4}
        pt={8}
      >
        {renderTime()}
        <Box w='full' px={2}>
          <Slider
            defaultValue={0}
            min={0}
            max={100}
            step={0.01}
            value={duration > 0 ? (pos / duration) * 100 : 0}
            onChange={onChange}
            // onChangeStart={onChangeStart}
            onChangeEnd={onChangeEnd}
            autoFocus={false}
            focusThumbOnChange={false}
          >
            <SliderTrack bg='lightGray'>
              {/* <Box position='relative' right={10} /> */}
              <SliderFilledTrack bg='darkGray' />
            </SliderTrack>
            <SliderThumb
              boxSize={4}
              borderColor={'lightGray'}
              autoFocus={false}
            />
          </Slider>
        </Box>
        <HStack w='full' justify={'space-between'} pt={6}>
          <HStack spacing={6} flex={1} justify={'flex-start'}>
            <Box
              as='button'
              onClick={togglePlay}
              boxSize={6}
              width={6}
              _hover={{ cursor: 'pointer' }}
            >
              {isPlaying ? <IconPause /> : <IconPlay />}
            </Box>
            {renderVolume()}
            {/* <Text textStyle={'small'}>
              {timeToString(pos)} / {timeToString(duration)}
            </Text> */}
          </HStack>
          {renderShiftButtons()}
          {renderSpeedSwitch()}
        </HStack>
      </VStack>
    </>
  )
}

export default AudioPlayer
