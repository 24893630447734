import { FC, useRef, useState, useMemo } from 'react'
import {
  Input,
  InputProps,
  VStack,
  Text,
  HStack,
  Box,
  Popover,
  useDisclosure,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Button,
  PopoverArrow,
  PopoverCloseButton,
  TagLabel,
  Tag
} from '@chakra-ui/react'
import { ReactComponent as IconEnter } from 'shared/assets/bsArrowReturnRight.svg'
import { useSelector } from 'model/hooks'
import { dbCreateCardTag } from 'controllers/cardTags'
import _ from 'lodash'
import { IWordCardTag } from 'shared/types'

type Props = {
  onSelect: (tagId: string) => void
  label?: string
  exclude?: string[]
}

const CardTagsSelector: FC<Props> = ({ onSelect, label, exclude }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cardTags = useSelector(state => state.cardTags)

  const ref = useRef<HTMLInputElement>(null)
  const [v, setV] = useState<string>('')

  const apply = () => {
    console.log('onBlur, apply', v)
    // onChange(v)
  }

  const sortedTags = useMemo(() => {
    return _.orderBy(cardTags, 'title')
  }, [cardTags])

  const tags = useMemo(() => {
    const exludedDict = _.keyBy(exclude)
    const filtered = _.filter(sortedTags, (c: IWordCardTag) => {
      const strMathces =
        v === '' || c.title.toLowerCase().includes(v.toLowerCase())
      const notExluded = !_.has(exludedDict, c.id)
      return strMathces && notExluded
    })
    return _.take(filtered, 30)
  }, [v, sortedTags, exclude])

  const renderInput = () => {
    return (
      <Input
        ref={ref}
        value={v}
        size='sm'
        textStyle={'small'}
        variant='flushed'
        h='4'
        placeholder='Поиск тэга'
        onChange={e => setV(e.target.value)}
        onFocus={onOpen}
        onBlur={onClose}
        color='black.600'
        onKeyDown={e => {
          if (e.key === 'Enter') {
            ref.current?.blur()
          }
        }}
      />
    )
  }

  const renderTags = () => {
    return (
      <HStack pt={2}>
        {_.map(tags, t => {
          return (
            <Tag
              key={t.id}
              size='sm'
              colorScheme='sun'
              as='button'
              onClick={() => onSelect(t.id)}
            >
              <TagLabel>{t.title}</TagLabel>
            </Tag>
          )
        })}
      </HStack>
    )
  }

  const onCreateTagClick = async () => {
    if (v !== '') {
      const tagId = await dbCreateCardTag(v)
      setV('')
      onSelect(tagId)
    }
  }

  const renderCreateTagButton = () => {
    if (v !== '') {
      return (
        <Button
          size='sm'
          variant={'link'}
          colorScheme='wood'
          onClick={onCreateTagClick}
        >
          {`Создать тэг ${v}`}
        </Button>
      )
    }
  }

  const renderPopoverContent = () => {
    return (
      <PopoverContent w='lg'>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody minH='32'>
          <VStack py={4} px={2} align='start' w='full'>
            {renderInput()}
            {renderCreateTagButton()}
            {renderTags()}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    )
  }

  return (
    <HStack w='full' align={'flex-end'}>
      <VStack w={'full'} spacing={1} align={'flex-start'}>
        {label && (
          <Text textStyle='small' color='black.950'>
            {label}
          </Text>
        )}
        <Popover
          returnFocusOnClose={false}
          // isOpen={isOpen}
          // onClose={onClose}
          placement='left'
          closeOnBlur={false}
        >
          <PopoverTrigger>
            <Button size='sm' variant={'link'}>
              + Add tag
            </Button>
          </PopoverTrigger>
          {renderPopoverContent()}
        </Popover>
      </VStack>
    </HStack>
  )
}

export default CardTagsSelector
