import ReactDOM from 'react-dom/client'
import './index.css'
import 'shared/qlStyles.css'
import App from './App'
import '@fontsource-variable/inter'
import '@fontsource-variable/inter/wght.css'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'

import 'dayjs/locale/ru'

dayjs.locale('ru')
dayjs.extend(duration)
dayjs.extend(relativeTime)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)
