import { functions } from 'controllers/db'
import { httpsCallable } from 'firebase/functions'
import { IEntityType } from 'shared/types'
import get from 'lodash/get'

export const duplicateWeek = async (
  entityId: string,
  entityType: IEntityType,
  weekId: string
) => {
  const f = httpsCallable(functions, 'duplicateWeek')
  const res = await f({ entityId, entityType, weekId })
  console.log('callable function res:', res)
  return null
}

export const duplicateDay = async (
  entityId: string,
  entityType: IEntityType,
  weekId: string,
  dayId: string
) => {
  const f = httpsCallable(functions, 'duplicateDay')
  const res = await f({ entityId, entityType, weekId, dayId })
  console.log('callable function res:', res)
  return null
}

export const duplicateLesson = async (
  entityId: string,
  entityType: IEntityType,
  weekId: string,
  dayId: string,
  lessonId: string
) => {
  const f = httpsCallable(functions, 'duplicateLesson')
  const res = await f({ entityId, entityType, weekId, dayId, lessonId })
  console.log('callable function res:', res)
  return null
}

export const dbDuplicateLessonTo = async (
  toEntityId: string,
  entityType: IEntityType,
  toWeekId: string,
  toDayId: string,
  entityId: string,
  weekId: string,
  dayId: string,
  lessonId: string
) => {
  const f = httpsCallable(functions, 'duplicateLessonTo')
  const res = await f({
    toEntityId,
    entityType,
    toWeekId,
    toDayId,
    lessonId,
    entityId,
    weekId,
    dayId
  })
  console.log('callable function res:', res)
  return get(res.data, 'success', false)
}

export const dbDuplicateDayTo = async (
  toEntityId: string,
  entityType: IEntityType,
  toWeekId: string,
  entityId: string,
  weekId: string,
  dayId: string
) => {
  const f = httpsCallable(functions, 'duplicateDayTo')
  const res = await f({
    toEntityId,
    entityType,
    toWeekId,
    entityId,
    weekId,
    dayId
  })
  console.log('callable function res:', res)
  return get(res.data, 'success', false)
}

export const dbDuplicateWeekTo = async (
  toEntityId: string,
  entityType: IEntityType,
  entityId: string,
  weekId: string
) => {
  const f = httpsCallable(functions, 'duplicateWeekTo')
  const res = await f({
    toEntityId,
    entityType,
    entityId,
    weekId
  })
  console.log('callable function res:', res)
  return get(res.data, 'success', false)
}
