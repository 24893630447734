import { FC } from 'react'
import { ISectionHeading, IStyledText } from 'shared/types'
import { Box, VStack } from '@chakra-ui/react'
import map from 'lodash/map'

type Props = {
  s: ISectionHeading
}

const SectionHeading: FC<Props> = ({ s }) => {
  const renderRow = (r: IStyledText, i: number) => {
    return (
      <Box
        w='full'
        key={i}
        textAlign={s.alignment}
        className='ql-snow'
        textStyle={r.textStyle || 'body'}
        dangerouslySetInnerHTML={{ __html: r.text }}
      />
    )
  }
  return (
    <VStack minH={10} align={s.alignment} w='full' spacing={2}>
      {map(s.data, renderRow)}
    </VStack>
  )
}

export default SectionHeading
