import { Box, HStack, FormControl, FormLabel, VStack } from '@chakra-ui/react'
import RowActionButtons from 'components/RowActionButtons'
import { FC, useMemo } from 'react'
import SimpleInput from 'shared/components/SimpleInput'
import { ITestContent } from 'shared/types'
import isString from 'lodash/isString'
import isArray from 'lodash/isArray'

type Props = {
  test: ITestContent
  onChange: (v: ITestContent) => void
}

const EditTestText: FC<Props> = ({ test, onChange }) => {
  const answer = useMemo(() => {
    if (test.answer && isString(test.answer)) {
      return [test.answer]
    } else {
      return test.answer || []
    }
  }, [test])

  const updateAnswerVariant = (i: number, v: string) => {
    const newAnswer = [...answer]
    newAnswer[i] = v
    onChange({ ...test, answer: newAnswer })
  }

  const deleteVariant = (i: number) => {
    const newAnswer = [...answer]
    newAnswer.splice(i, 1)
    onChange({ ...test, answer: newAnswer })
  }

  const addVariant = (v: string) => {
    if (v !== '') {
      const newAnswer = [...answer, v]
      onChange({ ...test, answer: newAnswer })
    }
  }

  const renderAnswerVariant = (v: string, i: number) => {
    return (
      <HStack key={i} w='full' position={'relative'} role='group'>
        <FormControl>
          <SimpleInput
            variant={'flushed'}
            size='sm'
            value={v}
            onChange={(v: string) => updateAnswerVariant(i, v)}
          />
        </FormControl>
        <Box position={'absolute'} right={-4} bottom={1}>
          <RowActionButtons onDelete={() => deleteVariant(i)} />
        </Box>
      </HStack>
    )
  }

  const renderNewOption = () => {
    return (
      <HStack w='full' pt={2}>
        <FormControl>
          <SimpleInput
            variant={'flushed'}
            placeholder='Добавить вариант'
            size='sm'
            onChange={addVariant}
            clearOnApply
          />
        </FormControl>
      </HStack>
    )
  }

  return (
    <VStack w='full' align={'flex-start'} spacing={4}>
      <HStack w='full'>
        <FormControl>
          <FormLabel>Варианты ответа:</FormLabel>
          <VStack>{isArray(answer) && answer.map(renderAnswerVariant)}</VStack>
          {renderNewOption()}
        </FormControl>
      </HStack>
    </VStack>
  )
}

export default EditTestText
