import { createReducer } from '@reduxjs/toolkit'
import { ITeacher } from 'shared/types'
import { receiveTeachers } from 'src/model/actions'

const initialState = null as Record<string, ITeacher> | null

const reducer = createReducer(initialState, builder => {
  builder.addCase(receiveTeachers, (state, action) => action.payload)
})

export default reducer
