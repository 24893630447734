import { functions } from 'controllers/db'
import { httpsCallable } from 'firebase/functions'
import { FileT } from 'shared/types'
import _ from 'lodash'

export const filePathByUrl = (url: string) => {
  const baseUrl = url.split('?')[0]
  const urlAr = baseUrl.split('/')
  const fPathRaw = _.last(urlAr)
  return fPathRaw?.split('%2F').join('/')
}

const sleep = async (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const dbProcessAudioFile = async (file: FileT) => {
  if (!file.url) {
    console.error('no url', file)
    return file
  }
  if (file.contentType !== 'audio/mpeg') {
    const f = httpsCallable(functions, 'processAudioFileNew')
    const res = await f(file)
    await sleep(500)
    return res.data as FileT
  } else {
    console.log('file is already mpeg')
    return file
  }
}
