import { FC } from 'react'
import { ISectionVimeo } from 'shared/types'
import { Box, Text, VStack, HStack } from '@chakra-ui/react'
import Vimeo from '@u-wave/react-vimeo'
import SectionTextContent from 'shared/components/SectionTextContent'

type Props = {
  s: ISectionVimeo
}

const SectionVimeo: FC<Props> = ({ s }) => {
  return (
    <VStack
      w='full'
      h='fit-content'
      maxH='full'
      position='relative'
      // py={{ base: 3, lg: 4 }}
      // pt={{ base: 2, lg: 6 }}
      // pb={{ base: 8, lg: '22px' }}
      spacing={2}
    >
      {s.title && s.title !== '' && (
        <SectionTextContent r={{ text: s.title, textStyle: s.textStyle }} />
      )}
      <Box
        // flex={1}
        w='full'
        // pt={{ base: 6, lg: 6 }}
        // pb={{ base: 6, lg: '22px' }}
        // maxH={{ base: 'full', lg: '80vh' }}
        // position={'relative'}
        sx={{
          iframe: { borderRadius: 'xs' },
          // iframe: { maxHeight: { base: 'none', lg: '80vh' } },
          '#vimeo_video > div': {
            // padding: { base: 'none', lg: '0px !important' }
            // position: { base: 'relative', lg: 'unset !important' }
          }
        }}
      >
        {s.vimeoVideoId && (
          <Vimeo
            id='vimeo_video'
            video={s.vimeoVideoId}
            responsive
            showPortrait={false}
            onError={e => console.log('vimeo video error:', e)}
          />
        )}
      </Box>
    </VStack>
  )
}

export default SectionVimeo
