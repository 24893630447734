import {
  onSnapshot,
  query,
  collection,
  doc,
  setDoc,
  updateDoc,
  where,
  getDocs,
  writeBatch,
  deleteField
} from 'firebase/firestore'
import { db, generateId } from 'src/controllers/db'
import { ILevel } from 'shared/types'
import { receiveLevels } from 'src/model/actions'
import store from 'src/model/store'
import { addListener } from 'controllers/listeners'

export const dbFetchLevels = async () => {
  try {
    const q = query(collection(db, 'levels'))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: Record<string, ILevel> = {}
        sn.forEach(doc => {
          const p = doc.data() as ILevel
          res[doc.id] = p
        })
        store.dispatch(receiveLevels(res))
      },
      err => {
        console.log(`dbFetchLevels error: ${err.message}`)
      }
    )
    addListener('levels', unsubscribe)
  } catch (e) {
    console.error('dbFetchLevels error', e)
  }
}

export const dbCreateLevel = async () => {
  const id = generateId()
  const p: ILevel = {
    id,
    name: 'Новый уровень'
  }
  const r = doc(db, 'levels', id)
  await setDoc(r, p)
  return id
}

export const dbUpdateLevel = async (id: string, name: string) => {
  const r = doc(db, 'levels', id)
  await updateDoc(r, { name })
}

export const dbDeleteLevel = async (id: string) => {
  const q = query(collection(db, 'library'), where('levelId', '==', id))
  const coursesSN = await getDocs(q)
  const batch = writeBatch(db)
  coursesSN.forEach(courseDoc =>
    batch.update(doc(db, 'library', courseDoc.id), {
      levelId: deleteField()
    })
  )
  batch.delete(doc(db, 'levels', id))
  await batch.commit()
}
