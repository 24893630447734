import { AddIcon, DeleteIcon } from '@chakra-ui/icons'
import {
  Button,
  Card,
  CardBody,
  HStack,
  Text,
  VStack,
  useToast
} from '@chakra-ui/react'
import NavBar from 'components/NavBar'
import UsersSearch from 'components/UsersSearch'
import {
  dbChangeUserEmail,
  dbDeleteUser,
  dbGetUser,
  dbUpdateUser
} from 'controllers/users'
import UserPayments from 'pages/users/UserPayments'
import UserSettings from 'pages/users/UserSettings'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { IUser } from 'shared/types'
import AddUserModal, { IAddUserModal } from 'modals/AddUserModal'
import DeleteConfirmation, {
  IDeleteConfirmationModal
} from 'modals/DeleteConfirmation'

const Users = () => {
  const addUserModalRef = useRef<IAddUserModal>(null)
  const navigate = useNavigate()
  const { userId } = useParams()
  const [user, setUser] = useState<IUser>()
  const toast = useToast()
  const deleteModalRef = useRef<IDeleteConfirmationModal>(null)
  const [isDeleting, setIsDeleting] = useState(false)

  useEffect(() => {
    if (userId) {
      const run = async () => {
        const u = await dbGetUser(userId)
        if (u) {
          setUser(u)
        } else {
          setUser(undefined)
        }
      }
      run()
    } else {
      setUser(undefined)
    }
  }, [userId])

  const updateUser = async (email: string, name: string, phone: string) => {
    if (user) {
      if (email !== user.email) {
        const err = await dbChangeUserEmail(user.id, email)
        if (err) {
          toast({
            title: 'Произошла ошибка',
            description: err,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top'
          })
        }
        return
      }
      await dbUpdateUser(user.id, { name, phone })
      setUser({ ...user, email, name, phone })
    }
  }

  const onDelete = async () => {
    if (user) {
      setIsDeleting(true)
      const err = await dbDeleteUser(user.id)
      setIsDeleting(false)
      if (err) {
        toast({
          title: 'Произошла ошибка',
          description: err,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top'
        })
      } else {
        toast({
          title: 'Пользователь удален',
          // description: err,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top'
        })
      }
      navigate('/users')
    }
  }

  const navBarActions = (
    <HStack spacing={4}>
      {user && (
        <Button
          variant={'outline'}
          colorScheme='red'
          leftIcon={<DeleteIcon />}
          onClick={() => deleteModalRef.current?.open()}
          isLoading={isDeleting}
        >
          Удалить
        </Button>
      )}
      <Button
        colorScheme='teal'
        leftIcon={<AddIcon />}
        onClick={() => addUserModalRef.current?.open()}
      >
        Создать
      </Button>
    </HStack>
  )

  return (
    <>
      <VStack
        w='full'
        h='full'
        spacing={6}
        overflow={'hidden'}
        maxH={'full'}
        pb={6}
      >
        {user ? (
          <NavBar title={user.name} backUrl='/users'>
            {navBarActions}
          </NavBar>
        ) : (
          <NavBar hideBackButton title={'Пользователи'}>
            {navBarActions}
          </NavBar>
        )}
        <VStack w='full' h='full' overflowY={'auto'} spacing={6}>
          <Card w='full' maxW={'2xl'}>
            <CardBody>
              <UsersSearch
                onSelect={(id: string) => navigate(`/users/${id}`)}
              />
            </CardBody>
          </Card>
          {user && <UserSettings user={user} updateUser={updateUser} />}
          {user && <UserPayments key={user.id} user={user} />}
        </VStack>
      </VStack>
      <AddUserModal ref={addUserModalRef} />
      <DeleteConfirmation
        ref={deleteModalRef}
        title='Удаление пользователя'
        description={
          <Text>
            Вы собираетесь удалить пользователя <b>{user?.name}</b> и все его
            платежи. Это действие нельзя отменить. Вы уверены?'
          </Text>
        }
        onDelete={onDelete}
      />
    </>
  )
}

export default Users
