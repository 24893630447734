import { Box, Button, Divider, HStack, Text, VStack } from '@chakra-ui/react'
import { FC, useMemo } from 'react'
import { ILesson } from 'shared/types'
import { ReactComponent as IconRepeat } from 'shared/assets/bsArrowRepeat.svg'
import reduce from 'lodash/reduce'
import { getTestQuestionsAmount, percToAmount } from 'shared/utils/tests'
import { ISessionAnswer } from 'shared/types'
import { TEST_MAX_WIDTH } from 'shared/constants/main'

type Props = {
  lesson: ILesson
  sessionAnswers: Record<string, ISessionAnswer>
  onStartAgain: () => void
  goNext?: () => void
}

const TestResults: FC<Props> = ({
  lesson,
  sessionAnswers,
  onStartAgain,
  goNext
}) => {
  const tasksAmount = useMemo(() => {
    return getTestQuestionsAmount(lesson)
  }, [lesson])

  const [answersAmount, correctAnswersAmount] = useMemo(() => {
    return reduce(
      sessionAnswers,
      (res, sa) => {
        return [res[0] + 1, res[1] + (sa.isCorrect ? 1 : 0)]
      },
      [0, 0]
    )
  }, [sessionAnswers])

  const requiredAmount = useMemo(() => {
    // console.log('requiredAmount, tasksAmount', tasksAmount, 'perc', lesson.perc)
    return percToAmount(tasksAmount, lesson.perc)
  }, [lesson, tasksAmount])

  // console.log('requiredAmount', requiredAmount)

  return (
    <Box
      // boxShadow='shadow1'
      rounded='xs'
      w='full'
      maxW={TEST_MAX_WIDTH}
      bg='white'
      borderWidth={1}
      borderColor={'sun.950'}
      mt={{ base: 3, lg: 4 }}
      p={4}
    >
      <Text textStyle='h3' fontWeight={700}>
        Результаты теста
      </Text>
      <VStack
        w='full'
        py={'22px'}
        textStyle={'small'}
        color='black.950'
        divider={<Divider borderColor='black.200' />}
        spacing={4}
      >
        <HStack justify={'space-between'} w='full'>
          <Text>Правильных ответов:</Text>
          <Text>
            {correctAnswersAmount} из {tasksAmount}
          </Text>
        </HStack>
        <HStack justify={'space-between'} w='full'>
          <Text>Дано ответов:</Text>
          <Text>
            {answersAmount} из {tasksAmount}
          </Text>
        </HStack>
        <HStack justify={'space-between'} w='full'>
          <Text>
            Минимальное число <br />
            правильных ответов:
          </Text>
          <Text>{requiredAmount}</Text>
        </HStack>
      </VStack>
      <VStack w='full' justify={'center'} spacing={4}>
        <Button
          size='sm'
          variant='primary'
          isDisabled={!goNext}
          onClick={goNext}
          bg='black.950'
          color='white'
          _hover={{ bg: 'black.800' }}
        >
          Дальше
        </Button>
        <Button
          size='sm'
          variant='unstyled'
          color='black.800'
          fontWeight={450}
          onClick={onStartAgain}
        >
          <HStack>
            <IconRepeat width={12} />
            <Text textStyle={'tag'} color='black.800'>
              Пройти тест еще раз
            </Text>
          </HStack>
        </Button>
      </VStack>
    </Box>
  )
}

export default TestResults
