import { ChangeEvent, FC, useEffect, useState, useMemo } from 'react'
import { HStack, VStack, Text, Box } from '@chakra-ui/react'
import { AutoResizeTextarea } from 'shared/components/chat/AutoResizeTextarea'
import { ISyncTranslate } from 'shared/types'
import map from 'lodash/map'
import get from 'lodash/get'
import trim from 'lodash/trim'
import has from 'lodash/has'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from 'react-beautiful-dnd'
import { arrayMove } from 'shared/utils/array'
import { findIndex, uniq } from 'lodash'

type Props = {
  st: ISyncTranslate
  onChange: (st: ISyncTranslate) => void
}

const findMatches = (str: string) => {
  const res = str.match(/\{(.*?)\}/g)
  return res || ([] as string[])
}

const Paragraph: FC<Props> = ({ st, onChange }) => {
  const [task, setTask] = useState(st.task)
  const [translate, setTranslate] = useState(st.translate)
  const [taskMatches, setTaskMatches] = useState<string[]>(findMatches(st.task))
  const [translateMatches, setTranslateMatches] = useState<string[]>(
    findMatches(st.translate)
  )

  useEffect(() => {
    onChange({ ...st, task })
  }, [task])

  useEffect(() => {
    onChange({ ...st, translate })
  }, [translate])

  const matching = useMemo(() => {
    const createArrayN = (n: number) => {
      const a: number[] = []
      for (let i = 0; i < n; i++) {
        a[i] = i
      }
      return a
    }
    const matchingIsValid =
      st.matching &&
      findIndex(st.matching, (v, k) => v >= st.matching.length) === -1 &&
      st.matching.length === translateMatches.length &&
      uniq(st.matching).length === translateMatches.length
    const res = matchingIsValid
      ? st.matching
      : createArrayN(translateMatches.length)
    return res
  }, [st, translateMatches])

  const onTaskChanged = (v: string) => {
    setTaskMatches(findMatches(v))
    setTask(v)
  }

  const onTranslateChanged = (v: string) => {
    setTranslateMatches(findMatches(v))
    setTranslate(v)
  }

  const renderTask = () => {
    return (
      <AutoResizeTextarea
        value={task || ''}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
          onTaskChanged(e.target.value)
        }
      />
    )
  }

  const renderTranslate = () => {
    return (
      <AutoResizeTextarea
        value={translate || ''}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
          onTranslateChanged(e.target.value)
        }
      />
    )
  }

  console.log('matching', matching)

  const onDragEnd = (result: DropResult) => {
    console.log('onDragEnd', result)
    if (result.destination && result.destination.index >= 0) {
      const newMathcing = arrayMove(
        matching,
        result.source.index,
        result.destination?.index || 0
      )
      onChange({ ...st, matching: newMathcing })
    }
  }

  const getListStyle = (isDraggingOver: boolean) => ({
    // bg: isDraggingOver ? 'white' : 'lightGrey',
    // p: 2
  })

  const getItemStyle = (isDragging: boolean) => ({
    // some basic styles to make the items look a bit nicer
    // userSelect: 'none',
    px: 2,
    py: 1,
    // m: `0 0 2 0`,

    // change background colour if dragging
    background: isDragging ? 'lightGray' : 'white'

    // // styles we need to apply on draggables
    // ...draggableStyle
  })

  const renderTranslateMatches = () => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable'>
          {(provided, snapshot) => (
            <VStack
              align='flex-end'
              flex={1}
              justify={'flex-start'}
              spacing={1}
              fontWeight={700}
              color='darkGray'
              ref={provided.innerRef}
              {...getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
            >
              {map(matching, (v, i) => {
                if (has(translateMatches, v)) {
                  const tm = translateMatches[v]
                  return (
                    <Draggable key={i} draggableId={tm + i} index={i}>
                      {(provided, snapshot) => (
                        <HStack
                          w='full'
                          ref={provided.innerRef}
                          borderWidth={1}
                          borderColor='lightGray'
                          rounded='4px'
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          {...getItemStyle(snapshot.isDragging)}
                        >
                          <Text>{trim(tm, '{}')}</Text>
                        </HStack>
                      )}
                    </Draggable>
                  )
                }
              })}
              {provided.placeholder}
            </VStack>
          )}
        </Droppable>
      </DragDropContext>
    )
  }

  const renderTaskMatches = () => {
    return (
      <VStack
        align='flex-start'
        flex={1}
        justify={'flex-start'}
        spacing={1}
        color='black'
        // bg='lightGray'
        // rounded={'4px'}
        // p={1}
      >
        {map(taskMatches, (tm, i) => {
          return (
            <HStack
              w='full'
              borderWidth={1}
              borderColor='lightGray'
              rounded='4px'
              justify='flex-end'
              py={1}
              px={2}
            >
              <Text key={i}>{trim(tm, '{}')}</Text>
            </HStack>
          )
        })}
      </VStack>
    )
  }

  return (
    <VStack w='full'>
      <HStack w='full'>
        {renderTask()}
        {renderTranslate()}
      </HStack>
      <HStack w='full' h='fit-content' align={'flex-start'} pt={2}>
        {renderTaskMatches()}
        {renderTranslateMatches()}
      </HStack>
    </VStack>
  )
}

export default Paragraph
