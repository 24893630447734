import { createReducer } from '@reduxjs/toolkit'
import { receiveAuthData, logout } from 'src/model/actions'

const initialState = null as { uid: string; email: string | null } | null

const authDataReducer = createReducer(initialState, builder => {
  builder
    .addCase(receiveAuthData, (state, action) => action.payload)
    .addCase(logout, () => initialState)
})

export default authDataReducer
