import { FC, useMemo } from 'react'
import {
  VStack,
  HStack,
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text
} from '@chakra-ui/react'
import { IPadding, ISectionTeachers } from 'shared/types'
import map from 'lodash/map'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from 'react-beautiful-dnd'
import { ReactComponent as IconX } from 'shared/assets/bsXCircle.svg'
import { ReactComponent as IconList } from 'shared/assets/bsList.svg'
import { arrayMove } from 'shared/utils/array'
import PaddingSelect from 'components/PaddingSelect'
import { useSelector } from 'model/hooks'
import keys from 'lodash/keys'
import get from 'lodash/get'
import difference from 'lodash/difference'

type Props = {
  s: ISectionTeachers
  onChange: (s: ISectionTeachers) => void
}

const EditSectionTeachers: FC<Props> = ({ s, onChange }) => {
  const teachers = useSelector(state => state.teachers)

  const elapsedTeachersIds = useMemo(() => {
    const allTeachersIds = keys(teachers)
    return difference(allTeachersIds, s.teachers)
  }, [teachers, s.teachers])

  const onRemoveRow = (i: number) => {
    const newData = [...s.teachers]
    newData.splice(i, 1)
    onChange({ ...s, teachers: newData })
  }

  const onDragEnd = (result: DropResult) => {
    console.log('onDragEnd', result)
    if (result.destination && result.destination.index >= 0) {
      const newData = arrayMove(
        s.teachers,
        result.source.index,
        result.destination?.index || 0
      )
      onChange({ ...s, teachers: newData })
    }
  }

  const addTeacher = (tId: string) => {
    const newTeachers = [...(s.teachers || []), tId]
    onChange({ ...s, teachers: newTeachers })
  }

  const renderRow = (tId: string, i: number, isDraggable: boolean) => {
    if (isDraggable) {
      return (
        <Draggable key={i} draggableId={tId} index={i}>
          {(provided, snapshot) => (
            <HStack
              borderWidth={1}
              borderColor='wood.600'
              px={2}
              w='full'
              // h={{ base: 6, lg: 8 }}
              rounded='4px'
              flexShrink={0}
              align='center'
              justify={'space-between'}
              color='wood.600'
              textStyle={{ base: 'tag', lg: 'small' }}
              ref={provided.innerRef}
              {...provided.draggableProps}
            >
              <Box {...provided.dragHandleProps}>
                <IconList width='14' height='14' />
              </Box>
              <Text>{get(teachers, [tId, 'name'])}</Text>
              <Box
                textStyle={{ base: 'tag', lg: 'small' }}
                as='button'
                onClick={() => onRemoveRow(i)}
                color='red.700'
              >
                <IconX width='14' height='14' />
              </Box>
            </HStack>
          )}
        </Draggable>
      )
    } else {
      return (
        <HStack
          borderWidth={1}
          borderColor='wood.600'
          px={2}
          w='full'
          rounded='4px'
          flexShrink={0}
          align='center'
          justify={'space-between'}
          color='wood.600'
          textStyle={{ base: 'tag', lg: 'small' }}
        >
          <HStack minH={8} w='full' justify={'center'}>
            <Text textAlign={'center'}>{get(teachers, [tId, 'name'])}</Text>
          </HStack>
        </HStack>
      )
    }
  }

  const renderRows = () => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId='tags'
          renderClone={(provided, snapshot, rubric) => {
            return (
              <div ref={provided.innerRef} {...provided.draggableProps}>
                {renderRow(s.teachers[rubric.source.index], 0, false)}
              </div>
            )
          }}
        >
          {(provided, snapshot) => (
            <VStack
              w='full'
              direction={'column'}
              align='flex-start'
              textStyle='body'
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {map(s.teachers, (p, i) => renderRow(p, i, true))}
              {provided.placeholder}
            </VStack>
          )}
        </Droppable>
      </DragDropContext>
    )
  }

  const renderAddRow = () => {
    return (
      <HStack w='full' align='center' spacing={2} justify={'space-between'}>
        <Menu>
          <MenuButton
            as={Button}
            variant={'ghost'}
            size='sm'
            colorScheme='wood'
            isDisabled={elapsedTeachersIds.length === 0}
          >
            Добавить преподавателя
          </MenuButton>
          <MenuList>
            {map(elapsedTeachersIds, tId => {
              return (
                <MenuItem key={tId} onClick={() => addTeacher(tId)}>
                  {get(teachers, [tId, 'name'])}
                </MenuItem>
              )
            })}
          </MenuList>
        </Menu>
      </HStack>
    )
  }

  const renderPadding = () => {
    return (
      <PaddingSelect
        value={s.paddingTop}
        onChange={(v: IPadding) => onChange({ ...s, paddingTop: v })}
      />
    )
  }

  return (
    <VStack w='full' spacing={6}>
      {/* {renderSectionWidth()} */}
      {renderPadding()}
      <VStack w='full'>
        {renderRows()}
        {renderAddRow()}
      </VStack>
    </VStack>
  )
}

export default EditSectionTeachers
