export const mapObject = <TValue, TNewValue>(
  obj: Record<string, TValue>,
  mapFunc: (value: TValue, key: string) => TNewValue
): Record<string, TNewValue> => {
  return Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      [key]: mapFunc(obj[key], key)
    }),
    {} as Record<string, TNewValue>
  )
}
