import { query, collection, where, orderBy, getDocs } from 'firebase/firestore'
import { db } from 'controllers/db'
import { IFeedback } from 'shared/types'

export const dbGetFeedbackByDate = async (
  startDate: number,
  endDate: number
) => {
  const q = query(
    collection(db, 'feedback'),
    where('createdAt', '>=', startDate),
    where('createdAt', '<', endDate),
    orderBy('createdAt')
  )
  const sn = await getDocs(q)
  return sn.docs.map(doc => doc.data() as IFeedback)
}
