import { FC, ReactNode } from 'react'
import { motion } from 'framer-motion'

type Props = {
  children: ReactNode
  isOpen: boolean
  width: number | string
}

const HorizontalCollapse: FC<Props> = ({ children, isOpen, width }) => {
  return (
    <motion.div
      initial={false}
      animate={{ width: isOpen ? width : 0 }}
      style={{
        height: '100%'
      }}
      transition={{ type: 'easeIn' }}
    >
      {children}
    </motion.div>
  )
}

export default HorizontalCollapse
