import { HStack, Select, Flex } from '@chakra-ui/react'
import Label from 'shared/components/Label'
import { FC } from 'react'
import { IPadding } from 'shared/types'

type Props = {
  label?: string
  value?: IPadding
  onChange: (v: IPadding) => void
}

const PaddingSelect: FC<Props> = ({
  label = 'Отступ сверху',
  value,
  onChange
}) => {
  return (
    <HStack w='full' align='center' spacing={2} justify={'space-between'}>
      <Label>{label}</Label>
      <Flex>
        <Select
          value={value || IPadding.SMALL}
          onChange={e => onChange(e.target.value as IPadding)}
          variant={'solid'}
          size='xs'
        >
          <option value={IPadding.SMALL}>Small</option>
          <option value={IPadding.MEDIUM}>Medium</option>
          <option value={IPadding.LARGE}>Large</option>
        </Select>
      </Flex>
    </HStack>
  )
}

export default PaddingSelect
