import { FC } from 'react'
import { Flex, Box, FlexProps } from '@chakra-ui/react'

type Props = FlexProps & {
  variant: 'default' | 'success' | 'error' | 'selected'
}

const RadioIcon: FC<Props> = ({ variant, ...rest }) => {
  return (
    <Flex
      rounded={'full'}
      borderWidth={1}
      borderColor={
        variant === 'success'
          ? 'green.800'
          : variant === 'error'
          ? 'red.700'
          : 'black.700'
      }
      boxSize={4}
      align={'center'}
      justify={'center'}
      flexShrink={0}
      {...rest}
    >
      {variant !== 'default' && (
        <Box
          boxSize={2.5}
          rounded={'full'}
          bg={
            variant === 'selected'
              ? 'black.700'
              : variant === 'success'
              ? 'green.800'
              : 'red.700'
          }
        />
      )}
    </Flex>
  )
}

export default RadioIcon
