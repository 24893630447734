import { FC, useState, useEffect } from 'react'
import { VStack, HStack, Flex, Text } from '@chakra-ui/react'
import map from 'lodash/map'
import get from 'lodash/get'
import forEach from 'lodash/forEach'

import filter from 'lodash/filter'
import replace from 'lodash/replace'
import isUndefined from 'lodash/isUndefined'
import { IWord } from 'shared/components/sections/sectionTestSyncTranslate/SyncTranslateTask'

type Props = {
  translate: string
  isExamination: boolean
  showUserAnswer: boolean
  completed: boolean
  highlightCorrectAnswer: boolean
  addedWords: (null | boolean)[]
  matching: number[]
  attempts: (null | boolean)[]
}

const SyncTranslateTranslate = ({
  translate,
  isExamination,
  showUserAnswer,
  completed,
  highlightCorrectAnswer,
  addedWords,
  matching,
  attempts
}) => {
  const [textParts, setTextParts] = useState<IWord[][]>([])

  // console.log('addedWords', addedWords)

  useEffect(() => {
    if (translate) {
      // findMatches(task)
      // const cleanString = t.replace(/{\s*(.*?)\s*}/g, '{$1}')
      const cleanString = replace(
        translate,
        /{\s*(.*?)\s*}/g,
        v => {
          // console.log('cleanString part:', v)
          return `{${v
            .substring(1, v.length - 1)
            .trim()
            .split(' ')
            .join('_')}}`
        }
        // v.split(' ').join('')
      )
      const partsRow = cleanString.split(/\r?\n/)
      let wordNum = 0
      const parts = map(partsRow, s => {
        const filteredSections = s.split(' ').filter(v => v)
        const res = []

        forEach(filteredSections, s => {
          if (s.startsWith('{')) {
            res.push({ s, wordNum })
            wordNum++
          } else {
            res.push({ s })
          }
        })
        return res
      })
      // console.log('parts', parts)
      setTextParts(parts)
    }
  }, [translate])

  const renderWord = (v: string, i: number, wordNum: number) => {
    // console.log('renderWord', v, i, wordNum)
    let bg = 'blue10'
    let color = 'black'
    // console.log('matching', matching, 'addedWords', addedWords)
    let wIndex = matching.indexOf(wordNum)
    if (matching.length === 0) wIndex = wordNum
    // console.log('wIndex', wIndex)
    if (wIndex >= 0 && !showUserAnswer) {
      let v = get(addedWords, wIndex)
      if (v === null) {
        v = get(attempts, wIndex)
      }
      if (v !== null) {
        bg = v ? 'green10' : 'red10'
        color = v ? 'green' : 'red'
      }
    }
    return (
      <Flex h={6} align={'center'} key={i}>
        <Flex bg={bg} px={0.5}>
          <Text as='span' key={i} color={color}>
            {v}
          </Text>
        </Flex>
      </Flex>
    )
  }

  return (
    <VStack justify={'flex-start'} align={'flex-start'} spacing={0}>
      {map(textParts, (section, sectionIndex) => {
        return (
          <HStack
            key={sectionIndex}
            spacing={1.5}
            textStyle={'medium'}
            flexWrap='wrap'
            justify={'flex-start'}
            align={'flex-start'}
          >
            {map(section, (s, i) => {
              if (!isUndefined(s.wordNum)) {
                let value = filter(s.s, s => s !== '{' && s !== '}').join('')
                value = value.split('_').join(' ')
                return renderWord(value, i, s.wordNum)
              } else {
                return (
                  <Flex h={6} align={'center'} key={i}>
                    <Text as='span' key={i}>
                      {s.s}
                    </Text>
                  </Flex>
                )
              }
            })}
          </HStack>
        )
      })}
    </VStack>
  )
}

export default SyncTranslateTranslate
