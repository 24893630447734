import {
  VStack,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem
} from '@chakra-ui/react'
import NavBar from 'components/NavBar'
import { useSelector } from 'model/hooks'
import {
  ILesson,
  ILessonType,
  ISection,
  IWebinar,
  SectionType
} from 'shared/types'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import get from 'lodash/get'
import Loading from 'components/Loading'
import LessonsList from 'components/LessonsList'
import { useContext, useMemo, useRef, useState } from 'react'
import LessonsContext from 'contexts/LessonsContext'
import { generateId, storageRef } from 'controllers/db'
import { dbCreateLesson, dbDeleteLessonEntity } from 'controllers/lessons'
import filter from 'lodash/filter'
import { CopyIcon, DeleteIcon, SettingsIcon } from '@chakra-ui/icons'
import DeleteConfirmation, {
  IDeleteConfirmationModal
} from 'modals/DeleteConfirmation'
import CustomTabPanel from 'components/CustomTabPanel'
import EntityUsers from 'components/EntityUsers'
import {
  dbDeleteWebinar,
  dbDuplicateWebinar,
  dbUpdateWebinar
} from 'controllers/webinars'
import WebinarSettingsDrawer, {
  IWebinarSettingsDrawer
} from 'modals/webinar/WebinarSettingsDrawer'
import EditSectionDrawer from 'modals/EditSectionDrawer'
import { createSection } from 'controllers/sections'
import { arrayInsertAt, arrayMove } from 'shared/utils/array'
import CoursePage from 'shared/pages/CoursePage'
import { compact } from 'lodash'
import Footer from 'shared/components/Footer'

const Webinar = () => {
  const navigate = useNavigate()
  const { lessons } = useContext(LessonsContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const { entityId } = useParams() as { entityId: string }
  const deleteModalRef = useRef<IDeleteConfirmationModal>(null)
  const partitions = useSelector(state => state.partitions)
  const levels = useSelector(state => state.levels)
  const teachers = useSelector(state => state.teachers)
  const c: IWebinar | undefined = useSelector(state =>
    get(state.webinars, entityId)
  )
  const settingsDrawerRef = useRef<IWebinarSettingsDrawer>(null)
  const [editSectionId, setEditSectionId] = useState<string>()

  const currentTabIndex = useMemo(() => {
    const tab = searchParams.get('tab')
    if (tab) {
      return +tab
    } else {
      return 0
    }
  }, [searchParams])

  const updateLessons = (ids: string[]) => {
    dbUpdateWebinar(entityId, { lessons: ids })
  }

  const onDuplicateLesson = (lessonId: string) => {
    const l = lessons && lessons[lessonId]
    if (l && c) {
      const id = generateId()
      const newL: ILesson = {
        ...l,
        id,
        name: l.name + ' (copy)',
        entityId: c.id
      }
      dbCreateLesson(id, newL)
      dbUpdateWebinar(entityId, { lessons: [...(c?.lessons || []), id] })
    }
  }

  const onAddLesson = () => {
    if (c) {
      const id = generateId()
      const newL: ILesson = {
        id,
        name: 'Новый урок',
        entityId: c.id,
        type: ILessonType.LECTURE
      }
      dbCreateLesson(id, newL)
      dbUpdateWebinar(entityId, { lessons: [...(c?.lessons || []), id] })
    }
  }

  const onDeleteLesson = (lessonId: string) => {
    if (c) {
      const newIds = filter(c.lessons, lId => lId !== lessonId)
      dbUpdateWebinar(entityId, { lessons: newIds })
      dbDeleteLessonEntity(lessonId, c.id)
    }
  }

  const onLessonClick = (id: string) => {
    navigate(`/webinars/${entityId}/${id}`)
  }

  const onDelete = () => {
    console.log('deleteWebinar')
    if (c) {
      dbDeleteWebinar(c)
      navigate('/webinars')
    }
  }

  const onDuplicate = async () => {
    if (c && lessons) {
      const newId = await dbDuplicateWebinar(c, lessons)
      navigate(`/webinars/${newId}`)
    }
  }

  const navBarActions = (
    <Menu>
      <MenuButton as={IconButton} icon={<SettingsIcon />}>
        Actions
      </MenuButton>
      <MenuList>
        <MenuItem icon={<CopyIcon />} onClick={onDuplicate}>
          Создать копию вебинара
        </MenuItem>
        <MenuItem
          color='red'
          icon={<DeleteIcon />}
          onClick={() => deleteModalRef.current?.open()}
        >
          Удалить вебинар
        </MenuItem>
      </MenuList>
    </Menu>
  )

  const onAddSection = (sType: SectionType, i: number) => {
    if (c) {
      const s = createSection(sType)
      const newSections = {
        ...(c.sections || {}),
        [s.id]: s
      }
      const newSectionsOrder = arrayInsertAt(c.sectionsOrder || [], i, s.id)
      const upd = {
        sections: newSections,
        sectionsOrder: newSectionsOrder
      }
      dbUpdateWebinar(c.id, upd)
      setEditSectionId(s.id)
    }
  }

  const onMoveSection = (fromIndex: number, toIndex: number) => {
    console.log('onMoveSection', fromIndex, toIndex)
    if (c && c.sectionsOrder) {
      const newSectionsOrder = arrayMove(c.sectionsOrder, fromIndex, toIndex)
      const upd = {
        sectionsOrder: newSectionsOrder
      }
      dbUpdateWebinar(c.id, upd)
    }
  }

  const updateSection = (s: ISection) => {
    if (c) {
      const upd = {
        sections: {
          ...(c.sections || {}),
          [s.id]: s
        }
      }
      dbUpdateWebinar(c.id, upd)
    }
  }

  const onEditSection = (sId: string) => {
    setEditSectionId(sId)
  }

  const onDeleteSection = () => {
    if (c && editSectionId) {
      const newOrder = filter(c.sectionsOrder, lId => lId !== editSectionId)
      const newSections = { ...c.sections }
      delete newSections[editSectionId]
      setEditSectionId(undefined)
      const upd = { sections: newSections, sectionsOrder: newOrder }
      dbUpdateWebinar(c.id, upd)
    }
  }

  const onDuplicateSection = () => {
    if (c && editSectionId && c.sections && c.sectionsOrder) {
      const s = c.sections[editSectionId]
      const index = c.sectionsOrder.indexOf(editSectionId)
      const id = generateId()
      const newS: ISection = {
        ...s,
        id
      }
      const newSections = {
        ...c.sections,
        [newS.id]: newS
      }
      const newSectionsOrder = arrayInsertAt(
        c.sectionsOrder || [],
        index + 1,
        newS.id
      )
      const upd = { sections: newSections, sectionsOrder: newSectionsOrder }
      dbUpdateWebinar(c.id, upd)
      setEditSectionId(id)
    }
  }

  const renderTabs = () => {
    return (
      <TabList
        display='flex'
        alignItems='center'
        justifyContent='center'
        p='4'
        overflow={'hidden'}
      >
        <Tab>Страница вебинара</Tab>
        <Tab>Уроки</Tab>
        <Tab>Ученики</Tab>
      </TabList>
    )
  }

  if (!c) {
    return <Loading />
  } else {
    const tags = [
      c.partitionId ? get(partitions, [c.partitionId, 'name']) : undefined,
      c.levelId ? get(levels, [c.levelId, 'name']) : undefined,
      ...(c.tags || [])
    ]
    return (
      <VStack w='full' h='full' spacing={0} overflow={'hidden'} maxH={'full'}>
        <Tabs
          size='sm'
          isLazy
          colorScheme='wood'
          variant='soft-rounded'
          w='full'
          overflow={'hidden'}
          h='full'
          flex={1}
          p={0}
          display={'flex'}
          flexDirection={'column'}
          index={currentTabIndex}
          onChange={(tabIndex: number) =>
            setSearchParams({ tab: tabIndex.toString() })
          }
        >
          <NavBar title={c.title} backUrl='/webinars' tabs={renderTabs()}>
            {navBarActions}
          </NavBar>

          <TabPanels overflow={'hidden'} flex={1}>
            <CustomTabPanel bg='black.950'>
              <VStack
                w='full'
                bg={'wood.100'}
                borderBottomRadius={{ base: 'sm', lg: 'lg' }}
                overflowY={'auto'}
              >
                <VStack
                  w='full'
                  justify={'flex-start'}
                  flex={1}
                  spacing={0}
                  h='full'
                  overflowX={'hidden'}
                  overflowY={'auto'}
                  minH='full'
                  bg='black.950'
                >
                  <VStack
                    w='full'
                    bg={'wood.100'}
                    borderBottomRadius={{ base: 'sm', lg: 'lg' }}
                  >
                    <CoursePage
                      storageRef={storageRef}
                      course={{ ...c, tags: compact(tags) }}
                      onPay={() => null}
                      toCourse={() => null}
                      userCourses={{}}
                      admin={{
                        onAddSection,
                        onMoveSection,
                        onEditSection,
                        onEditHeader: () =>
                          settingsDrawerRef.current?.open(c.id)
                      }}
                      teachers={teachers || {}}
                      partitions={partitions}
                    />
                  </VStack>
                  <Footer />
                </VStack>
              </VStack>
            </CustomTabPanel>

            <CustomTabPanel>
              <LessonsList
                ids={c.lessons}
                updateIds={updateLessons}
                onAddLesson={onAddLesson}
                onDuplicate={onDuplicateLesson}
                onDelete={onDeleteLesson}
                onClick={onLessonClick}
              />
            </CustomTabPanel>
            <CustomTabPanel>
              <EntityUsers />
            </CustomTabPanel>
          </TabPanels>
        </Tabs>
        <DeleteConfirmation
          ref={deleteModalRef}
          title='Удаление семинара'
          description={`Вы собираетесь удалить вебинар "${c.title}" и все его уроки. Вебинар станет не доступен для пользователей купивших его.`}
          onDelete={onDelete}
        />
        <WebinarSettingsDrawer ref={settingsDrawerRef} />
        <EditSectionDrawer
          s={editSectionId && get(c, ['sections', editSectionId])}
          onChange={updateSection}
          onClose={() => setEditSectionId(undefined)}
          entityId={c.id}
          onDelete={onDeleteSection}
          onDuplicate={onDuplicateSection}
        />
      </VStack>
    )
  }
}

export default Webinar
