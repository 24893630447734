import { createReducer } from '@reduxjs/toolkit'
import { IUser } from 'shared/types'
import { receiveUser, logout } from 'model/actions'

const initialState = null as IUser | null

const reducer = createReducer(initialState, builder => {
  builder.addCase(receiveUser, (state, action) => action.payload)
  builder.addCase(logout, (state, action) => null)
})

export default reducer
