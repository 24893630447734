const conf = {
  apiKey: 'AIzaSyB0hODalLKsytQYftj3CtnHf4EdzV_T7U0',
  authDomain: 'thequeenenglish.com',
  projectId: 'englishqueen-1a5cb',
  storageBucket: 'englishqueen-1a5cb.appspot.com',
  messagingSenderId: '626687619232',
  appId: '1:626687619232:web:4d1715c6c0e22a052ad9c5',
  measurementId: 'G-HD2ZRXP5J2',
  isDev: false,
  algoliaAppId: 'WO6OL86785',
  algoliaSearchKey: '11dde30943b8aa5fe747ab21b2f0e516',
  algoliaIndex: 'prod_users',
  algoliaCardsIndex: 'prod_cards',
  algoliaCardsSearchKey: '84dad69ee63e9b1e9ba2313ad62ea5b0',
  frontUrl: 'https://thequeenenglish.com'
}

module.exports = conf
