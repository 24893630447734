import { useEffect, useMemo, useRef, FC } from 'react'
import { Unsubscribe } from 'firebase/firestore'
import LessonsContext from 'contexts/LessonsContext'
import { useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { IEntity, IEntityType, ILesson, IUserPayment } from 'shared/types'
import { dbSubscribeToLessons } from 'controllers/lessons'
import { useSelector } from 'model/hooks'
import get from 'lodash/get'
import { dbSubscribeToUserPayments } from 'controllers/userPayments'

type Props = {
  entityType: IEntityType
}

const LessonsDataProvider: FC<Props> = ({ entityType }) => {
  const [lessons, setLessons] = useState<Record<string, ILesson> | null>(null)
  const [lessonsIds, setLessonsIds] = useState<string[]>([])
  const [userPayments, setUserPayments] = useState<IUserPayment[]>([])
  const { entityId } = useParams()
  const lessonsUnsubscribeRef = useRef<Unsubscribe | null>(null)
  const paymentsUnsubscribeRef = useRef<Unsubscribe | null>(null)
  const frees = useSelector(state => state.frees)
  const courses = useSelector(state => state.courses)
  const library = useSelector(state => state.library)
  const webinars = useSelector(state => state.webinars)
  const seminars = useSelector(state => state.seminars)
  const conferences = useSelector(state => state.conferences)

  const entity = useMemo(() => {
    if (entityId) {
      switch (entityType) {
        case IEntityType.FREE:
          return get(frees, entityId, null) as IEntity | null
        case IEntityType.COURSES:
          return get(courses, entityId, null) as IEntity | null
        case IEntityType.LIBRARY:
          return get(library, entityId, null) as IEntity | null
        case IEntityType.WEBINARS:
          return get(webinars, entityId, null) as IEntity | null
        case IEntityType.SEMINARS:
          return get(seminars, entityId, null) as IEntity | null
        case IEntityType.CONFERENCES:
          return get(conferences, entityId, null) as IEntity | null
        default:
          return null
      }
    } else {
      return null
    }
  }, [
    courses,
    webinars,
    conferences,
    library,
    entityId,
    entityType,
    frees,
    seminars
  ])

  useEffect(() => {
    let ids: string[] = []
    if (entityId) {
      switch (entityType) {
        case IEntityType.FREE:
          ids = get(courses, [entityId, 'lessons'], [])
          break
        case IEntityType.COURSES:
          ids = get(courses, [entityId, 'lessons'], [])
          break
        case IEntityType.LIBRARY:
          ids = get(library, [entityId, 'lessons'], [])
          break
        case IEntityType.WEBINARS:
          ids = get(webinars, [entityId, 'lessons'], [])
          break
        case IEntityType.SEMINARS:
          ids = get(seminars, [entityId, 'lessons'], [])
          break
        case IEntityType.CONFERENCES:
          ids = get(conferences, [entityId, 'lessons'], [])
          break
      }
    }
    setLessonsIds(ids)
  }, [
    courses,
    webinars,
    conferences,
    library,
    entityId,
    entityType,
    frees,
    seminars
  ])

  useEffect(() => {
    if (entityId) {
      lessonsUnsubscribeRef.current = dbSubscribeToLessons(entityId, res =>
        setLessons(res)
      )

      paymentsUnsubscribeRef.current = dbSubscribeToUserPayments(
        entityId,
        res => setUserPayments(res)
      )
    }
    return () => {
      if (lessonsUnsubscribeRef.current) {
        lessonsUnsubscribeRef.current()
      }
      if (paymentsUnsubscribeRef.current) {
        paymentsUnsubscribeRef.current()
      }
    }
  }, [entityId])

  const providerValue = useMemo(() => {
    return {
      lessons,
      lessonsIds,
      userPayments,
      entity,
      entityId: entityId || null,
      entityType
    }
  }, [lessons, lessonsIds, userPayments, entityId, entityType, entity])

  return (
    <LessonsContext.Provider value={providerValue}>
      <Outlet />
    </LessonsContext.Provider>
  )
}

export default LessonsDataProvider
