import { HStack, VStack, Box, Button, Flex, Select } from '@chakra-ui/react'
import { FC } from 'react'
import {
  IAlignment,
  IPadding,
  ISectionCard,
  ISectionColumns
} from 'shared/types'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from 'react-beautiful-dnd'
import map from 'lodash/map'
import { ReactComponent as IconX } from 'shared/assets/bsXCircle.svg'
import { ReactComponent as IconList } from 'shared/assets/bsList.svg'
import { arrayMove } from 'shared/utils/array'
import Label from 'shared/components/Label'
import SwitchField from 'components/formFields/SwitchField'
import AlignmentSelect from 'components/AlignmentSelect'
import SectionTextContent from 'shared/components/SectionTextContent'
import PaddingSelect from 'components/PaddingSelect'

type Props = {
  s: ISectionColumns
  onChange: (s: ISectionColumns) => void
  onEditCard: (i: number) => void
}

const EditSectionColumnsMain: FC<Props> = ({ s, onChange, onEditCard }) => {
  const onDragEnd = (result: DropResult) => {
    console.log('onDragEnd', result)
    if (result.destination && result.destination.index >= 0) {
      const columns = arrayMove(
        s.columns,
        result.source.index,
        result.destination?.index || 0
      )
      onChange({ ...s, columns })
    }
  }

  const onRemove = (i: number) => {
    console.log('onRemove', i)
    const columns = [...(s.columns || [])]
    columns.splice(i, 1)
    onChange({ ...s, columns })
  }

  const addCard = () => {
    const p: ISectionCard = {
      title: {
        text: 'Заголовок',
        textStyle: 'h3'
      },
      data: []
    }
    const columns = [...(s.columns || []), p]
    onChange({ ...s, columns })
  }

  const renderCard = (p: ISectionCard, i: number, isDraggable: boolean) => {
    if (isDraggable) {
      return (
        <Draggable key={i} draggableId={'card' + i} index={i}>
          {(provided, snapshot) => (
            <HStack
              borderWidth={1}
              borderColor='wood.600'
              px={2}
              w='full'
              // h={{ base: 6, lg: 8 }}
              rounded='4px'
              flexShrink={0}
              align='center'
              justify={'space-between'}
              color='wood.600'
              textStyle={{ base: 'tag', lg: 'small' }}
              ref={provided.innerRef}
              {...provided.draggableProps}
            >
              <Box {...provided.dragHandleProps}>
                <IconList width='14' height='14' />
              </Box>
              <HStack as='button' onClick={() => onEditCard(i)} w='full'>
                <SectionTextContent r={p.title} />
              </HStack>
              <Box
                textStyle={{ base: 'tag', lg: 'small' }}
                as='button'
                onClick={() => onRemove(i)}
                color='red.700'
              >
                <IconX width='14' height='14' />
              </Box>
            </HStack>
          )}
        </Draggable>
      )
    } else {
      return (
        <HStack
          borderWidth={1}
          borderColor='wood.600'
          px={2}
          w='full'
          // h={{ base: 6, lg: 8 }}
          rounded='4px'
          flexShrink={0}
          align='center'
          justify={'space-between'}
          color='wood.600'
          textStyle={{ base: 'tag', lg: 'small' }}
        >
          <SectionTextContent r={p.title} />
        </HStack>
      )
    }
  }

  const renderAddCard = () => {
    return (
      <VStack w={'full'}>
        <Button
          variant={'ghost'}
          size='sm'
          colorScheme='wood'
          onClick={addCard}
        >
          + Добавить карточку
        </Button>
      </VStack>
    )
  }

  const renderCards = () => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId='tags'
          renderClone={(provided, snapshot, rubric) => {
            return (
              <div ref={provided.innerRef} {...provided.draggableProps}>
                {renderCard(s.columns[rubric.source.index], 0, false)}
              </div>
            )
          }}
        >
          {(provided, snapshot) => (
            <VStack
              w='full'
              direction={'column'}
              align='flex-start'
              textStyle='body'
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {map(s.columns, (p, i) => renderCard(p, i, true))}
              {provided.placeholder}
            </VStack>
          )}
        </Droppable>
      </DragDropContext>
    )
  }

  const renderCardsPerRow = () => {
    return (
      <HStack w='full' align='center' spacing={2} justify={'space-between'}>
        <Label>Количество колонок</Label>
        <Flex>
          <Select
            value={s.columnsAmount || 4}
            onChange={e =>
              onChange({ ...s, columnsAmount: Number(e.target.value) })
            }
            variant={'solid'}
            size='xs'
          >
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
          </Select>
        </Flex>
      </HStack>
    )
  }

  const renderNumerationSwitch = () => {
    return (
      <SwitchField
        value={s.showNumbers || false}
        onChange={(v: boolean) => onChange({ ...s, showNumbers: v })}
        label='Показывать нумерацию'
      />
    )
  }

  const renderNumerationAlignment = () => {
    if (s.showNumbers) {
      return (
        <AlignmentSelect
          value={s.numbersAlignment}
          onChange={(v: IAlignment) => onChange({ ...s, numbersAlignment: v })}
          label='Выравнивание нумерации'
        />
      )
    }
  }

  const renderPadding = () => {
    return (
      <PaddingSelect
        value={s.paddingTop}
        onChange={(v: IPadding) => onChange({ ...s, paddingTop: v })}
      />
    )
  }

  const renderCarouselSwitch = () => {
    return (
      <SwitchField
        label='Показывать карусль'
        value={s.showCarousel || false}
        onChange={(v: boolean) => onChange({ ...s, showCarousel: v })}
      />
    )
  }

  return (
    <VStack align='flex-start' w='full' spacing={6}>
      {renderPadding()}
      {renderCardsPerRow()}
      {renderCarouselSwitch()}
      {renderNumerationSwitch()}
      {renderNumerationAlignment()}
      <VStack w='full'>
        {renderCards()}
        {renderAddCard()}
      </VStack>
    </VStack>
  )
}

export default EditSectionColumnsMain
