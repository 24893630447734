import { FC } from 'react'
import { IStyledText, ISectionTextList } from 'shared/types'
import { Box, VStack, Divider } from '@chakra-ui/react'
import map from 'lodash/map'
import SectionTextContent from 'shared/components/SectionTextContent'

type Props = {
  s: ISectionTextList
}

const SectionTextList: FC<Props> = ({ s }) => {
  const renderHeading = () => {
    return s.title && <SectionTextContent r={s.title} />
  }

  const renderRow = (r: IStyledText, i: number) => {
    return <SectionTextContent key={i} r={r} />
  }

  return (
    <VStack minH={10} align={s.alignment} w='full' spacing={2}>
      {renderHeading()}
      <VStack w='full' divider={<Divider />}>
        {map(s.data, renderRow)}
      </VStack>
    </VStack>
  )
}

export default SectionTextList
