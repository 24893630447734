import { FC, useState, MouseEvent } from 'react'
import { Box } from '@chakra-ui/react'
import { ReactComponent as IconVolumeUp } from 'shared/assets/bsVolumeFillUp.svg'

type Props = {
  url: string
}

const AudioPlayerIconMode: FC<Props> = ({ url }) => {
  const [playClicked, setPlayClicked] = useState(false)

  const onIconClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setPlayClicked(true)
  }

  return (
    <>
      {playClicked && (
        <audio
          src={url}
          controls={false}
          loop={false}
          autoPlay={true}
          onEnded={() => setPlayClicked(false)}
        />
      )}
      <Box as='button' onClick={onIconClick}>
        <IconVolumeUp width={24} height={24} />
      </Box>
    </>
  )
}

export default AudioPlayerIconMode
