import { VStack, HStack, Text } from '@chakra-ui/react'
import { DatePickerInput } from 'chakra-datetime-picker'
import dayjs from 'dayjs'
import { FC } from 'react'
import Label from 'shared/components/Label'

type Props = {
  label: string
  value?: number
  onChange: (v?: number) => void
}

const DateTimeField: FC<Props> = ({ label, value, onChange }) => {
  return (
    <VStack w={'full'} spacing={1} align={'flex-start'}>
      <HStack w={'full'} justify={'space-between'} align='center' pb={2}>
        <Label>{label}</Label>
        <Text textStyle={'tag'} color='gray.600'>
          Timezone: {dayjs().format('Z')}
        </Text>
      </HStack>
      <DatePickerInput
        currentLangKey='en'
        showTimeSelector
        format='YYYY-MM-DD HH:mm'
        colorScheme='gray'
        size='sm'
        disableTimestampBefore={0}
        wrapPortal={false}
        placement='left-start'
        inputProps={{ variant: 'flushed', sx: { div: { zIndex: 54321 } } }}
        value={value ? dayjs(value) : undefined}
        onChange={(str, d) => {
          onChange(d ? +d : undefined)
        }}
      />
    </VStack>
  )
}

export default DateTimeField
