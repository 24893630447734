import { useState } from 'react'
import {
  VStack,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem
} from '@chakra-ui/react'
import NavBar from 'components/NavBar'
import { useSelector } from 'model/hooks'
import { ICoursesPack, ISection, SectionType } from 'shared/types'
import { useNavigate, useParams } from 'react-router-dom'
import get from 'lodash/get'
import Loading from 'components/Loading'
import { generateId, storageRef } from 'controllers/db'
import { useRef } from 'react'
import CoursesPackPage from 'shared/pages/CoursesPackPage'
import { getCoursesDict } from 'model/selectors/courses'
import CoursesPackSettingsDrawer, {
  ICoursesPackSettingsDrawer
} from 'modals/coursesPack/CoursesPackSettingsDrawer'
import { ReactComponent as IconDots } from 'shared/assets/bsDotsVertical.svg'
import CoursesPackPlanDrawer, {
  ICoursesPackPlanDrawer
} from 'modals/coursesPack/CoursesPackPlanDrawer'
import {
  dbDeleteCoursesPack,
  dbDuplicateCoursesPack,
  dbUpdateCoursesPack
} from 'controllers/coursesPacks'
import DeleteConfirmation, {
  IDeleteConfirmationModal
} from 'modals/DeleteConfirmation'
import { ReactComponent as IconDelete } from 'shared/assets/bsTrash.svg'
import { ReactComponent as IconCopy } from 'shared/assets/bsDuplicate.svg'
import { createSection } from 'controllers/sections'
import { arrayInsertAt, arrayMove } from 'shared/utils/array'
import EditSectionDrawer from 'modals/EditSectionDrawer'
import filter from 'lodash/filter'
import Footer from 'shared/components/Footer'

const CoursesPack = () => {
  const { entityId } = useParams() as { entityId: string }
  const deleteModalRef = useRef<IDeleteConfirmationModal>(null)
  const navigate = useNavigate()
  const courses = useSelector(getCoursesDict)
  const settingsDrawerRef = useRef<ICoursesPackSettingsDrawer>(null)
  const planDrawerRef = useRef<ICoursesPackPlanDrawer>(null)
  const [editSectionId, setEditSectionId] = useState<string>()
  const c: ICoursesPack | undefined = useSelector(state =>
    get(state.coursesPacks, entityId)
  )
  const teachers = useSelector(state => state.teachers)
  const partitions = useSelector(state => state.partitions)

  const onDelete = () => {
    if (c) {
      dbDeleteCoursesPack(c)
      navigate('/tariffs')
    }
  }

  const onDuplicate = async () => {
    if (c) {
      const newCoursesPackId = await dbDuplicateCoursesPack(c)
      navigate(`/tariffs/${newCoursesPackId}`)
    }
  }

  const onAddSection = (sType: SectionType, i: number) => {
    if (c) {
      const s = createSection(sType)
      const newSections = {
        ...(c.sections || {}),
        [s.id]: s
      }
      const newSectionsOrder = arrayInsertAt(c.sectionsOrder || [], i, s.id)
      const upd = {
        sections: newSections,
        sectionsOrder: newSectionsOrder
      }
      dbUpdateCoursesPack(c.id, upd)
      setEditSectionId(s.id)
    }
  }

  const navBarActions = (
    <Menu>
      <MenuButton
        as={IconButton}
        icon={<IconDots />}
        colorScheme='wood'
        variant={'outline'}
      />
      <MenuList>
        <MenuItem
          icon={<IconCopy width={12} height={12} />}
          onClick={onDuplicate}
          textStyle={'small'}
        >
          Duplicate
        </MenuItem>
        <MenuItem
          color='red.800'
          textStyle={'small'}
          icon={<IconDelete width={12} height={12} />}
          onClick={() => deleteModalRef.current?.open()}
        >
          Delete
        </MenuItem>
      </MenuList>
    </Menu>
  )

  const onMoveSection = (fromIndex: number, toIndex: number) => {
    console.log('onMoveSection', fromIndex, toIndex)
    if (c && c.sectionsOrder) {
      const newSectionsOrder = arrayMove(c.sectionsOrder, fromIndex, toIndex)
      const upd = {
        sectionsOrder: newSectionsOrder
      }
      dbUpdateCoursesPack(c.id, upd)
    }
  }

  const updateSection = (s: ISection) => {
    if (c) {
      const upd = {
        sections: {
          ...(c.sections || {}),
          [s.id]: s
        }
      }
      dbUpdateCoursesPack(c.id, upd)
    }
  }

  const onEditSection = (sId: string) => {
    setEditSectionId(sId)
  }

  const onDeleteSection = () => {
    if (c && editSectionId) {
      const newOrder = filter(c.sectionsOrder, lId => lId !== editSectionId)
      const newSections = { ...c.sections }
      delete newSections[editSectionId]
      setEditSectionId(undefined)
      const upd = { sections: newSections, sectionsOrder: newOrder }
      dbUpdateCoursesPack(c.id, upd)
    }
  }

  const onDuplicateSection = () => {
    if (c && editSectionId && c.sections && c.sectionsOrder) {
      const s = c.sections[editSectionId]
      const index = c.sectionsOrder.indexOf(editSectionId)
      const id = generateId()
      const newS: ISection = {
        ...s,
        id
      }
      const newSections = {
        ...c.sections,
        [newS.id]: newS
      }
      const newSectionsOrder = arrayInsertAt(
        c.sectionsOrder || [],
        index + 1,
        newS.id
      )
      const upd = { sections: newSections, sectionsOrder: newSectionsOrder }
      dbUpdateCoursesPack(c.id, upd)
      setEditSectionId(id)
    }
  }

  if (!c) {
    return <Loading />
  } else {
    return (
      <VStack w='full' h='full' spacing={0} overflowY={'hidden'} maxH={'full'}>
        <NavBar title={c.title} backUrl='/tariffs'>
          {navBarActions}
        </NavBar>
        <VStack
          w='full'
          bg={'wood.100'}
          borderBottomRadius={{ base: 'sm', lg: 'lg' }}
          overflowY={'auto'}
        >
          <VStack w='full' justify={'flex-start'} flex={1} spacing={0}>
            <VStack
              w='full'
              justify={'flex-start'}
              flex={1}
              spacing={0}
              h='full'
              overflowX={'hidden'}
              overflowY={'auto'}
              minH='full'
              bg='black.950'
            >
              <VStack
                w='full'
                bg={'wood.100'}
                borderBottomRadius={{ base: 'sm', lg: 'lg' }}
              >
                <CoursesPackPage
                  storageRef={storageRef}
                  coursesPack={c}
                  courses={courses}
                  onPay={() => null}
                  toCourse={() => null}
                  userCourses={{}}
                  admin={{
                    onAddSection,
                    onMoveSection,
                    onEditSection,
                    onEditPlans: () => planDrawerRef.current?.open(c.id),
                    onEditHeader: () => settingsDrawerRef.current?.open(c.id)
                  }}
                  user={null}
                  dbSaveFeedback={() => undefined}
                  teachers={teachers || {}}
                  partitions={partitions}
                />
              </VStack>
              <Footer />
            </VStack>
          </VStack>
        </VStack>
        <CoursesPackSettingsDrawer ref={settingsDrawerRef} />
        <CoursesPackPlanDrawer ref={planDrawerRef} />
        <DeleteConfirmation
          ref={deleteModalRef}
          title='Удаление курса'
          description={`Вы собираетесь удалить тариф "${c.title}"`}
          onDelete={onDelete}
        />
        <EditSectionDrawer
          s={editSectionId && get(c, ['sections', editSectionId])}
          onChange={updateSection}
          onClose={() => setEditSectionId(undefined)}
          entityId={c.id}
          onDelete={onDeleteSection}
          onDuplicate={onDuplicateSection}
        />
      </VStack>
    )
  }
}

export default CoursesPack
