import { FC, useRef } from 'react'
import {
  Menu,
  MenuItem,
  MenuButton,
  Box,
  Portal,
  MenuList,
  Text
} from '@chakra-ui/react'
import { ReactComponent as IconPlus } from 'shared/assets/bsPlus.svg'
import { ILessonType, SectionType } from 'shared/types'
import sectionsByPageType from 'shared/constants/sectionsByPageType'
import { sectionName } from 'shared/utils/sectionName'
import AddSectionDrawer, {
  IAddSectionDrawer
} from 'shared/components/admin/AddSectionDrawer'

type Props = {
  lt?: ILessonType
  addSection: (sType: SectionType) => void
}

const AddSectionButton: FC<Props> = ({ lt, addSection }) => {
  const drawerRef = useRef<IAddSectionDrawer>(null)
  const sTypes = sectionsByPageType(lt)

  const renderMenuItem = (type: SectionType) => {
    console.log('name', sectionName[type])
    return (
      <MenuItem key={type} onClick={() => addSection(type)}>
        <Text textStyle={'small'} color='wood.950'>
          {sectionName[type]}
        </Text>
      </MenuItem>
    )
  }

  return (
    <>
      <Box
        position='absolute'
        display='none'
        bottom={0}
        left={'50%'}
        color='white'
        bg='wood.500'
        rounded={'full'}
        // as={MenuButton}
        _groupHover={{ display: 'block' }}
        _hover={{ cursor: 'pointer' }}
        w={6}
        h={6}
        ml={-3}
        onClick={() => drawerRef.current.open(lt)}
      >
        <IconPlus />
      </Box>
      {/* <Portal>
        <MenuList maxW={'md'}>{sTypes.map(t => renderMenuItem(t))}</MenuList>
      </Portal> */}
      <AddSectionDrawer ref={drawerRef} addSection={addSection} />
    </>
  )
}

export default AddSectionButton
