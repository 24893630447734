import { FC, useMemo } from 'react'
import { ILesson } from 'shared/types'
import { dbUpdateLesson } from 'controllers/lessons'
import { VStack } from '@chakra-ui/react'
import SimpleInput from 'shared/components/SimpleInput'
import Label from 'shared/components/Label'
import PercSlider from 'components/PercSlider'
import { COMPLETION_PERC } from 'shared/constants'
import { getTestQuestionsAmount } from 'shared/utils/tests'

type Props = {
  l: ILesson
}

const TestSettings: FC<Props> = ({ l }) => {
  const onChange = (upd: Partial<ILesson>) => {
    dbUpdateLesson(l.id, upd)
  }

  const tasksAmount = useMemo(() => {
    return getTestQuestionsAmount(l)
  }, [l])

  const renderSlider = () => {
    const perc = l.perc || COMPLETION_PERC
    return (
      <VStack w='full' spacing={2} align={'start'}>
        <Label>Процент выполнения</Label>
        <PercSlider
          perc={perc}
          onChange={val => onChange({ perc: val })}
          tasksAmount={tasksAmount}
        />
      </VStack>
    )
  }

  return (
    <VStack w='full' spacing={6} align={'start'}>
      <SimpleInput
        label='Название'
        value={l.name || ''}
        onChange={(v: string) => onChange({ name: v })}
      />
      <SimpleInput
        label='Описание'
        value={l.description || ''}
        onChange={(v: string) => onChange({ description: v })}
      />
      {renderSlider()}
    </VStack>
  )
}

export default TestSettings
