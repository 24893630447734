import { FC, useMemo } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import NavWrapper from 'src/navigation/NavWrapper'
import Courses from 'src/pages/Courses'
import NotFound from 'shared/components/NotFound'
import SignIn from 'src/pages/SignIn'
import { useSelector } from 'src/model/hooks'
import { getAppLoaded } from 'src/model/selectors/base'
import Loading from 'src/components/Loading'
import PageLayout from 'src/navigation/PageLayout'
import Course from 'pages/Course'
import LessonsDataProvider from 'contexts/LessonsDataProvider'
import Lesson from 'pages/Lesson'
import Webinars from 'pages/Webinars'
import Webinar from 'pages/Webinar'
import Conferences from 'pages/Conferences'
import Conference from 'pages/Conference'
import Users from 'pages/Users'
import Settings from 'pages/Settings'
import Library from 'pages/Library'
import LibraryCourse from 'pages/LibraryCourse'
import Teachers from 'pages/Teachers'
import { IEntityType } from 'shared/types'
import Seminars from 'pages/Seminars'
import Seminar from 'pages/Seminar'
import Payments from 'pages/Payments'
import Feedback from 'pages/Feedback'
import Free from 'pages/Free'
import Frees from 'pages/Frees'
import CoursesPacks from 'pages/CoursesPacks'
import CoursesPack from 'pages/CoursesPack'
import PaymentLinks from 'pages/PaymentLinks'
import Cards from 'pages/Cards'

const AppRouter: FC = () => {
  const authData = useSelector(state => state.authData)
  const appLoaded = useSelector(getAppLoaded)

  const privateRoutes = useMemo(() => {
    if (authData === null || appLoaded !== null) {
      return <Route path='*' element={<Loading />} />
    } else {
      return (
        <Route path='/' element={<PageLayout />}>
          <Route
            path='/free/:entityId'
            element={<LessonsDataProvider entityType={IEntityType.FREE} />}
          >
            <Route path=':lessonId' element={<Lesson />} />
            <Route path='' element={<Free />} />
          </Route>

          <Route
            path='/conferences/:entityId'
            element={
              <LessonsDataProvider entityType={IEntityType.CONFERENCES} />
            }
          >
            <Route path=':lessonId' element={<Lesson />} />
            <Route path='' element={<Conference />} />
          </Route>

          <Route
            path='/courses/:entityId'
            element={<LessonsDataProvider entityType={IEntityType.COURSES} />}
          >
            <Route path=':lessonId' element={<Lesson />} />
            <Route path='' element={<Course />} />
          </Route>

          <Route
            path='/seminars/:entityId'
            element={<LessonsDataProvider entityType={IEntityType.SEMINARS} />}
          >
            <Route path=':lessonId' element={<Lesson />} />
            <Route path='' element={<Seminar />} />
          </Route>

          <Route
            path='/webinars/:entityId'
            element={<LessonsDataProvider entityType={IEntityType.WEBINARS} />}
          >
            <Route path=':lessonId' element={<Lesson />} />
            <Route path='' element={<Webinar />} />
          </Route>

          <Route path='/tariffs/:entityId' element={<CoursesPack />} />

          <Route
            path='/library/:entityId'
            element={<LessonsDataProvider entityType={IEntityType.LIBRARY} />}
          >
            <Route path=':lessonId' element={<Lesson />} />
            <Route path='' element={<LibraryCourse />} />
          </Route>
          <Route path='/users/:userId' element={<Users />} />
          <Route path='/users' element={<Users />} />
          <Route path='/free' element={<Frees />} />
          <Route path='/conferences' element={<Conferences />} />
          <Route path='/payments' element={<Payments />} />
          <Route path='/payment_links' element={<PaymentLinks />} />
          <Route path='/feedback' element={<Feedback />} />
          <Route path='/webinars' element={<Webinars />} />
          <Route path='/library' element={<Library />} />
          <Route path='/courses' element={<Courses />} />
          <Route path='/tariffs' element={<CoursesPacks />} />
          <Route path='/seminars' element={<Seminars />} />
          <Route path='/settings' element={<Settings />} />
          <Route path='/teachers' element={<Teachers />} />
          <Route path='/cards' element={<Cards />} />
          <Route path='/' element={<Navigate to='/courses' />} />
        </Route>
      )
    }
  }, [authData, appLoaded])

  const publicRoutes = (
    <>
      <Route path='/signin' element={<SignIn />}></Route>
      <Route
        path='*'
        element={<NotFound message='Такая страница не существует' />}
      ></Route>
    </>
  )

  return (
    <BrowserRouter>
      <NavWrapper>
        <Routes>
          {privateRoutes}
          {publicRoutes}
        </Routes>
      </NavWrapper>
    </BrowserRouter>
  )
}

export default AppRouter
