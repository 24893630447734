import { createReducer } from '@reduxjs/toolkit'
import { IAdmin } from 'shared/types'
import { receiveAdmins } from 'src/model/actions'

const initialState: Record<string, IAdmin> = {}

const reducer = createReducer(initialState, builder => {
  builder.addCase(receiveAdmins, (state, action) => action.payload)
})

export default reducer
