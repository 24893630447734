import { VStack } from '@chakra-ui/react'
import {
  dbSendMessage,
  dbSubscribeToChat,
  dbUpdateReadBy
} from 'controllers/chat'
import { Unsubscribe } from 'firebase/auth'
import { useSelector } from 'model/hooks'
import { FC, useEffect, useRef, useState } from 'react'
import { IMessage, IMessageContent } from 'shared/types'
import ChatMessages from 'shared/components/chat/ChatMessages'
import ChatInput from 'shared/components/chat/ChatInput'
import { generateId } from 'controllers/db'
import dayjs from 'dayjs'
import _ from 'lodash'

type Props = {
  lessonId: string
  userId: string
  entityId: string
}

const Chat: FC<Props> = ({ lessonId, userId, entityId }) => {
  const user = useSelector(state => state.user)
  const [messages, setMessages] = useState<IMessage[]>([])
  const messagesUnsubscribeRef = useRef<Unsubscribe | null>(null)

  useEffect(() => {
    const lastMessage = _.last(messages)
    if (user && lastMessage && lastMessage?.senderId !== user?.id) {
      dbUpdateReadBy(lastMessage.channelId, user.id, lastMessage.createdAt + 1)
    }
  }, [messages, user])

  useEffect(() => {
    if (messagesUnsubscribeRef.current) {
      messagesUnsubscribeRef.current()
    }

    messagesUnsubscribeRef.current = dbSubscribeToChat(lessonId, userId, res =>
      setMessages(res)
    )
    return () => {
      if (messagesUnsubscribeRef.current) {
        messagesUnsubscribeRef.current()
      }
    }
  }, [lessonId, userId])

  const onSend = (c: IMessageContent) => {
    if (user) {
      const channelId = `${lessonId}_${userId}`
      const m: IMessage = {
        id: generateId(),
        lessonId,
        channelId,
        createdAt: +dayjs(),
        userId: userId,
        senderId: user.id,
        senderName: user.name,
        content: c
      }
      dbSendMessage(m, entityId)
    }
  }

  return (
    <VStack flex={1} w='full'>
      {user && <ChatMessages messages={messages} userId={user.id} />}
      <ChatInput send={onSend} />
    </VStack>
  )
}

export default Chat
