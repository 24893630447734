import { FC } from 'react'
import { HStack, Text, Flex, Box } from '@chakra-ui/react'
import { ISessionAnswer } from 'shared/types'
import { ReactComponent as IconCheckmark } from 'shared/assets/bsCheckCircle.svg'
import { ReactComponent as IconX } from 'shared/assets/bsXCircle.svg'

type Props = {
  answer?: ISessionAnswer
  title: string
  testNum?: number
}

const TestTask: FC<Props> = ({ answer, title, testNum }) => {
  const renderTestNum = () => {
    if (testNum) {
      return (
        <Flex
          rounded={'full'}
          bg={
            answer ? (answer.isCorrect ? 'green.800' : 'red.700') : 'black.600'
          }
          minW={5}
          minH={5}
          align='center'
          justify={'center'}
        >
          <Text textStyle={'tag'} color='white'>
            {testNum}
          </Text>
        </Flex>
      )
    }
  }

  return (
    <HStack w='full' justify={'space-between'}>
      <HStack w='full' spacing={2}>
        {renderTestNum()}
        <Text textStyle={{ base: 'tag', lg: 'small' }} color='black.600'>
          {title}
        </Text>
      </HStack>
      {answer && (
        <Box color={answer.isCorrect ? 'green.800' : 'red.700'}>
          {answer.isCorrect ? <IconCheckmark /> : <IconX />}
        </Box>
      )}
    </HStack>
  )
}

export default TestTask
