import { FC } from 'react'
import { ISectionButton } from 'shared/types'
import { VStack, Button } from '@chakra-ui/react'

type Props = {
  s: ISectionButton
}

const SectionTextBodyButton: FC<Props> = ({ s }) => {
  return (
    <VStack w='full' spacing={0} align={s.button?.alignment || 'flex-start'}>
      <Button
        variant={'primary'}
        onClick={() => window.open(s.button?.url, '_blank')}
      >
        {s.button?.title || 'Download'}
      </Button>
    </VStack>
  )
}

export default SectionTextBodyButton
