import { createReducer } from '@reduxjs/toolkit'
import { IUserPayment } from 'shared/types'
import { receiveUserPayments } from 'src/model/actions'

const initialState = null as Record<string, IUserPayment> | null

const reducer = createReducer(initialState, builder => {
  builder.addCase(receiveUserPayments, (state, action) => action.payload)
})

export default reducer
