import { Stack, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { FC } from 'react'

type Props = {
  timestamp: number
  name: string
  isLeft: boolean
}

const NameElement: FC<Props> = ({ timestamp, name, isLeft }) => {
  return (
    <Stack
      w='full'
      pt={3}
      pb={1}
      align={isLeft ? 'flex-start' : 'flex-end'}
      direction={isLeft ? 'row' : 'row-reverse'}
      spacing={1}
    >
      <Text textStyle='tag' fontWeight={700} color='darkGray'>
        {name}
      </Text>
      <Text textStyle='tag' color='darkGray'>
        {dayjs(timestamp).format('hh:mm')}
      </Text>
    </Stack>
  )
}

export default NameElement
