import { FC, ReactNode } from 'react'
import _ from 'lodash'
import { Flex, VStack, Text, HStack, IconButton } from '@chakra-ui/react'
import { ChevronLeftIcon } from '@chakra-ui/icons'
import { useNavigate } from 'react-router-dom'

type Props = {
  title: ReactNode
  children?: ReactNode
  subheading?: string
  backUrl?: string
  hideBackButton?: boolean
  tabs?: ReactNode
}

const NavBar: FC<Props> = ({
  title,
  children,
  subheading,
  backUrl,
  hideBackButton,
  tabs
}) => {
  const navigate = useNavigate()

  const renderTitle = () => {
    if (_.isString(title)) {
      return (
        <VStack align='flex-start' spacing={0}>
          <Text
            textStyle='small'
            noOfLines={1}
            color='sun.950'
            fontWeight={700}
          >
            {title}
          </Text>
          {subheading && <Text fontSize='xs'>{subheading}</Text>}
        </VStack>
      )
    } else {
      return title
    }
  }

  const onBackClick = () => {
    if (backUrl) {
      navigate(backUrl)
    } else {
      navigate(-1)
    }
  }

  const renderBackButton = () => {
    if (!hideBackButton) {
      return (
        <IconButton
          variant={'ghost'}
          aria-label='back'
          icon={<ChevronLeftIcon boxSize='8' />}
          onClick={onBackClick}
        />
      )
    }
  }

  return (
    <Flex
      borderBottomWidth={1}
      // bg='white'
      minH='16'
      align='center'
      pl={10}
      pr={4}
      w='full'
      justify={'space-between'}
      flexShrink={0}
    >
      <VStack align={'flex-start'} spacing={0} flex={1}>
        <HStack align='center' spacing={0}>
          {renderBackButton()}
          {renderTitle()}
        </HStack>
      </VStack>
      <HStack flex={1} justify={'center'}>
        {tabs}
      </HStack>
      <HStack flex={1} spacing={4} justify={'end'}>
        {children}
      </HStack>
    </Flex>
  )
}

export default NavBar
