import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  HStack,
  Heading,
  StackDivider,
  VStack
} from '@chakra-ui/react'
import { useSelector } from 'model/hooks'
import { FC, useMemo } from 'react'
import map from 'lodash/map'
import { IPartition } from 'shared/types'
import EditableRow from 'components/EditableRow'
import { dbCreateLevel, dbDeleteLevel, dbUpdateLevel } from 'controllers/levels'
import sortBy from 'lodash/sortBy'

type Props = {}

const SettingsLevels: FC<Props> = () => {
  const levels = useSelector(state => state.levels)

  const sortedLevels = useMemo(() => sortBy(levels, 'name'), [levels])

  const renderRow = (p: IPartition) => {
    return (
      <EditableRow
        key={p.id}
        value={p.name}
        onChange={v => dbUpdateLevel(p.id, v)}
        onDelete={() => dbDeleteLevel(p.id)}
      />
    )
  }

  return (
    <VStack w='full'>
      <Card w='full' maxW='2xl'>
        <CardHeader>
          <HStack w='full' justify={'space-between'}>
            <Heading size='md'>Уровни</Heading>
            <Button
              colorScheme='teal'
              onClick={() => dbCreateLevel()}
              size='sm'
            >
              Добавить
            </Button>
          </HStack>
        </CardHeader>
        <CardBody>
          <VStack w='full' divider={<StackDivider color='gray.100' />}>
            {map(sortedLevels, renderRow)}
          </VStack>
        </CardBody>
        <CardFooter justify={'flex-end'}></CardFooter>
      </Card>
    </VStack>
  )
}

export default SettingsLevels
