import { onSnapshot, doc, collection, updateDoc } from 'firebase/firestore'
import { db, auth } from 'controllers/db'
import { IUser } from 'shared/types'
import { receiveUser } from 'model/actions'
import store from 'model/store'
import { addListener } from 'controllers/listeners'

export const dbFetchUser = async (userId: string) => {
  console.log('fetch user', userId)
  try {
    const unsubscribe = onSnapshot(
      doc(db, 'users', userId),
      userSN => {
        if (userSN.exists()) {
          const user = userSN.data() as IUser
          store.dispatch(receiveUser(user))
        } else {
          store.dispatch(receiveUser(null))
        }
      },
      err => {
        console.log('fetch user error', err)
      }
    )
    addListener('user', unsubscribe)
  } catch (e) {
    console.log('fetch user error', e)
  }
}

export const dbUpdateUserParams = async (
  name: string,
  phone: string,
  telegram: string
) => {
  const user = auth.currentUser
  if (user) {
    const upd = {
      name,
      phone,
      telegram
    }

    const ref = doc(collection(db, 'users'), user.uid)
    await updateDoc(ref, upd)
  }
}
