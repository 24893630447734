import { FC, useState } from 'react'
import { ISectionCard, ISectionColumns } from 'shared/types'
import has from 'lodash/has'
import EditSectionColumnsCard from 'components/sections/editSectionColumns/EditSectionColumnsCard'
import EditSectionColumnsMain from 'components/sections/editSectionColumns/EditSectionColumnsMain'

type Props = {
  s: ISectionColumns
  onChange: (s: ISectionColumns) => void
}

const EditSectionColumns: FC<Props> = ({ s, onChange }) => {
  const [selectedColumnIndex, setSelectedColumnIndex] = useState<number | null>(
    null
  )

  const onChangeCard = (c: ISectionCard) => {
    if (selectedColumnIndex !== null) {
      const newColumns = [...s.columns]
      newColumns[selectedColumnIndex] = c
      onChange({ ...s, columns: newColumns })
    }
  }

  if (selectedColumnIndex !== null && has(s.columns, selectedColumnIndex)) {
    return (
      <EditSectionColumnsCard
        s={s.columns[selectedColumnIndex]}
        onChange={onChangeCard}
        onBack={() => setSelectedColumnIndex(null)}
      />
    )
  } else {
    return (
      <EditSectionColumnsMain
        s={s}
        onChange={onChange}
        onEditCard={(i: number) => setSelectedColumnIndex(i)}
      />
    )
  }
}

export default EditSectionColumns
