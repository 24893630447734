import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  VStack
} from '@chakra-ui/react'
import { FC } from 'react'
import {
  ISection,
  SectionType,
  ISectionHeading,
  ISectionTextList,
  ISectionColumns,
  ISectionColumnsVideo,
  ISectionColumnsImage,
  ISectionVimeo,
  ISectionAudio,
  ISectionTestButtons,
  ISectionTestTrueFalse,
  ISectionTestOneOption,
  ISectionTestInputAnswer,
  ISectionTestTextAnswer,
  ISectionTestAddWords,
  ISectionTestAddLetters,
  ISectionTestSyncTranslate,
  ISectionYoutube,
  ISectionText,
  ISectionButton,
  ISectionTeachers,
  ISectionDivider,
  ISectionSyncTranslate,
  ISectionWordCard
} from 'shared/types'
import isNil from 'lodash/isNil'
import { sectionName } from 'shared/utils/sectionName'
import { ReactComponent as IconDelete } from 'shared/assets/bsTrash.svg'
import { ReactComponent as IconDuplicate } from 'shared/assets/bsDuplicate.svg'
import EditSectionHeading from 'components/sections/EditSectionHeading'
import EditSectionTestList from 'components/sections/EditSectionTextList'
import EditSectionColumns from 'components/sections/EditSectionColumns'
import EditSectionColumnsVideo from 'components/sections/EditSectionColumnsVideo'
import EditSectionColumnsImage from 'components/sections/EditSectionColumnsImage'
import EditSectionVimeo from 'components/sections/EditSectionVimeo'
import EditSectionAudio from 'components/sections/EditSectionAudio'
import EditSectionTestButtons from 'components/sections/EditSectionTestButtons'
import EditSectionTestTrueFalse from 'components/sections/EditSectionTestTrueFalse'
import EditSectionTestOneOption from 'components/sections/EditSectionTestOneOption'
import EditSectionTestInputAnswer from 'components/sections/EditSectionTestInputAnswer'
import EditSectionTestTextAnswer from 'components/sections/EditSectionTestTextAnswer'
import EditSectionTestAddWords from 'components/sections/EditSectionTestAddWords'
import EditSectionTestAddLetters from 'components/sections/EditSectionTestAddLetters'
import EditSectionTestSyncTranslate from 'components/sections/EditSectionTestSyncTranslate'
import EditSectionYoutube from 'components/sections/EditSectionYoutube'
import EditSectionText from 'components/sections/EditSectionText'
import EditSectionButton from 'components/sections/EditSectionButton'
import EditSectionTeachers from 'components/sections/EditSectionTeachers'
import EditSectionDivider from 'components/sections/EditSectionDivider'
import EditSectionSyncTranslate from 'components/sections/EditSectionSyncTranslate'
import EditSectionWordCard from 'components/sections/EditSectionWordCard'

type Props = {
  s?: ISection
  onChange: (s: ISection) => void
  onClose: () => void
  entityId: string
  onDelete: () => void
  onDuplicate: () => void
  lessonId?: string
}

const EditSectionDrawer: FC<Props> = ({
  s,
  onChange,
  onClose,
  entityId,
  onDelete,
  onDuplicate
}) => {
  const renderContent = () => {
    if (s) {
      switch (s.type) {
        case SectionType.DIVIDER: {
          return (
            <EditSectionDivider
              s={s as ISectionDivider}
              onChange={(s: ISectionDivider) => onChange(s)}
            />
          )
        }
        case SectionType.TEACHERS: {
          return (
            <EditSectionTeachers
              s={s as ISectionTeachers}
              onChange={(s: ISectionTeachers) => onChange(s)}
            />
          )
        }
        case SectionType.HEADING: {
          return (
            <EditSectionHeading
              s={s as ISectionHeading}
              onChange={(s: ISectionHeading) => onChange(s)}
            />
          )
        }
        case SectionType.TEXT_LIST: {
          return (
            <EditSectionTestList
              s={s as ISectionTextList}
              onChange={(s: ISectionTextList) => onChange(s)}
            />
          )
        }
        case SectionType.COLUMNS: {
          return (
            <EditSectionColumns
              s={s as ISectionColumns}
              onChange={(s: ISectionColumns) => onChange(s)}
            />
          )
        }
        case SectionType.COLUMNS_VIDEO: {
          return (
            <EditSectionColumnsVideo
              s={s as ISectionColumnsVideo}
              onChange={(s: ISectionColumnsVideo) => onChange(s)}
            />
          )
        }
        case SectionType.COLUMNS_IMAGE: {
          return (
            <EditSectionColumnsImage
              s={s as ISectionColumnsImage}
              onChange={(s: ISectionColumnsImage) => onChange(s)}
            />
          )
        }
        case SectionType.VIMEO: {
          return (
            <EditSectionVimeo
              s={s as ISectionVimeo}
              onChange={(s: ISectionVimeo) => onChange(s)}
            />
          )
        }
        case SectionType.YOUTUBE: {
          return (
            <EditSectionYoutube
              s={s as ISectionYoutube}
              onChange={(s: ISectionYoutube) => onChange(s)}
            />
          )
        }

        case SectionType.AUDIO: {
          return (
            <EditSectionAudio
              entityId={entityId}
              s={s as ISectionAudio}
              onChange={(s: ISectionAudio) => onChange(s)}
            />
          )
        }
        case SectionType.TEST_BUTTONS: {
          return (
            <EditSectionTestButtons
              s={s as ISectionTestButtons}
              onChange={(s: ISectionTestButtons) => onChange(s)}
            />
          )
        }
        case SectionType.TEST_TRUE_FALSE: {
          return (
            <EditSectionTestTrueFalse
              s={s as ISectionTestTrueFalse}
              onChange={(s: ISectionTestTrueFalse) => onChange(s)}
            />
          )
        }
        case SectionType.TEST_ONE_OPTION: {
          return (
            <EditSectionTestOneOption
              s={s as ISectionTestOneOption}
              onChange={(s: ISectionTestOneOption) => onChange(s)}
            />
          )
        }
        case SectionType.TEST_INPUT_ANSWER: {
          return (
            <EditSectionTestInputAnswer
              s={s as ISectionTestInputAnswer}
              onChange={(s: ISectionTestInputAnswer) => onChange(s)}
            />
          )
        }
        case SectionType.TEST_TEXT_ANSWER: {
          return (
            <EditSectionTestTextAnswer
              s={s as ISectionTestTextAnswer}
              onChange={(s: ISectionTestTextAnswer) => onChange(s)}
            />
          )
        }
        case SectionType.TEST_ADD_WORDS: {
          return (
            <EditSectionTestAddWords
              s={s as ISectionTestAddWords}
              onChange={(s: ISectionTestAddWords) => onChange(s)}
            />
          )
        }
        case SectionType.TEST_ADD_LETTERS: {
          return (
            <EditSectionTestAddLetters
              s={s as ISectionTestAddLetters}
              onChange={(s: ISectionTestAddLetters) => onChange(s)}
            />
          )
        }
        case SectionType.TEST_SYNC_TRANSLATE: {
          return (
            <EditSectionTestSyncTranslate
              s={s as ISectionTestSyncTranslate}
              onChange={(s: ISectionTestSyncTranslate) => onChange(s)}
            />
          )
        }
        case SectionType.TEXT: {
          return (
            <EditSectionText
              s={s as ISectionText}
              onChange={(s: ISectionText) => onChange(s)}
            />
          )
        }
        case SectionType.BUTTON: {
          return (
            <EditSectionButton
              s={s as ISectionButton}
              onChange={(s: ISectionButton) => onChange(s)}
            />
          )
        }
        case SectionType.SYNC_TRANSLATE: {
          return (
            <EditSectionSyncTranslate
              s={s as ISectionSyncTranslate}
              onChange={(s: ISectionSyncTranslate) => onChange(s)}
            />
          )
        }
        case SectionType.WORD_CARD: {
          return (
            <EditSectionWordCard
              s={s as ISectionWordCard}
              onChange={(s: ISectionWordCard) => onChange(s)}
            />
          )
        }
      }
    }
  }

  return (
    <Drawer isOpen={!isNil(s)} placement='right' onClose={onClose} size='lg'>
      <DrawerOverlay bg='transparent' />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader textStyle='small'>
          {s && sectionName[s.type]}
        </DrawerHeader>

        <DrawerBody pb={6}>
          <VStack flex={1} spacing={6}>
            {renderContent()}
          </VStack>
        </DrawerBody>
        <DrawerFooter>
          <HStack w='full' justify={'space-between'}>
            <Button
              variant='outline'
              onClick={onDuplicate}
              colorScheme='wood'
              size='sm'
              leftIcon={<IconDuplicate width={12} />}
            >
              Duplicate
            </Button>
            <Button
              variant='solid'
              onClick={onDelete}
              colorScheme='red'
              size='sm'
              leftIcon={<IconDelete width={12} />}
            >
              Delete
            </Button>
          </HStack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

export default EditSectionDrawer
