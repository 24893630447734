import {
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Text
} from '@chakra-ui/react'
import { FC, useState } from 'react'
import { percToAmount } from 'shared/utils/tests'

type Props = {
  perc: number
  onChange: (v: number) => void
  tasksAmount: number
  size?: 'sm' | 'md'
}

const PercSlider: FC<Props> = ({
  perc,
  onChange,
  tasksAmount,
  size = 'md'
}) => {
  const [v, setV] = useState(perc)

  const labelStyles = {
    mt: '2',
    ml: '-2.5',
    textStyle: 'tag',
    color: 'wooo.800'
  }

  return (
    <Slider
      aria-label='slider-ex-6'
      value={v}
      onChange={v => setV(v)}
      onChangeEnd={val => onChange(val)}
      mt={8}
    >
      <SliderMark value={25} {...labelStyles}>
        25%
      </SliderMark>
      <SliderMark value={50} {...labelStyles}>
        50%
      </SliderMark>
      <SliderMark value={75} {...labelStyles}>
        75%
      </SliderMark>
      <SliderMark
        value={v}
        textAlign='center'
        textStyle={size === 'md' ? 'small' : 'tag'}
        bg='wood.500'
        color='white'
        mt='-10'
        py={0.5}
        rounded={'sm'}
        ml={size === 'md' ? '-10' : '-20'}
        w={size === 'md' ? '20' : '72px'}
      >
        <Text textStyle='tag'>
          {tasksAmount === 0
            ? '0 / 0'
            : `${percToAmount(v, tasksAmount)}/${tasksAmount}`}{' '}
          - {v}%
        </Text>
      </SliderMark>
      <SliderTrack bg='wood.200'>
        <SliderFilledTrack bg={'wood.600'} />
      </SliderTrack>
      <SliderThumb />
    </Slider>
  )
}

export default PercSlider
