import {
  query,
  collection,
  where,
  getDocs,
  documentId,
  doc,
  setDoc,
  onSnapshot,
  updateDoc,
  arrayRemove,
  deleteDoc
} from 'firebase/firestore'
import { db, dbOmit } from 'controllers/db'
import { ILesson } from 'shared/types'
import map from 'lodash/map'
import chunk from 'lodash/chunk'
import flatten from 'lodash/flatten'
import keyBy from 'lodash/keyBy'

export const dbGetLessonsById = async (lessonsIds: string[]) => {
  try {
    const chunks = chunk(lessonsIds, 10)
    const promises = map(chunks, async ids => {
      const q = query(collection(db, 'lessons'), where(documentId(), 'in', ids))
      const sn = await getDocs(q)
      return sn.docs
    })
    const resAr = await Promise.all(promises)
    const docs = flatten(resAr)
    return keyBy(
      map(docs, doc => ({ ...doc.data(), id: doc.id } as ILesson)),
      'id'
    )
  } catch (e) {
    console.error('dbGetLessonsById error', e)
    return null
  }
}

export const dbCreateLesson = async (lessonId: string, lesson: ILesson) => {
  try {
    console.log('dbCreateLesson', lessonId, lesson)
    const ref = doc(collection(db, 'lessons'), lessonId)
    await setDoc(ref, dbOmit(lesson))
  } catch (e) {
    console.log('dbCreateLesson error', e)
  }
}

// export const dbDeleteLesson = async (lessonId: string) => {
//   try {
//     console.log('dbDeleteLesson', lessonId)
//     const ref = doc(collection(db, 'lessons'), lessonId)
//     await deleteDoc(ref)
//   } catch (e) {
//     console.log('dbDeleteLesson error', e)
//   }
// }

export const dbSubscribeToLessons = (
  entityId: string,
  callback: (lessons: Record<string, ILesson>) => void
) => {
  try {
    const q = query(
      collection(db, 'lessons'),
      where('entityId', '==', entityId)
    )
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: Record<string, ILesson> = {}
        sn.forEach(doc => {
          const p = doc.data() as ILesson
          res[doc.id] = { ...p, id: doc.id }
        })
        callback(res)
      },
      err => {
        console.log(`dbFetchCourses error: ${err.message}`)
      }
    )
    return unsubscribe
  } catch (e) {
    console.error('dbFetchCourses error', e)
    return null
  }
}

export const dbUpdateLesson = async (
  lessonId: string,
  upd: Partial<ILesson>
) => {
  try {
    console.log('dbUpdateLesson', lessonId, upd)
    const ref = doc(collection(db, 'lessons'), lessonId)
    await updateDoc(ref, dbOmit(upd))
  } catch (e) {
    console.log('dbUpdateLesson error', e)
  }
}

export const dbDeleteLesson = async (lessonId: string) => {
  try {
    const ref = doc(collection(db, 'lessons'), lessonId)
    deleteDoc(ref)
  } catch (e) {
    console.log('dbDeleteLesson error', e)
  }
}

export const dbDeleteLessonEntity = async (
  lessonId: string,
  entityId: string
) => {
  try {
    console.log('dbDeleteLessonEntity', lessonId, entityId)
    const ref = doc(collection(db, 'lessons'), lessonId)
    await updateDoc(ref, { entityId: arrayRemove(entityId) })
  } catch (e) {
    console.log('dbDeleteLessonEntity error', e)
  }
}
