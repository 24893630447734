import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  HStack,
  Heading,
  StackDivider,
  VStack
} from '@chakra-ui/react'
import { useSelector } from 'model/hooks'
import { FC, useMemo } from 'react'
import map from 'lodash/map'
import { IPartition } from 'shared/types'
import { dbCreatePartition, dbUpdatePartition } from 'controllers/partitions'
import EditableRow from 'components/EditableRow'
import sortBy from 'lodash/sortBy'
import { dbDeletePartition } from 'controllers/partitions'

type Props = {}

const SettingsPartitions: FC<Props> = () => {
  const partitions = useSelector(state => state.partitions)

  const sortedPartitions = useMemo(
    () => sortBy(partitions, 'name'),
    [partitions]
  )

  const renderRow = (p: IPartition) => {
    return (
      <EditableRow
        key={p.id}
        value={p.name}
        onChange={v => dbUpdatePartition(p.id, v)}
        onDelete={() => dbDeletePartition(p.id)}
      />
    )
  }

  return (
    <VStack w='full'>
      <Card w='full' maxW='2xl'>
        <CardHeader>
          <HStack w='full' justify={'space-between'}>
            <Heading size='md'>Разделы</Heading>
            <Button
              colorScheme='teal'
              onClick={() => dbCreatePartition()}
              size='sm'
            >
              Добавить
            </Button>
          </HStack>
        </CardHeader>
        <CardBody>
          <VStack w='full' divider={<StackDivider color='gray.100' />}>
            {map(sortedPartitions, renderRow)}
          </VStack>
        </CardBody>
        <CardFooter justify={'flex-end'}></CardFooter>
      </Card>
    </VStack>
  )
}

export default SettingsPartitions
