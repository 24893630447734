import { ChangeEvent, FC, useState } from 'react'
import { HStack, Flex } from '@chakra-ui/react'
import { AutoResizeTextarea } from 'shared/components/chat/AutoResizeTextarea'
import { ReactComponent as IconPlus } from 'shared/assets/bsPlus.svg'
import { ReactComponent as IconSend } from 'shared/assets/bsSend.svg'
import { IMessageContent } from 'shared/types'

type Props = {
  send: (c: IMessageContent) => void
}

const ChatInput: FC<Props> = ({ send }) => {
  const [value, setValue] = useState('')

  const onSend = () => {
    const c: IMessageContent = {
      text: value
    }
    send(c)
    setValue('')
  }

  return (
    <HStack
      px={4}
      w='full'
      borderTopWidth={1}
      borderTopColor={'lightGray'}
      spacing={3}
      align='flex-start'
      py={3}
    >
      <Flex
        justify={'center'}
        align={'center'}
        _hover={{ cursor: 'pointer' }}
        as='button'
      >
        <IconPlus />
      </Flex>
      <AutoResizeTextarea
        px={0}
        py={0.5}
        rounded='none'
        border='none'
        outline='none'
        maxH={52}
        minRows={1}
        size='sm'
        placeholder='Start typing...'
        textStyle='small'
        sx={{
          '::placeholder': {
            color: 'darkGray'
          },
          ':focus-visible': {
            border: 'none',
            boxShadow: 'none'
          }
        }}
        value={value}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
          setValue(e.target.value)
        }
      />
      <Flex
        justify={'center'}
        align={'center'}
        _hover={{ cursor: 'pointer' }}
        as='button'
        onClick={onSend}
      >
        <IconSend />
      </Flex>
    </HStack>
  )
}

export default ChatInput
