import { FC } from 'react'
import { ISection, ISectionAudio } from 'shared/types'
import { Box, Text, VStack, HStack } from '@chakra-ui/react'
import AudioPlayer from 'shared/components/AudioPlayer'
import { StorageReference } from 'firebase/storage'
import split from 'lodash/split'
import SectionTextContent from 'shared/components/SectionTextContent'

type Props = {
  s: ISectionAudio
  entityId: string
  storageRef: (refer: string) => StorageReference
}

const SectionAudio: FC<Props> = ({ s, entityId, storageRef }) => {
  return (
    <VStack w='full' spacing={4}>
      {s.title && s.title !== '' && (
        <SectionTextContent r={{ text: s.title, textStyle: s.textStyle }} />
      )}

      <Box w='full'>
        {s.audio && s.audio.url && <AudioPlayer url={s.audio.url} />}
      </Box>
    </VStack>
  )
}

export default SectionAudio
