import { FC, useMemo, useState } from 'react'
import { VStack } from '@chakra-ui/react'
import { IPadding, ISectionWordCard } from 'shared/types'
import PaddingSelect from 'components/PaddingSelect'
import CardsSearch from 'components/CardsSearch'
import LanguageSelector from 'components/LanguageSelector'
import { dbAppendWordCard } from 'controllers/cards'
import { useSelector } from 'model/hooks'
import _ from 'lodash'

type Props = {
  s: ISectionWordCard
  onChange: (s: ISectionWordCard) => void
}

const EditSectionWordCard: FC<Props> = ({ s, onChange }) => {
  const [curLang, setCurLang] = useState<string>()
  const wordCards = useSelector(state => state.wordCards)

  const card = useMemo(() => {
    if (s.cardId) {
      const c = _.get(wordCards, s.cardId)
      return c
    }
  }, [wordCards, s.cardId])

  const setCardId = (cardId: string) => {
    dbAppendWordCard(cardId)
    onChange({ ...s, cardId })
  }

  const renderPadding = () => {
    return (
      <PaddingSelect
        value={s.paddingTop}
        onChange={(v: IPadding) => onChange({ ...s, paddingTop: v })}
      />
    )
  }

  const renderContent = () => {
    return (
      <VStack w='full' align={'start'}>
        <CardsSearch
          title={card?.word || ''}
          onSelect={v => setCardId(v)}
          lang={curLang}
        />
        <LanguageSelector value={curLang} onChange={setCurLang} />
      </VStack>
    )
  }

  return (
    <VStack w='full' spacing={6}>
      {renderPadding()}
      {renderContent()}
    </VStack>
  )
}

export default EditSectionWordCard
