import { RootState } from 'src/model/store'
import { createSelector } from '@reduxjs/toolkit'
import reduce from 'lodash/reduce'
import isNil from 'lodash/isNil'
import size from 'lodash/size'

export const getCourses = (state: RootState) => state.courses
export const getLibrary = (state: RootState) => state.library
export const getWebinars = (state: RootState) => state.webinars
export const getSeminars = (state: RootState) => state.seminars
export const getConferences = (state: RootState) => state.conferences
export const getFrees = (state: RootState) => state.frees
export const getUserPayments = (state: RootState) => state.userPayments
export const getChannels = (state: RootState) => state.channels

export const getAppLoaded = createSelector([getCourses], courses => {
  const lst = [courses]
  const doneAmount = reduce(
    lst,
    (res, elt) => {
      return isNil(elt) ? res : res + 1
    },
    0
  )
  // console.table([
  //   ['courses', !_.isNil(courses)],
  // ])
  return doneAmount === size(lst) ? null : (doneAmount / size(lst)) * 100
})
