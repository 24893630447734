import { Provider } from 'react-redux'
import { ChakraProvider } from '@chakra-ui/react'
import AppRouter from 'navigation/AppRouter'
import { theme } from 'shared/theme'
import store from 'model/store'

function App () {
  return (
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <AppRouter />
      </ChakraProvider>
    </Provider>
  )
}

export default App
