import { FC } from 'react'
import { IPartition, ISectionTeachers, ITeacher } from 'shared/types'
import { VStack } from '@chakra-ui/react'
import map from 'lodash/map'
import get from 'lodash/get'
import TeacherCard from 'shared/components/TeacherCard'

type Props = {
  s: ISectionTeachers
  teachers: Record<string, ITeacher>
  partitions: Record<string, IPartition>
}

const SectionTeachers: FC<Props> = ({ s, teachers, partitions }) => {
  const renderRow = (tId: string, i: number) => {
    const t = get(teachers, tId)
    if (t) {
      return <TeacherCard key={tId} t={t} partitions={partitions} />
    }
  }
  return (
    <VStack minH={10} w='full' spacing={6}>
      {map(s.teachers, renderRow)}
    </VStack>
  )
}

export default SectionTeachers
