import { FC, useMemo } from 'react'
import { IExaminationSettings, ILesson } from 'shared/types'
import { dbUpdateLesson } from 'controllers/lessons'
import {
  Divider,
  NumberInput,
  NumberInputField,
  VStack
} from '@chakra-ui/react'
import SimpleInput from 'shared/components/SimpleInput'
import Label from 'shared/components/Label'
import PercSlider from 'components/PercSlider'
import { COMPLETION_PERC } from 'shared/constants'
import { getTestQuestionsAmount } from 'shared/utils/tests'
import get from 'lodash/get'
import { defaultExaminationSettings } from 'shared/constants/examination'
import DurationInput from 'pages/lesson/DurationInput'
import ExaminationResultMessages from 'pages/lesson/ExaminationResultMessages'

type Props = {
  l: ILesson
}

const ExaminationSettings: FC<Props> = ({ l }) => {
  const onChange = (upd: Partial<ILesson>) => {
    dbUpdateLesson(l.id, upd)
  }

  const tasksAmount = useMemo(() => {
    return getTestQuestionsAmount(l)
  }, [l])

  const renderSlider = () => {
    const perc = l.perc || COMPLETION_PERC
    return (
      <VStack w='full' spacing={2} align={'start'}>
        <Label>Процент выполнения</Label>
        <PercSlider
          perc={perc}
          onChange={val => onChange({ perc: val })}
          tasksAmount={tasksAmount}
        />
      </VStack>
    )
  }

  const onChangeExamination = (k: keyof IExaminationSettings) => (v: any) => {
    const currentValue = get(l, 'examination', defaultExaminationSettings)
    const newValue = {
      ...defaultExaminationSettings,
      ...currentValue,
      [k]: v
    }
    onChange({ examination: newValue })
  }
  const renderExaminationSettings = () => {
    return (
      <>
        <VStack w='full' spacing={2} align={'start'}>
          <Label>{'Сколько минут отводится на задание'}</Label>
          <NumberInput
            w='full'
            size='sm'
            variant={'flushed'}
            value={
              get(
                l,
                'examination.duration',
                defaultExaminationSettings.duration
              ) / 60000
            }
            onChange={(s, n) => {
              const v = isNaN(n) ? 0 : n
              onChangeExamination('duration')(v * 60000)
            }}
          >
            <NumberInputField />
          </NumberInput>
        </VStack>
        <VStack w='full' spacing={2} align={'start'}>
          <Label>{'Количество попыток'}</Label>
          <NumberInput
            w='full'
            size='sm'
            variant={'flushed'}
            value={get(
              l,
              'examination.attempts',
              defaultExaminationSettings.attempts
            )}
            onChange={(s, n) => {
              console.log('onChange', n)
              const v = isNaN(n) ? 0 : n
              onChangeExamination('attempts')(v)
            }}
          >
            <NumberInputField />
          </NumberInput>
        </VStack>
        <VStack w='full' spacing={2} align={'start'}>
          <Label>{'Сброс попыток через'}</Label>
          <DurationInput
            value={get(
              l,
              'examination.cooldown',
              defaultExaminationSettings.cooldown
            )}
            onChange={(v: number) => onChangeExamination('cooldown')(v)}
          />
        </VStack>
      </>
    )
  }

  const renderExaminationResultMessages = () => {
    return (
      <VStack w='full' pt={12}>
        <Divider />
        <Label>
          Сообщения о результате экзамена в зависимости от количества выполненых
          заданий
        </Label>
        <ExaminationResultMessages
          messages={l.examination?.messages || []}
          setMessages={v => onChangeExamination('messages')(v)}
          tasksAmount={tasksAmount}
        />
      </VStack>
    )
  }

  return (
    <VStack w='full' spacing={6} align={'start'}>
      <SimpleInput
        label='Название'
        value={l.name || ''}
        onChange={(v: string) => onChange({ name: v })}
      />
      <SimpleInput
        label='Описание'
        value={l.description || ''}
        onChange={(v: string) => onChange({ description: v })}
      />
      {renderSlider()}
      {renderExaminationSettings()}
      {renderExaminationResultMessages()}
    </VStack>
  )
}

export default ExaminationSettings
