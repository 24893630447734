import { FC, useState, useEffect } from 'react'
import { ISectionTestAddWords, ISessionAnswer } from 'shared/types'
import { VStack, Text, HStack, Flex } from '@chakra-ui/react'
import TestTask from 'shared/components/sections/tests/TestTask'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import trim from 'lodash/trim'
import replace from 'lodash/replace'
import WordInput from 'shared/components/sections/tests/WordInput'
import { getTestTitle } from 'shared/utils/tests'
import TestCard from 'shared/components/sections/tests/TestCard'
import WordInputExamination from 'shared/components/sections/tests/WordInputExamination'

type Props = {
  s: ISectionTestAddWords
  onAnswer?: (sectionId: string, answer: ISessionAnswer) => void
  answer?: ISessionAnswer
  testNum?: number
  showUserAnswer?: boolean
  isExamination: boolean
}

const SectionTestAddWords: FC<Props> = ({
  s,
  onAnswer,
  answer,
  testNum,
  showUserAnswer,
  isExamination
}) => {
  const [textParts, setTextParts] = useState<string[][]>([])
  const [addedWords, setAddedWords] = useState([])
  const [matches, setMatches] = useState([])

  const findMatches = (str: string) => {
    const res = str.match(/\{(.*?)\}/g)
    // console.log('matches', res)
    setMatches(res)
  }

  useEffect(() => {
    const t = s.test?.text
    if (t) {
      findMatches(t)
      // const cleanString = t.replace(/{\s*(.*?)\s*}/g, '{$1}')
      const cleanString = replace(
        t,
        /{\s*(.*?)\s*}/g,
        v => {
          // console.log('cleanString part:', v)
          return `{${v
            .substring(1, v.length - 1)
            .trim()
            .split(' ')
            .join('_')}}`
        }
        // v.split(' ').join('')
      )
      // console.log('cleanString', cleanString)
      const sections = cleanString.split(/\r?\n/)
      const parts = map(sections, s => {
        return s.split(' ').filter(v => v)
      })
      // console.log('parts', parts)
      setTextParts(parts)
    }
  }, [s.test?.text])

  const onWordAdded = (isCorrect: boolean) => {
    // console.log('onWordAdded', isCorrect)
    const newAddedWords = [...addedWords, isCorrect]
    setAddedWords(newAddedWords)
    // console.log('newAddedWords', newAddedWords)
    if (matches.length === newAddedWords.length) {
      const correctAnswers = newAddedWords.filter(v => v !== null && v)
      // console.log('correctAnswers', correctAnswers)
      const answerIsCorrect = correctAnswers.length === newAddedWords.length
      const sa: ISessionAnswer = {
        isCorrect: answerIsCorrect,
        answer: ''
      }
      // console.log('session answer', sa)
      if (onAnswer) {
        onAnswer(s.id, sa)
      }
    }
  }

  // console.log('answer !!!!!', answer)

  const renderQuestion = () => {
    if (s.test) {
      return (
        <VStack justify={'flex-start'} align={'flex-start'} spacing={1}>
          {map(textParts, (section, sectionIndex) => {
            return (
              <HStack
                key={sectionIndex}
                spacing={1}
                minH={6}
                // className='ql-snow'
                textStyle={'body'}
                // fontWeight={700}
                flexWrap='wrap'
                justify={'flex-start'}
                align={'flex-start'}
              >
                {map(section, (t, i) => {
                  let wordNum = -1
                  if (t.startsWith('{')) {
                    wordNum++

                    const correctValues = t
                      .substring(1, t.length - 1)
                      .split('/')
                      .map(s => trim(s).split('_').join(' '))
                    if (isExamination) {
                      return (
                        <WordInputExamination
                          correctValues={correctValues}
                          key={i}
                          onWordAdded={(isCorrect: boolean) =>
                            onWordAdded(isCorrect)
                          }
                          completed={!isNil(answer)}
                          highlightCorrectAnswer={
                            !showUserAnswer &&
                            get(s, 'test.highlightCorrectAnswer', false)
                          }
                          showUserAnswer={showUserAnswer}
                        />
                      )
                    } else {
                      return (
                        <WordInput
                          correctValues={correctValues}
                          key={i}
                          onWordAdded={(isCorrect: boolean) =>
                            onWordAdded(isCorrect)
                          }
                          completed={!isNil(answer)}
                          highlightCorrectAnswer={
                            !showUserAnswer &&
                            get(s, 'test.highlightCorrectAnswer', false)
                          }
                        />
                      )
                    }
                  }
                  return (
                    <Flex
                      h={6}
                      align={'flex-start'}
                      justify={'flex-start'}
                      key={i}
                      pr={1}
                    >
                      <Text as='span' key={i}>
                        {t}
                      </Text>
                    </Flex>
                  )
                })}
              </HStack>
            )
          })}
        </VStack>
      )
    }
  }

  return (
    <TestCard showUserAnswer={showUserAnswer} answer={answer}>
      <TestTask
        title={s.test?.title || getTestTitle(s.type)}
        answer={showUserAnswer ? undefined : answer}
        testNum={testNum}
      />
      {renderQuestion()}
    </TestCard>
  )
}

export default SectionTestAddWords
