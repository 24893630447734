import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  VStack
} from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'
import { IUser } from 'shared/types'
import isEmail from 'validator/lib/isEmail'

type Props = {
  user: IUser
  updateUser: (email: string, name: string, phone: string) => Promise<void>
}

const UserSettings: FC<Props> = ({ user, updateUser }) => {
  const [phone, setPhone] = useState(user.phone || '')
  const [name, setName] = useState(user.name || '')
  const [email, setEmail] = useState(user.email || '')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setPhone(user.phone || '')
    setName(user.name || '')
    setEmail(user.email || '')
  }, [user])

  const onSave = async () => {
    setLoading(true)
    await updateUser(email, name, phone)
    setLoading(false)
  }

  const renderUserInfo = () => {
    return (
      <Card w='full' maxW='2xl'>
        <CardHeader>
          <Heading size='md'>{user.name || 'noname'}</Heading>
        </CardHeader>
        <CardBody>
          <VStack w='full' spacing={6}>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <HStack w='full'>
                <Input
                  variant={'flushed'}
                  size='sm'
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </HStack>
            </FormControl>
            <FormControl>
              <FormLabel>Имя</FormLabel>
              <HStack w='full'>
                <Input
                  variant={'flushed'}
                  size='sm'
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </HStack>
            </FormControl>
            <FormControl>
              <FormLabel>Телефон</FormLabel>
              <HStack w='full'>
                <Input
                  variant={'flushed'}
                  size='sm'
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                />
              </HStack>
            </FormControl>
          </VStack>
        </CardBody>
        <CardFooter justify={'flex-end'}>
          <Button
            colorScheme='teal'
            isDisabled={
              (name === (user.name || '') &&
                phone === (user.phone || '') &&
                email === user.email) ||
              !isEmail(email)
            }
            onClick={onSave}
            size='sm'
            isLoading={loading}
          >
            Сохранить
          </Button>
        </CardFooter>
      </Card>
    )
  }

  return <VStack w='full'>{renderUserInfo()}</VStack>
}

export default UserSettings
