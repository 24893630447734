import {
  Card,
  CardHeader,
  Heading,
  CardBody,
  Divider,
  CardFooter,
  VStack,
  HStack,
  Button
} from '@chakra-ui/react'
import { FC, ReactNode } from 'react'

type Props = {
  title?: string
  children: ReactNode
  saveDisabled: boolean
  onSave: () => void
  hideHeaderButton?: boolean
}

const SettingsCard: FC<Props> = ({
  title = 'Настройки',
  children,
  saveDisabled,
  onSave,
  hideHeaderButton
}) => {
  const saveButton = (
    <Button
      colorScheme='teal'
      isDisabled={saveDisabled}
      onClick={onSave}
      size='sm'
    >
      Сохранить
    </Button>
  )

  return (
    <Card w='full' maxW='2xl'>
      <CardHeader>
        <HStack w='full' justify={'space-between'}>
          <Heading size='md'>{title}</Heading>
          {hideHeaderButton ? null : saveButton}
        </HStack>
      </CardHeader>
      <Divider color='gray.200' />
      <CardBody>
        <VStack w='full' spacing={6}>
          {children}
        </VStack>
      </CardBody>
      <Divider color='gray.200' />
      <CardFooter justify={'flex-end'}>{saveButton}</CardFooter>
    </Card>
  )
}

export default SettingsCard
