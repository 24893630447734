import {
  VStack,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem
} from '@chakra-ui/react'
import NavBar from 'components/NavBar'
import { useSelector } from 'model/hooks'
import { IConference, ILesson, ILessonType } from 'shared/types'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import get from 'lodash/get'
import Loading from 'components/Loading'
import ConferenceSettingsCard from 'pages/conferences/ConferenceSettingsCard'
import LessonsList from 'components/LessonsList'
import {
  dbDeleteConference,
  dbDuplicateConference,
  dbUpdateConference
} from 'controllers/conferences'
import { useContext, useMemo, useRef } from 'react'
import LessonsContext from 'contexts/LessonsContext'
import { generateId } from 'controllers/db'
import { dbCreateLesson, dbDeleteLessonEntity } from 'controllers/lessons'
import filter from 'lodash/filter'
// import omitBy from 'lodash/omitBy'
// import isUndefined from 'lodash/isUndefined'
import { CopyIcon, DeleteIcon, SettingsIcon } from '@chakra-ui/icons'
import DeleteConfirmation, {
  IDeleteConfirmationModal
} from 'modals/DeleteConfirmation'
import CustomTabPanel from 'components/CustomTabPanel'
import EntityUsers from 'components/EntityUsers'

const Conference = () => {
  const navigate = useNavigate()
  const { lessons } = useContext(LessonsContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const { entityId } = useParams() as { entityId: string }
  const deleteModalRef = useRef<IDeleteConfirmationModal>(null)
  const c: IConference | undefined = useSelector(state =>
    get(state.conferences, entityId)
  )

  const currentTabIndex = useMemo(() => {
    const tab = searchParams.get('tab')
    if (tab) {
      return +tab
    } else {
      return 0
    }
  }, [searchParams])

  const updateLessons = (ids: string[]) => {
    dbUpdateConference(entityId, { lessons: ids })
  }

  const onDuplicateLesson = (lessonId: string) => {
    const l = lessons && lessons[lessonId]
    if (l && c) {
      const id = generateId()
      const newL: ILesson = {
        ...l,
        id,
        name: l.name + ' (copy)',
        entityId: c.id
      }
      dbCreateLesson(id, newL)
      dbUpdateConference(entityId, { lessons: [...(c?.lessons || []), id] })
    }
  }

  const onAddLesson = () => {
    if (c) {
      const id = generateId()
      const newL: ILesson = {
        id,
        name: 'Новый урок',
        entityId: c.id,
        type: ILessonType.LECTURE
      }
      dbCreateLesson(id, newL)
      dbUpdateConference(entityId, { lessons: [...(c?.lessons || []), id] })
    }
  }

  const onDeleteLesson = (lessonId: string) => {
    if (c) {
      const newIds = filter(c.lessons, lId => lId !== lessonId)
      dbUpdateConference(entityId, { lessons: newIds })
      dbDeleteLessonEntity(lessonId, c.id)
    }
  }

  const onLessonClick = (id: string) => {
    navigate(`/conferences/${entityId}/${id}`)
  }

  // const update = ({
  //   sections,
  //   sectionsOrder
  // }: {
  //   sections?: Record<string, ISection>
  //   sectionsOrder?: string[]
  // }) => {
  //   if (c) {
  //     const upd = {
  //       sections,
  //       sectionsOrder
  //     }
  //     dbUpdateConference(c.id, omitBy(upd, isUndefined))
  //   }
  // }

  const onDelete = () => {
    if (c) {
      dbDeleteConference(c)
      navigate('/conferences')
    }
  }

  const onDuplicate = async () => {
    if (c && lessons) {
      const newId = await dbDuplicateConference(c, lessons)
      navigate(`/conferences/${newId}`)
    }
  }

  const navBarActions = (
    <Menu>
      <MenuButton as={IconButton} icon={<SettingsIcon />}>
        Actions
      </MenuButton>
      <MenuList>
        <MenuItem icon={<CopyIcon />} onClick={onDuplicate}>
          Создать копию конференции
        </MenuItem>
        <MenuItem
          color='red'
          icon={<DeleteIcon />}
          onClick={() => deleteModalRef.current?.open()}
        >
          Удалить конференцию
        </MenuItem>
      </MenuList>
    </Menu>
  )

  if (!c) {
    return <Loading />
  } else {
    return (
      <VStack w='full' h='full' spacing={0} overflow={'hidden'} maxH={'full'}>
        <NavBar title={c.title} backUrl='/conferences'>
          {navBarActions}
        </NavBar>
        <Tabs
          colorScheme='blackAlpha'
          variant='soft-rounded'
          w='full'
          overflow={'hidden'}
          h='full'
          flex={1}
          display={'flex'}
          flexDirection={'column'}
          index={currentTabIndex}
          onChange={(tabIndex: number) =>
            setSearchParams({ tab: tabIndex.toString() })
          }
        >
          <TabList
            display='flex'
            alignItems='center'
            justifyContent='center'
            borderBottom='none'
            p='4'
            overflow={'hidden'}
          >
            <Tab>Настройки</Tab>
            <Tab>Уроки</Tab>
            <Tab>Ученики</Tab>
          </TabList>

          <TabPanels overflow={'hidden'} flex={1}>
            <CustomTabPanel>
              <ConferenceSettingsCard c={c} />
            </CustomTabPanel>
            <CustomTabPanel>
              <LessonsList
                ids={c.lessons}
                updateIds={updateLessons}
                onAddLesson={onAddLesson}
                onDuplicate={onDuplicateLesson}
                onDelete={onDeleteLesson}
                onClick={onLessonClick}
              />
            </CustomTabPanel>
            <CustomTabPanel>
              <EntityUsers />
            </CustomTabPanel>
          </TabPanels>
        </Tabs>
        <DeleteConfirmation
          ref={deleteModalRef}
          title='Удаление конференции'
          description={`Вы собираетесь удалить конференцию "${c.title}" и все ее уроки. Она станет не доступена для пользователей.`}
          onDelete={onDelete}
        />
      </VStack>
    )
  }
}

export default Conference
