import { FC } from 'react'
import ReactQuill, { ReactQuillProps } from 'react-quill'
// import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.bubble.css'
import 'components/contentSettingsCard/reachEditor/custom.css'
import { theme } from 'shared/theme'

const Font = ReactQuill.Quill.import('formats/font')
Font.whitelist = ['Inter', 'NewtonPhonetic']
ReactQuill.Quill.register(Font, true)

type Props = ReactQuillProps & {
  value: string
  onChange: (v: string) => void
}

const importColor = (v: string) => {
  return Object.values(theme.colors[v])
}

const colors = [
  ...importColor('black'),
  ...importColor('wood'),
  ...importColor('blue'),
  ...importColor('red'),
  ...importColor('green'),
  ...importColor('sun')
]

// console.log('colors', colors)

const modules = {
  toolbar: [
    [{ font: Font.whitelist }],
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    // ['blockquote', 'code-block'],
    ['link'],
    // [{ list: 'ordered' }, { list: 'bullet' }],
    // [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    // [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    // [{ direction: 'rtl' }], // text direction

    [{ color: colors }, { background: colors }], // dropdown with defaults from theme
    [{ align: [] }],

    ['clean'] // remove formatting button
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false
  }
}

const formats = [
  'size',
  'font',
  'bold',
  'italic',
  'underline',
  // 'code-block',
  'strike',
  // 'blockquote',
  'background',
  'list',
  'bullet',
  'indent',
  'link',
  'color',
  'rtl',
  'align'
  // 'image',
  // 'video'
]

const ReachEditor: FC<Props> = ({ value, onChange, ...rest }) => {
  return (
    <ReactQuill
      // theme='snow'
      theme='bubble'
      value={value}
      onChange={onChange}
      modules={modules}
      formats={formats}
      {...rest}
    />
  )
}

export default ReachEditor
