import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  Text,
  VStack,
  HStack,
  Button,
  Flex,
  Box
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import {
  Ref,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
  useRef,
  useEffect
} from 'react'
import { useNavigate } from 'react-router-dom'
import { IExaminationAttempt, IExaminationSettings, IUser } from 'shared/types'
import isEmpty from 'lodash/isEmpty'
import DayJSUtc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import weekday from 'dayjs/plugin/weekday'
import dayOfYear from 'dayjs/plugin/dayOfYear'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(DayJSUtc)
dayjs.extend(timezone)
dayjs.extend(weekday)
dayjs.extend(dayOfYear)
dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.locale('ru')

export interface IStartExaminationModal {
  open: () => void
}

type Props = {
  lessonId: string
  entityId: string
  title: string
  examinationSettings: IExaminationSettings
  currentAttempt: IExaminationAttempt | null
  lastResult: {
    correctAnswersAmount: number
    questionsAmount: number
  } | null
  onStart: () => void
  canViewErrors: boolean
  onViewErrors: () => void
  user: IUser
  dbStartExaminationAttempt: (ea: IExaminationAttempt) => Promise<void>
}

const StartExaminationModal: ForwardRefRenderFunction<
  IStartExaminationModal,
  Props
> = (
  {
    lessonId,
    entityId,
    title,
    examinationSettings,
    lastResult,
    currentAttempt,
    onStart,
    canViewErrors,
    onViewErrors,
    user,
    dbStartExaminationAttempt
  },
  ref: Ref<unknown>
) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate = useNavigate()
  const [refresher, setRefresher] = useState<number>(0)
  const refresherTimerRef = useRef<number>(0)

  useImperativeHandle(ref, () => ({
    open: () => {
      onOpen()
    }
  }))

  useEffect(() => {
    refresherTimerRef.current = window.setInterval(
      () => setRefresher(Date.now()),
      10000
    )
    return () => clearInterval(refresherTimerRef.current)
  }, [])

  const isExpired = useMemo(() => {
    const startTime = currentAttempt?.firstAttemptTimestamp || Date.now()
    const cooldownTimestamp = startTime + examinationSettings.cooldown
    return cooldownTimestamp < Date.now()
  }, [currentAttempt, examinationSettings])

  const startAttempt = async () => {
    if (user) {
      onStart()
      onClose()
      const attemptId = `${user.id}_${lessonId}`
      let firstAttemptTimestamp = Date.now()
      if (currentAttempt && !isExpired) {
        firstAttemptTimestamp = currentAttempt.firstAttemptTimestamp
      }
      const ea: IExaminationAttempt = {
        id: attemptId,
        firstAttemptTimestamp,
        timestamp: Date.now(),
        lessonId,
        entityId,
        userId: user.id,
        attemptNum:
          currentAttempt && !isExpired ? currentAttempt.attemptNum + 1 : 1
      }
      await dbStartExaminationAttempt(ea)
    }
  }

  const renderInfo = () => {
    const durationHours = dayjs.duration(examinationSettings.duration).hours()
    const durationMinutes = dayjs
      .duration(examinationSettings.duration)
      .minutes()
    console.log(
      'durationHours',
      durationHours,
      'durationMinutes',
      durationMinutes
    )
    const startTime = currentAttempt?.firstAttemptTimestamp || Date.now()
    const cooldownTimestamp = startTime + examinationSettings.cooldown
    const isExpired = cooldownTimestamp < Date.now()
    const nextReset = isExpired
      ? Date.now() + examinationSettings.cooldown
      : cooldownTimestamp
    return (
      <VStack w='full' spacing={0}>
        <HStack
          w='full'
          h='12'
          align='center'
          bg='lightGray'
          justify={'space-between'}
          p={2}
          textStyle='small'
          rounded={'4px'}
        >
          <Text>Время</Text>
          <Text>
            {durationHours > 0 &&
              `${dayjs.duration(durationHours, 'hours').format('HH')} ч :`}{' '}
            {`${dayjs.duration(durationMinutes, 'minutes').format('mm')} мин`}
          </Text>
        </HStack>
        <HStack
          w='full'
          h='12'
          align='center'
          justify={'space-between'}
          p={2}
          textStyle='small'
          rounded={'4px'}
        >
          <Text>Попытка</Text>
          <Text>{`${
            currentAttempt && !isExpired ? currentAttempt.attemptNum : 0
          } / ${examinationSettings.attempts}`}</Text>
        </HStack>
        <HStack
          w='full'
          h='12'
          align='center'
          bg='lightGray'
          justify={'space-between'}
          p={2}
          textStyle='small'
          rounded={'4px'}
        >
          <Text>Результат</Text>
          {lastResult ? (
            <Text>{`${lastResult?.correctAnswersAmount || 0} / ${
              lastResult?.questionsAmount || 0
            }`}</Text>
          ) : (
            <Text>-</Text>
          )}
        </HStack>
        <HStack
          w='full'
          h='12'
          align='center'
          justify={'space-between'}
          p={2}
          textStyle='small'
          rounded={'4px'}
        >
          <Text>Сброс попыток</Text>
          <Text>{dayjs(nextReset).format('D MMMM, HH:mm')}</Text>
        </HStack>
      </VStack>
    )
  }

  const renderStartButton = () => {
    return (
      <Button
        variant={'primary'}
        w='full'
        onClick={startAttempt}
        isDisabled={
          currentAttempt !== null &&
          currentAttempt.attemptNum >= examinationSettings.attempts &&
          !isExpired
        }
      >
        Начать попытку
      </Button>
    )
  }

  const switchToViewErrors = () => {
    onClose()
    onViewErrors()
  }

  const renderViewErrorsButton = () => {
    if (canViewErrors) {
      return (
        <Button
          variant={'outline'}
          w='full'
          colorScheme='black'
          rounded={'full'}
          onClick={switchToViewErrors}
        >
          Посмотреть ошибки
        </Button>
      )
    }
  }

  const renderExitButton = () => {
    return (
      <Flex w='full' justify='center'>
        <Button
          variant={'link'}
          textStyle='body'
          fontWeight={450}
          color='black'
          textDecoration={'underline'}
          onClick={() => {
            onClose()
            navigate(-1)
          }}
        >
          Выйти
        </Button>
      </Flex>
    )
  }

  const renderMessage = () => {
    if (lastResult && !isEmpty(examinationSettings.messages)) {
      const perc = Math.floor(
        (lastResult.correctAnswersAmount / lastResult.questionsAmount) * 100
      )
      let message = get(examinationSettings.messages, [0, 'msg'])
      forEach(examinationSettings.messages, m => {
        if (perc >= m.perc) {
          message = m.msg
        }
      })
      return (
        <VStack w='full' align='flex-start' spacing={0}>
          <Box
            // py={{ base: 3, lg: 4 }}
            className='ql-snow'
            // textStyle={s.textStyle || 'body'}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        </VStack>
      )
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay
        bg='blackAlpha.800'
        backdropFilter='auto'
        backdropBlur='2px'
      />
      <ModalContent rounded='16px' w={{ base: '360px', lg: '596px' }}>
        <ModalBody p={6} m={0}>
          <VStack spacing={6} w='full' align={'flex-start'}>
            <Text textStyle='h3' fontWeight={700}>
              {title}
            </Text>
            {renderMessage()}
            {renderInfo()}
            <VStack w='full' spacing={2}>
              {renderStartButton()}
              {renderViewErrorsButton()}
              {renderExitButton()}
            </VStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(StartExaminationModal)
