import { FC } from 'react'
import { ILesson } from 'shared/types'
import { dbUpdateLesson } from 'controllers/lessons'
import { VStack } from '@chakra-ui/react'
import SimpleInput from 'shared/components/SimpleInput'

type Props = {
  l: ILesson
}

const LectureSettings: FC<Props> = ({ l }) => {
  const onChange = (upd: Partial<ILesson>) => {
    dbUpdateLesson(l.id, upd)
  }

  return (
    <VStack w='full' spacing={6} align={'start'}>
      <SimpleInput
        label='Название'
        value={l.name || ''}
        onChange={(v: string) => onChange({ name: v })}
      />
      {/* <SimpleInput
        label='Описание'
        value={l.description || ''}
        onChange={(v: string) => onChange({ description: v })}
      /> */}
    </VStack>
  )
}

export default LectureSettings
