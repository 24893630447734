import { Card, Center, Flex, HStack, Text } from '@chakra-ui/react'
import EntityContentWeeks from 'components/entityContent/EntityContentWeeks'
import { dbUpdateCourse } from 'controllers/courses'
import { dbUpdateFree } from 'controllers/frees'
import { FC, useEffect, useMemo, useState } from 'react'
import { IDay, IEntityType, IWeek } from 'shared/types'
import _ from 'lodash'
import EntityContentWeek from 'components/entityContent/EntityContentWeek'
import EntityContentDay from 'components/entityContent/EntityContentDay'
import { useSearchParams } from 'react-router-dom'

type Props = {
  content: IWeek[]
  entityId: string
  entityType: IEntityType
}

const EntityContent: FC<Props> = ({ entityId, entityType, content }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedWeek, setSelectedWeek] = useState(
    searchParams.get('w') || _.get(content, [0, 'id'], undefined)
  )
  const [selectedDay, setSelectedDay] = useState<string | undefined>(
    searchParams.get('d') || _.get(content, [0, 'days', 0, 'id'], undefined)
  )

  // useEffect(() => {
  //   const w = _.find(content, w => w.id === selectedWeek)
  //   if (w) {
  //     if (w.days && w.days.length > 0) {
  //       setSelectedDay(_.get(w, ['days', 0, 'id']))
  //     } else {
  //       setSelectedDay(undefined)
  //     }
  //   }
  // }, [selectedWeek])

  useEffect(() => {
    const params: any = { tab: '1' }
    if (selectedDay) {
      params.d = selectedDay.toString()
    }
    if (selectedWeek) {
      params.w = selectedWeek.toString()
    }
    setSearchParams(params)
  }, [selectedWeek, selectedDay])

  const week = useMemo(() => {
    return _.find(content, w => w.id === selectedWeek)
  }, [content, selectedWeek])

  const day = useMemo(() => {
    const w = _.find(content, w => w.id === selectedWeek)
    if (w) {
      return _.find(w.days, d => d.id === selectedDay)
    }
  }, [content, selectedWeek, selectedDay])

  const updateContent = (c: IWeek[]) => {
    switch (entityType) {
      case IEntityType.COURSES: {
        dbUpdateCourse(entityId, { content: c })
        break
      }
      case IEntityType.FREE: {
        dbUpdateFree(entityId, { content: c })
        break
      }
      default: {
        console.error('cannot update the entity type', entityType)
      }
    }
  }

  const renderWeeks = () => {
    return (
      <EntityContentWeeks
        content={content}
        updateContent={updateContent}
        selectedWeek={selectedWeek}
        setSelectedWeek={setSelectedWeek}
        entityId={entityId}
        entityType={entityType}
      />
    )
  }

  const updateWeek = (w: IWeek) => {
    const newContent: IWeek[] = _.reduce(
      content,
      (res: IWeek[], week) => {
        if (week.id === w.id) {
          return [...res, w]
        } else {
          return [...res, week]
        }
      },
      []
    )
    updateContent(newContent)
  }

  const moveDay = (dayId: string, toWeekId: string) => {
    if (week) {
      const day = _.find(week.days, d => d.id === dayId)
      if (day) {
        const newContent: IWeek[] = []
        _.forEach(content, w => {
          const newW: IWeek = {
            ...w,
            days: [
              ..._.filter(w.days, d => d.id !== dayId),
              ...(w.id === toWeekId ? [day] : [])
            ]
          }
          newContent.push(newW)
        })
        updateContent(newContent)
      }
    }
  }

  const renderWeek = () => {
    if (week) {
      return (
        <EntityContentWeek
          week={week}
          updateWeek={updateWeek}
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          entityId={entityId}
          entityType={entityType}
          content={content}
          moveDay={moveDay}
        />
      )
    } else {
      return (
        <Center w='full' h='full'>
          <Text textStyle={'small'} color='darkGray'>
            Неделя не выбрана
          </Text>
        </Center>
      )
    }
  }

  const updateDay = (d: IDay) => {
    if (week) {
      const newWeekDays: IDay[] = _.reduce(
        week.days,
        (res: IDay[], day) => {
          if (day.id === d.id) {
            return [...res, d]
          } else {
            return [...res, day]
          }
        },
        []
      )
      const newWeek = { ...week, days: newWeekDays }
      updateWeek(newWeek)
    }
  }

  const moveLesson = (lId: string, toWeekId: string, toDayId: string) => {
    const newContent: IWeek[] = []
    _.forEach(content, w => {
      const newW = {
        ...w,
        days: _.map(w.days, d => {
          return {
            ...d,
            lessons: [
              ..._.filter(d.lessons, lessonId => lessonId !== lId),
              ...(w.id === toWeekId && d.id === toDayId ? [lId] : [])
            ]
          }
        })
      }
      newContent.push(newW)
    })
    updateContent(newContent)
  }

  const renderDay = () => {
    if (day && week) {
      return (
        <EntityContentDay
          day={day}
          weekId={week.id}
          updateDay={updateDay}
          entityId={entityId}
          entityType={entityType}
          content={content}
          moveLesson={moveLesson}
        />
      )
    } else {
      return (
        <Center w='full' h='full'>
          <Text textStyle={'small'} color='darkGray'>
            Урок не выбран
          </Text>
        </Center>
      )
    }
  }

  return (
    <Card w='full' maxW='full'>
      <HStack w='full' h='full'>
        <Flex h='full' flex={1} borderRightWidth={1}>
          {renderWeeks()}
        </Flex>
        <Flex h='full' flex={1} borderRightWidth={1}>
          {renderWeek()}
        </Flex>
        <Flex h='full' flex={2}>
          {renderDay()}
        </Flex>
      </HStack>
    </Card>
  )
}

export default EntityContent
