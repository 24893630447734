import ReachEditor from 'components/contentSettingsCard/ReachEditor'
import { FC, useState } from 'react'
import { Button, HStack, VStack } from '@chakra-ui/react'

type Props = {
  onAdd: (v: string) => void
}

const AddBulletPointButton: FC<Props> = ({ onAdd }) => {
  const [isEdit, setIsEdit] = useState(false)
  const [value, setValue] = useState('')

  const onApply = () => {
    onAdd(value)
    setValue('')
    setIsEdit(false)
  }

  if (isEdit) {
    return (
      <VStack w='full'>
        <ReachEditor value={value} onChange={(v: string) => setValue(v)} />
        <HStack justify={'space-between'} w='full'>
          <Button
            variant={'ghost'}
            size='xs'
            onClick={onApply}
            colorScheme='wood'
          >
            Cancel
          </Button>
          <Button variant={'outline'} size='xs' onClick={onApply}>
            Add
          </Button>
        </HStack>
      </VStack>
    )
  } else {
    return (
      <Button
        variant={'ghost'}
        size='sm'
        colorScheme='wood'
        onClick={() => setIsEdit(true)}
      >
        + Добавить строку
      </Button>
    )
  }
}

export default AddBulletPointButton
