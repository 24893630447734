import { createReducer } from '@reduxjs/toolkit'
import { ICourse } from 'shared/types'
import { receiveCourses } from 'src/model/actions'

const initialState = null as Record<string, ICourse> | null

const reducer = createReducer(initialState, builder => {
  builder.addCase(receiveCourses, (state, action) => action.payload)
})

export default reducer
