import { VStack, Text, RadioGroup, Radio } from '@chakra-ui/react'
import { FC } from 'react'
import isString from 'lodash/isString'
import { ITestContent } from 'shared/types'

type Props = {
  test: ITestContent
  onChange: (v: ITestContent) => void
}

const EditTestTrueFalse: FC<Props> = ({ test, onChange }) => {
  const setAnswer = (optionId: string) => {
    onChange({ ...test, answer: optionId })
  }

  return (
    <VStack w='full' align={'flex-start'} spacing={4}>
      <Text textStyle={'body'}>Выберите верный ответ:</Text>
      <RadioGroup
        w='full'
        value={isString(test.answer) ? test.answer : undefined}
        onChange={(v: string) => setAnswer(v)}
      >
        <VStack w='full' align={'flex-start'}>
          <Radio
            value={'yes'}
            textStyle={'body'}
            borderColor='darkGray'
            borderWidth={1}
          >
            Верно
          </Radio>
          <Radio
            value={'no'}
            textStyle={'body'}
            borderColor='darkGray'
            borderWidth={1}
          >
            Неверно
          </Radio>
        </VStack>
      </RadioGroup>
    </VStack>
  )
}

export default EditTestTrueFalse
