import { FC, useRef, useState, useEffect, MouseEvent, useMemo } from 'react'
import {
  Input,
  InputProps,
  Flex,
  HStack,
  VStack,
  Box,
  Text
} from '@chakra-ui/react'
import AutowidthInput from 'react-autowidth-input'
import { ReactComponent as IconEnter } from 'shared/assets/bsArrowReturnRight.svg'
import last from 'lodash/last'
import trim from 'lodash/trim'
import find from 'lodash/find'
import isNil from 'lodash/isNil'
import { cleanupString } from 'shared/utils/tests'
import CorrectText from 'shared/components/sections/tests/CorrectText'

type Props = Omit<InputProps, 'onChange'> & {
  correctValues: string[]
  onWordAdded: (isCorrect: boolean) => void
  highlightCorrectAnswer: boolean
  completed: boolean
  showUserAnswer?: boolean
}

const GREEN = '#64C9001A'
const RED = '#E54F201A'

const WordInputExamination: FC<Props> = ({
  correctValues,
  onWordAdded,
  value,
  highlightCorrectAnswer,
  completed,
  showUserAnswer,
  ...rest
}) => {
  const ref = useRef<HTMLInputElement>(null)
  const [focused, setFocused] = useState(false)
  const [v, setV] = useState<string>(value ? value.toString() : '')

  useEffect(() => {
    setV(value ? value.toString() : '')
  }, [value])

  const correctValue = useMemo(() => {
    return find(correctValues, cv => {
      return cleanupString(v) === cleanupString(cv)
    })
  }, [v, correctValues])

  const readOnly = !showUserAnswer

  const onFocus = () => {
    if (!readOnly) {
      setFocused(true)
      setV('')
    }
  }

  const isCorrect = !isNil(correctValue)

  // console.log('correct values', correctValues, 'found value', correctValue)

  const apply = () => {
    if (trim(v) !== '') {
      const isCorrect = !isNil(correctValue)
      onWordAdded(isCorrect)
    }
    setFocused(false)
  }

  const inputColor = useMemo(() => {
    return showUserAnswer ? 'black' : isCorrect ? 'green' : 'red'
  }, [showUserAnswer, isCorrect])

  const renderInput = () => {
    if (isCorrect && !showUserAnswer) {
      return (
        <HStack px={2} h={8}>
          <CorrectText answer={v} correctAnswer={correctValue} />
        </HStack>
      )
    } else {
      return (
        <Input
          type='text'
          ref={ref}
          value={v}
          fontSize={{ base: '16px', lg: 'lg' }}
          fontWeight={450}
          letterSpacing={{ base: '-0.32px', lg: '-0.36px' }}
          lineHeight={{ base: '20px', lg: '24px' }}
          as={AutowidthInput}
          onChange={e => setV(e.target.value)}
          onBlur={apply}
          px={2}
          autoComplete='off'
          // placeholder='Перевод'
          _placeholder={{ color: 'lightGray' }}
          // minW={focused || v !== '' ? '16px' : '48px'}
          minW={'48px'}
          h={8}
          onFocus={onFocus}
          // width={'auto'}
          border='none'
          bg={
            !completed || showUserAnswer ? undefined : isCorrect ? GREEN : RED
          }
          // _disabled={{ opacity: 1 }}
          // disabled={attempts.length === 3 || last(attempts) === true}
          readOnly={readOnly}
          // borderColor={'midGray'}
          // _focus={{
          //   borderColor: 'black'
          // }}
          _focusVisible={{
            outline: 'none'
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              ref.current?.blur()
            }
          }}
          rounded={'0'}
          extraWidth={0}
          {...rest}
        />
      )
    }
  }

  return (
    <HStack spacing={1}>
      <HStack
        w='auto'
        role={'group'}
        spacing={0}
        borderWidth={1}
        rounded='4px'
        color={inputColor}
        borderColor={
          !completed || showUserAnswer
            ? focused
              ? 'black'
              : 'midGray'
            : isCorrect
            ? 'green'
            : 'red'
        }
      >
        {renderInput()}
        {highlightCorrectAnswer && readOnly && !isCorrect && completed && (
          <Flex h={8} bg={GREEN} align='center' px={2}>
            <Text color='green'>{correctValues.join(' / ')}</Text>
          </Flex>
        )}
        {/* <InputRightAddon
          h={'32px'}
          px={0}
          display={focused ? 'block' : 'hidden'}
          border='none'
          opacity={readOnly ? 0 : 1}
          bg='white'
          _hover={{ cursor: readOnly ? 'text' : 'pointer' }}
        > */}
        {/* {focused && (
          <Flex
            bg={'white'}
            _hover={{ cursor: 'pointer' }}
            h={8}
            w={8}
            // borderWidth={1}
            // borderColor={'red'}
            // outline={'1px solid black'}
            rounded={'0px 2px 2px 0px'}
            color='white'
            align='center'
            justify='center'
            // _hover={{ cursor: 'pointer' }}
            // onClick={onRightAddonClick}
          >
            {!completed && <IconEnter />}
          </Flex>
        )} */}
        {/* </InputRightAddon> */}
      </HStack>
    </HStack>
  )
}

export default WordInputExamination
