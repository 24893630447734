import {
  Flex,
  VStack,
  Text,
  Box,
  AspectRatio,
  Stack,
  Show,
  Grid,
  GridItem,
  HStack,
  Image
} from '@chakra-ui/react'
import BackButton from 'shared/components/BackButton'
import { FC, ReactNode } from 'react'
import Vimeo from '@u-wave/react-vimeo'
import ContentContainer from 'shared/components/ContentContainer'
import map from 'lodash/map'
import CourseTag from 'shared/components/CourseTag'
import EditSectionButton from 'shared/components/EditSectionButton'
import { MAX_WIDTH } from 'shared/constants/main'

type Props = {
  backUrl?: string
  partition?: string
  level?: string
  teacher?: string
  title: string
  price?: number
  oldPrice?: number
  priceEur?: number
  oldPriceEur?: number
  children?: ReactNode
  videoId?: string
  studentsLimit?: number
  tags?: string[]
  imageUrl?: string
  onEditHeader?: () => void
}

const PageContentHeader: FC<Props> = ({
  backUrl,
  partition,
  level,
  teacher,
  title,
  price,
  oldPrice,
  priceEur,
  oldPriceEur,
  children,
  videoId,
  studentsLimit,
  imageUrl,
  tags,
  onEditHeader
}) => {
  const hasbanner = videoId || imageUrl

  const renderBanner = () => {
    if (videoId) {
      return (
        <AspectRatio ratio={16 / 9} flex={1} w='full' rounded={'xs'}>
          <Flex flex={1} w='full' h='full'>
            <Box flex={1} sx={{ iframe: { borderRadius: 'xs' } }}>
              {videoId && (
                <Vimeo
                  video={videoId}
                  responsive
                  onError={e => console.log(e)}
                />
              )}
            </Box>
          </Flex>
        </AspectRatio>
      )
    } else if (imageUrl) {
      return (
        <AspectRatio ratio={16 / 9} flex={1} w='full' rounded={'xs'}>
          <Flex flex={1} w='full' h='full' rounded={'xs'}>
            <Box flex={1} sx={{ iframe: { borderRadius: '8px' } }}>
              <Image src={imageUrl} rounded={'xs'} />
            </Box>
          </Flex>
        </AspectRatio>
      )
    }
  }

  const renderPrice = () => {
    if (price) {
      return (
        <Stack
          spacing={{ lg: 0, base: 2 }}
          // direction={{ lg: 'column', base: 'row-reverse' }}
          // align={{ base: 'flex-end', lg: 'flex-start' }}
        >
          <HStack textStyle='body' color='black.200'>
            {oldPrice && (
              <Text textDecoration={'line-through'}>{oldPrice} РУБ</Text>
            )}
            {oldPriceEur && oldPrice && <Text>/</Text>}
            {oldPriceEur && (
              <Text textDecoration={'line-through'}>{oldPriceEur} EUR</Text>
            )}
          </HStack>
          <HStack textStyle='h2' color='sun.950'>
            <Text>{price} РУБ</Text>
            {priceEur && price && <Text>/</Text>}
            {priceEur && <Text>{priceEur} EUR</Text>}
          </HStack>
        </Stack>
      )
    }
  }

  const renderTags = () => {
    if (tags && tags.length > 0) {
      return (
        <Stack direction={'row'} textStyle='body' wrap={'wrap'}>
          {map(tags, (t, i) => {
            return <CourseTag key={t + i} t={t} i={i} />
          })}
        </Stack>
      )
    }
  }

  return (
    <ContentContainer
      borderBottomRadius={{ base: 'sm', lg: 'lg' }}
      bg='wood.100'
      backBg='white'
      containerProps={{ role: 'group' }}
    >
      <EditSectionButton onClick={onEditHeader} />
      <VStack
        // bg='gray.100'
        w='full'
        pt={{ base: 16, lg: 20 }}
        pb={{ base: 4, lg: 8 }}
        // px={{ base: 8, lg: 8 }}
        align={'flex-start'}
        spacing={4}
      >
        <BackButton backUrl={backUrl} />
        <Show below='lg'>
          <VStack align={'flex-start'} w='full'>
            <VStack align={'flex-start'} spacing={4} w='full'>
              <Text textStyle='h2' fontWeight={700}>
                {title}
              </Text>
              {renderTags()}
              {renderBanner()}
              {renderPrice()}
              {children}
            </VStack>
          </VStack>
        </Show>

        <Show above='lg'>
          <Grid
            templateColumns={hasbanner ? 'repeat(2, 1fr)' : '1fr'}
            w='full'
            gap={4}
          >
            <GridItem>
              <VStack
                flex={1}
                // bg='brand.50'
                align={'flex-start'}
                borderRadius={'xs'}
                h='full'
                justify={'space-between'}
                spacing={6}
                // p={{ base: 4, lg: 6 }}
              >
                <VStack align={'flex-start'} spacing={6} w='full'>
                  <Text textStyle='h2' fontWeight={700}>
                    {title}
                  </Text>
                  {renderTags()}
                </VStack>
                <HStack align={'flex-end'} spacing={6}>
                  {renderPrice()}
                  <Box pb={price ? 2 : 0}>{children}</Box>
                </HStack>
              </VStack>
            </GridItem>
            {hasbanner && <GridItem>{renderBanner()}</GridItem>}
          </Grid>
        </Show>
      </VStack>
    </ContentContainer>
  )
}

export default PageContentHeader
