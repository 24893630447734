import { FC, useMemo, ReactNode } from 'react'
import { Text, TextProps } from '@chakra-ui/react'
import words from 'lodash/words'
import toLower from 'lodash/toLower'
import forEach from 'lodash/forEach'

type Props = TextProps & {
  answer: string
  correctAnswer: string
}

const CorrectText: FC<Props> = ({ answer, correctAnswer, ...rest }) => {
  const value = useMemo(() => {
    const cAr = words(toLower(correctAnswer))
    const res: ReactNode[] = []
    const answerAr = answer.split(' ')
    // console.log('answerAr', answerAr)
    let wordNum = 0
    forEach(answerAr, (aw, k) => {
      const aWords = words(aw)
      if (aWords.length > 0) {
        const word = toLower(aWords[0])
        const correctWord = cAr[wordNum]
        // console.log('aWords', word, correctWord)
        wordNum = wordNum + 1
        if (word !== correctWord) {
          // console.log('----> found:', word, correctWord)
          const syms = aw.split('')
          let delta = 0
          for (const i in syms) {
            const s = syms[i]
            const ls = toLower(s)
            const cs = correctWord[i]
            if (ls === cs) {
              res.push(
                <Text key={aw + '_' + wordNum + '_' + i} as='span'>
                  {s}
                </Text>
              )
            } else {
              res.push(
                <Text
                  key={aw + '_' + wordNum + '_' + i}
                  as='span'
                  color='darkGray'
                  textDecor={'underline'}
                >
                  {cs}
                </Text>
              )
            }
          }
          // res.push(
          //   <Text key={wordNum} as='span' color='darkGray'>
          //     <Highlight
          //       query='spot'
          //       styles={{ py: '1', fontWeight: 'normal' }}
          //     >
          //       {aw}
          //     </Highlight>
          //   </Text>
          // )
        } else {
          res.push(
            <Text as='span' key={aw + '_' + k.toString()}>
              {aw}
            </Text>
          )
        }
      } else {
        res.push(
          <Text as='span' key={aw + '_' + k.toString()}>
            {aw}
          </Text>
        )
      }
      res.push(
        <Text as='span' key={k + '_space'}>
          {' '}
        </Text>
      )
    })

    return res
  }, [answer, correctAnswer])

  return (
    <Text textStyle={'body'} color='green' {...rest}>
      {value}
    </Text>
  )
}

export default CorrectText
