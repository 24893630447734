import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  VStack,
  useDisclosure
} from '@chakra-ui/react'
import {
  Ref,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useState
} from 'react'
import SimpleInput from 'shared/components/SimpleInput'
import { ICoursesPack, IVideoPlatform } from 'shared/types'
import get from 'lodash/get'
import { dbUpdateCoursesPack } from 'controllers/coursesPacks'
import SwitchField from 'components/formFields/SwitchField'
import { useSelector } from 'model/hooks'
import EditTags from 'components/EditTags'
import ImageField from 'components/formFields/ImageField'
import VideoField from 'components/formFields/VideoField'

export interface ICoursesPackSettingsDrawer {
  open: (cId: string) => void
}
type Props = {}

const CoursesPackSettingsDrawer: ForwardRefRenderFunction<
  ICoursesPackSettingsDrawer,
  Props
> = (_props, ref: Ref<unknown>) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [cId, setCId] = useState<string>()
  const c = useSelector(state =>
    cId ? get(state.coursesPacks, cId) : undefined
  )

  const update = (upd: Partial<ICoursesPack>) => {
    if (c) {
      dbUpdateCoursesPack(c.id, upd)
    }
  }

  useImperativeHandle(ref, () => ({
    open: (cId: string) => {
      setCId(cId)
      onOpen()
    }
  }))

  const renderTags = () => {
    const tags = c?.tags || []
    return (
      <EditTags tags={tags} onChange={(tags: string[]) => update({ tags })} />
    )
  }

  return (
    <Drawer isOpen={isOpen} placement='right' onClose={onClose} size='sm'>
      <DrawerOverlay bg='transparent' />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader textStyle='small'>Настройки страницы</DrawerHeader>

        <DrawerBody pb={6}>
          <VStack w='full' spacing={6}>
            <SimpleInput
              label='Название'
              value={c?.title || ''}
              onChange={(v: string) => update({ title: v })}
              variant='flushed'
            />
            <SwitchField
              label='Виден на странице курсов'
              value={!c?.invisible}
              onChange={v => update({ invisible: !v })}
            />
            {renderTags()}
            <ImageField
              label='Картинка курса в каталоге'
              value={c?.imageUrl}
              onChange={(imageUrl: string) => update({ imageUrl })}
              storagePath={`/courses/${c?.id}`}
            />
            <VideoField
              label='Видео превью'
              value={c?.vimeoVideoUrl || ''}
              onChange={(vimeoVideoUrl: string) => update({ vimeoVideoUrl })}
              platform={IVideoPlatform.VIMEO}
            />
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default forwardRef(CoursesPackSettingsDrawer)
