import { FC } from 'react'
import { ISectionTwoColumnsText } from 'shared/types'
import { Box, Text, Stack } from '@chakra-ui/react'

type Props = {
  s: ISectionTwoColumnsText
}

const SectionTwoColumnsText: FC<Props> = ({ s }) => {
  return (
    <Stack
      direction={{ base: 'column', lg: 'row' }}
      w='full'
      spacing={{ base: 2, lg: 6 }}
    >
      <Box flex={1}>
        <Text textStyle={'h2'} fontWeight={700}>
          {s.title || ''}
        </Text>
      </Box>
      <Box
        className='ql-snow'
        flex={1}
        textStyle={'body'}
        dangerouslySetInnerHTML={
          s.htmlString ? { __html: s.htmlString } : undefined
        }
      />
    </Stack>
  )
}

export default SectionTwoColumnsText
