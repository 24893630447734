import {
  AuthError,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut
} from 'firebase/auth'
import { NavigateFunction } from 'react-router-dom'
import { auth } from 'src/controllers/db'
import { clearListeners } from 'src/controllers/listeners'
import store from 'src/model/store'
import { logout } from 'src/model/actions'

export const dbSignIn = async (email: string, password: string) => {
  try {
    await signInWithEmailAndPassword(auth, email, password)
    return null
  } catch (e) {
    const er = e as AuthError
    console.log('error', er.code, er.message)
    return er.code
  }
}

export const dbResetPassword = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email)
    return true
  } catch (e) {
    console.warn('password reset email was not sent', e)
    return false
  }
}

export const dbSignOut = async (navigate: NavigateFunction) => {
  try {
    if (auth && auth.currentUser) {
      console.log('%cLOGOUT', 'color: orange;')
      clearListeners()
      store.dispatch(logout())
      await signOut(auth)
    } else {
      navigate('/signin')
    }
  } catch (e) {
    if (typeof e === 'string') {
      console.log('signOut error:', e.toUpperCase())
    } else if (e instanceof Error) {
      console.log('signOut error:', e.message)
    }
  }
}
