import { FC } from 'react'
import { IVideo, IVideoPlatform } from 'shared/types'
import { Box } from '@chakra-ui/react'
import Vimeo from '@u-wave/react-vimeo'
import YoutubePlayer from 'shared/components/YoutubePlayer'

type Props = {
  v: IVideo
}

const VideoPlayer: FC<Props> = ({ v }) => {
  if (v.url === '') {
    return null
  } else if (v.platform === IVideoPlatform.VIMEO) {
    return (
      // <Box
      // flex={1}
      // w='full'
      // h={52}
      // bg='yellow'
      // pt={{ base: 6, lg: 6 }}
      // pb={{ base: 6, lg: '22px' }}
      // maxH={{ base: 'full', lg: '80vh' }}
      // position={'relative'}
      // sx={{
      //   iframe: { borderRadius: 'xs' },
      //   // iframe: { maxHeight: { base: 'none', lg: '80vh' } },
      //   '#vimeo_video > div': {
      //     // padding: { base: 'none', lg: '0px !important' }
      //     // position: { base: 'relative', lg: 'unset !important' }
      //   }
      // }}
      // >
      <Vimeo
        id={'vimeo_video' + v.url}
        video={v.url}
        responsive
        showPortrait={false}
        onError={e => console.log('vimeo video error:', e)}
      />
      // </Box>
    )
  } else {
    return <YoutubePlayer videoId={v.url} />
  }
}

export default VideoPlayer
