import { FC } from 'react'
import {
  Box,
  HStack,
  Tag,
  TagCloseButton,
  TagLabel,
  TagRightIcon,
  VStack
} from '@chakra-ui/react'
import SimpleInput from 'shared/components/SimpleInput'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from 'react-beautiful-dnd'
import { arrayMove } from 'shared/utils/array'
import _ from 'lodash'
import Label from 'shared/components/Label'
import CardTagsSelector from 'components/CardTagsSelector'
import { useSelector } from 'model/hooks'

type Props = {
  tags: string[]
  onChange: (tags: string[]) => void
}

const EditCardTags: FC<Props> = ({ tags, onChange }) => {
  const cardTags = useSelector(state => state.cardTags)

  const onSelectTag = (v: string) => {
    if (v !== '') {
      onChange([...tags, v])
    }
  }

  const renderAddRow = () => {
    return <CardTagsSelector onSelect={onSelectTag} exclude={tags} />
  }

  const onDragEnd = (result: DropResult) => {
    console.log('onDragEnd', result)
    if (result.destination && result.destination.index >= 0) {
      const newData = arrayMove(
        tags,
        result.source.index,
        result.destination?.index || 0
      )
      onChange(newData)
    }
  }

  const onDelete = (i: number) => {
    const newData = [...tags]
    newData.splice(i, 1)
    onChange(newData)
  }

  const renderRow = (t: string, i: number, isDraggable: boolean) => {
    if (isDraggable) {
      return (
        <Draggable key={i} draggableId={t + i} index={i}>
          {(provided, snapshot) => (
            <VStack
              rounded='4px'
              flexShrink={0}
              align='center'
              justify={'space-between'}
              color='wood.600'
              textStyle={{ base: 'tag', lg: 'small' }}
              ref={provided.innerRef}
              {...provided.draggableProps}
            >
              <Box {...provided.dragHandleProps}>
                <Tag colorScheme='sun'>
                  <TagLabel>{_.get(cardTags, [t, 'title'])}</TagLabel>
                  <TagCloseButton onClick={() => onDelete(i)} />
                </Tag>
              </Box>
            </VStack>
          )}
        </Draggable>
      )
    } else {
      return (
        <HStack
          flexShrink={0}
          align='center'
          justify={'space-between'}
          color='wood.600'
          textStyle={{ base: 'tag', lg: 'small' }}
        >
          <Tag>
            <TagLabel>{_.get(cardTags, [t, 'title'])}</TagLabel>
          </Tag>
        </HStack>
      )
    }
  }

  const renderRows = () => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          direction='horizontal'
          droppableId='tags'
          renderClone={(provided, snapshot, rubric) => {
            return (
              <div ref={provided.innerRef} {...provided.draggableProps}>
                {renderRow(tags[rubric.source.index], 0, false)}
              </div>
            )
          }}
        >
          {(provided, snapshot) => (
            <HStack
              w='full'
              align='flex-start'
              textStyle='body'
              wrap={'wrap'}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {_.map(tags, (p, i) => renderRow(p, i, true))}
              {provided.placeholder}
            </HStack>
          )}
        </Droppable>
      </DragDropContext>
    )
  }

  return (
    <VStack w='full' align={'start'}>
      <Label>Tags</Label>
      {renderRows()}
      {renderAddRow()}
    </VStack>
  )
}

export default EditCardTags
