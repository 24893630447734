import {
  Ref,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useState
} from 'react'
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  useDisclosure,
  useToast
} from '@chakra-ui/react'

import { dbCreateUser } from 'controllers/users'
import isEmail from 'validator/lib/isEmail'
import { IUser } from 'shared/types'
import isString from 'lodash/isString'

export interface IAddUserModal {
  open: () => void
}

type Props = {}

const AddUserModal: ForwardRefRenderFunction<IAddUserModal, Props> = (
  _props,
  ref: Ref<unknown>
) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [name, setName] = useState('')
  const [loading, setLoading] = useState(false)
  const toast = useToast()

  useImperativeHandle(ref, () => ({
    open: () => {
      setEmail('')
      setPhone('')
      setName('')
      onOpen()
    }
  }))

  // console.log('user', user)

  const apply = async () => {
    setLoading(true)
    const newUser: IUser | string = await dbCreateUser(email, name, phone)
    setLoading(false)
    if (isString(newUser)) {
      toast({
        title: 'Произошла ошибка',
        description: newUser,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top'
      })
      onClose()
    } else {
      toast({
        title: 'Пользователь создан',
        description: `ID: ${newUser.id}`,
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top'
      })
      onClose()
    }
  }

  const renderBody = () => {
    return (
      <VStack pt={8} w='full' spacing={6}>
        <FormControl>
          <FormLabel>Email</FormLabel>
          <Input
            variant={'flushed'}
            type='email'
            size='sm'
            value={email}
            onChange={e => {
              setEmail(e.target.value)
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Имя</FormLabel>
          <HStack w='full'>
            <Input
              variant={'flushed'}
              size='sm'
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </HStack>
        </FormControl>
        <FormControl>
          <FormLabel>Телефон</FormLabel>
          <HStack w='full'>
            <Input
              variant={'flushed'}
              size='sm'
              value={phone}
              onChange={e => setPhone(e.target.value)}
            />
          </HStack>
        </FormControl>
      </VStack>
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Создание пользователя</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{renderBody()}</ModalBody>
        <ModalFooter pt={12}>
          <Button
            variant='solid'
            size='sm'
            colorScheme='teal'
            isDisabled={loading || !isEmail(email) || name === ''}
            onClick={apply}
            isLoading={loading}
          >
            Создать
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(AddUserModal)
