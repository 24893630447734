import { FC, useState, useEffect } from 'react'
import size from 'lodash/size'
import { Stack, Text, Flex } from '@chakra-ui/react'
import { ISyncTranslate } from 'shared/types'
import SyncTranslateTask from 'shared/components/sections/sectionTestSyncTranslate/SyncTranslateTask'
import SyncTranslateTranslate from 'shared/components/sections/sectionTestSyncTranslate/SyncTranslateTranslate'

type Props = {
  st: ISyncTranslate
  isExamination: boolean
  showUserAnswer: boolean
  completed: boolean
  highlightCorrectAnswer: boolean
  onComplete: (isCorrect: boolean) => void
}

const SyncTranslateParagraph: FC<Props> = ({
  st,
  isExamination,
  showUserAnswer,
  completed,
  highlightCorrectAnswer,
  onComplete
}) => {
  const [addedWords, setAddedWords] = useState<(null | boolean)[]>([])
  const [attempts, setAttempts] = useState<(null | boolean)[]>([])

  useEffect(() => {
    const matches = st.task.match(/\{(.*?)\}/g)
    setAddedWords(new Array(size(matches)).fill(null))
    setAttempts(new Array(size(matches)).fill(null))
  }, [st.task])

  // console.log('addedWords', addedWords)

  const onWordAdded = (wordNum: number, isCorrect: boolean) => {
    // console.log('onWordAdded', wordNum, isCorrect)
    const newAddedWords = [...addedWords]
    newAddedWords[wordNum] = isCorrect
    setAddedWords(newAddedWords)

    const matches = st.task.match(/\{(.*?)\}/g)
    // console.log('newAddedWords', newAddedWords)

    const addedWordsAmount = newAddedWords.filter(v => v !== null).length
    // console.log({ addedWordsAmount, matches })
    if (addedWordsAmount === size(matches)) {
      const correctAnswers = newAddedWords.filter(v => v !== null && v)
      // console.log('correctAnswers', correctAnswers)
      const answerIsCorrect = correctAnswers.length === newAddedWords.length
      onComplete(answerIsCorrect)
    }
  }

  const onAttempt = (wordNum: number, isCorrect: boolean) => {
    const newAttempts = [...attempts]
    newAttempts[wordNum] = isCorrect
    setAttempts(newAttempts)
  }

  const renderTask = () => {
    return (
      <Flex flex={1} flexShrink={0}>
        <SyncTranslateTask
          task={st.task}
          isExamination={isExamination}
          showUserAnswer={showUserAnswer}
          completed={completed}
          onWordAdded={onWordAdded}
          onAttempt={onAttempt}
          highlightCorrectAnswer={highlightCorrectAnswer}
        />
      </Flex>
    )
  }

  const renderTranslate = () => {
    return (
      <Flex flex={1} flexShrink={0}>
        <SyncTranslateTranslate
          translate={st.translate}
          isExamination={isExamination}
          showUserAnswer={showUserAnswer}
          completed={completed}
          addedWords={addedWords}
          matching={st.matching}
          attempts={attempts}
          highlightCorrectAnswer={highlightCorrectAnswer}
        />
      </Flex>
    )
  }

  return (
    <Stack
      direction={{ base: 'column', lg: 'row' }}
      spacing={{ base: 4, lg: 6 }}
      w='full'
    >
      {renderTask()}
      {renderTranslate()}
    </Stack>
  )
}

export default SyncTranslateParagraph
