import { Checkbox, HStack, Text } from '@chakra-ui/react'
import { FC } from 'react'

type Props = {
  value: boolean
  onChange: (v: boolean) => void
}

const HighlightCorrectAnswer: FC<Props> = ({ value, onChange }) => {
  return (
    <HStack w='full' align='center' justify={'flex-start'} pt={4}>
      <Checkbox isChecked={value} onChange={e => onChange(e.target.checked)} />
      <Text textStyle={'small'} color='darkGray'>
        Показать пользователю правильный ответ, если он ответил неправильно
      </Text>
    </HStack>
  )
}

export default HighlightCorrectAnswer
