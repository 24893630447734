import { onAuthStateChanged, User } from 'firebase/auth'
import { NavigateFunction } from 'react-router-dom'
import startsWith from 'lodash/startsWith'
import { auth, db } from 'src/controllers/db'
import store from 'src/model/store'
import { logout, receiveAuthData } from 'src/model/actions'
import { dbFetchCourses } from 'src/controllers/courses'
import { dbSignOut } from 'controllers/auth'
import { doc, getDoc } from 'firebase/firestore'
import { dbFetchWebinars } from 'controllers/webinars'
import { dbFetchConferences } from 'controllers/conferences'
import { dbFetchAdmins } from 'controllers/admins'
import { dbFetchNotificationsSettings } from 'controllers/settings'
import { dbFetchLibrary } from 'controllers/library'
import { dbFetchPartitions } from 'controllers/partitions'
import { dbFetchLevels } from 'controllers/levels'
import { dbFetchTeachers } from 'controllers/teachers'
import { dbFetchSeminars } from 'controllers/seminars'
import { dbFetchChatChannels } from 'controllers/chat'
import { dbFetchUser } from 'controllers/user'
import { dbFetchFrees } from 'controllers/frees'
import { dbFetchCoursesPacks } from 'controllers/coursesPacks'
import { dbFetchPaymentLinks } from 'controllers/userPayments'
import { dbFetchLanguages } from 'controllers/languages'
import { dbFetchCardTags } from 'controllers/cardTags'

const init = async (uid: string) => {
  await Promise.all([
    dbFetchCourses(),
    dbFetchCoursesPacks(),
    dbFetchFrees(),
    dbFetchLibrary(),
    dbFetchWebinars(),
    dbFetchConferences(),
    dbFetchAdmins(),
    dbFetchNotificationsSettings(),
    dbFetchPartitions(),
    dbFetchLevels(),
    dbFetchTeachers(),
    dbFetchSeminars(),
    dbFetchChatChannels(uid),
    dbFetchUser(uid),
    dbFetchPaymentLinks(),
    dbFetchLanguages(),
    dbFetchCardTags()
  ])
}

const checkIsAdmin = async (uid: string) => {
  const docSn = await getDoc(doc(db, 'admins', uid))
  return docSn.exists()
}

const onAuth = async (authData: User | null, navigate: NavigateFunction) => {
  console.log('onAuthStateChanged', authData)
  if (authData) {
    console.log('authData', authData)
    const { uid } = authData
    const isAdmin = await checkIsAdmin(uid)
    console.log('isAdmin', isAdmin)
    if (isAdmin) {
      store.dispatch(
        receiveAuthData({ uid: authData.uid, email: authData.email })
      )
      const pathname = window.location.pathname
      if (startsWith(pathname, '/sign')) {
        navigate('/')
      }
      init(uid)
    } else {
      dbSignOut(navigate)
      navigate('/signin', { state: { notAdmin: true } })
    }
  } else {
    store.dispatch(receiveAuthData(null))
    // clearListeners()
    navigate('/signin')
    store.dispatch(logout())
  }
}

export const appInitialized = (navigate: NavigateFunction): void => {
  try {
    // fetchPublicData()
    onAuthStateChanged(auth, authData => onAuth(authData, navigate))
  } catch (e) {
    console.log('app initialization error', e)
  }
}
