import { HStack, Select, Flex } from '@chakra-ui/react'
import Label from 'shared/components/Label'
import { FC } from 'react'

type Props = {
  value?: number
  onChange: (v: number) => void
}

const SectionWidthSelect: FC<Props> = ({ value, onChange }) => {
  return (
    <HStack w='full' align='center' spacing={2} justify={'space-between'}>
      <Label>Ширина блока (колонки)</Label>
      <Flex>
        <Select
          // placeholder='Выравнивание'
          value={value || 12}
          onChange={e => onChange(Number(e.target.value))}
          variant={'solid'}
          size='xs'
        >
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
          <option value={4}>4</option>
          <option value={5}>5</option>
          <option value={6}>6</option>
          <option value={7}>7</option>
          <option value={8}>8</option>
          <option value={9}>9</option>
          <option value={10}>10</option>
          <option value={11}>11</option>
          <option value={12}>12</option>
        </Select>
      </Flex>
    </HStack>
  )
}

export default SectionWidthSelect
