import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import {
  Ref,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useRef
} from 'react'
import { FileT, ISyncTransalteParagraph, IWordCard } from 'shared/types'
import map from 'lodash/map'
import { Unsubscribe } from 'firebase/firestore'
import { dbSubscribeToCard, dbUpdateCard } from 'controllers/cards'
import LanguageSelector from 'components/LanguageSelector'
import SimpleInput from 'shared/components/SimpleInput'
import EditCardTranslate from 'modals/editCardDrawer/EditCardTranslate'
import EditCardAudio from 'modals/editCardDrawer/EditCardAudio'
import EditCardTags from 'modals/editCardDrawer/EditCardTags'
import SyncTranslateParagraph from 'components/sections/editSectionSyncTranslte/SyncTranslatePargraph'
import Label from 'shared/components/Label'
import EditableReachField from 'components/EditableReachField'

type Props = {}

export interface IEditCardDrawer {
  open: (id?: string) => void
}

const EditCardDrawer: ForwardRefRenderFunction<IEditCardDrawer, Props> = (
  _props,
  ref: Ref<unknown>
) => {
  const [cardId, setCardId] = useState<string>()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [card, setCard] = useState<IWordCard>()
  const cardUnsubscriberRef = useRef<Unsubscribe | null>(null)

  useEffect(() => {
    if (cardUnsubscriberRef.current) {
      cardUnsubscriberRef.current()
    }
    if (cardId) {
      cardUnsubscriberRef.current = dbSubscribeToCard(cardId, setCard)
    }
  }, [cardId])

  useImperativeHandle(ref, () => ({
    open: (id?: string) => {
      setCardId(id)
      onOpen()
    }
  }))

  const handleClose = () => {
    setCardId(undefined)
    setCard(undefined)
    onClose()
  }

  const onParagraphChange = (p: ISyncTransalteParagraph, i: number) => {
    if (card) {
      const newParagraphs = [...(card.paragraphs || [])]
      newParagraphs[i] = p
      dbUpdateCard(card.id, { paragraphs: newParagraphs })
    }
  }

  const onParagraphDelete = (i: number) => {
    if (card) {
      const newParagraphs = [...(card.paragraphs || [])]
      newParagraphs.splice(i, 1)
      dbUpdateCard(card.id, { paragraphs: newParagraphs })
    }
  }

  const onAddParagraph = () => {
    if (card) {
      const p = { left: '', right: '' }
      dbUpdateCard(card.id, { paragraphs: [...(card.paragraphs || []), p] })
    }
  }

  const renderEditParagraphs = () => {
    if (card) {
      return (
        <VStack w='full' align={'start'}>
          <Label>Usecases</Label>
          {map(card.paragraphs, (p, i) => {
            return (
              <SyncTranslateParagraph
                key={i}
                p={p}
                onChange={p => onParagraphChange(p, i)}
                onDelete={() => onParagraphDelete(i)}
              />
            )
          })}
          <HStack justify={'flex-start'}>
            <Button
              size='sm'
              colorScheme='wood'
              onClick={onAddParagraph}
              variant={'link'}
            >
              Add paragraph
            </Button>
          </HStack>
        </VStack>
      )
    }
  }

  const renderContent = () => {
    if (card) {
      return (
        <VStack w='full' align={'start'} spacing={6}>
          <LanguageSelector
            value={card.lang}
            onChange={(v: string) => dbUpdateCard(card.id, { lang: v })}
          />
          <SimpleInput
            label='Word'
            value={card.word}
            onChange={(v: string) => dbUpdateCard(card.id, { word: v })}
          />
          <EditCardTranslate
            translate={card.translate}
            onChange={(v: string[]) => dbUpdateCard(card.id, { translate: v })}
          />
          <SimpleInput
            label='Transcription'
            value={card.transcription}
            onChange={(v: string) =>
              dbUpdateCard(card.id, { transcription: v })
            }
          />
          <EditCardTags
            tags={card.tags}
            onChange={(v: string[]) => dbUpdateCard(card.id, { tags: v })}
          />
          <EditCardAudio
            file={card.audio}
            onChange={(f: FileT) => dbUpdateCard(card.id, { audio: f })}
          />
          <SimpleInput
            label='Definition'
            value={card.definition || ''}
            onChange={(v: string) => dbUpdateCard(card.id, { definition: v })}
          />

          {renderEditParagraphs()}
        </VStack>
      )
    }
  }

  return (
    <Drawer isOpen={isOpen} placement='right' onClose={handleClose} size='lg'>
      <DrawerOverlay bg='transparent' />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader textStyle='small'>
          {cardId ? 'Edit card' : 'Create card'}
        </DrawerHeader>

        <DrawerBody pb={6}>
          <VStack flex={1} spacing={6}>
            {renderContent()}
          </VStack>
        </DrawerBody>
        {/* <DrawerFooter>
          <HStack w='full' justify={'end'}>
            <Button
              variant='solid'
              onClick={onDelete}
              colorScheme='red'
              size='sm'
              leftIcon={<IconDelete width={12} />}
            >
              Delete
            </Button>
          </HStack>
        </DrawerFooter> */}
      </DrawerContent>
    </Drawer>
  )
}

export default forwardRef(EditCardDrawer)
