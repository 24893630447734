import { Text, VStack, HStack, Input } from '@chakra-ui/react'
import { FC } from 'react'
import toString from 'lodash/toString'
import toNumber from 'lodash/toNumber'

type Props = {
  label: string
  value: number
  onChange: (v: number) => void
}

const InputFild: FC<Props> = ({ label, value, onChange }) => {
  return (
    <HStack w='full' align={'flex-end'}>
      <VStack w={'full'} spacing={1} align={'flex-start'}>
        {label && (
          <Text textStyle='small' color='black.950'>
            {label}
          </Text>
        )}
        <Input
          variant={'flushed'}
          size='sm'
          value={toString(value)}
          onChange={e => onChange(toNumber(e.target.value))}
        />
      </VStack>
    </HStack>
  )
}

export default InputFild
