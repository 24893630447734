import { HStack, VStack, Box } from '@chakra-ui/react'
import { FC } from 'react'
import { ICoursesPack, IPlan } from 'shared/types'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from 'react-beautiful-dnd'
import map from 'lodash/map'
import { ReactComponent as IconX } from 'shared/assets/bsXCircle.svg'
import { ReactComponent as IconList } from 'shared/assets/bsList.svg'
import { dbUpdateCoursesPack } from 'controllers/coursesPacks'
import { arrayMove } from 'shared/utils/array'
import Label from 'shared/components/Label'
import AddBulletPointButton from 'modals/coursesPack/coursesPackPlanDrawer/AddBulletPointButton'
import EditableBulletPoint from 'modals/coursesPack/coursesPackPlanDrawer/EditableBulletPoint'
import SimpleInput from 'shared/components/SimpleInput'

type Props = {
  c: ICoursesPack
  p: IPlan
  i: number
  onBack: () => void
}

const CoursesPackPlanDrawerMain: FC<Props> = ({ p, i, c, onBack }) => {
  const updatePlan = (upd: Partial<IPlan>) => {
    const plans = [...(c.plans || [])]
    plans[i] = {
      ...plans[i],
      ...upd
    }
    dbUpdateCoursesPack(c.id, { plans })
  }

  const onDragEnd = (result: DropResult) => {
    console.log('onDragEnd', result)
    if (result.destination && result.destination.index >= 0) {
      const points = arrayMove(
        p.bulletPoints || [],
        result.source.index,
        result.destination?.index || 0
      )
      updatePlan({ bulletPoints: points })
    }
  }

  const onRemove = (i: number) => {
    console.log('onRemove', i)
    const points = [...(p.bulletPoints || [])]
    points.splice(i, 1)
    updatePlan({ bulletPoints: points })
  }

  const onBulletPointChange = (v: string, i: number) => {
    console.log('onBulletPointChange', v, i, p.bulletPoints)
    const points = [...(p.bulletPoints || [])]
    points[i] = v
    updatePlan({ bulletPoints: points })
  }

  const onAddBulletPoint = (v: string) => {
    const currentPoints = p.bulletPoints || []
    const newPoints = [...currentPoints, v]
    updatePlan({ bulletPoints: newPoints })
  }

  const updateButtonTitle = (planId: string, buttonTitle: string) => {
    updatePlan({ buttonTitle })
  }

  const updateButtonUrl = (planId: string, buttonUrl: string) => {
    updatePlan({ buttonUrl })
  }

  const renderPoint = (point: string, i: number, isDraggable: boolean) => {
    if (isDraggable) {
      return (
        <Draggable key={i} draggableId={point + i} index={i}>
          {(provided, snapshot) => (
            <HStack
              borderWidth={1}
              borderColor='wood.600'
              px={2}
              w='full'
              // h={{ base: 6, lg: 8 }}
              rounded='4px'
              flexShrink={0}
              align='center'
              justify={'space-between'}
              color='wood.600'
              textStyle={{ base: 'tag', lg: 'small' }}
              ref={provided.innerRef}
              {...provided.draggableProps}
            >
              <Box {...provided.dragHandleProps}>
                <IconList width='14' height='14' />
              </Box>
              <EditableBulletPoint
                v={point}
                onChange={(v: string) => onBulletPointChange(v, i)}
              />
              <Box
                textStyle={{ base: 'tag', lg: 'small' }}
                as='button'
                onClick={() => onRemove(i)}
                color='red.700'
              >
                <IconX width='14' height='14' />
              </Box>
            </HStack>
          )}
        </Draggable>
      )
    } else {
      return (
        <HStack
          borderWidth={1}
          borderColor='wood.600'
          px={2}
          w='full'
          rounded='4px'
          flexShrink={0}
          align='center'
          justify={'space-between'}
          color='wood.600'
          textStyle={{ base: 'tag', lg: 'small' }}
        >
          <HStack minH={8} w='full'>
            <Box dangerouslySetInnerHTML={{ __html: point }} />
          </HStack>
        </HStack>
      )
    }
  }

  const renderBulletPoints = () => {
    return (
      <VStack w='full' align={'start'}>
        <Label>Описание</Label>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId='tags'
            renderClone={(provided, snapshot, rubric) => {
              return (
                <div ref={provided.innerRef} {...provided.draggableProps}>
                  {renderPoint(
                    p.bulletPoints ? p.bulletPoints[rubric.source.index] : '',
                    0,
                    false
                  )}
                </div>
              )
            }}
          >
            {(provided, snapshot) => (
              <VStack
                w='full'
                direction={'column'}
                align='flex-start'
                textStyle='body'
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {map(p.bulletPoints, (point, i) => renderPoint(point, i, true))}
                {provided.placeholder}
              </VStack>
            )}
          </Droppable>
        </DragDropContext>
        <AddBulletPointButton onAdd={onAddBulletPoint} />
      </VStack>
    )
  }

  return (
    <VStack align='flex-start' w='full' spacing={8}>
      {renderBulletPoints()}
      <VStack w='full' align='start' spacing={0}>
        <Label>Название кнопки</Label>
        <SimpleInput
          key={p.id + '_buttonTitle'}
          size='sm'
          variant='flushed'
          placeholder='Название кнопки'
          value={p.buttonTitle || ''}
          onChange={(v: string) => updateButtonTitle(p.id, v)}
        />
      </VStack>
      <VStack w='full' align='start' spacing={0}>
        <Label>Ссылка кнопки</Label>
        <SimpleInput
          key={p.id + '_buttonUrl'}
          size='sm'
          variant='flushed'
          placeholder='Ссылка кнопки'
          value={p.buttonUrl || ''}
          onChange={(v: string) => updateButtonUrl(p.id, v)}
        />
      </VStack>
    </VStack>
  )
}

export default CoursesPackPlanDrawerMain
