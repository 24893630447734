import { Stack, Button } from '@chakra-ui/react'
import { FC } from 'react'
import { ReactComponent as IconLeft } from 'shared/assets/bsArrowLeft.svg'
import { ReactComponent as IconRight } from 'shared/assets/bsArrowRight.svg'

type Props = {
  onPrevClick?: () => void
  onNextClick?: () => void
}

const LessonNavButtons: FC<Props> = ({ onPrevClick, onNextClick }) => {
  return (
    <Stack
      direction={'row'}
      w={{ base: 'full', lg: 'auto' }}
      justify={{ base: 'space-between', lg: 'flex-start' }}
      pt={{ base: 2, lg: 0 }}
      spacing={2}
    >
      <Button
        color='black'
        leftIcon={<IconLeft width='12px' height='12px' />}
        variant='outline'
        rounded={'full'}
        px={4}
        size='xs'
        colorScheme='blackAlpha'
        onClick={onPrevClick}
        fontWeight={500}
        isDisabled={!onPrevClick}
      >
        Назад
      </Button>
      <Button
        color='black'
        rightIcon={<IconRight width='12px' height='12px' />}
        variant='outline'
        rounded={'full'}
        px={4}
        size='xs'
        colorScheme='blackAlpha'
        onClick={onNextClick}
        fontWeight={500}
        isDisabled={!onNextClick}
      >
        Дальше
      </Button>
    </Stack>
  )
}

export default LessonNavButtons
