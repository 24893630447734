import { FC } from 'react'
import { VStack } from '@chakra-ui/react'
import { IPadding, ISectionTestAddWords, ITestContent } from 'shared/types'
import get from 'lodash/get'
import PaddingSelect from 'components/PaddingSelect'
import HighlightCorrectAnswer from 'components/contentSettingsCard/HighlightCorrectAnswer'
import SimpleInput from 'shared/components/SimpleInput'
import { getTestTitle } from 'shared/utils/tests'
import EditTestAddWords from 'components/contentSettingsCard/EditTestAddWords'

type Props = {
  s: ISectionTestAddWords
  onChange: (s: ISectionTestAddWords) => void
}

const EditSectionTestAddWords: FC<Props> = ({ s, onChange }) => {
  const renderPadding = () => {
    return (
      <PaddingSelect
        value={s.paddingTop}
        onChange={(v: IPadding) => onChange({ ...s, paddingTop: v })}
      />
    )
  }

  const renderHighlightCorrectAnswer = () => {
    const v = get(s, 'test.highlightCorrectAnswer', false)
    return (
      <HighlightCorrectAnswer
        value={v}
        onChange={(v: boolean) =>
          onChange({
            ...s,
            test: { ...(s.test || {}), highlightCorrectAnswer: v }
          })
        }
      />
    )
  }

  const renderEditContent = () => {
    return (
      <EditTestAddWords
        test={s.test || {}}
        onChange={(v: ITestContent) => onChange({ ...s, test: v })}
      />
    )
  }

  const renderTitle = () => {
    return (
      <SimpleInput
        value={s.test?.title || getTestTitle(s.type)}
        onChange={(v: string) =>
          onChange({ ...s, test: { ...(s.test || {}), title: v } })
        }
        label='Вопрос'
      />
    )
  }

  return (
    <VStack w='full' spacing={6}>
      {renderPadding()}
      {renderTitle()}
      {renderHighlightCorrectAnswer()}
      {renderEditContent()}
    </VStack>
  )
}

export default EditSectionTestAddWords
