import {
  Ref,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useState
} from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  useDisclosure,
  useToast
} from '@chakra-ui/react'

import isEmail from 'validator/lib/isEmail'
import InputField from 'components/formFields/InputField'
import NumberInputField from 'components/formFields/NumberInputField'
import { dbCreatePaymentLink } from 'controllers/userPayments'

export interface ICreatePaymentLink {
  open: () => void
}

type Props = {}

const CreatePaymentLink: ForwardRefRenderFunction<ICreatePaymentLink, Props> = (
  _props,
  ref: Ref<unknown>
) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [price, setPrice] = useState(0)
  const [name, setName] = useState('')
  const [loading, setLoading] = useState(false)

  useImperativeHandle(ref, () => ({
    open: () => {
      setPrice(0)
      setName('')
      onOpen()
    }
  }))

  // console.log('user', user)

  const apply = async () => {
    setLoading(true)
    await dbCreatePaymentLink(name, price)
    setLoading(false)
    onClose()
  }

  const renderBody = () => {
    return (
      <VStack pt={8} w='full' spacing={6}>
        <InputField label='Описание' value={name} onChange={v => setName(v)} />
        <NumberInputField
          label='Цена (рубли)'
          value={price}
          onChange={v => setPrice(v)}
        />
      </VStack>
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Создание пользователя</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{renderBody()}</ModalBody>
        <ModalFooter pt={12}>
          <Button
            variant='solid'
            size='sm'
            colorScheme='teal'
            isDisabled={loading || name === '' || price === 0}
            onClick={apply}
            isLoading={loading}
          >
            Создать
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(CreatePaymentLink)
