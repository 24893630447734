import { Tab, TabList, TabPanels, Tabs, VStack } from '@chakra-ui/react'
import NavBar from 'components/NavBar'
import CustomTabPanel from 'components/CustomTabPanel'
import SettingsAdmins from 'pages/settings/SettingsAdmins'
import SettingsNotifications from 'pages/settings/SettingsNotifications'
import SettingsPartitions from 'pages/settings/SettingsPartitions'
import { useLocation } from 'react-router-dom'
import get from 'lodash/get'
import SettingsLevels from 'pages/settings/SettingsLevels'
import SettingsLanguages from 'pages/settings/SettingsLanguages'

const Settings = () => {
  const location = useLocation()
  return (
    <VStack w='full' h='full' spacing={0} overflow={'hidden'} maxH={'full'}>
      <NavBar title={'Настройки'} />
      <Tabs
        colorScheme='blackAlpha'
        variant='soft-rounded'
        w='full'
        overflow={'hidden'}
        h='full'
        flex={1}
        display={'flex'}
        flexDirection={'column'}
        defaultIndex={get(location, 'state.tabIndex', 0)}
      >
        <TabList
          display='flex'
          alignItems='center'
          justifyContent='center'
          borderBottom='none'
          p='4'
          overflow={'hidden'}
        >
          <Tab>Языки</Tab>
          <Tab>Администраторы</Tab>
          <Tab>Уведомления</Tab>
          <Tab>Разделы</Tab>
          <Tab>Уровни</Tab>
        </TabList>

        <TabPanels overflow={'hidden'} flex={1}>
          <CustomTabPanel>
            <SettingsLanguages />
          </CustomTabPanel>
          <CustomTabPanel>
            <SettingsAdmins />
          </CustomTabPanel>
          <CustomTabPanel>
            <SettingsNotifications />
          </CustomTabPanel>
          <CustomTabPanel>
            <SettingsPartitions />
          </CustomTabPanel>
          <CustomTabPanel>
            <SettingsLevels />
          </CustomTabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  )
}

export default Settings
