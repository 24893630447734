import {
  onSnapshot,
  query,
  collection,
  doc,
  updateDoc,
  setDoc,
  writeBatch
} from 'firebase/firestore'
import { db, dbOmit, generateId } from 'src/controllers/db'
import { IFree, IDay, ILesson, IWeek, IEntityType } from 'shared/types'
import { receiveFrees } from 'src/model/actions'
import store from 'src/model/store'
import { addListener } from 'controllers/listeners'
import dayjs from 'dayjs'
import forEach from 'lodash/forEach'

export const dbFetchFrees = async () => {
  try {
    const q = query(collection(db, 'free'))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: Record<string, IFree> = {}
        sn.forEach(doc => {
          const p = doc.data() as IFree
          res[doc.id] = {
            ...p,
            id: doc.id,
            createdAt: +dayjs(p.createdAt || 0)
          }
        })
        store.dispatch(receiveFrees(res))
      },
      err => {
        console.log(`dbFetchFrees error: ${err.message}`)
      }
    )
    addListener('frees', unsubscribe)
  } catch (e) {
    console.error('dbFetchFrees error', e)
  }
}

export const dbUpdateFree = async (courseId: string, upd: Partial<IFree>) => {
  try {
    console.log('dbUpdateFree', courseId, upd)
    const ref = doc(collection(db, 'free'), courseId)
    await updateDoc(ref, dbOmit(upd))
  } catch (e) {
    console.log('dbUpdateCourse error', e)
  }
}

export const dbCreateFree = async () => {
  const id = generateId()
  const c: IFree = {
    id,
    title: 'Новый курс',
    invisible: true,
    closed: true,
    price: 0,
    lessons: [],
    sections: {},
    sectionsOrder: [],
    createdAt: +dayjs()
  }
  const ref = doc(collection(db, 'free'), id)
  await setDoc(ref, dbOmit(c))
  return id
}

export const dbDeleteFree = async (c: IFree) => {
  const batch = writeBatch(db)
  batch.delete(doc(collection(db, 'free'), c.id))
  if (c.lessons) {
    for (const lId of c.lessons) {
      batch.delete(doc(collection(db, 'lessons'), lId))
    }
  }
  await batch.commit()
}

export const dbDuplicateFree = async (
  c: IFree,
  lessons: Record<string, ILesson>
) => {
  const batch = writeBatch(db)
  const courseId = generateId()
  const newCourse: IFree = {
    ...c,
    id: courseId,
    lessons: [],
    closed: true,
    invisible: true,
    createdAt: +dayjs(),
    title: c.title + ' (copy)',
    copyOf: {
      entityId: c.id,
      entityType: IEntityType.FREE
    }
  }
  if (c.lessons) {
    for (const lId of c.lessons) {
      const l = lessons[lId]
      if (l) {
        const newL: ILesson = {
          ...l,
          id: generateId(),
          entityId: courseId
        }
        newCourse.lessons.push(newL.id)
        batch.set(doc(collection(db, 'lessons'), newL.id), newL)
      }
    }
  }
  if (c.content) {
    const newContent: IWeek[] = []
    forEach(c.content, w => {
      const newWeek: IWeek = { ...w, days: [], enabled: false }
      forEach(w.days, d => {
        const newDay: IDay = { ...d, lessons: [] }
        forEach(d.lessons, lId => {
          const l = lessons[lId]
          if (l) {
            const newL: ILesson = {
              ...l,
              id: generateId(),
              entityId: courseId
            }
            newDay.lessons.push(newL.id)
            // console.log('add new lesson to batch', newL)
            batch.set(doc(collection(db, 'lessons'), newL.id), newL)
          }
        })
        newWeek.days.push(newDay)
      })
      newContent.push(newWeek)
    })
    newCourse.content = newContent
  }

  // console.log('new course', newCourse)

  batch.set(doc(collection(db, 'free'), courseId), newCourse)
  await batch.commit()
  return courseId
}
