import {
  HStack,
  VStack,
  Text,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button
} from '@chakra-ui/react'
import { FC, useMemo } from 'react'
import { ICoursesPack, IPlan } from 'shared/types'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from 'react-beautiful-dnd'
import map from 'lodash/map'
import get from 'lodash/get'
import forEach from 'lodash/forEach'
import { ReactComponent as IconX } from 'shared/assets/bsXCircle.svg'
import { ReactComponent as IconList } from 'shared/assets/bsList.svg'
import { useSelector } from 'model/hooks'
import { dbUpdateCoursesPack } from 'controllers/coursesPacks'
import { generateId } from 'controllers/db'
import { arrayMove } from 'shared/utils/array'

type Props = {
  c: ICoursesPack
  onEditPlan: (i: number) => void
}

const CoursesPackPlanDrawerMain: FC<Props> = ({ c, onEditPlan }) => {
  const courses = useSelector(state => state.courses)

  const availableCourses = useMemo(() => {
    const aCourses = { ...(courses || {}) }
    forEach(c.plans, p => {
      delete aCourses[p.courseId]
    })
    return aCourses
  }, [courses, c.plans])

  const onDragEnd = (result: DropResult) => {
    console.log('onDragEnd', result)
    if (result.destination && result.destination.index >= 0) {
      const plans = arrayMove(
        c.plans,
        result.source.index,
        result.destination?.index || 0
      )
      dbUpdateCoursesPack(c.id, { plans })
    }
  }

  const onRemove = (i: number) => {
    console.log('onRemove', i)
    const plans = [...(c.plans || [])]
    plans.splice(i, 1)
    dbUpdateCoursesPack(c.id, { plans })
  }

  const addPlan = (cId: string) => {
    const planId = generateId()
    const p: IPlan = {
      id: planId,
      courseId: cId
    }
    const plans = [...(c.plans || []), p]
    dbUpdateCoursesPack(c.id, { plans })
  }

  const renderAddCourse = () => {
    return (
      <Menu>
        <Button variant={'ghost'} as={MenuButton} size='sm' colorScheme='wood'>
          + Добавить курс в тариф
        </Button>
        <MenuList overflowY={'auto'} maxHeight={'300px'}>
          {map(availableCourses, c => {
            return (
              <MenuItem
                key={c.id}
                value={c.id}
                onClick={() => addPlan(c.id)}
                textStyle={'small'}
                color='wood.950'
              >
                {c.title}
              </MenuItem>
            )
          })}
        </MenuList>
      </Menu>
    )
  }

  const renderPlan = (p: IPlan, i: number, isDraggable: boolean) => {
    if (isDraggable) {
      return (
        <Draggable key={p.id} draggableId={p.id} index={i}>
          {(provided, snapshot) => (
            <HStack
              borderWidth={1}
              borderColor='wood.600'
              px={2}
              w='full'
              h={{ base: 6, lg: 8 }}
              rounded='4px'
              flexShrink={0}
              align='center'
              justify={'space-between'}
              color='wood.600'
              textStyle={{ base: 'tag', lg: 'small' }}
              ref={provided.innerRef}
              {...provided.draggableProps}
            >
              <Box {...provided.dragHandleProps}>
                <IconList width='14' height='14' />
              </Box>
              <HStack as='button' onClick={() => onEditPlan(i)}>
                <Text noOfLines={1} textStyle={'small'}>
                  {get(courses, [p.courseId, 'title'])}
                </Text>
              </HStack>
              <Box
                textStyle={{ base: 'tag', lg: 'small' }}
                as='button'
                onClick={() => onRemove(i)}
                color='red.700'
              >
                <IconX width='14' height='14' />
              </Box>
            </HStack>
          )}
        </Draggable>
      )
    } else {
      return (
        <HStack
          borderWidth={1}
          borderColor='wood.600'
          px={2}
          w='full'
          h={{ base: 6, lg: 8 }}
          rounded='4px'
          flexShrink={0}
          align='center'
          justify={'space-between'}
          color='wood.600'
          textStyle={{ base: 'tag', lg: 'small' }}
        >
          <HStack w='full' justify={'center'}>
            <Text noOfLines={1} textStyle={'small'} textAlign={'center'}>
              {get(courses, [p.courseId, 'title'])}
            </Text>
          </HStack>
        </HStack>
      )
    }
  }

  return (
    <VStack align='flex-start'>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId='tags'
          renderClone={(provided, snapshot, rubric) => {
            return (
              <div ref={provided.innerRef} {...provided.draggableProps}>
                {renderPlan(c.plans[rubric.source.index], 0, false)}
              </div>
            )
          }}
        >
          {(provided, snapshot) => (
            <VStack
              w='full'
              direction={'column'}
              align='flex-start'
              textStyle='body'
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {map(c.plans, (p, i) => renderPlan(p, i, true))}
              {provided.placeholder}
            </VStack>
          )}
        </Droppable>
      </DragDropContext>
      {renderAddCourse()}
    </VStack>
  )
}

export default CoursesPackPlanDrawerMain
