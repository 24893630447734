import { FC, useEffect, useMemo, useState, useRef } from 'react'
import ContentContainer from 'shared/components/ContentContainer'
import PageContentWrapper from 'shared/components/PageContentWrapper'
import LessonNavButtons from 'shared/pages/lessonPage/LessonNavButtons'
import {
  IEntity,
  IEntityType,
  IExaminationAttempt,
  ILesson,
  ILessonType,
  IPartition,
  ISessionAnswer,
  ISubmitAnswerRequest,
  ISubmitAnswerResponse,
  ITeacher,
  ITestResult,
  IUser,
  IWordCard,
  IWordCardTag,
  SectionType
} from 'shared/types'
import PageContent from 'shared/components/PageContent'
import LessonHeader from 'shared/pages/lessonPage/LessonHeader'
import find from 'lodash/find'
import TestResults from 'shared/pages/lessonPage/TestResults'
import { getTestQuestionsAmount } from 'shared/utils/tests'
import size from 'lodash/size'
import get from 'lodash/get'
import StartExaminationModal, {
  IStartExaminationModal
} from 'shared/modals/StartExaminationModal'
import { defaultExaminationSettings } from 'shared/constants/examination'
import ExaminationTimer from 'shared/pages/lessonPage/ExaminationTimer'
import { Button, VStack, Divider } from '@chakra-ui/react'
import forEach from 'lodash/forEach'
import has from 'lodash/has'
import isEmpty from 'lodash/isEmpty'
import { MAX_WIDTH, TEST_MAX_WIDTH } from 'shared/constants/main'
import { StorageReference } from 'firebase/storage'
import AddSectionButton from 'shared/components/admin/AddSectionButton'

type Props = {
  lesson: ILesson
  entity: IEntity
  entityType: IEntityType
  toNext?: () => void
  toPrev?: () => void
  backUrl: string
  examinationAttempt: IExaminationAttempt | null
  testResult: ITestResult | null
  storageRef: (refer: string) => StorageReference
  dbFinishExaminationAttempt: (attemptId: string) => Promise<void>
  submitTestAnswers: (
    req: ISubmitAnswerRequest
  ) => Promise<ISubmitAnswerResponse | null>
  user: IUser | null
  dbStartExaminationAttempt: (ea: IExaminationAttempt) => Promise<void>
  weekId: string
  dayId: string
  admin?: {
    onAddSection: (sType: SectionType, i: number) => void
    onMoveSection: (fromIndex: number, toIndex: number) => void
    onEditSection: (sid: string) => void
    onEditHeader?: () => void
  }
  teachers: Record<string, ITeacher>
  partitions: Record<string, IPartition> | null
  wordCards: Record<string, IWordCard>
  cardTags: Record<string, IWordCardTag> | null
  toLearnDictionary: () => void
  cardStatuses?: Record<string, boolean>
}

const LessonPage: FC<Props> = ({
  lesson,
  entity,
  entityType,
  toNext,
  toPrev,
  backUrl,
  examinationAttempt,
  testResult,
  storageRef,
  dbFinishExaminationAttempt,
  submitTestAnswers,
  user,
  dbStartExaminationAttempt,
  weekId,
  dayId,
  admin,
  teachers,
  partitions,
  wordCards,
  cardTags,
  toLearnDictionary,
  cardStatuses
}) => {
  const [contentKey, setContentKey] = useState(Date.now())
  const [sessionAnswers, setSessionAnswers] = useState<
    Record<string, ISessionAnswer>
  >({})
  const startExaminationModalRef = useRef<IStartExaminationModal>(null)
  const [examinationStarted, setExaminationStarted] = useState(false)
  const [viewExaminationErrors, setViewExaminationErrors] = useState(false)

  useEffect(() => {
    if (lesson.type === ILessonType.EXAMINATION && !admin) {
      startExaminationModalRef.current?.open()
    }
  }, [])

  useEffect(() => {
    setSessionAnswers({})
  }, [lesson.id])

  const examinationSettings = useMemo(() => {
    return lesson.examination || defaultExaminationSettings
  }, [lesson])

  const currentExaminationAttempt = useMemo(() => {
    if (
      examinationAttempt &&
      !examinationAttempt.finished &&
      examinationAttempt.timestamp + examinationSettings.duration > Date.now()
    ) {
      return examinationAttempt
    }
    return null
  }, [examinationAttempt, examinationSettings])

  // console.log('currentExaminationAttempt', currentExaminationAttempt)

  const week = useMemo(() => {
    if (entity.content) {
      return find(entity.content, w => w.id === weekId)
    }
  }, [entity, weekId])

  const day = useMemo(() => {
    if (week && dayId) {
      return find(week.days, d => d.id === dayId)
    }
  }, [week, dayId])

  const onStartAgain = () => {
    setSessionAnswers({})
    setContentKey(Date.now())
  }

  const renderTestResults = () => {
    if (lesson.type === ILessonType.TEST) {
      return (
        <TestResults
          lesson={lesson}
          sessionAnswers={sessionAnswers}
          onStartAgain={onStartAgain}
          goNext={toNext}
        />
      )
    }
  }

  const lastExaminationResult = useMemo(() => {
    if (testResult) {
      return {
        correctAnswersAmount: get(testResult, 'correctAnswersAmount', 0),
        questionsAmount: getTestQuestionsAmount(lesson)
      }
    } else {
      return null
    }
  }, [testResult, lesson])

  const startExamination = () => {
    setExaminationStarted(true)
    onStartAgain()
  }

  const finishExamination = () => {
    if (currentExaminationAttempt) {
      const newSessionAnswers = { ...sessionAnswers }
      setExaminationStarted(false)
      forEach(lesson.sections, s => {
        if (!has(newSessionAnswers, s.id)) {
          const ca = get(s, 'test.answer', undefined) as string | undefined
          const sa: ISessionAnswer = {
            answer: '',
            correctValue: ca,
            isCorrect: false
          }
          newSessionAnswers[s.id] = sa
        }
      })
      setSessionAnswers(newSessionAnswers)
      console.log(
        'size(sessionAnswers)',
        size(sessionAnswers),
        'tasksAmount',
        tasksAmount
      )
      const req: ISubmitAnswerRequest = {
        entityId: entity.id,
        weekId,
        dayId,
        lessonId: lesson.id,
        sessionAnswers: newSessionAnswers
      }
      console.log('--- submitTestAnswers', req)
      submitTestAnswers(req)
      dbFinishExaminationAttempt(currentExaminationAttempt.id)
      startExaminationModalRef.current?.open()
    }
  }

  const renderFinishExaminationButton = () => {
    if (
      lesson.type === ILessonType.EXAMINATION &&
      currentExaminationAttempt &&
      examinationStarted
    ) {
      return (
        <Button
          variant='primary'
          w={{ base: 'full', lg: '510px' }}
          position={'sticky'}
          bottom={{ lg: '72px', base: 16 }}
          size={{ base: 'md', lg: 'lg' }}
          onClick={finishExamination}
        >
          Завершить
        </Button>
      )
    }
  }

  const renderViewExaminationResultsButton = () => {
    if (viewExaminationErrors) {
      return (
        <Button
          variant='primary'
          w={{ base: 'full', lg: '510px' }}
          position={'sticky'}
          bottom={{ lg: '72px', base: 16 }}
          size={{ base: 'md', lg: 'lg' }}
          onClick={() => {
            startExaminationModalRef.current?.open()
            setViewExaminationErrors(false)
          }}
        >
          Смотреть результат
        </Button>
      )
    }
  }

  const tasksAmount = useMemo(() => {
    if (lesson) {
      return getTestQuestionsAmount(lesson)
    } else {
      return null
    }
  }, [lesson])

  const onAnswer = async (sectionId: string, answer: ISessionAnswer) => {
    console.log('onAnswer', sectionId, answer)
    const newSessionAnswers = {
      ...sessionAnswers,
      [sectionId]: answer
    }
    setSessionAnswers(newSessionAnswers)
    console.log(
      'size(sessionAnswers)',
      size(newSessionAnswers),
      'tasksAmount',
      tasksAmount
    )
    const req: ISubmitAnswerRequest = {
      entityId: entity.id,
      weekId,
      dayId,
      lessonId: lesson.id,
      sessionAnswers: newSessionAnswers
    }
    console.log('--- submitTestAnswers', req)
    submitTestAnswers(req)
  }

  const renderStartDictionaryTrainingButton = () => {
    if (lesson.type === ILessonType.DICTIONARY) {
      return (
        <VStack w='full' maxW={TEST_MAX_WIDTH} spacing={6}>
          <Button
            size='lg'
            w='full'
            variant={'primary'}
            onClick={toLearnDictionary}
          >
            Учить слова
          </Button>
          <Divider />
        </VStack>
      )
    }
  }

  return (
    <PageContentWrapper>
      <ContentContainer
        bg='wood.100'
        backBg='white'
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
        containerProps={{ role: 'group' }}
      >
        <LessonHeader
          title={lesson.name || ''}
          backUrl={backUrl}
          entity={entity}
          entityType={entityType}
          weekId={week?.id}
          dayId={day?.id}
          lessonId={lesson.id}
          onEditHeader={admin?.onEditHeader}
        >
          <LessonNavButtons onPrevClick={toPrev} onNextClick={toNext} />
        </LessonHeader>
        {admin && (
          <AddSectionButton
            lt={lesson.type}
            addSection={(sType: SectionType) => admin.onAddSection(sType, 0)}
          />
        )}
      </ContentContainer>
      <ContentContainer
        bg='white'
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
        py={{ base: 6, lg: 8 }}
        minH='80vh'
      >
        {renderStartDictionaryTrainingButton()}
        {lesson.type === ILessonType.EXAMINATION &&
          currentExaminationAttempt &&
          examinationStarted && (
            <ExaminationTimer
              endTime={
                currentExaminationAttempt
                  ? currentExaminationAttempt.timestamp +
                    examinationSettings.duration
                  : undefined
              }
              onEnded={finishExamination}
            />
          )}
        {lesson.sections && lesson.sectionsOrder && (
          <PageContent
            key={contentKey}
            sections={lesson.sections}
            sectionsOrder={lesson.sectionsOrder}
            containerProps={{ maxW: TEST_MAX_WIDTH }}
            entityId={entity.id}
            storageRef={storageRef}
            onAnswer={onAnswer}
            sessionAnswers={sessionAnswers}
            isExamination={lesson.type === ILessonType.EXAMINATION}
            showUserAnswer={currentExaminationAttempt !== null}
            lt={lesson.type}
            admin={admin}
            teachers={teachers}
            partitions={partitions}
            wordCards={wordCards}
            cardTags={cardTags}
            cardStatuses={cardStatuses}
          />
        )}
        {renderFinishExaminationButton()}
        {renderViewExaminationResultsButton()}
        {renderTestResults()}
        {/* {<Chat lessonId={lesson.id} entityId={lesson.entityId} />} */}
      </ContentContainer>
      {lesson.type === ILessonType.EXAMINATION && user && (
        <StartExaminationModal
          lessonId={lesson.id}
          entityId={entity.id}
          ref={startExaminationModalRef}
          title={lesson.name}
          examinationSettings={examinationSettings}
          currentAttempt={examinationAttempt}
          lastResult={lastExaminationResult}
          onStart={startExamination}
          canViewErrors={!isEmpty(sessionAnswers)}
          onViewErrors={() => setViewExaminationErrors(true)}
          user={user}
          dbStartExaminationAttempt={dbStartExaminationAttempt}
        />
      )}
    </PageContentWrapper>
  )
}

export default LessonPage
