import {
  onSnapshot,
  query,
  collection,
  doc,
  setDoc,
  updateDoc
} from 'firebase/firestore'
import { db, dbOmit } from 'src/controllers/db'
import { ITeacher } from 'shared/types'
import { receiveTeachers } from 'src/model/actions'
import store from 'src/model/store'
import { addListener } from 'controllers/listeners'
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'

export const dbFetchTeachers = async () => {
  try {
    const q = query(collection(db, 'teachers'))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: Record<string, ITeacher> = {}
        sn.forEach(doc => {
          const p = doc.data() as ITeacher
          res[doc.id] = p
        })
        store.dispatch(receiveTeachers(res))
      },
      err => {
        console.log(`dbFetchTeachers error: ${err.message}`)
      }
    )
    addListener('teachers', unsubscribe)
  } catch (e) {
    console.error('dbFetchTeachers error', e)
  }
}

export const dbCreateTeacher = async (t: ITeacher) => {
  const ref = doc(db, 'teachers', t.id)
  await setDoc(ref, omitBy(t, isNil))
}

export const dbUpdateTeacher = async (id: string, upd: Partial<ITeacher>) => {
  const ref = doc(db, 'teachers', id)
  await updateDoc(ref, dbOmit(upd))
}
