import { FC } from 'react'
import { VStack } from '@chakra-ui/react'
import { IPadding, ISectionTestOneOption, ITestContent } from 'shared/types'
import get from 'lodash/get'
import EditableReachField from 'components/EditableReachField'
import PaddingSelect from 'components/PaddingSelect'
import Label from 'shared/components/Label'
import HighlightCorrectAnswer from 'components/contentSettingsCard/HighlightCorrectAnswer'
import SimpleInput from 'shared/components/SimpleInput'
import { getTestTitle } from 'shared/utils/tests'
import EditTestOneOption from 'components/contentSettingsCard/EditTestOneOption'

type Props = {
  s: ISectionTestOneOption
  onChange: (s: ISectionTestOneOption) => void
}

const EditSectionTestOneOption: FC<Props> = ({ s, onChange }) => {
  const renderDescription = () => {
    return (
      <VStack w='full' align='start'>
        <Label>Описание</Label>
        <VStack
          w='full'
          spacing={2}
          borderWidth={1}
          borderColor='wood.600'
          px={2}
          rounded='4px'
          flexShrink={0}
          align='center'
          justify={'space-between'}
          color='wood.600'
        >
          <EditableReachField
            v={s.htmlString || ''}
            onChange={(v: string) => onChange({ ...s, htmlString: v })}
            textStyle={s.textStyle}
            onTextStyleChange={v => onChange({ ...s, textStyle: v })}
          />
        </VStack>
      </VStack>
    )
  }

  const renderPadding = () => {
    return (
      <PaddingSelect
        value={s.paddingTop}
        onChange={(v: IPadding) => onChange({ ...s, paddingTop: v })}
      />
    )
  }

  const renderHighlightCorrectAnswer = () => {
    const v = get(s, 'test.highlightCorrectAnswer', false)
    return (
      <HighlightCorrectAnswer
        value={v}
        onChange={(v: boolean) =>
          onChange({
            ...s,
            test: { ...(s.test || {}), highlightCorrectAnswer: v }
          })
        }
      />
    )
  }

  const renderEditContent = () => {
    return (
      <EditTestOneOption
        test={s.test || {}}
        onChange={(v: ITestContent) => onChange({ ...s, test: v })}
      />
    )
  }

  const renderTitle = () => {
    return (
      <SimpleInput
        value={s.test?.title || getTestTitle(s.type)}
        onChange={(v: string) =>
          onChange({ ...s, test: { ...(s.test || {}), title: v } })
        }
        label='Вопрос'
      />
    )
  }

  return (
    <VStack w='full' spacing={6}>
      {renderPadding()}
      {renderTitle()}
      {renderDescription()}
      {renderHighlightCorrectAnswer()}
      {renderEditContent()}
    </VStack>
  )
}

export default EditSectionTestOneOption
