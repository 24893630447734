import { HStack, Flex, Select } from '@chakra-ui/react'
import { useSelector } from 'model/hooks'
import { FC } from 'react'
import _ from 'lodash'
import Label from 'shared/components/Label'

type Props = {
  label: string
  value?: string
  onChange: (v?: string) => void
}

const TeacherSelect: FC<Props> = ({ label, value, onChange }) => {
  const teachers = useSelector(state => state.teachers)
  return (
    <HStack w='full' justify={'space-between'}>
      <Label>{label}</Label>
      <Flex>
        <Select
          key={value}
          size='sm'
          variant={'flushed'}
          value={value}
          placeholder='не выбран'
          onChange={e =>
            onChange(e.target.value === '' ? undefined : e.target.value)
          }
        >
          {_.map(teachers, p => (
            <option key={p.id} value={p.id}>
              {p.name}
            </option>
          ))}
        </Select>
      </Flex>
    </HStack>
  )
}

export default TeacherSelect
