import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
  fonts: {
    body: "'Inter Variable', sans-serif"
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900
  },
  shadows: {
    shadow1: '0px 8px 20px 0px rgba(72, 29, 0, 0.13)',
    menu: '0px 2px 16px 0px rgba(0, 0, 0, 0.27)',
    large: '0px 16px 32px -8px rgba(12, 12, 13, 0.4)'
  },

  components: {
    Button: {
      variants: {
        outline: {
          color: 'sun.950',
          borderColor: 'sun.950',
          // textStyle: 'small',
          fontWeight: 500,
          _disabled: {
            color: 'sun.950',
            borderColor: 'sun.950',
            opacity: 0.2
          },
          _hover: {
            bg: 'sun.900',
            color: 'white'
          }
        },
        primary: {
          bg: 'sun.950',
          color: 'white',
          rounded: 'full',
          fontWeight: 500,
          _disabled: {
            opacity: 0.2,
            cursor: 'not-allowed'
          },
          _hover: {
            _disabled: {
              bg: 'sun.950'
            },
            bg: 'sun.900'
          }
        }
      },
      sizes: {
        sm: {
          h: 6,
          fontSize: '12px',
          px: 4
        },
        md: {
          h: 8,
          fontSize: '14px',
          px: 6
        },
        lg: {
          h: 10,
          fontSize: '18px',
          fontWeight: 500,
          px: 8
        }
      }
    },
    FormLabel: {
      baseStyle: {
        fontSize: 'lg',
        fontWeight: '450',
        letterSpacing: '-0.36px',
        lineHeight: '24px',
        // color: { base: 'black', lg: '#828282' },
        // fontWeight: { base: 'medium', lg: 'bold' },
        // fontSize: 'sm',
        mb: 2
      }
    },
    Select: {
      variants: {
        flushed: {
          field: {
            _placeholder: {
              fontSize: 'sm',
              color: 'midGray'
            },
            borderBottom: '1px solid',
            borderColor: 'black',
            borderRadius: '0',
            px: '0',
            bg: 'transparent',
            _readOnly: {
              boxShadow: 'none !important',
              userSelect: 'all'
            },
            _invalid: {
              borderColor: 'red.400',
              boxShadow: `0px 1px 0px 0px red.400`
            },
            _focusVisible: {
              borderColor: 'black',
              boxShadow: `0px 1px 0px 0px black`
            }
          },
          addon: {
            borderBottom: '2px solid',
            borderColor: 'inherit',
            borderRadius: '0',
            px: '0',
            bg: 'transparent'
          }
        }
      }
    },
    Input: {
      variants: {
        custom: {
          field: {
            fontSize: { base: '16px', lg: 'lg' },
            fontWeight: '450',
            letterSpacing: { base: '-0.32px', lg: '-0.36px' },
            lineHeight: { base: '20px', lg: '24px' },
            _placeholder: {
              textStyle: 'body',
              color: 'black.300'
            },
            bg: 'white',
            borderRadius: 'xs',
            p: 3,
            _success: {
              bg: 'green.800'
            }
          }
        },
        flushed: {
          field: {
            _placeholder: {
              fontSize: 'sm',
              color: 'black.500',
              lineHeight: '16px'
            },
            borderBottom: '1px solid',
            borderColor: 'black.200',
            borderRadius: '0',
            px: '0',
            bg: 'transparent',
            _readOnly: {
              boxShadow: 'none !important',
              userSelect: 'all'
            },
            _invalid: {
              borderColor: 'red.400',
              boxShadow: `0px 1px 0px 0px red.400`
            },
            _focusVisible: {
              borderColor: 'black.500',
              boxShadow: 'none'
              // boxShadow: `0px 1px 0px 0px black`
            }
          },
          addon: {
            borderBottom: '2px solid',
            borderColor: 'inherit',
            borderRadius: '0',
            px: '0',
            bg: 'transparent'
          }
        }
      }
    },
    Divider: {
      baseStyle: {
        borderColor: 'black.200',
        h: '0px'
      }
    },
    Textarea: {
      variants: {
        flushed: {
          _placeholder: {
            fontSize: 'sm',
            color: '#807F7E'
          },
          borderBottom: '1px solid',
          borderColor: 'black',
          borderRadius: '0',
          px: '0',
          bg: 'transparent',
          _readOnly: {
            boxShadow: 'none !important',
            userSelect: 'all'
          },
          _invalid: {
            borderColor: 'red.400',
            boxShadow: `0px 1px 0px 0px red.400`
          },
          _focusVisible: {
            borderColor: 'black',
            boxShadow: `0px 1px 0px 0px black`
          }
        },
        custom: {
          fontSize: { base: '16px', lg: 'lg' },
          fontWeight: '450',
          letterSpacing: { base: '-0.32px', lg: '-0.36px' },
          lineHeight: { base: '20px', lg: '24px' },
          _placeholder: {
            // textStyle: 'body',
            color: 'black.300'
          },
          bg: 'white',
          borderRadius: 'xs',
          p: 3,
          _success: {
            bg: 'green.800'
          }
        }
      }
    },
    Checkbox: {
      baseStyle: {
        control: {
          borderRadius: '2px',
          borderColor: 'black.950',
          border: '1px solid',

          _checked: {
            bg: 'black.950',
            borderColor: 'black.950',
            color: 'white',
            _hover: {
              bg: 'blackAlpha.700'
            }
            // _hover: {
            //   bg: 'gray.100',
            //   borderColor: 'black'
            // },
          },

          _disabled: {
            borderColor: 'black.400',
            bg: 'white',
            color: 'white'
          }
        },
        label: {
          _disabled: {
            color: 'black.400'
          }
        }
      }
    }
  },
  breakpoints: {
    lg: '62em'
  },
  colors: {
    black: {
      50: '#F7F7F6',
      100: '#E4E4E3',
      200: '#C9C9C6',
      300: '#A7A6A1',
      400: '#84837D',
      500: '#696863',
      600: '#53534E',
      700: '#444441',
      800: '#393936',
      900: '#32312F',
      950: '#10100F'
    },
    wood: {
      50: '#F7F6F2',
      100: '#EFEDE5',
      200: '#DEDACA',
      300: '#C9C1A8',
      400: '#B2A485',
      500: '#A3906C',
      600: '#958061',
      700: '#7D6951',
      800: '#665646',
      900: '#54473A',
      950: '#2C241E'
    },
    blue: {
      50: '#EDEFFF',
      100: '#D6DBFE',
      200: '#C0C7FD',
      300: '#A9B2FC',
      400: '#929CFB',
      500: '#7885FA',
      600: '#5B6CF8',
      700: '#394FF5',
      800: '#1832DB',
      900: '#0F1A9B',
      950: '#050447'
    },
    red: {
      50: '#FEF3F0',
      100: '#FCDBD3',
      200: '#FBC1B4',
      300: '#F9A695',
      400: '#F78572',
      500: '#F65C47',
      600: '#f4220d',
      700: '#CB1608',
      800: '#A40D06',
      900: '#7A0704',
      950: '#4B0202'
    },
    green: {
      50: '#F0F8E9',
      100: '#D0EEBE',
      200: '#A0E587',
      300: '#56E23F',
      400: '#21D017',
      500: '#12B911',
      600: '#0CA212',
      700: '#088A13',
      800: '#047013',
      900: '#025411',
      950: '#00360D'
    },
    sun: {
      50: '#FFFDEA',
      100: '#FFF7C5',
      200: '#FFF085',
      300: '#FFE146',
      400: '#FFCF1B',
      500: '#FFAE00',
      600: '#E28400',
      700: '#BB5C02',
      800: '#984708',
      900: '#7C3A0B',
      950: '#481D00'
    }
  },
  radii: {
    lg: '32px',
    sm: '16px',
    xs: '8px'
  },
  semanticTokens: {
    colors: {
      'chakra-body-text': 'black',
      'chakra-placeholder-color': 'midGray'
    }
  },
  sizes: {
    container: {
      xl: '1512px'
    }
  },

  textStyles: {
    title: {
      fontSize: { base: '26px', lg: '44px' },
      letterSpacing: { base: '-0.78px', lg: '-1.32px' },
      fontWeight: '450',
      lineHeight: { base: '32px', lg: '48px' }
    },
    h1: {
      fontSize: { base: '38px', lg: '62px' },
      fontWeight: { base: '450', lg: '450' },
      letterSpacing: { base: '-0.02em', lg: '-0.04em' },
      lineHeight: { base: '40px', lg: '64px' }
    },
    h2: {
      fontSize: { base: '29px', lg: '42px' },
      fontWeight: { base: '450', lg: '450' },
      letterSpacing: { base: '-0.02em', lg: '-0.02em' },
      lineHeight: { base: '32px', lg: '48px' }
    },
    h3: {
      fontSize: { base: '20px', lg: '26px' },
      fontWeight: { base: '450', lg: '450' },
      letterSpacing: { base: '-0.02em', lg: '-0.02em' },
      lineHeight: { base: '24px', lg: '32px' }
    },
    body: {
      fontSize: { base: '17px', lg: '19px' },
      fontWeight: '450',
      letterSpacing: { base: '-0.02em', lg: '-0.02em' },
      lineHeight: { base: '22px', lg: '24px' }
    },
    medium: {
      fontSize: { base: '16px', lg: '16px' },
      fontWeight: '450',
      letterSpacing: { base: '-0.32px', lg: '-0.36px' },
      lineHeight: { base: '20px', lg: '20px' }
    },
    small: {
      fontSize: '15px',
      fontWeight: '450',
      letterSpacing: '-0.02em',
      lineHeight: '20px'
    },
    tag: {
      fontSize: '12px',
      fontWeight: '450',
      letterSpacing: '-0.02em',
      lineHeight: '16px'
    }
  }
})
