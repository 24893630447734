import {
  Button,
  HStack,
  IconButton,
  Input,
  useOutsideClick
} from '@chakra-ui/react'
import { FC, useRef, useState } from 'react'
import { ReactComponent as IconApply } from 'shared/assets/bsCheck.svg'
import { ReactComponent as IconCancel } from 'shared/assets/bsX.svg'
import _ from 'lodash'

type Props = {
  buttonTitle: string
  placeholder?: string
  onSubmit: (value: string) => void
}

const AddTextField: FC<Props> = ({ buttonTitle, placeholder, onSubmit }) => {
  const [isButtonMode, setIsButtonMode] = useState(true)
  const [value, setValue] = useState('')
  const containerRef = useRef(null)

  const cancel = () => {
    setIsButtonMode(true)
    setValue('')
  }

  useOutsideClick({
    ref: containerRef,
    handler: () => cancel()
  })

  const submit = () => {
    console.log('submit', value)
    if (_.trim(value) !== '') {
      onSubmit(value)
      cancel()
    }
  }

  if (isButtonMode) {
    return (
      <HStack h={6}>
        <Button
          size='xs'
          variant={'ghost'}
          onClick={() => setIsButtonMode(false)}
        >
          {buttonTitle}
        </Button>
      </HStack>
    )
  } else {
    return (
      <HStack h={6} ref={containerRef} w='full'>
        <Input
          variant={'flushed'}
          size='sm'
          value={value}
          colorScheme='wood'
          onChange={e => setValue(e.target.value)}
          placeholder={placeholder}
          autoFocus
          onKeyDown={e => {
            if (e.key === 'Enter') {
              submit()
            }
          }}
        />
        <IconButton
          size='xs'
          aria-label='apply'
          colorScheme='wood'
          icon={<IconApply />}
          onClick={submit}
        />
        <IconButton
          size='xs'
          colorScheme='red'
          aria-label='cancel'
          icon={<IconCancel />}
          onClick={cancel}
        />
      </HStack>
    )
  }
}

export default AddTextField
