import React from 'react'
import { IEntity, IEntityType, ILesson, IUserPayment } from 'shared/types'

export interface ILessonsContext {
  entity: IEntity | null
  entityId: string | null
  entityType: IEntityType | null
  lessons: Record<string, ILesson> | null
  lessonsIds: string[]
  userPayments: IUserPayment[]
}

const Ctx = React.createContext<ILessonsContext>({
  entity: null,
  entityId: null,
  entityType: null,
  lessons: null,
  lessonsIds: [],
  userPayments: []
})

export default Ctx
