import {
  GridItem,
  VStack,
  Flex,
  Text,
  Box,
  HStack,
  Button,
  Divider,
  Stack
} from '@chakra-ui/react'
import { FC } from 'react'
import { ICourse, IPlan } from 'shared/types'
import has from 'lodash/has'
import numeral from 'numeral'
import map from 'lodash/map'
import { createLabel } from 'shared/utils/format'

type Props = {
  p: IPlan
  c: ICourse
  userCourses: Record<string, ICourse>
  toCourse: () => void
  onPay: () => void
}

const CoursesPackPlanCard: FC<Props> = ({
  p,
  c,
  userCourses,
  toCourse,
  onPay
}) => {
  const renderPrimaryButton = (c: ICourse, p: IPlan) => {
    const canBuy = !c.closed
    const bought = has(userCourses, c.id)
    const limitReached =
      (c.studentsAmount || 0) >= (c.studentsLimit || Number.MAX_SAFE_INTEGER)
    if (bought) {
      return (
        <Button
          variant={'primary'}
          w='full'
          flex={1}
          flexShrink={0}
          onClick={toCourse}
          // onClick={() => navigate(`/profile/courses/${c.id}`)}
        >
          Перейти к курсу
        </Button>
      )
    } else if (canBuy && !limitReached) {
      return (
        <Button
          variant={'primary'}
          w='full'
          flex={1}
          flexShrink={0}
          onClick={onPay}
          // onClick={() => onPay(c)}
        >
          Купить
        </Button>
      )
    } else {
      return (
        <Button variant={'primary'} w='full' flexShrink={0} isDisabled flex={1}>
          Продажи закрыты
        </Button>
      )
    }
  }

  const renderTitle = () => {
    return (
      <Text textStyle={'body'} fontWeight={700} color='sun.950'>
        {c.title}
      </Text>
    )
  }

  const renderPrice = () => {
    if (c.price) {
      return (
        <Stack spacing={0} minH={10}>
          <HStack textStyle='tag' color='black.200'>
            {c.oldPrice && (
              <Text textDecoration={'line-through'}>{c.oldPrice} РУБ</Text>
            )}
            {c.oldPriceEur && c.oldPrice && <Text>/</Text>}
            {c.oldPriceEur && (
              <Text textDecoration={'line-through'}>{c.oldPriceEur} EUR</Text>
            )}
          </HStack>
          <HStack textStyle='body' color='black.950'>
            <Text>{c.price} РУБ</Text>
            {c.priceEur && c.price && <Text>/</Text>}
            {c.priceEur && <Text>{c.priceEur} EUR</Text>}
          </HStack>
        </Stack>
      )
    } else {
      return <Stack minH={{ base: 2, lg: 10 }} />
    }
  }

  return (
    <GridItem
      maxW={{ base: 'full', lg: '389px' }}
      key={p.id}
      bg='white'
      rounded={'8px'}
      boxShadow={'shadow1'}
      p={4}
      display='flex'
      flexDirection={'column'}
      position={'relative'}
      role='group'
    >
      <VStack align='flex-start' spacing={6} h='full'>
        <VStack spacing={0} w='full' align={'flex-start'}>
          <Flex w='full' minH={4}>
            {c.studentsLimit && c.studentsLimit > 0 && (
              <Text textStyle={'small'} color='black.500'>
                {(c.studentsAmount || 0) < c.studentsLimit
                  ? `${c.studentsLimit - (c.studentsAmount || 0)} ${createLabel(
                      c.studentsLimit - (c.studentsAmount || 0),
                      ['место', 'места', 'мест']
                    )}`
                  : 'Мест нет'}
              </Text>
            )}
          </Flex>
          {renderTitle()}
        </VStack>
        {renderPrice()}
        <VStack w='full' align='start' divider={<Divider />}>
          {map(p.bulletPoints, (bp, i) => {
            return (
              <Box
                key={i}
                textStyle='small'
                dangerouslySetInnerHTML={{ __html: bp || '' }}
              />
            )
          })}
        </VStack>
      </VStack>
      <HStack w='full' pt={10}>
        {renderPrimaryButton(c, p)}
        {p.buttonTitle &&
          p.buttonTitle !== '' &&
          p.buttonUrl &&
          p.buttonUrl !== '' && (
            <Button
              variant={'outline'}
              w='full'
              flex={1}
              colorScheme='black'
              rounded='full'
              onClick={() => window.open(p.buttonUrl, '_blank')}
            >
              {p.buttonTitle}
            </Button>
          )}
      </HStack>
    </GridItem>
  )
}

export default CoursesPackPlanCard
