import {
  Card,
  CardHeader,
  HStack,
  Heading,
  CardBody,
  Divider,
  VStack,
  StackDivider,
  Text,
  Tag
} from '@chakra-ui/react'
import { FC, useContext, useRef } from 'react'
import LessonsContext from 'contexts/LessonsContext'
import map from 'lodash/map'
import { IUserPayment } from 'shared/types'
import dayjs from 'dayjs'
import RowActionButtons from 'components/RowActionButtons'
import DeleteConfirmation, {
  IDeleteConfirmationModal
} from 'modals/DeleteConfirmation'
import {
  dbCreateUserPayment,
  dbDeleteUserPayment
} from 'controllers/userPayments'

import { generateId } from 'controllers/db'
import UsersSearch from 'components/UsersSearch'

type Props = {}

const EntityUsers: FC<Props> = () => {
  const { userPayments, entityId, entityType } = useContext(LessonsContext)
  const deleteModalRef = useRef<IDeleteConfirmationModal>(null)

  const renderRow = (p: IUserPayment, i: number) => {
    return (
      <HStack
        key={p.id}
        w='full'
        justify={'space-between'}
        _hover={{ bg: 'gray.50' }}
        role='group'
      >
        <HStack
          align={'center'}
          justify={'space-between'}
          spacing={4}
          w='full'
          py={2}
          pl={4}
        >
          <Text fontSize={'sm'} fontWeight={'bold'}>
            {i + 1}.
          </Text>
          <VStack spacing={0} align={'flex-start'} flex={2}>
            <Text fontWeight={'medium'}>{p.name}</Text>
            <Text fontSize={'sm'} color={'gray.400'} noOfLines={1}>
              {p.email}
            </Text>
          </VStack>
          <VStack spacing={0} align={'flex-start'} flex={1}>
            {p.price ? (
              <Text fontWeight={'medium'}>{p.price}</Text>
            ) : (
              <Tag size='sm' variant='subtle' colorScheme='teal'>
                Admin
              </Tag>
            )}
            <Text fontSize={'xs'} color={'gray.400'} noOfLines={1}>
              {dayjs(p.createdAt).format('MMM D, YYYY HH:mm')}
            </Text>
          </VStack>
        </HStack>
        <RowActionButtons
          onDelete={() => {
            console.log('on Delete click')
            deleteModalRef.current?.open(p.id)
          }}
        />
      </HStack>
    )
  }

  const renderUsers = () => {
    return (
      <VStack divider={<StackDivider />} spacing={0}>
        {map(userPayments, renderRow)}
      </VStack>
    )
  }

  const onDelete = (id?: string) => {
    if (id) {
      dbDeleteUserPayment(id)
    }
  }

  const addUser = (id: string, name: string = '', email: string = '') => {
    console.log('add user', id, name, email)
    if (entityId && entityType) {
      const up: IUserPayment = {
        id: generateId(),
        createdAt: +dayjs(),
        entityId,
        price: '',
        transactionId: '',
        type: entityType,
        userId: id,
        name: name,
        email: email
      }
      console.log('up', up)
      dbCreateUserPayment(up)
    }
  }

  return (
    <VStack w='full'>
      <HStack w='full' maxW='2xl' pb={8}>
        <UsersSearch onSelect={addUser} title='Добавить ученика' />
      </HStack>
      <Card w='full' maxW='2xl'>
        <CardHeader>
          <HStack w='full' justify={'space-between'}>
            <Heading size='md'>Ученики</Heading>
          </HStack>
        </CardHeader>
        <Divider color='gray.200' />
        <CardBody>{renderUsers()}</CardBody>
        <DeleteConfirmation
          ref={deleteModalRef}
          title='Удаление платежа'
          description={`Вы собираетесь удалить платеж. Это действие нельзя отменить.`}
          onDelete={onDelete}
        />
      </Card>
    </VStack>
  )
}

export default EntityUsers
