import { useRef } from 'react'
import {
  Button,
  HStack,
  VStack,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Text,
  Td,
  IconButton
} from '@chakra-ui/react'
import NavBar from 'components/NavBar'
import CreatePaymentLink, { ICreatePaymentLink } from 'modals/CreatePaymentLink'
import { useSelector } from 'model/hooks'
import dayjs from 'dayjs'
import config from 'shared/config'
import { ReactComponent as IconClipboard } from 'shared/assets/bsClipboardPlus.svg'
import { ReactComponent as IconOpen } from 'shared/assets/bsArrowUpRight.svg'

const PaymentLinks = () => {
  const createLinkRef = useRef<ICreatePaymentLink>(null)
  const paymentLinks = useSelector(state => state.paymentLinks)

  const navBarActions = (
    <HStack spacing={6}>
      <Button colorScheme='teal' onClick={() => createLinkRef.current?.open()}>
        Создать ссылку
      </Button>
    </HStack>
  )

  const renderPaymentLinks = () => {
    return (
      <TableContainer
        w='full'
        bg='white'
        rounded={'md'}
        textStyle={'body'}
        overflowY='auto'
      >
        <Table size={'sm'}>
          <Thead position={'sticky'} top={0} zIndex={2} bg='white'>
            <Tr>
              <Th>Дата создания</Th>
              <Th>Название</Th>
              <Th>Цена</Th>
              <Th>Ссылка</Th>
            </Tr>
          </Thead>
          <Tbody>
            {paymentLinks.map(p => {
              const plUrl = `${config.frontUrl}/pl/${p.id}`
              return (
                <Tr>
                  <Td>{dayjs(p.createdAt).format('D MMMM, HH:mm')}</Td>
                  <Td>{p.title}</Td>
                  <Td>{p.price}</Td>
                  <Td>
                    <HStack spacing={2}>
                      <Text>{plUrl}</Text>
                      <IconButton
                        w={4}
                        variant={'ghost'}
                        size='sm'
                        aria-label='copy to clipboard'
                        icon={<IconClipboard />}
                        onClick={() => navigator.clipboard.writeText(plUrl)}
                      />
                      <IconButton
                        variant={'ghost'}
                        size='sm'
                        aria-label='open in a new tab'
                        icon={<IconOpen />}
                        onClick={() => window.open(plUrl, '_blank')}
                      />
                    </HStack>
                  </Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <VStack w='full' h='full' spacing={0} overflowY={'hidden'}>
      <NavBar hideBackButton title='Платежные ссылки'>
        {navBarActions}
      </NavBar>
      <VStack align={'flex-start'} w='full' h='full' p={4} overflowY={'auto'}>
        {renderPaymentLinks()}
      </VStack>
      <CreatePaymentLink ref={createLinkRef} />
    </VStack>
  )
}

export default PaymentLinks
