import {
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Switch,
  Text,
  VStack
} from '@chakra-ui/react'
import AddTextField from 'components/AddTextField'
import { generateId } from 'controllers/db'
import { FC, useMemo, useRef } from 'react'
import { IEntityType, IWeek } from 'shared/types'
import _ from 'lodash'
import RowActionButtons from 'components/RowActionButtons'
import { ReactComponent as IconChevronRight } from 'shared/assets/bsChevronRight.svg'
import { arrayMove } from 'shared/utils/array'
import DeleteConfirmation, {
  IDeleteConfirmationModal
} from 'modals/DeleteConfirmation'
import get from 'lodash/get'
import map from 'lodash/map'
import { dbDuplicateWeekTo, duplicateWeek } from 'controllers/entity'
import { ReactComponent as IconMove } from 'shared/assets/bsBoxArrowLeft.svg'
import { useSelector } from 'model/hooks'

type Props = {
  content: IWeek[]
  updateContent: (c: IWeek[]) => void
  selectedWeek?: string
  setSelectedWeek: (weekId: string) => void
  entityId: string
  entityType: IEntityType
}

type IEntityBase = {
  id: string
  title: string
  content?: IWeek[]
  createdAt: number
}

const EntityContentWeeks: FC<Props> = ({
  content,
  selectedWeek,
  setSelectedWeek,
  updateContent,
  entityId,
  entityType
}) => {
  const deleteModalRef = useRef<IDeleteConfirmationModal>(null)
  const entitiesRaw = useSelector(state => get(state, entityType)) as Record<
    string,
    IEntityBase
  > | null

  const entities = useMemo(() => {
    const filtered = _.filter(entitiesRaw, e => e.id !== entityId)
    return _.orderBy(filtered, 'createdAt', 'desc')
  }, [entitiesRaw, entityId])

  const addWeek = (title: string) => {
    const id = generateId()
    const w: IWeek = {
      id,
      title,
      days: [],
      enabled: false
    }
    const newContent = [...content, w]
    updateContent(newContent)
  }

  const onDelete = (id?: string) => {
    if (id) {
      const newContent = _.filter(content, w => w.id !== id)
      updateContent(newContent)
    }
  }

  const onMove = (i: number, isUp: boolean) => {
    const newContent = arrayMove(content, i, isUp ? i - 1 : i + 1)
    updateContent(newContent)
  }

  const onToggleDisabled = (weekNum: number) => {
    const newContent = map(content, (w, index) => {
      if (index === weekNum) {
        return { ...w, enabled: !w.enabled }
      } else {
        return w
      }
    })
    updateContent(newContent)
  }

  const onDuplicateWeek = (wId: string) => {
    console.log('onDuplicateWeek', wId)
    duplicateWeek(entityId, entityType, wId)
  }

  const onMoveTo = (toEntityId: string, weekId: string) => {
    dbDuplicateWeekTo(toEntityId, entityType, entityId, weekId)
  }

  const renderMoveMenuList = (weekId: string) => {
    return (
      <MenuList textStyle={'small'} overflowY='auto' maxH='lg'>
        {entities.map((e, wNum) => {
          return (
            <MenuItem pl={4} key={e.id} onClick={() => onMoveTo(e.id, weekId)}>
              {e.title}
            </MenuItem>
          )
        })}
      </MenuList>
    )
  }

  const renderWeek = (w: IWeek, i: number) => {
    const lAmount = _.size(w.days)
    return (
      <HStack
        key={w.id}
        _hover={{ bg: 'wood.100' }}
        role='group'
        w='full'
        rounded={'4px'}
        pl={2}
        pr={1}
        py={1}
        bg={selectedWeek === w.id ? 'wood.100' : undefined}
        as='button'
        onClick={() =>
          selectedWeek !== w.id ? setSelectedWeek(w.id) : undefined
        }
      >
        <VStack w='full' align={'flex-start'} spacing={1}>
          <Text textStyle={'small'} textAlign={'left'}>
            {w.title}
          </Text>
          <Text textStyle='tag' color='darkGray'>
            Уроков: {lAmount}
          </Text>
        </VStack>
        <Switch
          size='sm'
          isChecked={get(w, 'enabled', false)}
          onChange={() => onToggleDisabled(i)}
          colorScheme='wood'
        />
        <RowActionButtons
          isFirst={i === 0}
          isLast={i === content.length - 1}
          onMove={(isUp: boolean) => onMove(i, isUp)}
          // onEdit={() => null}
          onDuplicate={() => onDuplicateWeek(w.id)}
          onDelete={() => {
            console.log('on Delete click')
            deleteModalRef.current?.open(w.id)
          }}
        >
          <Menu placement='auto-start' preventOverflow={false} strategy='fixed'>
            <MenuButton w='full'>
              <MenuItem
                textStyle={'small'}
                icon={<IconMove width={14} />}
                w='full'
              >
                Перенести в
              </MenuItem>
            </MenuButton>
            {renderMoveMenuList(w.id)}
          </Menu>
        </RowActionButtons>
        <IconChevronRight />
      </HStack>
    )
  }

  const renderWeeks = () => {
    return (
      <VStack spacing={1} w='full'>
        {_.map(content, renderWeek)}
      </VStack>
    )
  }

  return (
    <VStack flex={1} p={4} align={'flex-start'}>
      {renderWeeks()}
      <VStack w='full' pt={4} align='flex-start'>
        <AddTextField
          buttonTitle='+ Добавить неделю'
          placeholder='Название недели'
          onSubmit={addWeek}
        />
      </VStack>
      <DeleteConfirmation
        ref={deleteModalRef}
        title='Удаление недели'
        description={`Вы собираетесь удалить неделю и все ее содержимое. Это действие нельзя отменить.`}
        onDelete={onDelete}
      />
    </VStack>
  )
}

export default EntityContentWeeks
