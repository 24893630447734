import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { HStack, Text } from '@chakra-ui/react'

type Props = {
  endTime?: number
  onEnded: () => void
}

const ExaminationTimer: FC<Props> = ({ endTime, onEnded }) => {
  const [remainingTime, setRemainigTime] = useState(
    endTime ? Math.floor((endTime - Date.now()) / 1000) : 0
  )
  const timeoutRef = useRef<number>(0)

  useEffect(() => {
    if (remainingTime <= 0) {
      clearInterval(timeoutRef.current)
      onEnded()
    }
  }, [remainingTime])

  const calcRemainigTime = useCallback(() => {
    if (endTime) {
      const newRemainigTime = Math.ceil((endTime - Date.now()) / 1000)
      if (newRemainigTime !== remainingTime) {
        setRemainigTime(newRemainigTime)
      }
    }
  }, [remainingTime, endTime])

  useEffect(() => {
    if (endTime) {
      timeoutRef.current = window.setInterval(calcRemainigTime, 100)
    }
    return () => clearInterval(timeoutRef.current)
  }, [endTime, calcRemainigTime])

  const h = Math.floor(remainingTime / 3600)
  const m = Math.floor((remainingTime - h * 3600) / 60)
  const s = Math.floor(remainingTime % 60)

  const renderT = (v: number) => {
    return v < 10 ? `0${v}` : v
  }

  return (
    <HStack
      rounded={'full'}
      borderWidth={1}
      borderColor='midGray'
      py={2}
      px={4}
      boxShadow={'menu'}
      position={'sticky'}
      top={'60px'}
      bg='white'
    >
      <Text>
        {h > 0 ? `${renderT(h)} ч : ` : ''}
        {m > 0 ? `${renderT(m)} мин : ` : ''}
        {renderT(s)} сек
      </Text>
    </HStack>
  )
}

export default ExaminationTimer
