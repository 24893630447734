import { SectionType } from 'shared/types'
import get from 'lodash/get'

export const isTestSection = (t: SectionType) => {
  const dict = {
    [SectionType.TEST_ONE_OPTION]: true,
    [SectionType.TEST_TRUE_FALSE]: true,
    [SectionType.TEST_TEXT_ANSWER]: true,
    [SectionType.TEST_BUTTONS]: true,
    [SectionType.TEST_ADD_WORDS]: true,
    [SectionType.TEST_INPUT_ANSWER]: true,
    [SectionType.TEST_ADD_LETTERS]: true,
    [SectionType.TEST_SYNC_TRANSLATE]: true
  }
  return get(dict, t, false)
}

export const sectionName: Record<SectionType, string> = {
  [SectionType.TEACHERS]: 'Преподаватели',
  [SectionType.HEADING]: 'Заголовок',
  [SectionType.TEXT_LIST]: 'Строки с разделителем',
  [SectionType.COLUMNS]: 'Колонки с карточками',
  [SectionType.COLUMNS_VIDEO]: 'Колонки c видео',
  [SectionType.COLUMNS_IMAGE]: 'Колонки c изображениями',
  [SectionType.TEXT]: 'Текстовый блок во всю ширину экрана',
  [SectionType.TWO_COLUMNS_TEXT]: 'Заголовок и текст в две колонки',
  [SectionType.TWO_COLUMNS_VIMEO]: 'Заголовок и видео в две колонки',
  [SectionType.VIMEO]: 'Vimeo video',
  [SectionType.YOUTUBE]: 'Youtube video',
  [SectionType.DIVIDER]: 'Горизонтальный разделитель',
  [SectionType.BUTTON]: 'Кнопка',
  [SectionType.AUDIO]: 'Аудио',
  [SectionType.TEST_ONE_OPTION]: 'Тест: выбор варианта',
  [SectionType.TEST_TRUE_FALSE]: 'Тест: верно/неверно',
  [SectionType.TEST_TEXT_ANSWER]: 'Тест: перевод текста',
  [SectionType.TEST_INPUT_ANSWER]: 'Тест: перевод слова/фразы',
  [SectionType.TEST_BUTTONS]: 'Тест: кнопки',
  [SectionType.TEST_ADD_WORDS]: 'Тест: пропущенные слова',
  [SectionType.TEST_ADD_LETTERS]: 'Тест: пропущенные буквы',
  [SectionType.TEST_SYNC_TRANSLATE]: 'Тест: параллельный перевод',
  [SectionType.SYNC_TRANSLATE]: 'Параллельный перевод',
  [SectionType.WORD_CARD]: 'Карточка слова'
}

export default {
  sectionName,
  isTestSection
}
