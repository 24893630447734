import { FC } from 'react'
import {
  Text,
  Image,
  VStack,
  Center,
  Box,
  Stack,
  Tag,
  Button,
  Tooltip
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

type Props = {
  url: string
  title: string
  imageUrl: string
  desc: string[]
  lessonsAmount: number
  lessonsAmountDesc?: string
  closed?: boolean
  invisible?: boolean
}

const EntityCard: FC<Props> = ({
  url,
  title,
  imageUrl,
  desc,
  lessonsAmount,
  lessonsAmountDesc = 'Уроки',
  closed,
  invisible
}) => {
  const navigate = useNavigate()

  return (
    <Center h='full'>
      <Box
        w={'280px'}
        // w={'full'}
        h='full'
        bg={'white'}
        boxShadow={'2xl'}
        rounded={'md'}
        overflow={'hidden'}
      >
        <Image h={'120px'} w={'full'} src={imageUrl} objectFit={'cover'} />

        <Box p={4}>
          <Stack spacing={0} align={'center'} mb={2}>
            <Tooltip label={title}>
              <Text
                textStyle='body'
                textAlign={'center'}
                lineHeight={1.2}
                noOfLines={3}
                h='16'
              >
                {title}
              </Text>
            </Tooltip>
          </Stack>

          <Stack
            direction={'row'}
            justify={'space-between'}
            align='center'
            spacing={2}
          >
            <Stack spacing={0} align={'center'} flex={1}>
              <Text textStyle='body' fontWeight={500}>
                {lessonsAmount}
              </Text>
              <Text textStyle={'small'} color={'darkGray'}>
                {lessonsAmountDesc}
              </Text>
            </Stack>

            <VStack spacing={1} flex={1} flexGrow={1.5}>
              {desc.map((d, i) => (
                <Text key={i} textStyle='tag' color='darkGray' noOfLines={1}>
                  {d}
                </Text>
              ))}
            </VStack>
            {closed !== undefined && invisible !== undefined && (
              <VStack spacing={0.5} flex={1}>
                {closed !== undefined && (
                  <Tag
                    size='sm'
                    variant='subtle'
                    colorScheme={closed ? 'gray' : 'green'}
                  >
                    {closed ? 'Closed' : 'Opened'}
                  </Tag>
                )}
                {invisible !== undefined && (
                  <Tag
                    size='sm'
                    variant='subtle'
                    colorScheme={invisible ? 'gray' : 'green'}
                  >
                    {invisible ? 'Invisible' : 'Visible'}
                  </Tag>
                )}
              </VStack>
            )}
          </Stack>

          <Button
            w={'full'}
            mt={8}
            variant={'outline'}
            // colorScheme='gray'
            rounded={'full'}
            _hover={{
              transform: 'translateY(-2px)',
              boxShadow: 'lg'
            }}
            onClick={() => navigate(url)}
          >
            Открыть
          </Button>
        </Box>
      </Box>
    </Center>
  )
}

export default EntityCard
