import { FC } from 'react'
import WordCard from 'shared/components/WordCard'
import { ISectionWordCard, IWordCard, IWordCardTag } from 'shared/types'
import get from 'lodash/get'

type Props = {
  s: ISectionWordCard
  card: IWordCard
  cardTags: Record<string, IWordCardTag> | null
  cardStatuses?: Record<string, boolean>
}

const SectionText: FC<Props> = ({ card, cardTags, cardStatuses }) => {
  const status = get(cardStatuses, card.id)
  return <WordCard card={card} cardTags={cardTags} status={status} />
}

export default SectionText
