import { ReactNode, useContext, useMemo, FC } from 'react'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex
} from '@chakra-ui/react'
import find from 'lodash/find'
import { useNavigate, useParams } from 'react-router-dom'
import entityName from 'shared/utils/entityName'
import { ReactComponent as IconChevronRight } from 'shared/assets/bsChevronRight.svg'
import { IEntity, IEntityType } from 'shared/types'

type Props = {
  entity: IEntity
  entityType: IEntityType
  weekId?: string
  dayId?: string
  lessonId?: string
}

const LessonBreadcrumb: FC<Props> = ({
  entity,
  entityType,
  weekId,
  dayId,
  lessonId
}) => {
  const week = useMemo(() => {
    if (entity && entity.content && weekId) {
      return find(entity.content, w => w.id === weekId)
    }
  }, [entity, weekId])

  const day = useMemo(() => {
    if (week && dayId) {
      return find(week.days, d => d.id === dayId)
    }
  }, [week, dayId])

  const navigate = useNavigate()

  const renderItems = () => {
    const res: ReactNode[] = [
      <BreadcrumbItem key='home'>
        <BreadcrumbLink onClick={() => navigate(`/profile/${entityType}`)}>
          {entityName[entityType]}
        </BreadcrumbLink>
      </BreadcrumbItem>
    ]
    if (entity) {
      res.push(
        <BreadcrumbItem key={entity.id} isCurrentPage={!week}>
          <BreadcrumbLink
            onClick={
              week
                ? () => navigate(`/profile/${entityType}/${entity.id}`)
                : undefined
            }
          >
            {day ? '...' : entity.title}
          </BreadcrumbLink>
        </BreadcrumbItem>
      )
    }

    if (entity && week) {
      res.push(
        <BreadcrumbItem key={week.id} isCurrentPage={!day}>
          <BreadcrumbLink
            noOfLines={2}
            onClick={
              week
                ? () =>
                    navigate(`/profile/${entityType}/${entity.id}/${week.id}`)
                : undefined
            }
          >
            {lessonId ? '...' : week.title}
          </BreadcrumbLink>
        </BreadcrumbItem>
      )
    }

    if (entity && week && day) {
      res.push(
        <BreadcrumbItem key={day.id} isCurrentPage={!lessonId}>
          <BreadcrumbLink
            noOfLines={2}
            onClick={
              week
                ? () =>
                    navigate(
                      `/profile/${entityType}/${entity.id}/${week.id}/${day.id}`
                    )
                : undefined
            }
          >
            {day.title}
          </BreadcrumbLink>
        </BreadcrumbItem>
      )
    }

    return res
  }

  return (
    <Flex w='full'>
      <Breadcrumb
        separator={<IconChevronRight color='wood.600' width={16} />}
        spacing={{ base: 0.5, lg: 2 }}
        color='wood.600'
        textStyle='tag'
        fontSize={{ base: '10px', lg: '12px' }}
      >
        {renderItems()}
      </Breadcrumb>
    </Flex>
  )
}

export default LessonBreadcrumb
