import {
  ButtonProps,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react'

import { ReactComponent as IconDots } from 'shared/assets/bsDotsVertical.svg'
import { ReactComponent as IconEdit } from 'shared/assets/bsEdit.svg'
import { ReactComponent as IconArrowUp } from 'shared/assets/bsArrowUp.svg'
import { ReactComponent as IconArrowDown } from 'shared/assets/bsArrowDown.svg'
import { ReactComponent as IconDuplicate } from 'shared/assets/bsDuplicate.svg'
import { ReactComponent as IconTrash } from 'shared/assets/bsTrash.svg'
import { FC, ReactNode } from 'react'

type Props = {
  isFirst?: boolean
  isLast?: boolean
  onMove?: (isUp: boolean) => void
  onDuplicate?: () => void
  onDelete?: () => void
  onEdit?: () => void
  editButtonProps?: ButtonProps
  children?: ReactNode
}

const RowActionButtons: FC<Props> = ({
  isFirst,
  isLast,
  onMove,
  onDuplicate,
  onDelete,
  onEdit,
  editButtonProps,
  children
}) => {
  const size = 'xs'
  const variant = 'ghost'
  return (
    <HStack
      visibility={'hidden'}
      _groupHover={{ visibility: 'visible' }}
      justify={'flex-end'}
      // pr={0}
      spacing={0}
    >
      {onEdit && (
        <IconButton
          variant={variant}
          size={size}
          icon={<IconEdit width={14} />}
          aria-label='edit'
          onClick={onEdit}
          {...editButtonProps}
        />
      )}
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label='Options'
          size={size}
          icon={<IconDots width={14} />}
          variant={variant}
        />
        <MenuList>
          {onMove && (
            <MenuItem
              textStyle={'small'}
              icon={<IconArrowUp width={14} />}
              isDisabled={isFirst}
              onClick={() => onMove(true)}
            >
              Подвинуть вверх
            </MenuItem>
          )}
          {onMove && (
            <MenuItem
              textStyle={'small'}
              icon={<IconArrowDown width={14} />}
              isDisabled={isLast}
              onClick={() => onMove(false)}
            >
              Подвинуть вниз
            </MenuItem>
          )}
          {onDuplicate && (
            <MenuItem
              textStyle={'small'}
              icon={<IconDuplicate width={14} />}
              onClick={onDuplicate}
            >
              Дублировать
            </MenuItem>
          )}
          {children}
          {onDelete && (
            <MenuItem
              color={'red'}
              textStyle={'small'}
              icon={<IconTrash width={14} />}
              onClick={onDelete}
            >
              Удалить
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </HStack>
  )
}

export default RowActionButtons
