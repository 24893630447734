import { useRef, useState, FC, ReactNode, useEffect } from 'react'
import {
  Flex,
  Progress,
  VStack,
  Center,
  Text,
  HStack,
  Button
} from '@chakra-ui/react'
import { FileT, StorageFileT, StorageT } from 'shared/types'
import { useDropzone, DropzoneOptions } from 'react-dropzone'
import { AttachmentIcon } from '@chakra-ui/icons'
import dayjs from 'dayjs'
import startsWith from 'lodash/startsWith'
import ceil from 'lodash/ceil'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import { ReactComponent as IconUpload } from 'shared/assets/bsUpload.svg'

type Props = {
  onComplete: (files: FileT[]) => void
  storagePath: string
  userId: string
  buttonTitle?: string
  fileProcessor?: (file: File) => Promise<File>
  thumbnailSize?: number
  storage: StorageT
  generateId: () => string
  children?: ReactNode
  options?: DropzoneOptions
  hideProgress?: boolean
  onFilesPicked?: (files: File[]) => void
  isPrivate?: boolean
  addPrefixToFilename?: boolean
  isLoading?: boolean
}

const FilesPickerButton: FC<Props> = ({
  onComplete,
  storagePath,
  userId,
  buttonTitle,
  fileProcessor,
  thumbnailSize,
  storage,
  generateId,
  children,
  options,
  hideProgress = false,
  onFilesPicked,
  isPrivate = false,
  addPrefixToFilename = false,
  isLoading
}) => {
  const filePickerRef = useRef<HTMLInputElement>(null)
  const [progress, setProgress] = useState<number | undefined>()
  const [inputKey, setInputKey] = useState(+dayjs())
  const [cancelled, setCancelled] = useState(false)
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles
  } = useDropzone(options)

  const openFilePicker = () => {
    setCancelled(false)
    filePickerRef?.current?.click()
  }

  const mimeIsImage = (contentType: string = '') => {
    return startsWith(contentType, 'image')
  }

  const processFiles = async (files: File[]) => {
    const count = files.length
    const res: FileT[] = []
    if (onFilesPicked) {
      onFilesPicked(files)
      setInputKey(+dayjs())
    } else {
      for (const i in files) {
        const fileRaw = fileProcessor ? await fileProcessor(files[i]) : files[i]
        if (cancelled) return
        console.log(i, 'fileRaw ===>', fileRaw)
        const id = generateId()
        const fnameRaw = get(fileRaw, 'name')
        const fname = addPrefixToFilename
          ? `${generateId()}_${fnameRaw}`
          : fnameRaw
        const p = `${storagePath}/${fname}`

        const onProgress = (v: number) => {
          console.log('onProgress', v)
          setProgress(ceil(v / count + (Number(i) / count) * 100))
        }
        const fileInfo: StorageFileT | undefined = await storage.saveFileObject(
          fileRaw,
          p,
          onProgress,
          isPrivate
        )
        console.log('onFilesPicked: fileInfo', fileInfo)
        const pFile: FileT = {
          id,
          createdAt: +dayjs() + Number(i),
          name: fname,
          createdBy: userId,
          ...(fileInfo || {})
        }
        if (mimeIsImage(fileRaw.type) && thumbnailSize) {
          const thumbnail: any = await storage.createImageThumbnail(
            fileRaw,
            thumbnailSize
          )
          const thumbnailUrl: string | undefined = get(
            await storage.saveFileObject(thumbnail, `${p}_thumbnail`),
            'url'
          )
          if (thumbnailUrl) {
            pFile.thumbnailUrl = thumbnailUrl
          }
        }
        console.log('push file to list', pFile)
        res.push(pFile)
      }

      console.log('%cres', 'color:green', res)
      setInputKey(+dayjs())

      setProgress(undefined)
      if (cancelled) {
        // deleteFiles(res)
      } else {
        onComplete(res)
      }
    }
  }

  useEffect(() => {
    processFiles(acceptedFiles)
  }, [acceptedFiles])

  // const onFilesPicked = async (event: ChangeEvent) => {
  //   const files: File[] = _.values(_.get(event, 'target.files'))
  //   await processFiles(files)
  // }

  const renderUploadFileButtonSecondary = (isDragActive: boolean) => {
    if (!hideProgress && !isNil(progress)) {
      return (
        <VStack
          borderWidth={0.5}
          borderColor={isDragActive ? 'blue.500' : 'gray.200'}
          w='56'
          h='32'
          rounded={'base'}
          _hover={{ bg: 'gray.50', cursor: 'pointer' }}
          align='center'
          justify={'center'}
        >
          <Text fontSize={'xs'} color='gray.500'>
            Uploading ({progress}%)
          </Text>
          <Progress
            w={32}
            size={'xs'}
            value={progress}
            isIndeterminate={progress === 0}
          />
        </VStack>
      )
    } else if (children) {
      return (
        <Flex
          as='button'
          w='full'
          onClick={openFilePicker}
          disabled={options && options.disabled}
          h='fit-content'
          flexShrink={0}
        >
          {children}
        </Flex>
      )
    } else {
      return (
        <VStack
          borderWidth={0.5}
          borderColor={isDragActive ? 'blue.500' : 'gray.200'}
          w='56'
          h='32'
          rounded={'base'}
          _hover={{ bg: 'gray.50', cursor: 'pointer' }}
          align='center'
          justify={'center'}
          outline='none'
        >
          <Center w={6} h={6} rounded='base' bg='gray.100' color='gray.600'>
            <AttachmentIcon />
          </Center>
          <VStack spacing={0}>
            <Text fontSize={'xs'} color='gray.500'>
              <Text as='span' color='blue.400'>
                Click to upload
              </Text>{' '}
              or drag and drop
            </Text>
            <Text fontSize={'xs'} color='gray.400'>
              {buttonTitle}
            </Text>
          </VStack>
        </VStack>
      )
    }
  }

  return (
    <HStack {...getRootProps()}>
      <Button
        variant={'outline'}
        leftIcon={<IconUpload width={12} height={12} />}
        size='sm'
        isLoading={isLoading || progress !== undefined}
      >
        Upload
      </Button>
      <input {...getInputProps()} id='file-input' />
    </HStack>
  )

  // return (
  //   <Dropzone
  //     // innerRef={filePickerRef}
  //     key={inputKey}
  //     onDrop={acceptedFiles => processFiles(acceptedFiles)}
  //     onError={e => alert(`error, ${JSON.stringify(e)}`)}
  //     // useFsAccessApi={false}
  //     // noClick={true}
  //     {...options}
  //   >
  //     {({ getRootProps, getInputProps, isDragActive }) => (
  //       <div {...getRootProps()} style={{ width: '100%' }}>
  //         {renderUploadFileButtonSecondary(isDragActive)}
  //         <input {...getInputProps()} id='file-input' />
  //       </div>
  //     )}
  //   </Dropzone>
  // )
}

export default FilesPickerButton
