import { useNavigate } from 'react-router-dom'
import { FC } from 'react'
import BackButtonSimple from 'shared/components/BackButtonSimple'

type Props = {
  backUrl?: string
}

const BackButton: FC<Props> = ({ backUrl }) => {
  const navigate = useNavigate()
  return (
    <BackButtonSimple
      onClick={() => (backUrl ? navigate(backUrl) : navigate(-1))}
    />
  )
}

export default BackButton
