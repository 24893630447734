import { FC } from 'react'
import { Divider } from '@chakra-ui/react'

type Props = {}

const SectionDivider: FC<Props> = () => {
  return <Divider my={2} />
}

export default SectionDivider
