import { FC } from 'react'

import {
  FormLabel,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  VStack
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import Label from 'shared/components/Label'

type Props = {
  value: number
  onChange: (v: number) => void
}

const DurationInput: FC<Props> = ({ value, onChange }) => {
  const days = dayjs.duration(value).days()
  const hours = dayjs.duration(value).hours()
  const minutes = dayjs.duration(value).minutes()

  const onDays = (n: number) => {
    console.log('onDays', n)
    if (isNaN(n)) return
    const v =
      minutes * 60 * 1000 + hours * 60 * 60 * 1000 + n * 24 * 60 * 60 * 1000
    onChange(v)
  }

  const onHours = (n: number) => {
    if (isNaN(n)) return
    const v =
      minutes * 60 * 1000 + n * 60 * 60 * 1000 + days * 24 * 60 * 60 * 1000
    onChange(v)
  }

  const onMinutes = (n: number) => {
    if (isNaN(n)) return
    const v =
      n * 60 * 1000 + hours * 60 * 60 * 1000 + days * 24 * 60 * 60 * 1000
    onChange(v)
  }

  return (
    <HStack>
      <VStack spacing={0} align='flex-start'>
        <Label>Дни</Label>
        <NumberInput
          value={days}
          size='sm'
          min={0}
          max={500}
          step={1}
          onChange={(s, n) => onDays(n)}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </VStack>
      <VStack spacing={0} align='flex-start'>
        <Label>Часы</Label>
        <NumberInput
          value={hours}
          size='sm'
          min={0}
          max={23}
          step={1}
          onChange={(s, n) => onHours(n)}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </VStack>
      <VStack spacing={0} align='flex-start'>
        <Label>Минуты</Label>
        <NumberInput
          value={minutes}
          size='sm'
          min={0}
          max={59}
          step={1}
          onChange={(s, n) => onMinutes(n)}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </VStack>
    </HStack>
  )
}

export default DurationInput
