import { AbsoluteCenter, Box, Divider, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { FC } from 'react'

type Props = {
  timestamp: number
}

const DateElement: FC<Props> = ({ timestamp }) => {
  return (
    <Box position='relative' w='full' py={6}>
      <Divider />
      <AbsoluteCenter bg='white' px='4'>
        <Text textStyle='tag' color='darkGray'>
          {dayjs(timestamp).format('D MMM, YYYY')}
        </Text>
      </AbsoluteCenter>
    </Box>
  )
}

export default DateElement
