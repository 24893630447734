import {
  VStack,
  HStack,
  FormControl,
  FormLabel,
  Text,
  Tag
} from '@chakra-ui/react'
import { ChangeEvent, FC, useState, useEffect } from 'react'
import { AutoResizeTextarea } from 'shared/components/chat/AutoResizeTextarea'
import { ITestContent } from 'shared/types'
import map from 'lodash/map'
import trim from 'lodash/trim'

type Props = {
  test: ITestContent
  onChange: (v: ITestContent) => void
}

const EditTestAddLetters: FC<Props> = ({ test, onChange }) => {
  const [text, setText] = useState(test.text || '')
  const [matches, setMatches] = useState<string[]>(
    (test.text || '').match(/\{(.*?)\}/g) || []
  )

  useEffect(() => {
    onChange({ ...test, text })
  }, [text])

  return (
    <VStack w='full' align={'flex-start'} spacing={4}>
      <HStack w='full'>
        <FormControl>
          <Text textStyle='small' color='black' fontWeight={450} pb={2}>
            Оберните нужные буквы в фигурные скобки <b>{'I{n}tera{ct}ion'}</b>,
            а чтобы оставить текст без ячеек используйте квадратные скобки вот
            так <b>[этот текст будет в задании без изменений]</b>
          </Text>

          <AutoResizeTextarea
            value={text}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
              setText(e.target.value)
              const m = e.target.value.match(/\{(.*?)\}/g)
              setMatches(m || [])
            }}
          />
        </FormControl>
      </HStack>
      <VStack pt={4} w='full' align='flex-start'>
        <Text textStyle='small' fontWeight={700} color='darkGray'>
          В тесте будут пропущены следующие буквы:
        </Text>
        <HStack w='full'>
          {map(matches, (m, i) => (
            <Tag key={i} size={'sm'} variant='solid' colorScheme='teal'>
              {trim(m, '{}')}
            </Tag>
          ))}
        </HStack>
      </VStack>
    </VStack>
  )
}

export default EditTestAddLetters
