import { createReducer } from '@reduxjs/toolkit'
import { IConference } from 'shared/types'
import { receiveConferences } from 'src/model/actions'

const initialState = null as Record<string, IConference> | null

const reducer = createReducer(initialState, builder => {
  builder.addCase(receiveConferences, (state, action) => action.payload)
})

export default reducer
