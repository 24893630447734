import { useMemo } from 'react'
import { Button, HStack, VStack, Wrap, WrapItem } from '@chakra-ui/react'
import NavBar from 'components/NavBar'
import { useSelector } from 'model/hooks'
import map from 'lodash/map'
import { IConference } from 'shared/types'
import imageDefault from 'shared/assets/lesson-default.png'
import { AddIcon } from '@chakra-ui/icons'
import { useNavigate } from 'react-router-dom'
import orderBy from 'lodash/orderBy'
import EntityCard from 'components/EntityCard'
import dayjs from 'dayjs'
import size from 'lodash/size'
import { dbCreateConference } from 'controllers/conferences'
import { createLabel } from 'shared/utils/format'

const Conferences = () => {
  const navigate = useNavigate()
  const conferences = useSelector(state => state.conferences)

  const sortedConferences = useMemo(() => {
    return orderBy(conferences, 'createdAt', 'desc')
  }, [conferences])

  const renderCard = (c: IConference) => {
    return (
      <WrapItem key={c.id}>
        <EntityCard
          url={`/conferences/${c.id}`}
          title={c.title}
          imageUrl={imageDefault}
          desc={[
            dayjs(c.startDate).format('MMM D, YYYY'),
            dayjs(c.endDate).format('MMM D, YYYY')
          ]}
          lessonsAmount={size(c.lessons)}
          lessonsAmountDesc={createLabel(size(c.lessons), [
            'Урок',
            'Урока',
            'Уроков'
          ])}
        />
      </WrapItem>
    )
  }

  const onCreateClick = async () => {
    const id = await dbCreateConference()
    navigate(`/conferences/${id}`)
  }

  const navBarActions = (
    <HStack>
      <Button colorScheme='teal' leftIcon={<AddIcon />} onClick={onCreateClick}>
        Создать конференцию
      </Button>
    </HStack>
  )

  return (
    <VStack w='full' h='full' spacing={0}>
      <NavBar hideBackButton title='Конференции'>
        {navBarActions}
      </NavBar>
      <VStack align={'flex-start'} w='full' h='full' p={4} overflowY={'auto'}>
        <Wrap spacing={4}>{map(sortedConferences, renderCard)}</Wrap>
      </VStack>
    </VStack>
  )
}

export default Conferences
