import {
  onSnapshot,
  query,
  collection,
  doc,
  setDoc,
  updateDoc,
  writeBatch
} from 'firebase/firestore'
import { db, dbOmit, generateId } from 'src/controllers/db'
import { IEntityType, ILesson, IWebinar } from 'shared/types'
import { receiveWebinars } from 'src/model/actions'
import store from 'src/model/store'
import { addListener } from 'controllers/listeners'
import dayjs from 'dayjs'

export const dbFetchWebinars = async () => {
  try {
    const q = query(collection(db, 'webinars'))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: Record<string, IWebinar> = {}
        sn.forEach(doc => {
          const p = doc.data() as IWebinar
          res[doc.id] = { ...p, id: doc.id }
        })
        store.dispatch(receiveWebinars(res))
      },
      err => {
        console.log(`dbFetchWebinars error: ${err.message}`)
      }
    )
    addListener('webinars', unsubscribe)
  } catch (e) {
    console.error('dbFetchWebinars error', e)
  }
}

export const dbCreateWebinar = async () => {
  const id = generateId()
  const c: IWebinar = {
    id,
    title: 'Новый вебинар',
    invisible: true,
    closed: true,
    price: 0,
    lessons: [],
    sections: {},
    sectionsOrder: [],
    createdAt: +dayjs()
  }
  const ref = doc(collection(db, 'webinars'), id)
  await setDoc(ref, dbOmit(c))
  return id
}

export const dbUpdateWebinar = async (
  webinarId: string,
  upd: Partial<IWebinar>
) => {
  try {
    console.log('dbUpdateWebinar', webinarId, upd)
    const ref = doc(collection(db, 'webinars'), webinarId)
    await updateDoc(ref, dbOmit(upd))
  } catch (e) {
    console.log('dbUpdateWebinar error', e)
  }
}

export const dbDeleteWebinar = async (c: IWebinar) => {
  const batch = writeBatch(db)
  batch.delete(doc(collection(db, 'webinars'), c.id))
  if (c.lessons) {
    for (const lId of c.lessons) {
      batch.delete(doc(collection(db, 'lessons'), lId))
    }
  }
  await batch.commit()
}

export const dbDuplicateWebinar = async (
  c: IWebinar,
  lessons: Record<string, ILesson>
) => {
  const batch = writeBatch(db)
  const webinarId = generateId()
  const newWebinar: IWebinar = {
    ...c,
    id: webinarId,
    lessons: [],
    closed: true,
    invisible: true,
    createdAt: +dayjs(),
    title: c.title + ' (copy)',
    copyOf: {
      entityId: c.id,
      entityType: IEntityType.WEBINARS
    }
  }
  if (c.lessons) {
    for (const lId of c.lessons) {
      const l = lessons[lId]
      if (l) {
        const newL: ILesson = {
          ...l,
          id: generateId(),
          entityId: webinarId
        }
        newWebinar.lessons.push(newL.id)
        batch.set(doc(collection(db, 'lessons'), newL.id), newL)
      }
    }
  }

  batch.set(doc(collection(db, 'webinars'), webinarId), newWebinar)
  await batch.commit()
  return webinarId
}
