import { VStack, Flex, Heading } from '@chakra-ui/react'
import SigninForm from 'src/pages/signin/SigninForm'

const SignIn = () => {
  return (
    <VStack w='full' h='full' bg='white' justify={'center'}>
      <Heading>Admin of TheQueenEnglish</Heading>
      <Flex pt={{ base: 8, lg: 12 }} pb={'20'} w='full' justify='center'>
        <SigninForm />
      </Flex>
    </VStack>
  )
}

export default SignIn
