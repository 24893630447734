import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useDisclosure
} from '@chakra-ui/react'
import {
  useRef,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  ReactNode
} from 'react'

export interface IDeleteConfirmationModal {
  open: (id?: string) => void
}

type Props = {
  title: string
  description: ReactNode
  onDelete: (id?: string) => void
}

const DeleteConfirmation: ForwardRefRenderFunction<
  IDeleteConfirmationModal,
  Props
> = ({ title, description, onDelete }, ref) => {
  const cancelRef = useRef(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [id, setId] = useState<string | undefined>()

  useImperativeHandle(ref, () => ({
    open: (id?: string) => {
      setId(id)
      onOpen()
    }
  }))

  useEffect(() => {
    if (!isOpen) {
      setId(undefined)
    }
  }, [isOpen])

  return (
    <AlertDialog
      motionPreset='slideInBottom'
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>{title}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>{description}</AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose}>
            Отмена
          </Button>
          <Button
            colorScheme='red'
            variant={'solid'}
            bg='red'
            ml={3}
            onClick={() => {
              onDelete(id)
              onClose()
            }}
          >
            Удалить
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default forwardRef(DeleteConfirmation)
