import { FC } from 'react'
import { HStack, Text, Flex, VStack } from '@chakra-ui/react'
import { ReactComponent as Logo } from '../assets/crrown.svg'

type Props = {
  message?: string
}

const NotFound: FC<Props> = ({ message }) => {
  return (
    <HStack w='full' h='80vh' align='center' justify={'center'}>
      <VStack>
        <HStack spacing={2}>
          <Flex
            h={{ base: '22px', lg: 7 }}
            w={{ base: '22px', lg: 7 }}
            bg='white'
            rounded={'full'}
            justify={'center'}
            align='center'
            px={0.5}
          >
            <Logo />
          </Flex>
          <Text
            color='black'
            fontWeight={'medium'}
            fontSize={{ base: 'xl', lg: '2xl' }}
            letterSpacing={{ base: '-1px', lg: '-1.3px' }}
          >
            thequeenenglish
          </Text>
        </HStack>
        <Text
          color='black'
          fontSize={{ base: 'sm', lg: 'lg' }}
          lineHeight={'119%'}
          fontWeight={{ base: 'medium', lg: 'normal' }}
        >
          {message}
        </Text>
      </VStack>
    </HStack>
  )
}

export default NotFound
