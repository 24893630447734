import { FC } from 'react'
import { VStack } from '@chakra-ui/react'
import { FileT, IPadding, ISectionAudio } from 'shared/types'
import EditableReachField from 'components/EditableReachField'
import PaddingSelect from 'components/PaddingSelect'
import Label from 'shared/components/Label'
import FilesPickerButton from 'components/FilesPickerButton'
import { generateId, storageRef } from 'controllers/db'
import AudioPlayer from 'shared/components/AudioPlayer'
import storage from 'controllers/storage'
import { dbProcessAudioFile } from 'controllers/files'

type Props = {
  entityId: string
  s: ISectionAudio
  onChange: (s: ISectionAudio) => void
}

const EditSectionAudio: FC<Props> = ({ s, onChange, entityId }) => {
  const renderTitle = () => {
    return (
      <VStack w='full' align='start'>
        <Label>Заголовок</Label>
        <VStack
          w='full'
          spacing={2}
          borderWidth={1}
          borderColor='wood.600'
          px={2}
          rounded='4px'
          flexShrink={0}
          align='center'
          justify={'space-between'}
          color='wood.600'
        >
          <EditableReachField
            v={s.title || ''}
            onChange={(v: string) => onChange({ ...s, title: v })}
            textStyle={s.textStyle}
            onTextStyleChange={v => onChange({ ...s, textStyle: v })}
          />
        </VStack>
      </VStack>
    )
  }

  const renderPadding = () => {
    return (
      <PaddingSelect
        value={s.paddingTop}
        onChange={(v: IPadding) => onChange({ ...s, paddingTop: v })}
      />
    )
  }

  const onAudioFileUploaded = async (files: FileT[]) => {
    console.log('on audio file uploaded', files)
    if (files.length > 0) {
      const fRaw = files[0]
      const f = await dbProcessAudioFile(fRaw)
      console.log('the file after processing', f)
      onChange({ ...s, audio: f })
    }
  }

  const renderAudioInput = () => {
    return (
      <>
        <FilesPickerButton
          onComplete={onAudioFileUploaded}
          storagePath={'/audio'}
          userId=''
          buttonTitle='Загрузить аудио файл'
          storage={storage}
          generateId={generateId}
          options={{
            maxFiles: 1,
            accept: { 'audio/*': ['.mp3', '.ogg', '.wav'] }
          }}
          isPrivate={false}
          addPrefixToFilename
        />
        {s.audio && s.audio.url && <AudioPlayer url={s.audio.url} />}
      </>
    )
  }

  return (
    <VStack w='full' spacing={6}>
      {renderPadding()}
      {renderTitle()}
      {renderAudioInput()}
    </VStack>
  )
}

export default EditSectionAudio
