import { FC, useState, useEffect } from 'react'
import { VStack, HStack, Flex, Text } from '@chakra-ui/react'
import map from 'lodash/map'
import trim from 'lodash/trim'
import replace from 'lodash/replace'
import forEach from 'lodash/forEach'
import isUndefined from 'lodash/isUndefined'
import WordInputExamination from 'shared/components/sections/tests/WordInputExamination'
import WordInputLight from 'shared/components/sections/tests/WordInputLight'
import WordInputExaminationLight from 'shared/components/sections/tests/WordInputExaminationLight'

export type IWord = {
  s: string
  wordNum?: number
}

type Props = {
  task: string
  isExamination: boolean
  showUserAnswer: boolean
  completed: boolean
  onAttempt: (wordNum: number, isCorrect: boolean) => void
  onWordAdded: (i: number, isCorrect: boolean) => void
  highlightCorrectAnswer: boolean
}

const SyncTranslateTask: FC<Props> = ({
  task,
  isExamination,
  showUserAnswer,
  completed,
  onAttempt,
  onWordAdded,
  highlightCorrectAnswer
}) => {
  // console.log('showUserAnswer', showUserAnswer)
  // console.log('highlightCorrectAnswer', highlightCorrectAnswer)

  const [textParts, setTextParts] = useState<IWord[][]>([])
  // const [matches, setMatches] = useState([])

  // const findMatches = (str: string) => {
  //   const res = str.match(/\{(.*?)\}/g)
  //   // console.log('matches', res)
  //   setMatches(res)
  // }

  useEffect(() => {
    if (task) {
      // findMatches(task)
      // const cleanString = t.replace(/{\s*(.*?)\s*}/g, '{$1}')
      const cleanString = replace(
        task,
        /{\s*(.*?)\s*}/g,
        v => {
          // console.log('cleanString part:', v)
          return `{${v
            .substring(1, v.length - 1)
            .trim()
            .split(' ')
            .join('_')}}`
        }
        // v.split(' ').join('')
      )
      // console.log('cleanString', cleanString)
      const partsRow = cleanString.split(/\r?\n/)
      let wordNum = 0
      const parts = map(partsRow, s => {
        const filteredSections = s.split(' ').filter(v => v)
        const res = []

        forEach(filteredSections, s => {
          if (s.startsWith('{')) {
            res.push({ s, wordNum })
            wordNum++
          } else {
            res.push({ s })
          }
        })
        return res
      })
      // console.log('parts', parts)
      setTextParts(parts)
    }
  }, [task])

  return (
    <VStack justify={'flex-start'} align={'flex-start'} spacing={0}>
      {map(textParts, (section, sectionIndex) => {
        return (
          <HStack
            key={sectionIndex}
            spacing={1.5}
            // className='ql-snow'
            textStyle={'medium'}
            // fontWeight={'semibold'}
            // fontWeight={700}
            flexWrap='wrap'
            justify={'flex-start'}
            align={'flex-start'}
          >
            {map(section, (s, i) => {
              const t = s.s
              if (!isUndefined(s.wordNum)) {
                const correctValues = t
                  .substring(1, t.length - 1)
                  .split('/')
                  .map(s => trim(s, '{} ').split('_').join(' '))
                if (isExamination) {
                  return (
                    <WordInputExaminationLight
                      correctValues={correctValues}
                      key={i}
                      onWordAdded={(isCorrect: boolean) =>
                        onWordAdded(s.wordNum, isCorrect)
                      }
                      completed={completed}
                      highlightCorrectAnswer={
                        !showUserAnswer && highlightCorrectAnswer
                      }
                      showUserAnswer={showUserAnswer}
                    />
                  )
                } else {
                  return (
                    <WordInputLight
                      correctValues={correctValues}
                      key={i}
                      wordNum={s.wordNum}
                      onWordAdded={onWordAdded}
                      onAttempt={onAttempt}
                      completed={completed}
                      highlightCorrectAnswer={
                        !showUserAnswer && highlightCorrectAnswer
                      }
                    />
                  )
                }
              }
              return (
                <Flex h={6} align={'center'} key={i}>
                  <Text as='span' key={i}>
                    {t}
                  </Text>
                </Flex>
              )
            })}
          </HStack>
        )
      })}
    </VStack>
  )
}

export default SyncTranslateTask
