import ReachEditor from 'components/contentSettingsCard/ReachEditor'
import { FC, useEffect, useState } from 'react'
import { Button, HStack, VStack, Box } from '@chakra-ui/react'

type Props = {
  v: string
  onChange: (v: string) => void
}

const EditableBulletPoint: FC<Props> = ({ onChange, v }) => {
  const [isEdit, setIsEdit] = useState(false)
  const [value, setValue] = useState('')

  useEffect(() => {
    setValue(v)
  }, [v])

  const onApply = () => {
    onChange(value)
    setIsEdit(false)
  }

  if (isEdit) {
    return (
      <VStack w='full' py={2} minH={8}>
        <ReachEditor value={value} onChange={(v: string) => setValue(v)} />
        <HStack justify={'space-between'} w='full'>
          <Button
            variant={'ghost'}
            size='xs'
            onClick={onApply}
            colorScheme='wood'
          >
            Cancel
          </Button>
          <Button variant={'outline'} size='xs' onClick={onApply}>
            Save
          </Button>
        </HStack>
      </VStack>
    )
  } else {
    return (
      <HStack
        minH={8}
        _hover={{ cursor: 'pointer' }}
        onClick={() => setIsEdit(true)}
        w='full'
      >
        <Box dangerouslySetInnerHTML={{ __html: v }} />
      </HStack>
    )
  }
}

export default EditableBulletPoint
