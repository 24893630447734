import { onSnapshot, query, collection, doc, setDoc } from 'firebase/firestore'
import { db, generateId } from 'src/controllers/db'
import { IWordCardTag } from 'shared/types'
import { receiveCardTags } from 'src/model/actions'
import store from 'src/model/store'
import { addListener } from 'controllers/listeners'

export const dbFetchCardTags = async () => {
  try {
    const q = query(collection(db, 'cardTags'))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: Record<string, IWordCardTag> = {}
        sn.forEach(doc => {
          const p = doc.data() as IWordCardTag
          res[doc.id] = p
        })
        store.dispatch(receiveCardTags(res))
      },
      err => {
        console.log(`dbFetchCardTags error: ${err.message}`)
      }
    )
    addListener('cardTags', unsubscribe)
  } catch (e) {
    console.error('dbFetchCardTags error', e)
  }
}

export const dbCreateCardTag = async (title: string) => {
  const id = generateId()
  const p: IWordCardTag = {
    id,
    title
  }
  const r = doc(db, 'cardTags', id)
  await setDoc(r, p)
  return id
}
