import algoliasearch from 'algoliasearch/lite'
import { createAutocomplete } from '@algolia/autocomplete-core'
import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia'
import {
  FormControl,
  FormLabel,
  HStack,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  StackDivider,
  Text,
  VStack,
  useDisclosure
} from '@chakra-ui/react'
import { useMemo, useState, useRef, useEffect, FC } from 'react'
import config from 'shared/config'
import get from 'lodash/get'

const searchClient = algoliasearch(
  config.algoliaAppId,
  config.algoliaCardsSearchKey
)

type Props = {
  title?: string
  lang?: string
  onSelect: (id: string) => void
}

const CardsSearch: FC<Props> = ({ title = 'Поиск', onSelect, lang }) => {
  const { onOpen, onClose, isOpen } = useDisclosure()
  const inputRef = useRef<HTMLInputElement>(null)
  const [suggestions, setAutocompleteState] = useState([])

  useEffect(() => {
    suggestions.length > 0 ? onOpen() : onClose()
  }, [suggestions])

  const autocomplete = useMemo(
    () =>
      createAutocomplete({
        onStateChange ({ state }) {
          // (2) Synchronize the Autocomplete state with the React state.
          // @ts-ignore
          setAutocompleteState(get(state, ['collections', 0, 'items'], []))
        },
        // @ts-ignore
        getSources () {
          return [
            // (3) Use an Algolia index source.
            {
              sourceId: config.algoliaIndex,
              getItemInputValue ({ item }) {
                return item.query
              },
              getItems ({ query }) {
                const params = {
                  hitsPerPage: 12
                }
                if (lang) {
                  // @ts-ignore
                  params.filters = `lang:${lang}`
                }
                console.log('params', params)
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: config.algoliaCardsIndex,
                      query,
                      params

                      // highlightPreTag: '<mark>',
                      // highlightPostTag: '</mark>'
                    }
                  ]
                })
              },
              getItemUrl ({ item }) {
                return item.url
              }
            }
          ]
        }
      }),
    [lang]
  )

  const onSuggestionClick = (id: string) => {
    if (inputRef.current) {
      inputRef.current.value = ''
      setAutocompleteState([])
      onSelect(id)
      autocomplete.setQuery('')
      onClose()
    }
  }

  const renderSuggestion = ({ id, word }: { id: string; word: string }) => {
    return (
      <HStack
        align={'flex-start'}
        w='full'
        key={id}
        _hover={{ bg: 'gray.50' }}
        cursor={'pointer'}
        onClick={() => onSuggestionClick(id)}
      >
        <Text color={'gray.800'} fontSize={'sm'} fontWeight={'semibold'}>
          {word}
        </Text>
      </HStack>
    )
  }

  return (
    <FormControl w='full'>
      <FormLabel>{title}</FormLabel>
      <Popover
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={onClose}
        placement='bottom-start'
        closeOnBlur={false}
        initialFocusRef={inputRef}
        matchWidth
      >
        <PopoverTrigger>
          <Input
            ref={inputRef}
            variant='flushed'
            placeholder='Поиск слова'
            type={'search'}
            value={
              autocomplete.getInputProps({ inputElement: inputRef.current })
                .value
            }
            // @ts-ignore
            onChange={
              autocomplete.getInputProps({ inputElement: inputRef.current })
                .onChange
            }
          />
        </PopoverTrigger>
        <PopoverContent w={'full'}>
          <PopoverBody w={'full'}>
            <VStack w={'full'} divider={<StackDivider color='gray.50' />}>
              {suggestions.map(renderSuggestion)}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </FormControl>
  )
}

export default CardsSearch
