import {
  Box,
  HStack,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import dayjs, { Dayjs } from 'dayjs'
import { FC, useRef } from 'react'
import { ReactComponent as BsCalendarIcon } from 'shared/assets/bsCalendar.svg'
import { ReactComponent as BsChevronDownIcon } from 'shared/assets/bsChevronDown.svg'
import { PickerPanel } from 'rc-picker'
import ruRu from 'rc-picker/lib/locale/ru_RU'
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs'

type Props = {
  value: Dayjs
  onSelect: (v: Dayjs) => void
}

const MonthPicker: FC<Props> = ({ value, onSelect }) => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const initialFocusRef = useRef(null)

  const onDateSelected = (d: Dayjs) => {
    const newDate = d.date(1).hour(0).minute(0).second(0).millisecond(0)
    onClose()
    onSelect(newDate)
  }

  return (
    <Popover
      initialFocusRef={initialFocusRef}
      placement='auto'
      isOpen={isOpen}
      onClose={onClose}
    >
      <PopoverAnchor>
        <Box />
      </PopoverAnchor>
      <PopoverTrigger>
        <HStack as='button' onClick={onToggle}>
          <BsCalendarIcon />
          <Text textStyle='body'>{value.format('MMMM, YYYY')}</Text>
          <BsChevronDownIcon />
        </HStack>
      </PopoverTrigger>
      <PopoverContent
        color='black'
        bg='white'
        w='fit-content'
        boxShadow='0px 45.868106842041016px 117.21849822998047px 0px rgba(0, 0, 0, 0.18)'
        border='1.274px solid lightGray'
        rounded={'sm'}
      >
        <PopoverBody
          p={8}
          sx={{
            '.rc-picker-header': {
              display: 'flex',
              flexDirection: 'row',
              width: 'full',
              justifyContent: 'space-between',
              textStyle: 'body'
            },
            '.rc-picker-body': {
              pt: 4
            },
            '.rc-picker-content th': {
              textStyle: 'tag',
              pb: 4
            },
            '.rc-picker-cell': {
              w: 20,
              h: 9,
              textAlign: 'center',
              cursor: 'pointer',
              _hover: { textDecoration: 'underline' }
            }
          }}
        >
          <PickerPanel<Dayjs>
            value={dayjs()}
            locale={ruRu}
            picker='month'
            generateConfig={dayjsGenerateConfig}
            onSelect={onDateSelected}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
export default MonthPicker
