import { Flex, HStack, Select } from '@chakra-ui/react'
import { useSelector } from 'model/hooks'
import { FC } from 'react'
import map from 'lodash/map'
import Label from 'shared/components/Label'

type Props = {
  label?: string
  value?: string
  onChange: (v?: string) => void
}

const LevelSelect: FC<Props> = ({
  label = 'Уровень сложности',
  value,
  onChange
}) => {
  const levels = useSelector(state => state.levels)
  return (
    <HStack w='full' justify={'space-between'}>
      <Label>{label}</Label>
      <Flex>
        <Select
          key={value}
          size='sm'
          variant={'flushed'}
          value={value}
          placeholder='не выбран'
          onChange={e =>
            onChange(e.target.value === '' ? undefined : e.target.value)
          }
        >
          {map(levels, p => (
            <option key={p.id} value={p.id}>
              {p.name}
            </option>
          ))}
        </Select>
      </Flex>
    </HStack>
  )
}

export default LevelSelect
