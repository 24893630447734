import { FC, useState } from 'react'
import {
  ButtonGroup,
  Editable,
  IconButton,
  useEditableControls,
  Input,
  EditableInput,
  EditablePreview,
  HStack
} from '@chakra-ui/react'
import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import RowActionButtons from 'components/RowActionButtons'

type Props = {
  value: string
  onChange: (v: string) => void
  onDelete?: () => void
}

const EditableRow: FC<Props> = ({ value, onChange, onDelete }) => {
  const [_value, _setValue] = useState(value)

  /* Here's a custom control */
  function EditableControls () {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps
    } = useEditableControls()

    return isEditing ? (
      <ButtonGroup justifyContent='center' size='sm'>
        <IconButton
          aria-label='done'
          icon={<CheckIcon />}
          {...getSubmitButtonProps()}
        />
        <IconButton
          aria-label='cancel'
          icon={<CloseIcon />}
          {...getCancelButtonProps()}
        />
      </ButtonGroup>
    ) : (
      <RowActionButtons
        onDelete={onDelete}
        onEdit={() => null}
        editButtonProps={{ ...getEditButtonProps() }}
      />
    )
  }

  return (
    <Editable
      fontSize='sm'
      // isPreviewFocusable={false}
      value={_value}
      w='full'
      flexDirection={'row'}
      onChange={v => _setValue(v)}
      onSubmit={() => onChange(_value)}
    >
      <HStack
        w='full'
        justify={'space-between'}
        role='group'
        position={'relative'}
      >
        <EditablePreview w='full' fontSize='sm' fontWeight='semibold' />
        <Input as={EditableInput} variant={'flushed'} size='sm' />
        <EditableControls />
      </HStack>
    </Editable>
  )
}

export default EditableRow
