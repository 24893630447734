import { createReducer } from '@reduxjs/toolkit'
import { IWordCard } from 'shared/types'
import { receiveWordCards, appendWordCard } from 'src/model/actions'

const initialState = {} as Record<string, IWordCard>

const reducer = createReducer(initialState, builder => {
  builder
    .addCase(receiveWordCards, (state, action) => action.payload)
    .addCase(appendWordCard, (state, action) => {
      state[action.payload.id] = action.payload
    })
})

export default reducer
