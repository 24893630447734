import { FC } from 'react'
import {
  Box,
  HStack,
  Tag,
  TagCloseButton,
  TagLabel,
  TagRightIcon,
  VStack
} from '@chakra-ui/react'
import SimpleInput from 'shared/components/SimpleInput'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from 'react-beautiful-dnd'
import { arrayMove } from 'shared/utils/array'
import _ from 'lodash'
import Label from 'shared/components/Label'

type Props = {
  translate: string[]
  onChange: (translate: string[]) => void
}

const EditCardTranslate: FC<Props> = ({ translate, onChange }) => {
  const onAddTranslate = (v: string) => {
    if (v !== '') {
      onChange([...translate, v])
    }
  }

  const renderAddRow = () => {
    return (
      <SimpleInput
        placeholder='Добавить перевод'
        onChange={onAddTranslate}
        clearOnApply
      />
    )
  }

  const onDragEnd = (result: DropResult) => {
    console.log('onDragEnd', result)
    if (result.destination && result.destination.index >= 0) {
      const newData = arrayMove(
        translate,
        result.source.index,
        result.destination?.index || 0
      )
      onChange(newData)
    }
  }

  const onDelete = (i: number) => {
    const newTranslate = [...translate]
    newTranslate.splice(i, 1)
    onChange(newTranslate)
  }

  const renderRow = (t: string, i: number, isDraggable: boolean) => {
    if (isDraggable) {
      return (
        <Draggable key={i} draggableId={t + i} index={i}>
          {(provided, snapshot) => (
            <VStack
              // borderWidth={1}
              // borderColor='wood.600'
              // px={2}
              // w='full'
              // h={{ base: 6, lg: 8 }}
              rounded='4px'
              flexShrink={0}
              align='center'
              justify={'space-between'}
              color='wood.600'
              textStyle={{ base: 'tag', lg: 'small' }}
              ref={provided.innerRef}
              {...provided.draggableProps}
            >
              <Box {...provided.dragHandleProps}>
                <Tag>
                  <TagLabel>{t}</TagLabel>
                  <TagCloseButton onClick={() => onDelete(i)} />
                </Tag>
              </Box>
            </VStack>
          )}
        </Draggable>
      )
    } else {
      return (
        <HStack
          // borderWidth={1}
          // borderColor='wood.600'
          // px={2}
          // w='full'
          // rounded='4px'
          flexShrink={0}
          align='center'
          justify={'space-between'}
          color='wood.600'
          textStyle={{ base: 'tag', lg: 'small' }}
        >
          {/* <HStack minH={8} w='full'> */}
          <Tag>
            <TagLabel>{t}</TagLabel>
          </Tag>
          {/* </HStack> */}
        </HStack>
      )
    }
  }

  const renderRows = () => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          direction='horizontal'
          droppableId='tags'
          renderClone={(provided, snapshot, rubric) => {
            return (
              <div ref={provided.innerRef} {...provided.draggableProps}>
                {renderRow(translate[rubric.source.index], 0, false)}
              </div>
            )
          }}
        >
          {(provided, snapshot) => (
            <HStack
              w='full'
              align='flex-start'
              textStyle='body'
              wrap={'wrap'}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {_.map(translate, (p, i) => renderRow(p, i, true))}
              {provided.placeholder}
            </HStack>
          )}
        </Droppable>
      </DragDropContext>
    )
  }

  return (
    <VStack w='full' align={'start'}>
      <Label>Перевод</Label>
      {renderRows()}
      {renderAddRow()}
    </VStack>
  )
}

export default EditCardTranslate
