import { FC, useState, useEffect, useRef, useMemo } from 'react'
import { ISection, ISectionTestAddLetters, ISessionAnswer } from 'shared/types'
import { Text, HStack, Stack, Show } from '@chakra-ui/react'
import TestTask from 'shared/components/sections/tests/TestTask'
import get from 'lodash/get'
import find from 'lodash/find'
import map from 'lodash/map'
import set from 'lodash/set'
import forEach from 'lodash/forEach'
import isNil from 'lodash/isNil'
import reduce from 'lodash/reduce'
import upperCase from 'lodash/upperCase'
import lowerCase from 'lodash/lowerCase'
import replace from 'lodash/replace'
import startsWith from 'lodash/startsWith'
import { getTestTitle } from 'shared/utils/tests'
import LetterContainer from 'shared/components/sections/tests/LetterContainer'
import LetterInput from 'shared/components/sections/tests/LetterInput'
import { ReactComponent as IconEnter } from 'shared/assets/bsArrowReturnRight.svg'
import { cleanupString } from 'shared/utils/tests'
import TestCard from 'shared/components/sections/tests/TestCard'

type CurAnswer = {
  correctAnswer: string
  value?: string
}

type Props = {
  s: ISectionTestAddLetters
  onAnswer?: (sectionId: string, answer: ISessionAnswer) => void
  answer?: ISessionAnswer
  testNum?: number
  showUserAnswer?: boolean
  isExamination: boolean
}

const SectionTestAddLetters: FC<Props> = ({
  s,
  onAnswer,
  answer,
  testNum,
  showUserAnswer,
  isExamination
}) => {
  const [currentAnswer, setCurrentAnswer] = useState<CurAnswer[]>([])
  const [focusedInputIndex, setFocusedInputIndex] = useState<number | null>()
  const buttonRef = useRef<HTMLDivElement>()

  useEffect(() => {
    const t = s.test?.text
    if (t) {
      const cleanString = replace(
        t,
        /\[\s*(.*?)\s*\]/g,
        v => {
          // console.log('cleanString part:', v)
          return `[${v
            .substring(1, v.length - 1)
            .trim()
            .split(' ')
            .join('_')}]`
        }
        // v.split(' ').join('')
      )
      // console.log('cleanString', cleanString)
      const wrds = cleanString.split(' ')
      const initialAnswer: CurAnswer[] = []
      let missing = false
      // console.log('wrds', wrds)
      forEach(wrds, (w, wIndex) => {
        forEach(w, l => {
          if (l === '{') {
            missing = true
            return null
          } else if (l === '}') {
            missing = false
            return null
          } else if (missing) {
            initialAnswer.push({ correctAnswer: l })
          }
        })
      })
      console.log({ initialAnswer })
      setCurrentAnswer(initialAnswer)
    }
  }, [s.test?.text])

  const submitAnswer = () => {
    const answer = map(currentAnswer, ca => ca.value)
    const isCorrect = reduce(
      currentAnswer,
      (res, ca) => {
        return (
          res && cleanupString(ca.correctAnswer) === cleanupString(ca.value)
        )
      },
      true
    )
    onAnswer &&
      onAnswer(s.id, {
        answer,
        isCorrect
      })
  }

  const allLettersAdded = useMemo(() => {
    const missingAnswer = find(
      currentAnswer,
      ca => !ca?.value || ca?.value === ''
    )
    return isNil(missingAnswer)
  }, [currentAnswer])

  const showButton = useMemo(() => {
    return !answer && allLettersAdded && !showUserAnswer
  }, [answer, allLettersAdded, showUserAnswer])

  const onLetterAdded = (v: string, lIndex: number) => {
    // console.log('onLetterAdded', v, lIndex)
    const newAnswer = [...currentAnswer]
    const ca = get(currentAnswer, [lIndex, 'correctAnswer'])
    const isUpperCase = upperCase(ca) === ca
    const vIsUpperCase = upperCase(v) === v
    const newValue =
      isUpperCase === vIsUpperCase
        ? v
        : isUpperCase
        ? upperCase(v)
        : lowerCase(v)
    set(newAnswer, [lIndex, 'value'], newValue)
    setCurrentAnswer(newAnswer)

    // console.log('isDiac', isDiac)
    // if (!isDiac) {
    if (v !== '') {
      if (lIndex === currentAnswer.length - 1) {
        // console.log('focus to button')
        buttonRef.current.focus()
      } else {
        setFocusedInputIndex(lIndex + 1)
      }
    }
    if (isExamination) {
      setTimeout(submitAnswer, 1)
    }
    // }
  }

  const renderQuestion = () => {
    if (s.test) {
      const cleanString = replace(
        s.test.text,
        /\[\s*(.*?)\s*\]/g,
        v => {
          // console.log('cleanString part:', v)
          return `[${v
            .substring(1, v.length - 1)
            .trim()
            .split(' ')
            .join('_')}]`
        }
        // v.split(' ').join('')
      )
      const wrds = cleanString.split(' ')
      let mLetterIndex = 0
      let missing = false
      return (
        <HStack spacing={4} rowGap={2} wrap={'wrap'} textStyle={'h3'}>
          {map(wrds, (w, wIndex) => {
            if (startsWith(w, '[')) {
              const originWords = w.substring(1, w.length - 1).split('_')
              return map(originWords, (ow, i) => {
                return <Text key={wIndex + ow + i}>{ow}</Text>
              })
            }
            return (
              <HStack key={wIndex} spacing={0}>
                {map(w, (l, i) => {
                  if (l === '{') {
                    missing = true
                    return null
                  } else if (l === '}') {
                    missing = false
                    return null
                  } else if (!missing) {
                    const isText = l.match(/\w/g)
                    if (isText) {
                      return (
                        <LetterContainer status='transparent' key={l + i}>
                          {l}
                        </LetterContainer>
                      )
                    } else {
                      return <Text key={l + i}>{l}</Text>
                    }
                  } else {
                    mLetterIndex = mLetterIndex + 1
                    const curL = get(
                      currentAnswer,
                      [mLetterIndex - 1, 'value'],
                      ''
                    )
                    let v = curL
                    const correctL = get(
                      currentAnswer,
                      [mLetterIndex - 1, 'correctAnswer'],
                      ''
                    )
                    let status = 'transparent'
                    if (curL !== '') {
                      status = 'gray'
                    }
                    if (answer && !showUserAnswer) {
                      status =
                        cleanupString(curL) === cleanupString(correctL)
                          ? 'success'
                          : 'error'
                    }
                    let letterStatus = status
                    if (status === 'success' && curL !== correctL) {
                      v = correctL
                      letterStatus = 'warn'
                    }
                    return (
                      <LetterContainer status={status} key={l + i}>
                        <LetterInput
                          isReadonly={!isNil(answer?.answer) && !showUserAnswer}
                          isFocused={focusedInputIndex === mLetterIndex - 1}
                          index={mLetterIndex - 1}
                          v={v}
                          onChange={onLetterAdded}
                          toPrev={i => setFocusedInputIndex(i - 1)}
                          toNext={i => setFocusedInputIndex(i + 1)}
                          status={letterStatus}
                        />
                      </LetterContainer>
                    )
                  }
                })}
              </HStack>
            )
          })}
        </HStack>
      )
    }
  }

  const renderButton = () => {
    return (
      <HStack
        w={{ base: 'full', lg: '72px' }}
        bg={showButton ? 'black.800' : 'transparent'}
        ref={buttonRef}
        rounded={'8px'}
        // autoFocus={true}
        align='center'
        justify={'center'}
        color='white'
        h={{ base: 8, lg: 'full' }}
        flexShrink={0}
        as='button'
        outline={'none'}
        _hover={{ cursor: showButton ? 'pointer' : 'auto' }}
        onClick={showButton ? submitAnswer : () => null}
        onKeyDown={e => {
          if (e.code === 'Enter') {
            submitAnswer()
          }
        }}
      >
        {showButton && (
          <>
            <Show above='lg'>
              <IconEnter width={24} height={24} />
            </Show>
            <Show below='lg'>
              <Text textStyle='small'>Проверить</Text>
              <IconEnter width={12} height={12} />
            </Show>
          </>
        )}
      </HStack>
    )
  }

  return (
    <TestCard showUserAnswer={showUserAnswer} answer={answer}>
      <TestTask
        title={s.test?.title || getTestTitle(s.type)}
        answer={showUserAnswer ? undefined : answer}
        testNum={testNum}
      />
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        width={'full'}
        justify={{ base: 'flex-start', lg: 'space-between' }}
      >
        {renderQuestion()}
        {renderButton()}
      </Stack>
    </TestCard>
  )
}

export default SectionTestAddLetters
