import { FC } from 'react'
import { HStack, Flex } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'

import Sidebar from 'src/components/Sidebar'
import ChatDrawer from 'components/ChatDrawer'

const PageLayout: FC<{}> = () => {
  return (
    <HStack align='flex-start' spacing={0} w='full' h='full' overflow='hidden'>
      <Sidebar />
      <Flex h='full' w='full' bg='white' overflow='hidden'>
        <Outlet />
      </Flex>
      <ChatDrawer />
    </HStack>
  )
}

export default PageLayout
