import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  VStack,
  useDisclosure
} from '@chakra-ui/react'
import {
  Ref,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useState
} from 'react'
import SimpleInput from 'shared/components/SimpleInput'
import { IVideoPlatform, IWebinar } from 'shared/types'
import get from 'lodash/get'
import toNumber from 'lodash/toNumber'
import SwitchField from 'components/formFields/SwitchField'
import { useSelector } from 'model/hooks'
import EditTags from 'components/EditTags'
import ImageField from 'components/formFields/ImageField'
import VideoField from 'components/formFields/VideoField'
import LevelSelect from 'components/formFields/LevelSelect'
import PartitionSelect from 'components/formFields/PartitionSelect'
import DateField from 'components/formFields/DateField'
import { dbUpdateWebinar } from 'controllers/webinars'

export interface IWebinarSettingsDrawer {
  open: (cId: string) => void
}
type Props = {}

const WebinarSettingsDrawer: ForwardRefRenderFunction<
  IWebinarSettingsDrawer,
  Props
> = (_props, ref: Ref<unknown>) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [cId, setCId] = useState<string>()
  const c = useSelector(state => (cId ? get(state.webinars, cId) : undefined))

  const update = (upd: Partial<IWebinar>) => {
    if (c) {
      dbUpdateWebinar(c.id, upd)
    }
  }

  useImperativeHandle(ref, () => ({
    open: (cId: string) => {
      setCId(cId)
      onOpen()
    }
  }))

  const renderTags = () => {
    const tags = c?.tags || []
    return (
      <EditTags tags={tags} onChange={(tags: string[]) => update({ tags })} />
    )
  }

  return (
    <Drawer isOpen={isOpen} placement='right' onClose={onClose} size='sm'>
      <DrawerOverlay bg='transparent' />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader textStyle='small'>Настройки страницы</DrawerHeader>

        <DrawerBody pb={6}>
          <VStack w='full' spacing={6}>
            <SimpleInput
              label='Название'
              value={c?.title || ''}
              onChange={(v: string) => update({ title: v })}
              variant='flushed'
            />
            <SwitchField
              label='Доступен для покупки'
              value={!c?.closed}
              onChange={v => update({ closed: !v })}
            />
            <SwitchField
              label='Виден на странице курсов'
              value={!c?.invisible}
              onChange={v => update({ invisible: !v })}
            />
            <PartitionSelect
              value={c?.partitionId}
              onChange={(partitionId?: string) => update({ partitionId })}
            />
            <LevelSelect
              value={c?.levelId}
              onChange={(levelId?: string) => update({ levelId })}
            />
            <DateField
              label='Дата начала'
              value={c?.startDate}
              onChange={(startDate?: number) => update({ startDate })}
            />
            <DateField
              label='Дата завершения'
              value={c?.endDate}
              onChange={(endDate?: number) => update({ endDate })}
            />
            {/* <SimpleInput
              label='Количество учеников'
              type='number'
              value={c?.studentsLimit || 0}
              onChange={(studentsLimit: string) =>
                update({ studentsLimit: toNumber(studentsLimit) })
              }
            /> */}
            <SimpleInput
              label='Цена (рубли)'
              type='number'
              value={c?.price || 0}
              onChange={(v: string) => update({ price: toNumber(v) })}
            />
            <SimpleInput
              label='Предыдущая цена (рубли)'
              type='number'
              value={c?.oldPrice || 0}
              onChange={(v: string) => update({ oldPrice: toNumber(v) })}
            />
            <SimpleInput
              label='Цена (Eur)'
              type='number'
              value={c?.priceEur || 0}
              onChange={(v: string) => update({ priceEur: toNumber(v) })}
            />
            <SimpleInput
              label='Предыдущая цена (Eur)'
              type='number'
              value={c?.oldPriceEur || 0}
              onChange={(v: string) => update({ oldPriceEur: toNumber(v) })}
            />
            <SimpleInput
              label='Ссылка на страницу оплаты'
              value={c?.paymentLink || ''}
              onChange={(v: string) => update({ paymentLink: v })}
            />
            {renderTags()}
            <ImageField
              label='Картинка вебинара в каталоге'
              value={c?.imageUrl}
              onChange={(imageUrl: string) => update({ imageUrl })}
              storagePath={`/courses/${c?.id}`}
            />
            <VideoField
              label='Видео превью'
              value={c?.vimeoVideoUrl || ''}
              onChange={(vimeoVideoUrl: string) => update({ vimeoVideoUrl })}
              platform={IVideoPlatform.VIMEO}
            />
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default forwardRef(WebinarSettingsDrawer)
