import { HStack, Select, Flex } from '@chakra-ui/react'
import Label from 'shared/components/Label'
import { FC } from 'react'
import { IAlignment } from 'shared/types'

type Props = {
  label?: string
  value?: IAlignment
  onChange: (v: IAlignment) => void
}

const AlignmentSelect: FC<Props> = ({
  label = 'Выравнивание',
  value,
  onChange
}) => {
  return (
    <HStack w='full' align='center' spacing={2} justify={'space-between'}>
      <Label>{label}</Label>
      <Flex>
        <Select
          value={value || 'center'}
          onChange={e => onChange(e.target.value as IAlignment)}
          variant={'solid'}
          size='xs'
        >
          <option value='start'>Слева</option>
          <option value='center'>По центру</option>
          <option value='end'>Справа</option>
        </Select>
      </Flex>
    </HStack>
  )
}

export default AlignmentSelect
