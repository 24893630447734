import { FC, useState, useEffect, useRef, useMemo } from 'react'
import { ISection, ISectionTestTextAnswer, ISessionAnswer } from 'shared/types'
import { VStack, Text, Box, HStack, Stack } from '@chakra-ui/react'
import TestTask from 'shared/components/sections/tests/TestTask'
import { AutoResizeTextarea } from 'shared/components/chat/AutoResizeTextarea'
import isObject from 'lodash/isObject'
import get from 'lodash/get'
import isString from 'lodash/isString'
import find from 'lodash/find'
import isNil from 'lodash/isNil'
import { cleanupString, getTestTitle } from 'shared/utils/tests'
import { ReactComponent as IconEnter } from 'shared/assets/bsArrowReturnRight.svg'
import CorrectText from 'shared/components/sections/tests/CorrectText'
import TestCard from 'shared/components/sections/tests/TestCard'
import SectionTextContent from 'shared/components/SectionTextContent'

type Props = {
  s: ISectionTestTextAnswer
  onAnswer?: (sectionId: string, answer: ISessionAnswer) => void
  answer?: ISessionAnswer
  testNum?: number
  showUserAnswer?: boolean
  isExamination: boolean
}

const SectionTestTextAnswer: FC<Props> = ({
  s,
  onAnswer,
  answer,
  testNum,
  showUserAnswer,
  isExamination
}) => {
  const [value, setValue] = useState('')
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const highlightCorrectAnswer = get(s, 'test.highlightCorrectAnswer', false)
  const [focused, setFocused] = useState(false)

  useEffect(() => {
    if (!answer) {
      setValue('')
    }
  }, [answer])

  const renderQuestion = () => {
    if (s.htmlString) {
      return (
        <SectionTextContent
          r={{ text: s.htmlString, textStyle: s.textStyle }}
        />
      )
    }
  }

  const correctAnswer = useMemo(() => {
    const answer = get(s, 'test.answer')
    if (isString(answer)) {
      return [answer]
    } else {
      return answer || []
    }
  }, [])

  const submitAnswer = () => {
    const correctValue = find(
      correctAnswer,
      ca => cleanupString(value) === cleanupString(ca)
    )
    const sa: ISessionAnswer = {
      answer: value,
      correctValue,
      isCorrect: !isNil(correctValue)
    }
    if (onAnswer) {
      onAnswer(s.id, sa)
    }
  }

  const renderInput = () => {
    if (
      answer &&
      answer.isCorrect &&
      isString(answer.answer) &&
      !showUserAnswer
    ) {
      return (
        <HStack px={4} py={3}>
          <CorrectText
            answer={answer.answer}
            correctAnswer={answer.correctValue}
          />
        </HStack>
      )
    } else {
      return (
        <AutoResizeTextarea
          borderRadius={'xs'}
          value={value}
          readOnly={isObject(answer) && !showUserAnswer}
          variant={'custom'}
          placeholder='Введите ответ'
          onChange={e => setValue(e.target.value)}
          onBlur={() => {
            setFocused(false)
            showUserAnswer && submitAnswer()
          }}
          onFocus={() => setFocused(true)}
          bg={
            answer && !showUserAnswer
              ? answer.isCorrect
                ? 'green.50'
                : 'red.50'
              : 'wood.50'
          }
          color={
            answer && !showUserAnswer
              ? answer.isCorrect
                ? 'green.800'
                : 'red.700'
              : 'black.950'
          }
          py={3}
          px={4}
          ref={inputRef}
          onKeyDown={e => {
            if (e.key === 'Enter' && onAnswer && !answer) {
              inputRef.current?.blur()
              submitAnswer()
            }
          }}
        />
      )
    }
  }

  return (
    <TestCard showUserAnswer={showUserAnswer} answer={answer}>
      <TestTask
        title={s.test?.title || getTestTitle(s.type)}
        answer={showUserAnswer ? undefined : answer}
        testNum={testNum}
      />
      {renderQuestion()}
      <Stack
        w='full'
        bg={
          answer && !showUserAnswer
            ? answer.isCorrect
              ? 'green.50'
              : 'red.50'
            : 'wood.50'
        }
        rounded={'xs'}
        direction={{ base: 'column', lg: 'row' }}
        borderWidth={1}
        borderColor={
          answer && !showUserAnswer
            ? answer.isCorrect
              ? 'green.800'
              : 'red.700'
            : focused
            ? 'black.950'
            : 'black.300'
        }
      >
        {renderInput()}
        {!answer && value !== '' && !showUserAnswer && (
          <HStack
            mr={'-1px'}
            as='button'
            rounded='xs'
            align='center'
            justify='center'
            h={{ base: 8, lg: 'full' }}
            w={{ base: 'full', lg: '72px' }}
            onClick={!onAnswer || answer ? undefined : () => submitAnswer()}
            cursor={answer ? 'not-allowed' : undefined}
            bg={'black.800'}
            color='white'
          >
            <Text
              textStyle='tag'
              color='white'
              fontWeight={500}
              display={{ base: 'block', lg: 'none' }}
            >
              Проверить
            </Text>
            <Box boxSize={{ base: 3, lg: 4 }}>
              <IconEnter width='100%' height='100%' />
            </Box>
          </HStack>
        )}
      </Stack>
      {highlightCorrectAnswer &&
        answer &&
        !answer.isCorrect &&
        !showUserAnswer && (
          <HStack>
            <Text textStyle={'body'} color='green.800'>
              {correctAnswer[0]}
            </Text>
          </HStack>
        )}
    </TestCard>
  )
}

export default SectionTestTextAnswer
