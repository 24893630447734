import { createReducer } from '@reduxjs/toolkit'
import { IChannel } from 'shared/types'
import { receiveChannels } from 'src/model/actions'

const initialState = null as Record<string, IChannel> | null

const reducer = createReducer(initialState, builder => {
  builder.addCase(receiveChannels, (state, action) => action.payload)
})

export default reducer
