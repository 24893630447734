import { createReducer } from '@reduxjs/toolkit'
import { IPaymentLink } from 'shared/types'
import { receivePaymentLinks } from 'src/model/actions'

const initialState = [] as IPaymentLink[]

const reducer = createReducer(initialState, builder => {
  builder.addCase(receivePaymentLinks, (state, action) => action.payload)
})

export default reducer
