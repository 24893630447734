import { FC, useRef, useEffect } from 'react'
import { Box, AspectRatio } from '@chakra-ui/react'
import get from 'lodash/get'

type Props = {
  videoId: string
}

const YoutubePlayer: FC<Props> = ({ videoId }) => {
  const containerRef = useRef(null)
  const containerIdRef = useRef(Date.now().toString())
  const playerRef = useRef(null)

  useEffect(() => {
    const Player = get(window, 'YT.Player') as any
    if (Player) {
      playerRef.current = new Player(containerIdRef.current, {
        width: '100%',
        height: '100%',
        videoId: videoId,
        events: {
          onError: (e: any) => console.log('onError', e)
        }
      })
    }
    return () => {
      if (playerRef.current) {
        playerRef.current.destroy()
      }
    }
  }, [videoId])

  return (
    <AspectRatio ratio={16 / 9}>
      <Box ref={containerRef} id={containerIdRef.current} w='full' h='full' />
    </AspectRatio>
  )
}

export default YoutubePlayer
