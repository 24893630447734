import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  HStack,
  Heading,
  StackDivider,
  Text,
  VStack
} from '@chakra-ui/react'
import { useSelector } from 'model/hooks'
import { FC } from 'react'
import map from 'lodash/map'
import { IAdmin } from 'shared/types'
import RowActionButtons from 'components/RowActionButtons'
import { dbAddAdmin, dbDeleteAdmin } from 'controllers/admins'
import UsersSearch from 'components/UsersSearch'

type Props = {}

const SettingsAdmins: FC<Props> = () => {
  const admins = useSelector(state => state.admins)

  const renderRow = (adm: IAdmin) => {
    return (
      <HStack key={adm.id} w='full' role='group' position={'relative'}>
        <VStack align='flex-start' flex={1} spacing={0}>
          <Text fontSize='sm' fontWeight='semibold'>
            {adm.email}
          </Text>
        </VStack>
        <RowActionButtons
          onDelete={() => {
            dbDeleteAdmin(adm.id)
          }}
        />
      </HStack>
    )
  }

  const addAdmin = (id: string, name: string, email: string) => {
    dbAddAdmin(id, email)
  }

  return (
    <VStack w='full'>
      <HStack w='full' maxW='2xl' pb={8}>
        <UsersSearch onSelect={addAdmin} title='Добавить администратора' />
      </HStack>
      <Card w='full' maxW='2xl'>
        <CardHeader>
          <Heading size='md'>Администраторы</Heading>
        </CardHeader>
        <CardBody>
          <VStack w='full' divider={<StackDivider color='gray.100' />}>
            {map(admins, renderRow)}
          </VStack>
        </CardBody>
        <CardFooter justify={'flex-end'}></CardFooter>
      </Card>
    </VStack>
  )
}

export default SettingsAdmins
