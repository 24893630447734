import { FC } from 'react'
import { Box } from '@chakra-ui/react'
import { IStyledText } from 'shared/types'
import get from 'lodash/get'

type Props = {
  r: IStyledText
}

const SectionTextContent: FC<Props> = ({ r }) => {
  return (
    <Box
      w='full'
      textAlign={get(r, 'alignment') || undefined}
      className='ql-snow'
      textStyle={r.textStyle || 'body'}
      dangerouslySetInnerHTML={{ __html: r.text }}
    />
  )
}

export default SectionTextContent
