import { FormControl, HStack, VStack, Box, Checkbox } from '@chakra-ui/react'
import { FC } from 'react'
import map from 'lodash/map'
import isString from 'lodash/isString'
import size from 'lodash/size'
import { ITestContent, ITestOption } from 'shared/types'
import SimpleInput from 'shared/components/SimpleInput'
import { generateId } from 'controllers/db'
import RowActionButtons from 'components/RowActionButtons'
import { arrayMove } from 'shared/utils/array'

type Props = {
  test: ITestContent
  onChange: (v: ITestContent) => void
}

const EditTestOneOption: FC<Props> = ({ test, onChange }) => {
  const deleteOption = (id: string) => {
    const currentOptions = test.options || []
    const newOptions = currentOptions.filter(v => v.id !== id)
    onChange({ ...test, options: newOptions })
  }

  const onMove = (i: number, isUp: boolean) => {
    const currentOptions = test.options || []
    const newOptions = arrayMove(currentOptions, i, isUp ? i - 1 : i + 1)
    onChange({ ...test, options: newOptions })
  }

  const updateOptionValue = (i: number, v: string) => {
    const currentOptions = test.options || []
    const newOptions = [...currentOptions]
    newOptions[i].value = v
    onChange({ ...test, options: newOptions })
  }

  const setAnswer = (optionId: string) => {
    onChange({ ...test, answer: optionId })
  }

  const renderOption = (option: ITestOption, i: number) => {
    return (
      <HStack key={option.id} w='full' position={'relative'} role='group'>
        <Checkbox
          size='sm'
          isChecked={isString(test?.answer) && test.answer === option.id}
          onChange={e => setAnswer(option.id)}
        />
        <FormControl>
          <SimpleInput
            variant={'flushed'}
            size='sm'
            value={option.value}
            onChange={(v: string) => updateOptionValue(i, v)}
          />
        </FormControl>
        <Box position={'absolute'} right={-4} bottom={1}>
          <RowActionButtons
            onDelete={() => deleteOption(option.id)}
            isFirst={i === 0}
            isLast={i === size(test?.options) - 1}
            onMove={(isUp: boolean) => onMove(i, isUp)}
          />
        </Box>
      </HStack>
    )
  }

  const addOption = (v: string) => {
    if (v !== '') {
      const id = generateId()
      const currentOptions = test.options || []
      const opt = {
        id,
        value: v
      }
      const newOptions = [...currentOptions, opt]
      onChange({ ...test, options: newOptions })
    }
  }

  const renderNewOption = () => {
    return (
      <HStack w='full'>
        <FormControl ml={5}>
          <SimpleInput
            variant={'flushed'}
            placeholder='Добавить вариант'
            size='sm'
            onChange={addOption}
            clearOnApply
          />
        </FormControl>
      </HStack>
    )
  }

  return (
    <VStack w='full'>
      <VStack w='full'>{map(test.options, renderOption)}</VStack>
      {renderNewOption()}
    </VStack>
  )
}

export default EditTestOneOption
