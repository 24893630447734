import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react'
import NavBar from 'components/NavBar'
import { AddIcon } from '@chakra-ui/icons'
import EditCardDrawer, { IEditCardDrawer } from 'modals/EditCardDrawer'
import { useEffect, useRef, useState } from 'react'
import { IWordCard } from 'shared/types'
import { generateId } from 'controllers/db'
import { useSelector } from 'model/hooks'
import _ from 'lodash'
import {
  dbCreateCard,
  dbSubscribeToCard,
  dbSubscribeToRecentlyAdded
} from 'controllers/cards'
import LanguageSelector from 'components/LanguageSelector'
import CardsSearch from 'components/CardsSearch'
import { Unsubscribe } from 'firebase/firestore'
import WordCard from 'shared/components/WordCard'
import Label from 'shared/components/Label'
// import { useSelector } from 'model/hooks'

const Cards = () => {
  const languages = useSelector(state => state.languages)
  const cardTags = useSelector(state => state.cardTags)
  const cardDrawerRef = useRef<IEditCardDrawer>(null)
  const [curLang, setCurLang] = useState<string>()
  const [cardId, setCardId] = useState<string>()
  const [card, setCard] = useState<IWordCard>()
  const cardUnsubscriberRef = useRef<Unsubscribe | null>(null)
  const recentlyAddedUnsubscribeRef = useRef<Unsubscribe | null>(null)
  const [recentCards, setRecentCards] = useState<IWordCard[]>([])

  useEffect(() => {
    if (!curLang && languages) {
      setCurLang(_.get(_.first(_.values(languages)), 'id'))
    }
  }, [curLang, languages])

  useEffect(() => {
    if (curLang) {
      if (recentlyAddedUnsubscribeRef.current) {
        recentlyAddedUnsubscribeRef.current()
      }
      recentlyAddedUnsubscribeRef.current = dbSubscribeToRecentlyAdded(
        curLang,
        setRecentCards
      )
    }
  }, [curLang])

  useEffect(() => {
    if (cardUnsubscriberRef.current) {
      cardUnsubscriberRef.current()
    }
    if (cardId) {
      cardUnsubscriberRef.current = dbSubscribeToCard(cardId, setCard)
    } else {
      setCard(undefined)
    }
  }, [cardId])

  const onCreateClick = async () => {
    const id = generateId()
    const card: IWordCard = {
      id,
      word: 'hello',
      translate: [],
      tags: [],
      createdAt: _.now()
    }
    if (curLang) {
      card.lang = curLang
    }
    const created = await dbCreateCard(card)
    if (created) {
      setCardId(id)
      cardDrawerRef.current?.open(card.id)
    }
  }

  const navBarActions = (
    <HStack>
      <Button colorScheme='teal' leftIcon={<AddIcon />} onClick={onCreateClick}>
        Добавить карточку
      </Button>
    </HStack>
  )

  const renderContent = () => {
    if (card) {
      return (
        <VStack w='802px' pt={12}>
          <WordCard card={card} cardTags={cardTags} />
        </VStack>
      )
    }
  }

  const renderEditCardButton = () => {
    console.log('card', card)
    if (card) {
      return (
        <HStack w='full' justify={'center'} pt={6}>
          <Button
            size='md'
            variant={'primary'}
            onClick={() => cardDrawerRef.current?.open(card.id)}
          >
            Edit
          </Button>
        </HStack>
      )
    }
  }

  const renderRecentlyAdded = () => {
    if (!_.isEmpty(recentCards)) {
      return (
        <VStack pt={12} maxW={'3xl'}>
          <Label>Недавно добавленные</Label>
          <HStack wrap={'wrap'}>
            {_.map(recentCards, c => {
              return (
                <Button
                  key={c.id}
                  variant={'link'}
                  onClick={() => setCardId(c.id)}
                >
                  {c.word}
                </Button>
              )
            })}
          </HStack>
        </VStack>
      )
    }
  }

  return (
    <VStack w='full' h='full' spacing={0}>
      <NavBar hideBackButton title='Словарь'>
        {navBarActions}
      </NavBar>
      <VStack align={'center'} w='full' h='full' p={4} overflowY={'auto'}>
        {/* <Wrap spacing={4}>{map(sortedFrees, renderCard)}</Wrap> */}

        <VStack w={'xl'} align={'start'}>
          <CardsSearch title='' onSelect={v => setCardId(v)} lang={curLang} />
          <LanguageSelector value={curLang} onChange={setCurLang} />
        </VStack>

        {renderContent()}
        {renderEditCardButton()}
        {renderRecentlyAdded()}
      </VStack>
      <EditCardDrawer ref={cardDrawerRef} />
    </VStack>
  )
}

export default Cards
