import { FC, ReactNode, useRef, useEffect } from 'react'
import { VStack } from '@chakra-ui/react'
import { IMessage } from 'shared/types'
import get from 'lodash/get'

import DateElement from 'shared/components/chat/elements/DateElement'
import NameElement from 'shared/components/chat/elements/NameElement'
import MessageElement from 'shared/components/chat/elements/MessageElement'

type Props = {
  messages: IMessage[]
  userId: string
}

const ChatMessages: FC<Props> = ({ messages, userId }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const getDayNum = (t: number) => Math.ceil(t / (1000 * 60 * 60 * 24))
  const getMinute = (t: number) => Math.ceil(t / (1000 * 60))

  useEffect(() => {
    if (containerRef.current) {
      const elt = containerRef.current
      elt.scrollTo({ top: elt.scrollHeight, behavior: 'smooth' })
    }
  }, [messages])

  const renderMessage = (m: IMessage, i: number, messages: IMessage[]) => {
    const prevM = get(messages, i - 1)
    const nextM = get(messages, i + 1)
    const nodes: ReactNode[] = []

    const addDate =
      prevM === undefined ||
      getDayNum(prevM.createdAt) !== getDayNum(m.createdAt)
    if (addDate) {
      nodes.push(<DateElement timestamp={m.createdAt} />)
    }

    const addUserName =
      prevM === undefined ||
      prevM.senderId !== m.senderId ||
      getMinute(prevM.createdAt) !== getMinute(m.createdAt)
    if (addUserName) {
      nodes.push(
        <NameElement
          timestamp={m.createdAt}
          name={m.senderName || m.senderId}
          isLeft={m.senderId !== userId}
        />
      )
    }

    nodes.push(
      <MessageElement
        m={m}
        isLeft={m.senderId !== userId}
        hasPrev={
          prevM &&
          prevM.senderId === m.senderId &&
          getMinute(prevM.createdAt) === getMinute(m.createdAt) &&
          getDayNum(prevM.createdAt) === getDayNum(m.createdAt)
        }
        hasNext={
          nextM &&
          nextM.senderId === m.senderId &&
          getMinute(nextM.createdAt) === getMinute(m.createdAt) &&
          getDayNum(nextM.createdAt) === getDayNum(m.createdAt)
        }
      />
    )

    return nodes
  }

  return (
    <VStack
      h='full'
      flex={1}
      flexDirection={'column'}
      w='full'
      px={4}
      py={4}
      spacing={0}
      overflowY='auto'
      ref={containerRef}
    >
      {messages.map(renderMessage)}
    </VStack>
  )
}

export default ChatMessages
