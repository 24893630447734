import { FC } from 'react'
import { VStack } from '@chakra-ui/react'
import { IPadding, ISectionDivider } from 'shared/types'
import PaddingSelect from 'components/PaddingSelect'

type Props = {
  s: ISectionDivider
  onChange: (s: ISectionDivider) => void
}

const EditSectionDivider: FC<Props> = ({ s, onChange }) => {
  const renderPadding = () => {
    return (
      <PaddingSelect
        value={s.paddingTop}
        onChange={(v: IPadding) => onChange({ ...s, paddingTop: v })}
      />
    )
  }

  return (
    <VStack w='full' spacing={6}>
      {renderPadding()}
    </VStack>
  )
}

export default EditSectionDivider
