import { FC, useRef, useState, useEffect, MouseEvent, useMemo } from 'react'
import {
  Input,
  InputProps,
  Flex,
  HStack,
  VStack,
  Box,
  Text,
  Divider
} from '@chakra-ui/react'
import AutowidthInput from 'react-autowidth-input'
import { ReactComponent as IconEnter } from 'shared/assets/bsArrowReturnRight.svg'
import last from 'lodash/last'
import trim from 'lodash/trim'
import find from 'lodash/find'
import isNil from 'lodash/isNil'
import { cleanupString } from 'shared/utils/tests'
import CorrectText from 'shared/components/sections/tests/CorrectText'

type Props = Omit<InputProps, 'onChange'> & {
  correctValues: string[]
  onWordAdded: (wordNum: number, isCorrect: boolean) => void
  onAttempt: (wordNum: number, isCorrect: boolean) => void
  highlightCorrectAnswer: boolean
  completed: boolean
  wordNum: number
}

const WordInputLight: FC<Props> = ({
  correctValues,
  onWordAdded,
  onAttempt,
  value,
  highlightCorrectAnswer,
  completed,
  wordNum,
  ...rest
}) => {
  const ref = useRef<HTMLInputElement>(null)
  const [attempts, setAttempts] = useState<boolean[]>([])
  const [focused, setFocused] = useState(false)
  const [v, setV] = useState<string>(value ? value.toString() : '')

  useEffect(() => {
    setV(value ? value.toString() : '')
  }, [value])

  const isCorrect = last(attempts) === true

  const correctValue = useMemo(() => {
    return find(correctValues, cv => {
      return cleanupString(v) === cleanupString(cv)
    })
  }, [v, correctValues])

  const apply = () => {
    // console.log('apply')

    if (trim(v) !== '' && attempts.length < 3 && last(attempts) !== true) {
      const isCorrect = !isNil(correctValue)
      const newAttempts = [...attempts, isCorrect]
      setAttempts([...attempts, isCorrect])
      onAttempt(wordNum, isCorrect)
      if (isCorrect || newAttempts.length === 3) {
        onWordAdded(wordNum, isCorrect)
      }
    }
    setFocused(false)

    // onChange(v)
    // setV('')
  }

  // console.log('attempts', attempts)

  const renderAttempts = () => {
    const borderColor =
      last(attempts) === true
        ? 'green.800'
        : attempts.length === 3 || lastTryFailed
        ? 'red.700'
        : focused
        ? 'black.500'
        : 'black.200'

    return (
      <HStack spacing={'1px'} w='full'>
        <Divider borderColor={borderColor} />
        {[0, 1, 2].map((a, i) => {
          let color = focused ? 'black.500' : 'black.200'
          if (i < attempts.length) {
            color = attempts[i] ? 'green.800' : 'red.700'
          }
          return (
            <Box flexShrink={0} key={i} rounded='full' w={1} h={1} bg={color} />
          )
        })}
      </HStack>
    )
  }

  const onRightAddonClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    e.preventDefault()
    if (!focused) {
      ref.current.focus()
    } else {
      ref.current.blur()
    }
  }

  const lastTryFailed = useMemo(
    () =>
      attempts.length < 3 &&
      attempts.length >= 1 &&
      last(attempts) === false &&
      !focused,
    [attempts, focused]
  )

  const inputColor = useMemo(() => {
    return last(attempts) === true
      ? 'green.800'
      : attempts.length === 3 || lastTryFailed
      ? 'red.700'
      : 'black.500'
  }, [attempts, lastTryFailed])

  const readOnly = attempts.length === 3 || last(attempts) === true

  const onFocus = () => {
    if (!readOnly) {
      setFocused(true)
      setV('')
    }
  }

  // console.log('correct value', correctValue)

  const renderInput = () => {
    if (isCorrect) {
      return (
        <CorrectText
          answer={v}
          correctAnswer={correctValue}
          h={5}
          pt={1}
          px={0}
          // fontWeight={'semibold'}
          textStyle={'medium'}
        />
      )
    } else {
      return (
        <Input
          type='text'
          ref={ref}
          value={v}
          fontSize={{ base: '16px', lg: '16px' }}
          fontWeight={450}
          letterSpacing={{ base: '-0.32px', lg: '-0.36px' }}
          lineHeight={{ base: '20px', lg: '24px' }}
          as={AutowidthInput}
          onChange={e => setV(e.target.value)}
          onBlur={apply}
          px={0}
          // pt={1}
          autoComplete='off'
          // placeholder='Перевод'
          _placeholder={{ color: 'black.300' }}
          minW={focused || v !== '' ? '16px' : '48px'}
          h={4}
          onFocus={onFocus}
          // width={'auto'}
          border='none'
          // bg={isCorrect ? GREEN : attempts.length === 3 ? RED : undefined}
          // _disabled={{ opacity: 1 }}
          // disabled={attempts.length === 3 || last(attempts) === true}
          readOnly={readOnly}
          // borderColor={'midGray'}
          // _focus={{
          //   borderColor: 'black'
          // }}
          _focusVisible={{
            outline: 'none'
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              ref.current?.blur()
            }
          }}
          rounded={'0'}
          extraWidth={0}
          {...rest}
        />
      )
    }
  }

  // console.log({
  //   highlightCorrectAnswer,
  //   readOnly,
  //   isCorrect,
  //   completed,
  //   lastTryFailed
  // })

  return (
    <VStack spacing={0} h='5' pt={0.5}>
      <HStack w='auto' role={'group'} spacing={1} color={inputColor}>
        <VStack spacing={0} justify='flex-end' flexShrink={0}>
          {renderInput()}
          {renderAttempts()}
        </VStack>
        {highlightCorrectAnswer && readOnly && !isCorrect && (
          // <Flex h={5} align='center' px={2} mb={1}>
          //   <Text color='green'>{correctValues.join(' / ')}</Text>
          // </Flex>
          <VStack align='flex-strt' justify={'flex-start'} px={0} spacing={0}>
            <Text color='green.800'>{correctValues.join(' / ')}</Text>
            <HStack w='full' h={1}>
              <Divider borderColor={'green.800'} />
            </HStack>
          </VStack>
        )}
        {!readOnly && focused && (
          <Flex
            // bg={
            //   completed
            //     ? inputColor === 'red'
            //       ? RED
            //       : GREEN
            //     : focused
            //     ? 'black'
            //     : 'white'
            // }
            bg='black.800'
            _hover={{ cursor: readOnly || !focused ? 'text' : 'pointer' }}
            h={6}
            w={6}
            rounded='full'
            color='white'
            align='center'
            justify='center'
          >
            {!completed && <IconEnter width={12} height={10} />}
          </Flex>
        )}
      </HStack>
    </VStack>
  )
}

export default WordInputLight
