import { FC } from 'react'
import { Button, HStack, VStack } from '@chakra-ui/react'
import {
  IPadding,
  ISectionSyncTranslate,
  ISyncTransalteParagraph,
  ITestContent
} from 'shared/types'
import get from 'lodash/get'
import map from 'lodash/map'
import PaddingSelect from 'components/PaddingSelect'
import SimpleInput from 'shared/components/SimpleInput'
import { getTestTitle } from 'shared/utils/tests'
import EditTestSyncTranslate from 'components/contentSettingsCard/EditTestSyncTranslate'
import SectionWidthSelect from 'components/SectionWidthSelect'
import SyncTranslateParagraph from 'components/sections/editSectionSyncTranslte/SyncTranslatePargraph'

type Props = {
  s: ISectionSyncTranslate
  onChange: (s: ISectionSyncTranslate) => void
}

const EditSectionSyncTranslate: FC<Props> = ({ s, onChange }) => {
  const onChangeSectionWidth = (v: number) => {
    onChange({ ...s, widthCol: v })
  }

  const renderSectionWidth = () => {
    return (
      <SectionWidthSelect value={s.widthCol} onChange={onChangeSectionWidth} />
    )
  }

  const renderPadding = () => {
    return (
      <PaddingSelect
        value={s.paddingTop}
        onChange={(v: IPadding) => onChange({ ...s, paddingTop: v })}
      />
    )
  }

  const onParagraphChange = (p: ISyncTransalteParagraph, i: number) => {
    const newParagraphs = [...s.paragraphs]
    newParagraphs[i] = p
    onChange({ ...s, paragraphs: newParagraphs })
  }

  const onParagraphDelete = (i: number) => {
    const newParagraphs = [...s.paragraphs]
    newParagraphs.splice(i, 1)
    onChange({ ...s, paragraphs: newParagraphs })
  }

  const onAddParagraph = () => {
    const p = { left: '', right: '' }
    onChange({ ...s, paragraphs: [...(s.paragraphs || []), p] })
  }

  const renderEditContent = () => {
    return (
      <VStack w='full'>
        {map(s.paragraphs, (p, i) => {
          return (
            <SyncTranslateParagraph
              key={i}
              p={p}
              onChange={p => onParagraphChange(p, i)}
              onDelete={() => onParagraphDelete(i)}
            />
          )
        })}
        <HStack justify={'flex-start'}>
          <Button size='sm' colorScheme='wood' onClick={onAddParagraph}>
            Add paragraph
          </Button>
        </HStack>
      </VStack>
    )
  }

  return (
    <VStack w='full' spacing={6}>
      {renderSectionWidth()}
      {renderPadding()}
      {renderEditContent()}
    </VStack>
  )
}

export default EditSectionSyncTranslate
