import { useMemo } from 'react'
import { Button, HStack, VStack, Wrap, WrapItem } from '@chakra-ui/react'
import NavBar from 'components/NavBar'
import { useSelector } from 'model/hooks'
import map from 'lodash/map'
import { ICoursesPack } from 'shared/types'
import imageDefault from 'shared/assets/course-default.png'
import { AddIcon } from '@chakra-ui/icons'
import { dbCreateCoursesPack } from 'controllers/coursesPacks'
import { useNavigate } from 'react-router-dom'
import orderBy from 'lodash/orderBy'
import EntityCard from 'components/EntityCard'
import dayjs from 'dayjs'
// import size from 'lodash/size'
// import { createLabel } from 'shared/utils/format'

const CoursesPacks = () => {
  const navigate = useNavigate()
  const coursesPacks = useSelector(state => state.coursesPacks)

  const sortedCoursesPacks = useMemo(() => {
    return orderBy(coursesPacks, 'createdAt', 'desc')
  }, [coursesPacks])

  const renderCard = (c: ICoursesPack) => {
    return (
      <WrapItem key={c.id}>
        <EntityCard
          url={`/tariffs/${c.id}`}
          title={c.title}
          imageUrl={c.imageUrl || imageDefault}
          desc={[
            c.startDate ? dayjs(c.startDate).format('MMM D, YYYY') : '-',
            c.endDate ? dayjs(c.endDate).format('MMM D, YYYY') : '-'
          ]}
          lessonsAmount={123123}
          lessonsAmountDesc={'asdfasd'}
          //   c.content
          //     ? createLabel(size(c.content), ['Неделя', 'Недели', 'Недель'])
          //     : createLabel(size(c.lessons), ['Урок', 'Урока', 'Уроков'])
          // }
          closed={c.closed}
          invisible={c.invisible}
        />
      </WrapItem>
    )
  }

  const onCreateCoursesPackClick = async () => {
    const packId = await dbCreateCoursesPack()
    navigate(`/tariffs/${packId}`)
  }

  const navBarActions = (
    <HStack>
      <Button
        colorScheme='teal'
        leftIcon={<AddIcon />}
        onClick={onCreateCoursesPackClick}
      >
        Создать тариф
      </Button>
    </HStack>
  )

  return (
    <VStack w='full' h='full' spacing={0}>
      <NavBar hideBackButton title='Тарифы'>
        {navBarActions}
      </NavBar>
      <VStack align={'flex-start'} w='full' h='full' p={4} overflowY={'auto'}>
        <Wrap spacing={4}>{map(sortedCoursesPacks, renderCard)}</Wrap>
      </VStack>
    </VStack>
  )
}

export default CoursesPacks
