import { createSelector } from '@reduxjs/toolkit'
import { getCourses } from 'src/model/selectors/base'
import orderBy from 'lodash/orderBy'
import keyBy from 'lodash/keyBy'

export const getSortedCourses = createSelector([getCourses], courses => {
  return orderBy(courses, 'createdAt', 'desc')
})

export const getCoursesDict = createSelector([getCourses], courses => {
  return keyBy(courses, 'id')
})
