import { FlexProps, Button, ButtonProps } from '@chakra-ui/react'
import startsWith from 'lodash/startsWith'
import { useLocation } from 'react-router-dom'

interface NavItemProps extends FlexProps {
  name: string
  path: string
  isDisabled?: boolean
  onClick: () => void
  buttonProps?: ButtonProps
}
const NavItem = ({
  name,
  path,
  isDisabled,
  buttonProps,
  onClick
}: NavItemProps) => {
  const location = useLocation()
  const isSelected = startsWith(location.pathname, path)

  return (
    <Button
      onClick={onClick}
      variant={'unstyled'}
      isDisabled={isDisabled}
      fontWeight={isSelected ? 'bold' : 'normal'}
      borderRadius='lg'
      _hover={{
        bg: 'gray.400',
        color: 'white'
      }}
      px={4}
      {...buttonProps}
    >
      {name}
    </Button>
  )
}

export default NavItem
