import { useState, useEffect } from 'react'
import {
  HStack,
  VStack,
  Text,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td
} from '@chakra-ui/react'
import NavBar from 'components/NavBar'
import isEmpty from 'lodash/isEmpty'
import { IFeedback } from 'shared/types'
import dayjs, { Dayjs } from 'dayjs'
import MonthPicker from 'components/MonthPicker'
import { dbGetFeedbackByDate } from 'controllers/feedback'

const Payments = () => {
  const [startTime, setStartTime] = useState(+dayjs())
  const [rows, setRows] = useState<IFeedback[]>([])

  useEffect(() => {
    setStartTime(+dayjs().date(1).hour(0).minute(0).second(0).millisecond(0))
  }, [])

  useEffect(() => {
    const run = async () => {
      const monthFeedback = await dbGetFeedbackByDate(
        startTime,
        +dayjs(startTime).add(1, 'month')
      )
      setRows(monthFeedback)
    }
    run()
  }, [startTime])

  const renderRows = () => {
    if (isEmpty(rows)) {
      return (
        <HStack w='full' justify={'center'} pt='20'>
          <Text>За выбраный месяц нет отзывов</Text>
        </HStack>
      )
    } else {
      return (
        <TableContainer
          w='full'
          bg='white'
          rounded={'md'}
          textStyle={'body'}
          overflowY='auto'
          whiteSpace='normal'
        >
          <Table size={'sm'}>
            <Thead position={'sticky'} top={0} zIndex={2} bg='white'>
              <Tr>
                <Th>Имя</Th>
                <Th>Email</Th>
                <Th>Телефон</Th>
                <Th>Дата</Th>
                <Th w='lg'>Отзыв</Th>
              </Tr>
            </Thead>
            <Tbody>
              {rows.map(p => {
                return (
                  <Tr>
                    <Td>{p.name}</Td>
                    <Td>{p.email}</Td>
                    <Td>{p.phone}</Td>
                    <Td>{dayjs(p.createdAt).format('D MMMM, HH:mm')}</Td>
                    <Td w='lg'>
                      <Text w='lg'>{p.message}</Text>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )
    }
  }

  const navBarActions = (
    <HStack>
      <MonthPicker
        value={dayjs(startTime)}
        onSelect={(v: Dayjs) => setStartTime(+v)}
      />
    </HStack>
  )

  return (
    <VStack w='full' h='full' spacing={0}>
      <NavBar hideBackButton title='Отзывы'>
        {navBarActions}
      </NavBar>
      <VStack align={'flex-start'} w='full' h='full' p={4} overflowY={'auto'}>
        {renderRows()}
      </VStack>
    </VStack>
  )
}

export default Payments
