import { FC } from 'react'
import { VStack, HStack, Box, Button } from '@chakra-ui/react'
import {
  IAlignment,
  IPadding,
  ISectionHeading,
  IStyledText,
  ITextStyle
} from 'shared/types'
import map from 'lodash/map'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from 'react-beautiful-dnd'
import { ReactComponent as IconX } from 'shared/assets/bsXCircle.svg'
import { ReactComponent as IconList } from 'shared/assets/bsList.svg'
import EditableReachField from 'components/EditableReachField'
import { arrayMove } from 'shared/utils/array'
import AlignmentSelect from 'components/AlignmentSelect'
import SectionWidthSelect from 'components/SectionWidthSelect'
import PaddingSelect from 'components/PaddingSelect'

type Props = {
  s: ISectionHeading
  onChange: (s: ISectionHeading) => void
}

const EditSectionHeading: FC<Props> = ({ s, onChange }) => {
  const onChangeRowText = (v: string, i: number) => {
    const newData = [...s.data]
    newData[i] = { ...newData[i], text: v }
    onChange({ ...s, data: newData })
  }

  const onRemoveRow = (i: number) => {
    const newData = [...s.data]
    newData.splice(i, 1)
    onChange({ ...s, data: newData })
  }

  const onDragEnd = (result: DropResult) => {
    console.log('onDragEnd', result)
    if (result.destination && result.destination.index >= 0) {
      const newData = arrayMove(
        s.data,
        result.source.index,
        result.destination?.index || 0
      )
      onChange({ ...s, data: newData })
    }
  }

  const onAddRow = () => {
    const newRow: IStyledText = {
      text: 'новая строка',
      textStyle: 'body'
    }
    const newData = [...s.data, newRow]
    onChange({ ...s, data: newData })
  }

  const onTextStyleChange = (v: ITextStyle, i: number) => {
    const newData = [...s.data]
    newData[i] = { ...newData[i], textStyle: v }
    onChange({ ...s, data: newData })
  }

  const onChangeAlignment = (alignment: IAlignment) => {
    onChange({ ...s, alignment })
  }

  const onChangeSectionWidth = (v: number) => {
    onChange({ ...s, widthCol: v })
  }

  const renderRow = (r: IStyledText, i: number, isDraggable: boolean) => {
    if (isDraggable) {
      return (
        <Draggable key={i} draggableId={r.text + i} index={i}>
          {(provided, snapshot) => (
            <HStack
              borderWidth={1}
              borderColor='wood.600'
              px={2}
              w='full'
              // h={{ base: 6, lg: 8 }}
              rounded='4px'
              flexShrink={0}
              align='center'
              justify={'space-between'}
              color='wood.600'
              textStyle={{ base: 'tag', lg: 'small' }}
              ref={provided.innerRef}
              {...provided.draggableProps}
            >
              <Box {...provided.dragHandleProps}>
                <IconList width='14' height='14' />
              </Box>
              <EditableReachField
                v={r.text}
                onChange={(v: string) => onChangeRowText(v, i)}
                textStyle={r.textStyle}
                onTextStyleChange={v => onTextStyleChange(v, i)}
              />
              <Box
                textStyle={{ base: 'tag', lg: 'small' }}
                as='button'
                onClick={() => onRemoveRow(i)}
                color='red.700'
              >
                <IconX width='14' height='14' />
              </Box>
            </HStack>
          )}
        </Draggable>
      )
    } else {
      return (
        <HStack
          borderWidth={1}
          borderColor='wood.600'
          px={2}
          w='full'
          rounded='4px'
          flexShrink={0}
          align='center'
          justify={'space-between'}
          color='wood.600'
          textStyle={{ base: 'tag', lg: 'small' }}
        >
          <HStack minH={8} w='full'>
            <Box dangerouslySetInnerHTML={{ __html: r.text }} />
          </HStack>
        </HStack>
      )
    }
  }

  const renderRows = () => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId='tags'
          renderClone={(provided, snapshot, rubric) => {
            return (
              <div ref={provided.innerRef} {...provided.draggableProps}>
                {renderRow(s.data[rubric.source.index], 0, false)}
              </div>
            )
          }}
        >
          {(provided, snapshot) => (
            <VStack
              w='full'
              direction={'column'}
              align='flex-start'
              textStyle='body'
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {map(s.data, (p, i) => renderRow(p, i, true))}
              {provided.placeholder}
            </VStack>
          )}
        </Droppable>
      </DragDropContext>
    )
  }

  const renderAddRow = () => {
    return (
      <Button variant={'ghost'} size='sm' colorScheme='wood' onClick={onAddRow}>
        + Добавить строку
      </Button>
    )
  }

  const renderAlignment = () => {
    return <AlignmentSelect value={s.alignment} onChange={onChangeAlignment} />
  }

  const renderSectionWidth = () => {
    return (
      <SectionWidthSelect value={s.widthCol} onChange={onChangeSectionWidth} />
    )
  }

  const renderPadding = () => {
    return (
      <PaddingSelect
        value={s.paddingTop}
        onChange={(v: IPadding) => onChange({ ...s, paddingTop: v })}
      />
    )
  }

  return (
    <VStack w='full' spacing={6}>
      {renderSectionWidth()}
      {renderPadding()}
      {renderAlignment()}
      <VStack w='full'>
        {renderRows()}
        {renderAddRow()}
      </VStack>
    </VStack>
  )
}

export default EditSectionHeading
