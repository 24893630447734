import { createReducer } from '@reduxjs/toolkit'
import { INotificationsSettings } from 'shared/types'
import { receiveNotificationsSettings } from 'src/model/actions'

const initialState: Record<string, INotificationsSettings> = {}

const reducer = createReducer(initialState, builder => {
  builder.addCase(
    receiveNotificationsSettings,
    (state, action) => action.payload
  )
})

export default reducer
