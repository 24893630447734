import { ReactNode, FC, useMemo } from 'react'
import { Flex } from '@chakra-ui/react'

type Props = {
  children: ReactNode
  status: 'transparent' | 'gray' | 'success' | 'error' | string
}

const LetterContainer: FC<Props> = ({ children, status }) => {
  const bgColor = useMemo(() => {
    switch (status) {
      case 'gray':
        return 'black.100'
      case 'success':
        return 'green.50'
      case 'error':
        return 'red.50'
      default:
        return 'transparent'
    }
  }, [status])

  return (
    <Flex
      w='28px'
      h='40px'
      rounded={'6px'}
      borderWidth={1}
      borderColor={'black.300'}
      backgroundColor={bgColor}
      align={'center'}
      justify={'center'}
      ml={'-1px'}
    >
      {children}
    </Flex>
  )
}

export default LetterContainer
