import { ReactNode, forwardRef, Ref, ForwardRefRenderFunction } from 'react'
import { Box, BoxProps, Flex, StackProps } from '@chakra-ui/react'
import BackButton from 'shared/components/BackButton'
import { MAX_WIDTH } from 'shared/constants/main'

type Props = StackProps & {
  children: ReactNode
  backUrl?: string
  backBg?: string
  full?: boolean
  containerProps?: BoxProps
}
export interface IContentContainer {}

const ContentContainer: ForwardRefRenderFunction<IContentContainer, Props> = (
  { children, backUrl, backBg, containerProps, full, ...rest },
  ref: Ref<unknown>
) => {
  return (
    <Box
      w='full'
      bg={backBg}
      h={full ? 'full' : 'auto'}
      minH={full ? '80vh' : undefined}
      position='relative'
      {...containerProps}
      ref={ref}
    >
      <Flex w='full' justify='center' h={full ? 'full' : 'auto'} {...rest}>
        <Flex
          w='full'
          maxW={MAX_WIDTH}
          px={{ base: 4, lg: 8 }}
          align={'center'}
          direction={'column'}
        >
          {backUrl && <BackButton backUrl={backUrl} />}
          {children}
        </Flex>
      </Flex>
    </Box>
  )
}

export default forwardRef(ContentContainer)
