import { HamburgerIcon } from '@chakra-ui/icons'
import {
  FlexProps,
  Flex,
  useColorModeValue,
  IconButton
} from '@chakra-ui/react'

interface MobileProps extends FlexProps {
  onOpen: () => void
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 2, md: 24 }}
      height='16'
      alignItems='center'
      // bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth='1px'
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent='flex-start'
      // bg='yellow'
      position={'fixed'}
      top={0}
      right={0}
      {...rest}
    >
      <IconButton
        variant='outline'
        onClick={onOpen}
        aria-label='open menu'
        icon={<HamburgerIcon />}
      />
    </Flex>
  )
}

export default MobileNav
