import { db } from 'controllers/db'
import dayjs from 'dayjs'
import {
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where
} from 'firebase/firestore'
import get from 'lodash/get'
import { receiveChannels } from 'model/actions'
import store from 'model/store'
import { IChannel, IMessage } from 'shared/types'

export const dbSendMessage = async (m: IMessage, entityId: string) => {
  const ref = doc(collection(db, 'chat'), m.id)
  await setDoc(ref, m)
  const channels = store.getState().channels
  const ch = get(channels, m.channelId)
  const chRef = doc(collection(db, 'chatChannel'), m.channelId)
  if (ch) {
    const upd = {
      [`readBy.${m.senderId}`]: +dayjs(),
      lastMessage: m
    }
    await updateDoc(chRef, upd)
  } else {
    const newCh: IChannel = {
      id: m.channelId,
      lessonId: m.lessonId,
      userId: m.userId,
      entityId,
      readBy: {
        [m.senderId]: +dayjs()
      },
      unread: {},
      lastMessage: m
    }
    await setDoc(chRef, newCh)
  }
}

export const dbUpdateReadBy = (
  channelId: string,
  userId: string,
  t: number
) => {
  const chRef = doc(collection(db, 'chatChannel'), channelId)
  const upd = {
    [`readBy.${userId}`]: t
  }
  updateDoc(chRef, upd)
}

export const dbSubscribeToChat = (
  lessonId: string,
  userId: string,
  callback: (messages: IMessage[]) => void
) => {
  try {
    if (userId) {
      const q = query(
        collection(db, 'chat'),
        where('lessonId', '==', lessonId),
        where('userId', '==', userId),
        orderBy('createdAt')
      )
      const unsubscribe = onSnapshot(
        q,
        sn => {
          const res: IMessage[] = []
          sn.forEach(doc => {
            const m = doc.data() as IMessage
            res.push(m)
          })
          callback(res)
        },
        err => {
          console.log(`dbSubscribeToChat error: ${err.message}`)
        }
      )
      return unsubscribe
    } else {
      return null
    }
  } catch (e) {
    console.error('dbSubscribeToChat error', e)
    return null
  }
}

export const dbFetchChatChannels = (userId: string) => {
  try {
    console.log('dbFetchChatChannels', userId)
    const q = query(
      collection(db, 'chatChannel'),
      where(`unread.${userId}`, '>', 0),
      limit(100)
    )
    onSnapshot(
      q,
      sn => {
        const res: Record<string, IChannel> = {}
        sn.forEach(doc => {
          const p = doc.data() as IChannel
          res[doc.id] = p
        })
        // console.log('receive chat channels', res)
        store.dispatch(receiveChannels(res))
      },
      err => {
        console.log(`dbFetchChatChannels error: ${err.message}`)
      }
    )
  } catch (e) {
    console.error('dbFetchChatChannels error', e)
  }
}
