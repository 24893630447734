import { IEntityType } from 'shared/types'

const entityName: Record<IEntityType, string> = {
  [IEntityType.WEBINARS]: 'Мои Вебинары',
  [IEntityType.CONFERENCES]: 'Мои Конференции',
  [IEntityType.SEMINARS]: 'Мои Семинары',
  [IEntityType.COURSES]: 'Мои Курсы',
  [IEntityType.LIBRARY]: 'Моя Библиотека',
  [IEntityType.FREE]: 'Бесплатно',
  [IEntityType.PAYMENT_LINKS]: 'Платежная ссылка'
}

export default entityName
