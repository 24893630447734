import { FC, ReactNode } from 'react'
import { ISection, ISectionTestTrueFalse, ISessionAnswer } from 'shared/types'
import { VStack, Text, Box, HStack, Button } from '@chakra-ui/react'
import { ReactComponent as IconCheck } from 'shared/assets/bsCheck.svg'
import { ReactComponent as IconX } from 'shared/assets/bsX.svg'
import TestTask from 'shared/components/sections/tests/TestTask'
import get from 'lodash/get'
import { getTestTitle } from 'shared/utils/tests'
import TestCard from 'shared/components/sections/tests/TestCard'
import SectionTextContent from 'shared/components/SectionTextContent'

type Props = {
  s: ISectionTestTrueFalse
  onAnswer?: (sectionId: string, answer: ISessionAnswer) => void
  answer?: ISessionAnswer
  testNum?: number
  showUserAnswer?: boolean
  isExamination: boolean
}

const SectionTestOneOption: FC<Props> = ({
  s,
  onAnswer,
  answer,
  testNum,
  showUserAnswer,
  isExamination
}) => {
  const renderQuestion = () => {
    if (s.htmlString) {
      return (
        <SectionTextContent
          r={{ text: s.htmlString, textStyle: s.textStyle }}
        />
      )
    }
  }

  const checkAnswer = (a: string) => {
    const correctAnswer = get(s, 'test.answer')
    const sa: ISessionAnswer = {
      answer: a,
      isCorrect: a === correctAnswer
    }
    if (onAnswer) {
      onAnswer(s.id, sa)
    }
  }

  const renderButton = (value: string, title: string, icon: ReactNode) => {
    let borderColor = 'black.950'
    if (showUserAnswer && answer && answer.answer === value) {
      borderColor = 'black.950'
    } else if (showUserAnswer && answer && answer.answer !== value) {
      borderColor = 'black.950'
    } else if (answer && answer.answer === value) {
      borderColor = answer.isCorrect ? 'green.800' : 'red.700'
    } else if (answer && !answer.isCorrect && s.test.answer === value) {
      borderColor = 'green.800'
    } else if (answer) {
      borderColor = 'black.300'
    }

    let bgColor = 'transparent'
    if (showUserAnswer && answer && answer.answer === value) {
      bgColor = 'black.950'
    } else if (showUserAnswer && answer && answer.answer !== value) {
      bgColor = 'white'
    } else if (answer && answer.answer === value) {
      bgColor = answer.isCorrect ? 'green.800' : 'red.700'
    }

    let color = 'black.950'
    if (showUserAnswer && answer && answer.answer === value) {
      color = 'white'
    } else if (showUserAnswer && answer && answer.answer !== value) {
      color = 'black.950'
    } else if (answer && answer.answer === value) {
      color = 'white'
    } else if (answer && !answer.isCorrect && s.test.answer === value) {
      color = 'green.800'
    } else if (answer) {
      color = 'black.300'
    }

    return (
      <HStack
        spacing={2}
        align={'center'}
        justify={'center'}
        borderWidth={1}
        borderColor={borderColor}
        rounded={'full'}
        py={{ base: '6px', lg: 2 }}
        px={'4'}
        w={{ base: 'full', lg: 'full' }}
        as='button'
        bg={bgColor}
        _hover={{
          cursor: answer && !showUserAnswer ? 'not-allowed' : 'pointer'
        }}
        onClick={
          (!answer || showUserAnswer) && onAnswer
            ? () => checkAnswer(value)
            : undefined
        }
        color={color}
      >
        {icon}
        <Text textStyle='small' color={color} fontWeight={500}>
          {title}
        </Text>
      </HStack>
    )
  }

  return (
    <TestCard showUserAnswer={showUserAnswer} answer={answer}>
      <TestTask
        title={s.test?.title || getTestTitle(s.type)}
        answer={showUserAnswer ? undefined : answer}
        testNum={testNum}
      />
      {renderQuestion()}
      <HStack w='full' spacing={{ base: 2, lg: 4 }}>
        {renderButton('yes', 'Верно', <IconCheck width={16} height={16} />)}
        {renderButton('no', 'Неверно', <IconX width={16} height={16} />)}
      </HStack>
    </TestCard>
  )
}

export default SectionTestOneOption
