import {
  HStack,
  Text,
  Box,
  Editable,
  EditablePreview,
  EditableInput,
  Input
} from '@chakra-ui/react'
import { FC, useState, useEffect } from 'react'
import { ReactComponent as IconX } from 'shared/assets/bsXCircle.svg'
import { ReactComponent as IconList } from 'shared/assets/bsList.svg'
import {
  DraggableProvidedDragHandleProps,
  Draggable
} from 'react-beautiful-dnd'
import SimpleInput from 'shared/components/SimpleInput'

type Props = {
  t: string
  i: number
  onRemove?: (i: number) => void
  onChange?: (t: string) => void
  // dragHandleProps?: DraggableProvidedDragHandleProps | null
}

const CourseTag: FC<Props> = ({ t, i, onRemove, onChange = () => null }) => {
  const [v, setV] = useState<string>(t ? t.toString() : '')

  useEffect(() => {
    setV(t ? t.toString() : '')
  }, [t])

  const apply = () => {
    console.log('onBlur, apply', v)
    onChange(v)
  }

  const getItemStyle = (isDragging: boolean) => ({
    py: 1,
    display: 'flex'
  })

  if (onRemove) {
    return (
      <Draggable key={t + i} draggableId={'draggable' + i} index={i}>
        {(provided, snapshot) => (
          <HStack
            borderWidth={1}
            borderColor='black.500'
            px={2}
            w='full'
            h={{ base: 6, lg: 8 }}
            rounded='4px'
            flexShrink={0}
            align='center'
            justify={'space-between'}
            color='black.500'
            textStyle={{ base: 'tag', lg: 'small' }}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...getItemStyle(snapshot.isDragging)}
          >
            <Box {...provided.dragHandleProps}>
              <IconList width='14' height='14' />
            </Box>
            <Editable
              value={v}
              w='full'
              textAlign='center'
              onBlur={apply}
              selectAllOnFocus={false}
              // onChange={v => setV(v)}
            >
              <EditablePreview color='black.500'>
                <Text color='black.500'>{t}</Text>
              </EditablePreview>
              <Input
                w='full'
                as={EditableInput}
                variant={'flushed'}
                borderBottom={'none'}
                value={v}
                onChange={e => setV(e.target.value)}
              />
            </Editable>

            <Box
              textStyle={{ base: 'tag', lg: 'small' }}
              as='button'
              onClick={() => onRemove(i)}
              color='red.700'
            >
              <IconX width='14' height='14' />
            </Box>
          </HStack>
        )}
      </Draggable>
    )
  } else {
    return (
      <HStack
        borderWidth={1}
        borderColor='black.500'
        px={2}
        h={{ base: 6, lg: 8 }}
        rounded='4px'
        flexShrink={0}
        textStyle={{ base: 'tag', lg: 'small' }}
        align='center'
        justify={'center'}
        color='black.500'
      >
        <Text color='black.500'>{t}</Text>
      </HStack>
    )
  }
}

export default CourseTag
