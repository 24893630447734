import { VStack, Button, Divider } from '@chakra-ui/react'
import { FC } from 'react'
import { ITestContent, ISyncTranslate } from 'shared/types'
import map from 'lodash/map'
import Paragraph from 'components/contentSettingsCard/testSyncTranslate/Paragraph'

type Props = {
  test: ITestContent
  onChange: (v: ITestContent) => void
}

const EditTestSyncTranslate: FC<Props> = ({ test, onChange }) => {
  const onChangeSt = (i: number, newSt: ISyncTranslate) => {
    const curSyncTranslates = test.syncTranslate || []
    const newSyncTranslate = [...curSyncTranslates]
    newSyncTranslate[i] = newSt
    onChange({ ...test, syncTranslate: newSyncTranslate })
  }

  const renderParagraphs = () => {
    return map(test.syncTranslate, (st: ISyncTranslate, i: number) => {
      return (
        <Paragraph
          key={i}
          st={st}
          onChange={(st: ISyncTranslate) => onChangeSt(i, st)}
        />
      )
    })
  }

  const addParagraph = () => {
    const st: ISyncTranslate = {
      task: '',
      translate: '',
      matching: []
    }
    const curSyncTranslates = test.syncTranslate || []
    onChange({ ...test, syncTranslate: [...curSyncTranslates, st] })
  }

  const renderButton = () => {
    return (
      <Button variant={'primary'} onClick={addParagraph}>
        Добавить абзац
      </Button>
    )
  }

  return (
    <VStack w='full' align={'flex-start'} spacing={8} divider={<Divider />}>
      {renderParagraphs()}
      {renderButton()}
    </VStack>
  )
}

export default EditTestSyncTranslate
