import {
  onSnapshot,
  query,
  collection,
  doc,
  deleteDoc,
  setDoc,
  updateDoc
} from 'firebase/firestore'
import { db } from 'src/controllers/db'
import { IAdmin, INotificationsSettings } from 'shared/types'
import { receiveNotificationsSettings } from 'src/model/actions'
import store from 'src/model/store'
import { addListener } from 'controllers/listeners'

export const dbFetchNotificationsSettings = async () => {
  try {
    const q = query(collection(db, 'notificationsSettings'))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: Record<string, IAdmin> = {}
        sn.forEach(doc => {
          const p = doc.data() as IAdmin
          res[doc.id] = {
            ...p,
            id: doc.id
          }
        })
        store.dispatch(receiveNotificationsSettings(res))
      },
      err => {
        console.log(`dbFetchNotificationsSettings error: ${err.message}`)
      }
    )
    addListener('notificationsSettings', unsubscribe)
  } catch (e) {
    console.error('dbFetchNotificationsSettings error', e)
  }
}

export const dbDeleteNotificationEmail = async (email: string) => {
  const ref = doc(db, 'notificationsSettings', email)
  await deleteDoc(ref)
}

export const dbAddNotificationEmail = async (email: string) => {
  const ref = doc(db, 'notificationsSettings', email)
  await setDoc(ref, { email })
}

export const dbUpdateNotificationEmail = async (
  email: string,
  upd: Partial<INotificationsSettings>
) => {
  const ref = doc(db, 'notificationsSettings', email)
  await updateDoc(ref, upd)
}
