import { initializeApp } from 'firebase/app'
import {
  doc,
  collection,
  initializeFirestore,
  deleteField
} from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage, ref } from 'firebase/storage'
import { mapObject } from 'shared/utils/object'
import { getFunctions } from 'firebase/functions'

import config from 'shared/config'

const firebaseApp = initializeApp(config)

export const db = initializeFirestore(firebaseApp, {
  experimentalForceLongPolling: true
})
export const auth = getAuth()
export const storage = getStorage(firebaseApp)
export const storageRef = (refer: string) => ref(storage, refer)
export const functions = getFunctions(firebaseApp, 'europe-central2')

export const generateId = (): string => {
  return doc(collection(db, 'tmp')).id
}

export const dbOmit = (data: Record<string, any>) => {
  return mapObject(data, (value: any) =>
    value === undefined ? deleteField() : value
  )
}
