import { createSelector } from '@reduxjs/toolkit'
import {
  getWebinars,
  getCourses,
  getLibrary,
  getSeminars,
  getConferences,
  getFrees
} from 'src/model/selectors/base'

export const getEntities = createSelector(
  [getWebinars, getCourses, getLibrary, getSeminars, getConferences, getFrees],
  (webinars, courses, library, seminars, conferences, frees) => {
    return {
      ...(webinars || {}),
      ...(courses || {}),
      ...(library || {}),
      ...(seminars || {}),
      ...(conferences || {}),
      ...(frees || {})
    }
  }
)
