import { FC } from 'react'
import { IWordCard, IWordCardTag } from 'shared/types'
import {
  VStack,
  HStack,
  Text,
  Box,
  Divider,
  Stack,
  Show
} from '@chakra-ui/react'
import map from 'lodash/map'
import get from 'lodash/get'
import AudioPlayerIconMode from 'shared/components/AudioPlayerIconMode'
import SectionTextContent from 'shared/components/SectionTextContent'

type Props = {
  card: IWordCard
  cardTags: Record<string, IWordCardTag> | null
  status?: boolean
}

const WordCard: FC<Props> = ({ card, cardTags, status }) => {
  const renderTags = () => {
    return (
      <HStack spacing={2}>
        {map(card.tags, t => {
          const title = get(cardTags, [t, 'title'])
          if (title) {
            return (
              <Box
                rounded='full'
                borderColor='black'
                borderWidth={1}
                key={t}
                py={{ base: 0.5, lg: 1 }}
                px={5}
              >
                <Text textStyle='tag'>{title}</Text>
              </Box>
            )
          }
        })}
      </HStack>
    )
  }

  const renderWord = () => {
    return (
      <HStack w='full' justify={{ base: 'start', lg: 'space-between' }}>
        <Text textStyle={'body'}>
          <Text color='sun.950' fontWeight={'bold'} as='span'>
            {card.word}
          </Text>
          <Show above='lg'>
            {' — '}
            <Text as='span'>{card.translate.join(' / ')}</Text>
          </Show>
        </Text>
        <HStack spacing={2}>
          {card.transcription && <Text>[{card.transcription || ''}]</Text>}
          {card.audio && card.audio.url && (
            <AudioPlayerIconMode url={card.audio.url} />
          )}
        </HStack>
      </HStack>
    )
  }

  const renderDefinition = () => {
    if (card.definition) {
      return (
        <VStack w='full' align='flex-start' pt={4} spacing={4}>
          <Divider />
          <Text textStyle={'body'} color='black'>
            <i>{card.definition}</i>
          </Text>
        </VStack>
      )
    }
  }

  const renderParagraphs = () => {
    if (card.paragraphs) {
      return (
        <VStack w='full' align='flex-start' pt={4} spacing={4}>
          <Divider />
          <VStack w='full' spacing={{ base: 4, lg: 2 }}>
            {map(card.paragraphs, (p, i) => {
              return (
                <Stack
                  key={i}
                  direction={{ base: 'column', lg: 'row' }}
                  w='full'
                  spacing={{ base: 0, lg: 6 }}
                >
                  <Box flex={1}>
                    <SectionTextContent
                      r={{ text: p.left, textStyle: 'small' }}
                    />
                  </Box>
                  <Box flex={1}>
                    <SectionTextContent
                      r={{ text: p.right, textStyle: 'small' }}
                    />
                  </Box>
                </Stack>
              )
            })}
          </VStack>
        </VStack>
      )
    }
  }

  return (
    <VStack
      w='full'
      bg='wood.50'
      borderColor={
        status === undefined ? 'wood.900' : status ? 'green.800' : 'red.700'
      }
      borderWidth={2}
      rounded={'xs'}
      p={4}
      align={'start'}
      spacing={0}
    >
      {renderTags()}
      <HStack w='full' justify={'space-between'} pt={5}>
        {renderWord()}
      </HStack>
      <Show below='lg'>
        <Text pt={2} as='span'>
          {card.translate.join(' / ')}
        </Text>
      </Show>
      {renderDefinition()}
      {renderParagraphs()}
    </VStack>
  )
}

export default WordCard
