import {
  onSnapshot,
  query,
  collection,
  doc,
  updateDoc,
  setDoc,
  writeBatch
} from 'firebase/firestore'
import { db, dbOmit, generateId } from 'src/controllers/db'
import { ISeminar, ILesson, IEntityType } from 'shared/types'
import { receiveSeminars } from 'src/model/actions'
import store from 'src/model/store'
import { addListener } from 'controllers/listeners'
import dayjs from 'dayjs'

export const dbFetchSeminars = async () => {
  try {
    const q = query(collection(db, 'seminars'))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: Record<string, ISeminar> = {}
        sn.forEach(doc => {
          const p = doc.data() as ISeminar
          res[doc.id] = {
            ...p,
            id: doc.id,
            createdAt: +dayjs(p.createdAt || 0)
          }
        })
        store.dispatch(receiveSeminars(res))
      },
      err => {
        console.log(`dbFetchSeminars error: ${err.message}`)
      }
    )
    addListener('seminars', unsubscribe)
  } catch (e) {
    console.error('dbFetchSeminars error', e)
  }
}

export const dbUpdateSeminar = async (
  seminarId: string,
  upd: Partial<ISeminar>
) => {
  try {
    console.log('dbUpdateSeminar', seminarId, upd)
    const ref = doc(collection(db, 'seminars'), seminarId)
    await updateDoc(ref, dbOmit(upd))
  } catch (e) {
    console.log('dbUpdateSeminar error', e)
  }
}

export const dbCreateSeminar = async () => {
  const id = generateId()
  const c: ISeminar = {
    id,
    title: 'Новый семинар',
    invisible: true,
    closed: true,
    price: 0,
    lessons: [],
    sections: {},
    sectionsOrder: [],
    createdAt: +dayjs()
  }
  const ref = doc(collection(db, 'seminars'), id)
  await setDoc(ref, dbOmit(c))
  return id
}

export const dbDeleteSeminar = async (c: ISeminar) => {
  const batch = writeBatch(db)
  batch.delete(doc(collection(db, 'seminars'), c.id))
  if (c.lessons) {
    for (const lId of c.lessons) {
      batch.delete(doc(collection(db, 'lessons'), lId))
    }
  }
  await batch.commit()
}

export const dbDuplicateSeminar = async (
  c: ISeminar,
  lessons: Record<string, ILesson>
) => {
  const batch = writeBatch(db)
  const seminarId = generateId()
  const newSeminar: ISeminar = {
    ...c,
    id: seminarId,
    lessons: [],
    closed: true,
    invisible: true,
    createdAt: +dayjs(),
    title: c.title + ' (copy)',
    copyOf: {
      entityId: c.id,
      entityType: IEntityType.SEMINARS
    }
  }
  if (c.lessons) {
    for (const lId of c.lessons) {
      const l = lessons[lId]
      if (l) {
        const newL: ILesson = {
          ...l,
          id: generateId(),
          entityId: seminarId
        }
        newSeminar.lessons.push(newL.id)
        batch.set(doc(collection(db, 'lessons'), newL.id), newL)
      }
    }
  }

  batch.set(doc(collection(db, 'seminars'), seminarId), newSeminar)
  await batch.commit()
  return seminarId
}
