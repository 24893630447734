import { FC, useMemo } from 'react'
import {
  ISection,
  ISectionTestButtons,
  ISessionAnswer,
  ITestOption
} from 'shared/types'
import { VStack, Text, Box, HStack, Grid, GridItem } from '@chakra-ui/react'
import TestTask from 'shared/components/sections/tests/TestTask'
import map from 'lodash/map'
import get from 'lodash/get'
import { getTestTitle } from 'shared/utils/tests'
import TestCard from 'shared/components/sections/tests/TestCard'
import SectionTextContent from 'shared/components/SectionTextContent'

type Props = {
  s: ISectionTestButtons
  onAnswer?: (sectionId: string, answer: ISessionAnswer) => void
  answer?: ISessionAnswer
  testNum?: number
  showUserAnswer?: boolean
  isExamination: boolean
}

const SectionTestButtons: FC<Props> = ({
  s,
  onAnswer,
  answer,
  testNum,
  showUserAnswer,
  isExamination
}) => {
  const renderQuestion = () => {
    if (s.htmlString) {
      return (
        <SectionTextContent
          r={{ text: s.htmlString, textStyle: s.textStyle }}
        />
      )
    }
  }

  const highlightCorrectAnswer = get(s, 'test.highlightCorrectAnswer', false)

  const checkAnswer = (a: string) => {
    const correctAnswer = get(s, 'test.answer')
    const sa: ISessionAnswer = {
      answer: a,
      isCorrect: a === correctAnswer
    }
    if (onAnswer) {
      onAnswer(s.id, sa)
    }
  }

  const renderButton = (opt: ITestOption) => {
    let borderColor = 'black.950'
    if (showUserAnswer) {
      borderColor = 'black.950'
    } else if (answer && answer.answer === opt.id) {
      borderColor = answer.isCorrect ? 'green.800' : 'red.700'
    } else if (
      answer &&
      !answer.isCorrect &&
      s.test.answer === opt.id &&
      highlightCorrectAnswer
    ) {
      borderColor = 'green.800'
    } else if (answer) {
      borderColor = 'black.300'
    }

    let color = 'black.950'
    if (showUserAnswer) {
      color = answer && answer.answer === opt.id ? 'white' : 'black.950'
    } else if (answer && answer.answer === opt.id) {
      color = 'white'
    } else if (
      answer &&
      !answer.isCorrect &&
      s.test.answer === opt.id &&
      highlightCorrectAnswer
    ) {
      color = 'green.800'
    } else if (answer) {
      color = 'black.300'
    }

    let bgColor = 'transparent'
    if (showUserAnswer) {
      bgColor = answer && answer.answer === opt.id ? 'black.950' : 'transparent'
    } else if (answer && answer.answer === opt.id) {
      bgColor = answer.isCorrect ? 'green.800' : 'red.700'
    }

    return (
      <GridItem key={opt.id}>
        <HStack
          spacing={2}
          align={'center'}
          justify={'center'}
          borderWidth={1}
          borderColor={borderColor}
          rounded={'full'}
          py={{ base: '6px', lg: 2 }}
          px={'4'}
          w={{ base: 'full', lg: 'full' }}
          as='button'
          bg={bgColor}
          _hover={{
            cursor: answer && !showUserAnswer ? 'not-allowed' : 'pointer'
          }}
          onClick={
            (!answer || showUserAnswer) && onAnswer
              ? () => checkAnswer(opt.id)
              : undefined
          }
        >
          <Text textStyle='small' color={color} fontWeight={500}>
            {opt.value}
          </Text>
        </HStack>
      </GridItem>
    )
  }

  const templateColumns = useMemo(() => {
    if (s.test?.options) {
      const l = s.test.options.length
      if (l % 2 === 0) {
        return 2
      } else {
        return 3
      }
    } else {
      return 1
    }
  }, [s.test?.options])

  return (
    <TestCard showUserAnswer={showUserAnswer} answer={answer}>
      <TestTask
        title={s.test?.title || getTestTitle(s.type)}
        answer={showUserAnswer ? undefined : answer}
        testNum={testNum}
      />
      {renderQuestion()}
      <Grid
        templateColumns={`repeat(${templateColumns}, 1fr)`}
        w='full'
        gap={{ base: 2, lg: 4 }}
      >
        {map(s.test?.options, renderButton)}
      </Grid>
    </TestCard>
  )
}

export default SectionTestButtons
