import { FC, useState, useEffect } from 'react'
import {
  ISectionTestSyncTranslate,
  ISessionAnswer,
  ISyncTranslate
} from 'shared/types'
import { VStack } from '@chakra-ui/react'
import TestTask from 'shared/components/sections/tests/TestTask'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import size from 'lodash/size'
import filter from 'lodash/filter'
import { getTestTitle } from 'shared/utils/tests'
import TestCard from 'shared/components/sections/tests/TestCard'
import SyncTranslateParagraph from 'shared/components/sections/sectionTestSyncTranslate/SyncTranslateParagraph'

type Props = {
  s: ISectionTestSyncTranslate
  onAnswer?: (sectionId: string, answer: ISessionAnswer) => void
  answer?: ISessionAnswer
  testNum?: number
  showUserAnswer?: boolean
  isExamination: boolean
}

const SectionTestSyncTranslate: FC<Props> = ({
  s,
  onAnswer,
  answer,
  testNum,
  showUserAnswer,
  isExamination
}) => {
  const [paragraphsRes, setParagraphsRes] = useState([])

  useEffect(() => {
    if (s.test) {
      const st = filter(
        s.test.syncTranslate,
        (st: ISyncTranslate) => !isEmpty(st.matching)
      )
      setParagraphsRes(new Array(size(st)).fill(null))
    }
  }, [s.test])

  const onParagraphComplete = (i: number, isCorrect: boolean) => {
    // console.log('onParagraphComplete', i, isCorrect)
    const newParagraphs = [...paragraphsRes]
    newParagraphs[i] = isCorrect
    setParagraphsRes(newParagraphs)

    const completed = newParagraphs.filter(v => v != null)
    const correct = newParagraphs.filter(v => v != null && v)
    console.log({ newParagraphs, completed })
    if (completed.length === newParagraphs.length) {
      const sa: ISessionAnswer = {
        answer: '',
        isCorrect: correct.length === newParagraphs.length
      }
      if (onAnswer) {
        onAnswer(s.id, sa)
      }
    }
  }

  const renderParagraph = (st: ISyncTranslate, i: number) => {
    return (
      <SyncTranslateParagraph
        key={i}
        st={st}
        isExamination={isExamination}
        showUserAnswer={showUserAnswer}
        completed={!isNil(answer)}
        highlightCorrectAnswer={get(s.test, 'highlightCorrectAnswer', false)}
        onComplete={(isCorrect: boolean) => onParagraphComplete(i, isCorrect)}
      />
    )
  }

  return (
    <TestCard showUserAnswer={showUserAnswer} answer={answer}>
      <TestTask
        title={s.test?.title || getTestTitle(s.type)}
        answer={showUserAnswer ? undefined : answer}
        testNum={testNum}
      />
      <VStack w='full' spacing={{ base: 4, lg: 6 }}>
        {map(s.test?.syncTranslate, renderParagraph)}
      </VStack>
    </TestCard>
  )
}

export default SectionTestSyncTranslate
