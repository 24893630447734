import { FC } from 'react'
import { Text, VStack } from '@chakra-ui/react'
import SimpleInput from 'shared/components/SimpleInput'
import { arrayMove } from 'shared/utils/array'
import map from 'lodash/map'
import get from 'lodash/get'
import CourseTag from 'shared/components/CourseTag'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'

type Props = {
  tags: string[]
  onChange: (tags: string[]) => void
}

const EditTags: FC<Props> = ({ tags, onChange }) => {
  const addTag = (t: string) => {
    if (t !== '') {
      const newTags = [...tags, t]
      onChange(newTags)
    }
  }

  const deleteTag = (i: number) => {
    const newTags = [...tags]
    newTags.splice(i, 1)
    onChange(newTags)
  }

  // const onMove = (i: number, isUp: boolean) => {
  //   const newTags = arrayMove(tags, i, isUp ? i - 1 : i + 1)
  //   onChange(newTags)
  // }

  const onDragEnd = (result: DropResult) => {
    console.log('onDragEnd', result)
    if (result.destination && result.destination.index >= 0) {
      const newTags = arrayMove(
        tags,
        result.source.index,
        result.destination?.index || 0
      )
      onChange(newTags)
    }
  }

  const onChangeTag = (v: string, i: number) => {
    console.log('onChangeTag', v, i)
    if (v === '') {
      deleteTag(i)
    } else {
      const newTags = [...tags]
      newTags[i] = v
      onChange(newTags)
    }
  }

  return (
    <VStack w='full' align='flex-start'>
      <Text textStyle='small' color='black.950'>
        Тэги
      </Text>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId='tags'
          renderClone={(provided, snapshot, rubric) => {
            return (
              <div ref={provided.innerRef} {...provided.draggableProps}>
                <CourseTag
                  t={get(tags, rubric.source.index)}
                  i={rubric.source.index}
                />
              </div>
            )
          }}
        >
          {(provided, snapshot) => (
            <VStack
              w='full'
              direction={'column'}
              align='flex-start'
              textStyle='body'
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {map(tags, (t, i) => {
                return (
                  <CourseTag
                    key={t + i}
                    t={t}
                    i={i}
                    onRemove={deleteTag}
                    onChange={(v: string) => onChangeTag(v, i)}
                  />
                )
              })}
              {provided.placeholder}
            </VStack>
          )}
        </Droppable>
      </DragDropContext>

      <SimpleInput
        size='sm'
        variant='flushed'
        placeholder='Добавить тэг'
        onChange={(v: string) => addTag(v)}
        clearOnApply
      />
    </VStack>
  )
}

export default EditTags
