import { FC } from 'react'
import PageContentWrapper from 'shared/components/PageContentWrapper'
import { StorageReference } from 'firebase/storage'
import ContentContainer from 'shared/components/ContentContainer'
import { Button, Flex } from '@chakra-ui/react'
import PageContent from 'shared/components/PageContent'
import has from 'lodash/has'
import {
  ICourse,
  IFeedback,
  IFree,
  IItemInfo,
  IPartition,
  ITeacher,
  IUser,
  SectionType
} from 'shared/types'
import PageContentHeader from 'shared/components/PageContentHeader'
import AddSectionButton from 'shared/components/admin/AddSectionButton'
import dayjs from 'dayjs'

type Props = {
  course: IFree
  onApplyClick: () => void
  storageRef: (refer: string) => StorageReference
  admin?: {
    onAddSection: (sType: SectionType, i: number) => void
    onMoveSection: (fromIndex: number, toIndex: number) => void
    onEditSection: (sid: string) => void
    onEditHeader?: () => void
  }
  teachers: Record<string, ITeacher>
  partitions: Record<string, IPartition> | null
}

const FreePage: FC<Props> = ({
  course,
  storageRef,
  onApplyClick,
  admin,
  teachers,
  partitions
}) => {
  const renderHeader = () => {
    if (course) {
      const sDate = dayjs(course.startDate)
      const eDate = dayjs(course.endDate)
      return (
        <PageContentHeader
          partition={`${sDate.format('D MMMM')} - ${eDate.format('D MMMM')}`}
          level={`${eDate.diff(sDate, 'weeks')} недель`}
          title={course.title}
          price={course.price}
          oldPrice={course.oldPrice}
          videoId={course.vimeoVideoUrl}
          tags={course.tags || []}
          onEditHeader={admin?.onEditHeader}
        >
          <Button
            variant={'primary'}
            onClick={onApplyClick}
            w={{ base: 'full', lg: 'auto' }}
          >
            Начать бесплатно
          </Button>
          {admin && (
            <AddSectionButton
              lt={undefined}
              addSection={(sType: SectionType) => admin.onAddSection(sType, 0)}
            />
          )}
        </PageContentHeader>
      )
    }
  }

  return (
    <PageContentWrapper>
      {renderHeader()}
      <ContentContainer
        bg='white'
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
        py={{ base: 6, lg: 8 }}
        minH='80vh'
      >
        <PageContent
          sections={course.sections}
          sectionsOrder={course.sectionsOrder}
          entityId={course.id}
          storageRef={storageRef}
          admin={admin}
          teachers={teachers}
          partitions={partitions}
        />
      </ContentContainer>
    </PageContentWrapper>
  )
}

export default FreePage
