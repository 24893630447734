import { ILessonType, SectionType } from 'shared/types'

const defaultSections = [
  SectionType.HEADING,
  SectionType.TEXT_LIST,
  SectionType.COLUMNS,
  SectionType.COLUMNS_VIDEO,
  SectionType.COLUMNS_IMAGE,
  SectionType.TEACHERS,
  SectionType.DIVIDER
]

const lessonSections = {
  [ILessonType.LECTURE]: [
    SectionType.HEADING,
    SectionType.VIMEO,
    SectionType.YOUTUBE,
    SectionType.DIVIDER,
    SectionType.BUTTON,
    SectionType.AUDIO,
    SectionType.SYNC_TRANSLATE
  ],
  [ILessonType.TEST]: [
    SectionType.HEADING,
    SectionType.VIMEO,
    SectionType.DIVIDER,
    SectionType.AUDIO,
    SectionType.TEST_BUTTONS,
    SectionType.TEST_TRUE_FALSE,
    SectionType.TEST_ONE_OPTION,
    SectionType.TEST_INPUT_ANSWER,
    SectionType.TEST_TEXT_ANSWER,
    SectionType.TEST_ADD_WORDS,
    SectionType.TEST_ADD_LETTERS,
    SectionType.TEST_SYNC_TRANSLATE
  ],
  [ILessonType.EXAMINATION]: [
    SectionType.HEADING,
    SectionType.VIMEO,
    SectionType.DIVIDER,
    SectionType.AUDIO,
    SectionType.TEST_BUTTONS,
    SectionType.TEST_TRUE_FALSE,
    SectionType.TEST_ONE_OPTION,
    SectionType.TEST_INPUT_ANSWER,
    SectionType.TEST_TEXT_ANSWER,
    SectionType.TEST_ADD_WORDS,
    SectionType.TEST_ADD_LETTERS,
    SectionType.TEST_SYNC_TRANSLATE
  ],
  [ILessonType.DICTIONARY]: [
    SectionType.WORD_CARD,
    SectionType.HEADING,
    SectionType.VIMEO,
    SectionType.YOUTUBE
  ]
}

const sectionsByPageType = (lt?: ILessonType) => {
  if (lt) {
    return lessonSections[lt]
  } else {
    return defaultSections
  }
}

export default sectionsByPageType
