import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  HStack,
  Heading,
  StackDivider,
  Tag,
  Text,
  VStack
} from '@chakra-ui/react'
import {
  dbDeleteUserPayment,
  dbSubscribeToPayments
} from 'controllers/userPayments'
import { Unsubscribe } from 'firebase/auth'
import { useSelector } from 'model/hooks'
import { FC, useEffect, useRef, useState } from 'react'
import get from 'lodash/get'
import { IEntityType, IUser, IUserPayment } from 'shared/types'
import dayjs from 'dayjs'
import RowActionButtons from 'components/RowActionButtons'
import DeleteConfirmation, {
  IDeleteConfirmationModal
} from 'modals/DeleteConfirmation'

type Props = {
  user: IUser
}

const UserPayments: FC<Props> = ({ user }) => {
  const paymentsUnsubscribeRef = useRef<Unsubscribe | null>(null)
  const [payments, setPayments] = useState<IUserPayment[]>([])
  const courses = useSelector(state => state.courses)
  const webinars = useSelector(state => state.webinars)
  const conferences = useSelector(state => state.conferences)
  const deleteModalRef = useRef<IDeleteConfirmationModal>(null)

  useEffect(() => {
    paymentsUnsubscribeRef.current = dbSubscribeToPayments(user.id, res =>
      setPayments(res)
    )

    return () => {
      if (paymentsUnsubscribeRef.current) {
        paymentsUnsubscribeRef.current()
      }
    }
  }, [user.id])

  const renderPayment = (p: IUserPayment) => {
    const entityName = get(
      { ...courses, ...webinars, ...conferences },
      [p.entityId, 'title'],
      'noname'
    )

    let tagColorScheme = 'teal'
    if (p.type === IEntityType.WEBINARS) {
      tagColorScheme = 'blue'
    } else if (p.type === IEntityType.CONFERENCES) {
      tagColorScheme = 'orange'
    }

    return (
      <HStack key={p.id} w='full' role='group' position={'relative'}>
        <VStack align='flex-start' flex={1} spacing={0}>
          <Text fontSize='sm' fontWeight='semibold'>
            {entityName}
          </Text>
          <Text fontSize={'xs'} color='gray.600'>
            {dayjs(p.createdAt).format('MMM D, YYYY HH:mm')}
          </Text>
        </VStack>
        <VStack align='flex-start' flex={0.5} spacing={0}>
          <Tag size='sm' colorScheme={tagColorScheme}>
            {p.type}
          </Tag>
          <Text fontSize={'xs'}>{Number(p.price)} руб.</Text>
        </VStack>
        <RowActionButtons
          onDelete={() => {
            console.log('on Delete click')
            deleteModalRef.current?.open(p.id)
          }}
        />
      </HStack>
    )
  }

  const onDelete = (id?: string) => {
    if (id) {
      dbDeleteUserPayment(id)
    }
  }

  return (
    <>
      <Card w='full' maxW='2xl'>
        <CardHeader>
          <Heading size='md'>Платежи</Heading>
        </CardHeader>
        <CardBody>
          <VStack w='full' divider={<StackDivider color='gray.100' />}>
            {payments.map(renderPayment)}
          </VStack>
        </CardBody>
        <CardFooter justify={'flex-end'}></CardFooter>
      </Card>
      <DeleteConfirmation
        ref={deleteModalRef}
        title='Удаление платежа'
        description={`Вы собираетесь удалить платеж. Это действие нельзя отменить.`}
        onDelete={onDelete}
      />
    </>
  )
}

export default UserPayments
