import { VStack } from '@chakra-ui/react'
import { FC, ReactNode } from 'react'
import { ISessionAnswer } from 'shared/types'

type Props = {
  children: ReactNode
  showUserAnswer?: boolean
  answer?: ISessionAnswer
}

const TestCard: FC<Props> = ({ children, showUserAnswer, answer }) => {
  let cardBorderColor = 'transparent'
  if (!showUserAnswer) {
    cardBorderColor = answer
      ? answer.isCorrect
        ? 'green.800'
        : 'red.700'
      : 'transparent'
  }

  return (
    <VStack
      bg='wood.50'
      // my={{ base: 3, lg: 4 }}
      p={{ base: 4, lg: 4 }}
      pb={{ base: 4, lg: 4 }}
      align='flex-start'
      spacing={4}
      w='full'
      maxW={'802px'}
      border={'1px'}
      borderColor={cardBorderColor}
      rounded={'xs'}
      // boxShadow={'shadow1'}
    >
      {children}
    </VStack>
  )
}

export default TestCard
