import { FC, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
  VStack
} from '@chakra-ui/react'
import { useSelector } from 'model/hooks'
import map from 'lodash/map'
import { INotificationsSettings } from 'shared/types'
import RowActionButtons from 'components/RowActionButtons'
import {
  dbAddNotificationEmail,
  dbDeleteNotificationEmail,
  dbUpdateNotificationEmail
} from 'controllers/settings'
import isEmail from 'validator/lib/isEmail'

type Props = {}

const SettingsNotifications: FC<Props> = () => {
  const [email, setEmail] = useState('')

  const notifications = useSelector(state => state.notificationsSettings)

  const renderRow = (n: INotificationsSettings) => {
    return (
      <HStack w='full' role='group' key={n.email}>
        <Flex flex={3}>
          <Text fontSize={'sm'}>{n.email}</Text>
        </Flex>
        <Flex flex={1}>
          <Checkbox
            size='sm'
            isChecked={n.payments}
            onChange={e =>
              dbUpdateNotificationEmail(n.email, { payments: e.target.checked })
            }
          />
        </Flex>
        <Flex flex={1}>
          <Checkbox
            size='sm'
            isChecked={n.feedback}
            onChange={e =>
              dbUpdateNotificationEmail(n.email, { feedback: e.target.checked })
            }
          />
        </Flex>
        <RowActionButtons
          onDelete={() => {
            dbDeleteNotificationEmail(n.email)
          }}
        />
      </HStack>
    )
  }

  const addEmail = () => {
    if (isEmail(email)) {
      dbAddNotificationEmail(email)
      setEmail('')
    }
  }

  const emailInput = (
    <FormControl>
      <FormLabel>Email</FormLabel>
      <HStack>
        <Input
          type='email'
          variant={'flushed'}
          size='sm'
          placeholder='example@domain.com'
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <Button
          variant={'solid'}
          colorScheme='teal'
          size='sm'
          onClick={addEmail}
        >
          Добавить
        </Button>
      </HStack>
    </FormControl>
  )

  const gridHeader = (
    <HStack w='full'>
      <Flex flex={3} fontWeight={'medium'}>
        Email
      </Flex>
      <Flex flex={1} fontWeight={'medium'}>
        Payments
      </Flex>
      <Flex flex={1} fontWeight={'medium'}>
        Feedback
      </Flex>
      <Flex w='48px' />
    </HStack>
  )

  return (
    <VStack w='full'>
      <HStack w='full' maxW='2xl' pb={8}>
        {emailInput}
      </HStack>
      <Card w='full' maxW='2xl'>
        {/* <CardHeader>
          <Heading size='md'>Уведомления</Heading>
        </CardHeader> */}
        <CardBody>
          <VStack w='full'>
            {gridHeader}
            {map(notifications, renderRow)}
          </VStack>
          {/* <VStack w='full' divider={<StackDivider color='gray.100' />}>
            {map(notifications, renderRow)}
          </VStack> */}
        </CardBody>
        <CardFooter justify={'flex-end'}></CardFooter>
      </Card>
    </VStack>
  )
}

export default SettingsNotifications
