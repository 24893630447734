import { FC, useState, useRef } from 'react'
import { IPartition, ITeacher } from 'shared/types'
import {
  Stack,
  HStack,
  Text,
  Flex,
  Avatar,
  VStack,
  Box,
  Collapse,
  Button,
  Show,
  Image
} from '@chakra-ui/react'
import useSize from '@react-hook/size'

import map from 'lodash/map'
import CourseTag from 'shared/components/CourseTag'
import VideoPlayer from 'shared/components/VideoPlayer'

type Props = {
  t: ITeacher
  partitions: Record<string, IPartition>
}

const TeacherCard: FC<Props> = ({ t, partitions }) => {
  const [show, setShow] = useState(false)
  const handleToggle = () => setShow(!show)
  const elementRef = useRef(null)
  // const [width, height] = useSize(elementRef)

  // console.log('dimensions', width, height)

  const renderInfo = () => {
    return (
      <VStack align='flex-start' spacing={0} w='full'>
        <Text textStyle={'h3'} fontWeight={'700'} pr={4}>
          {t.name}
        </Text>
        <Text textStyle={'h3'} color='black.500' pr={4}>
          {t.title}
        </Text>
        <HStack py={{ base: 2, lg: 2 }} wrap={'wrap'} w='full'>
          {map(t.partitions, (pId, i) => {
            const p = partitions[pId]
            if (p) {
              return <CourseTag key={pId} t={p.name} i={i} />
            }
          })}
        </HStack>
        <VStack
          w='full'
          pt={{ base: 0, lg: '6' }}
          align='flex-start'
          spacing={0}
        >
          <Collapse startingHeight={190} in={show}>
            <Box
              overflow={'hidden'}
              height={'auto'}
              textStyle='body'
              className='ql-snow'
              maxW={'full'}
              wordBreak={'break-word'}
              ref={elementRef}
              dangerouslySetInnerHTML={{ __html: t.description }}
            />
          </Collapse>
          {/* {height > 190 && ( */}
          <Button
            size='md'
            onClick={handleToggle}
            variant={'unstyled'}
            color='black.500'
            fontWeight={450}
          >
            {show ? 'свернуть' : 'еще...'}
          </Button>
          {/* )} */}
        </VStack>
      </VStack>
    )
  }

  const renderMedia = () => {
    if (t.video && t.video.url !== '') {
      return <VideoPlayer v={t.video} />
    } else if (t.image) {
      return <Image src={t.image} rounded={'4px'} />
    }
  }

  return (
    <>
      <Show above='lg'>
        <Stack
          w='full'
          spacing={{ base: 0, lg: 6 }}
          align='flex-start'
          direction={{ base: 'column', lg: 'row' }}
          borderRadius={'xs'}
          bg='wood.50'
          p={4}
          minH={'392px'}
        >
          <HStack flex={1} align='flex-start' spacing={6} w='full'>
            <Flex>
              <Avatar
                boxSize={{ base: '64px', lg: '104px' }}
                name={t.name}
                src={t.avatarUrl}
              />
            </Flex>
            {renderInfo()}
          </HStack>
          <Box
            w={{ base: 'full', lg: '360px' }}
            sx={{ iframe: { borderRadius: '4px' } }}
          >
            {renderMedia()}
          </Box>
          {/* {t.videoId ? (

              <Vimeo
                video={t.videoId}
                responsive
                onError={e => console.log(e)}
              />
            </Box>
          ) : (
            <Box w={{ base: '0', lg: '286px' }} />
          )} */}
        </Stack>
      </Show>
      <Show below='lg'>
        <VStack
          w='full'
          spacing={6}
          align='flex-start'
          borderRadius={'xs'}
          bg='wood.50'
          p={4}
        >
          <HStack
            // flex={1}
            // minH={{ base: '280px', lg: '376px' }}
            align='flex-start'
            spacing={4}
            w='full'
          >
            <Flex>
              <Avatar
                boxSize={{ base: '64px', lg: '104px' }}
                name={t.name}
                src={t.avatarUrl}
              />
            </Flex>
            <VStack align='flex-start' spacing={0} w='full'>
              <Text textStyle={'h3'} fontWeight={'700'} pr={4}>
                {t.name}
              </Text>
              <Text textStyle={'h3'} color='black.500' pr={4}>
                {t.title}
              </Text>
            </VStack>
          </HStack>
          <HStack wrap={'wrap'} w='full'>
            {map(t.partitions, (pId, i) => {
              const p = partitions[pId]
              if (p) {
                return <CourseTag key={pId} t={p.name} i={i} />
              }
            })}
          </HStack>
          <Box w={'full'} sx={{ iframe: { borderRadius: '4px' } }}>
            {renderMedia()}
          </Box>
          {/* {t.videoId ? (
            <Box w={'full'} sx={{ iframe: { borderRadius: '8px' } }}>
              <Vimeo
                video={t.videoId}
                responsive
                onError={e => console.log(e)}
              />
            </Box>
          ) : (
            <Box w={{ base: '0', lg: '286px' }} />
          )} */}
          {t.description && t.description !== '' && (
            <VStack
              w='full'
              pt={{ base: 0, lg: '6' }}
              align='flex-start'
              spacing={0}
            >
              <Collapse startingHeight={200} in={show}>
                <Box
                  overflow={'hidden'}
                  height={'auto'}
                  ref={elementRef}
                  textStyle='body'
                  className='ql-snow'
                  maxW={'full'}
                  wordBreak={'break-word'}
                  // fontSize={{ base: 'lg', lg: 'lg' }}
                  // fontWeight={450}
                  // letterSpacing={{ base: '-0.36', lg: '-0.36px' }}
                  // lineHeight={{ base: '24px', lg: '24px' }}
                  dangerouslySetInnerHTML={{ __html: t.description }}
                />
              </Collapse>
              {/* {height > 220 && ( */}
              <Button
                size='md'
                onClick={handleToggle}
                variant={'unstyled'}
                color='black.500'
                fontWeight={450}
              >
                {show ? 'свернуть' : 'еще...'}
              </Button>
              {/* )} */}
            </VStack>
          )}
        </VStack>
      </Show>
    </>
  )
}

export default TeacherCard
