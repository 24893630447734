import {
  Card,
  CardHeader,
  HStack,
  Heading,
  CardBody,
  Divider,
  VStack,
  StackDivider,
  Text,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  Button
} from '@chakra-ui/react'
import { FC, useContext, useRef } from 'react'
import LessonsContext from 'contexts/LessonsContext'
import Loading from 'components/Loading'
import map from 'lodash/map'
import get from 'lodash/get'
import { arrayMove } from 'shared/utils/array'
import RowActionButtons from 'components/RowActionButtons'
import { AddIcon } from '@chakra-ui/icons'

type Props = {
  ids: string[]
  updateIds: (ids: string[]) => void
  onAddLesson: () => void
  onDuplicate: (id: string) => void
  onDelete: (id: string) => void
  onClick: (id: string) => void
}

const LessonsList: FC<Props> = ({
  ids,
  updateIds,
  onAddLesson,
  onDuplicate,
  onDelete,
  onClick
}) => {
  const { lessons } = useContext(LessonsContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef(null)
  const deleteLessonRef = useRef<string>('')

  const onMove = (i: number, isUp: boolean) => {
    const newIds = arrayMove(ids, i, isUp ? i - 1 : i + 1)
    updateIds(newIds)
  }

  const renderDeleteAlert = () => {
    return (
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Удалить урок?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Этот урок будет удален без возможности восстановления.
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Отмена
            </Button>
            <Button
              colorScheme='red'
              variant={'solid'}
              bg='red'
              ml={3}
              onClick={() => {
                onDelete(deleteLessonRef.current)
                onClose()
              }}
            >
              Удалить
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    )
  }

  const renderRow = (id: string, i: number) => {
    const l = get(lessons, id)
    if (l) {
      return (
        <HStack
          key={id}
          w='full'
          justify={'space-between'}
          _hover={{ bg: 'gray.50' }}
          role='group'
        >
          <HStack
            align={'center'}
            justify={'center'}
            spacing={6}
            w='full'
            as='button'
            onClick={() => onClick(id)}
            py={2}
            px={4}
          >
            <Text fontSize={'sm'} fontWeight={'bold'}>
              {i + 1}.
            </Text>
            <VStack spacing={0} align={'flex-start'} w='full'>
              <Text fontWeight={'medium'} textAlign={'start'}>
                {l.name}
              </Text>
              <Text
                fontSize={'sm'}
                color={'gray.400'}
                noOfLines={1}
                textAlign={'start'}
              >
                {l.description}
              </Text>
            </VStack>
          </HStack>
          <RowActionButtons
            isFirst={i === 0}
            isLast={i === ids.length - 1}
            onMove={(isUp: boolean) => onMove(i, isUp)}
            onDuplicate={() => onDuplicate(id)}
            onDelete={() => {
              deleteLessonRef.current = id
              onOpen()
            }}
          />
        </HStack>
      )
    }
  }

  const renderLessons = () => {
    if (lessons != null) {
      return (
        <VStack divider={<StackDivider />} spacing={0}>
          {map(ids, renderRow)}
        </VStack>
      )
    } else {
      return <Loading />
    }
  }

  return (
    <Card w='full' maxW='2xl'>
      <CardHeader>
        <HStack w='full' justify={'space-between'}>
          <Heading size='md'>Уроки</Heading>
          <Button
            leftIcon={<AddIcon />}
            size='sm'
            colorScheme='teal'
            onClick={onAddLesson}
          >
            Урок
          </Button>
        </HStack>
      </CardHeader>
      <Divider color='gray.200' />
      <CardBody>{renderLessons()}</CardBody>
      {renderDeleteAlert()}
    </Card>
  )
}

export default LessonsList
