import {
  VStack,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel
} from '@chakra-ui/react'
import NavBar from 'components/NavBar'
import { useContext, useMemo, useState, useRef, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import LessonsContext from 'contexts/LessonsContext'
import Loading from 'components/Loading'
import { dbUpdateLesson } from 'controllers/lessons'
import { IDay, ILessonType, ISection, IWeek, SectionType } from 'shared/types'
import get from 'lodash/get'
import indexOf from 'lodash/indexOf'
import filter from 'lodash/filter'
import LessonChat from 'pages/lesson/LessonChat'
import CustomTabPanel from 'components/CustomTabPanel'
import Footer from 'shared/components/Footer'
import LessonPage from 'shared/pages/LessonPage'
import { generateId, storageRef } from 'controllers/db'
import { useSelector } from 'model/hooks'
import { createSection } from 'controllers/sections'
import { arrayInsertAt, arrayMove } from 'shared/utils/array'
import EditSectionDrawer from 'modals/EditSectionDrawer'
import LessonSettingsDrawer, {
  ILessonSettingsDrawer
} from 'modals/lesson/LessonSettingsDrawer'
import _ from 'lodash'
import { dbGetWordCards } from 'controllers/cards'

const Lesson = () => {
  const user = useSelector(state => state.user)
  const { lessons, lessonsIds, entityId, entity, entityType } =
    useContext(LessonsContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const { lessonId } = useParams()
  const navigate = useNavigate()
  const [editSectionId, setEditSectionId] = useState<string>()
  const settingsDrawerRef = useRef<ILessonSettingsDrawer>(null)
  const teachers = useSelector(state => state.teachers)
  const partitions = useSelector(state => state.partitions)
  const wordCards = useSelector(state => state.wordCards)
  const cardTags = useSelector(state => state.cardTags)

  const l = useMemo(() => {
    if (!lessonId || _.isEmpty(lessons)) return
    return _.get(lessons, lessonId)
  }, [lessonId, lessons])

  useEffect(() => {
    if (l?.sections) {
      if (l.type === ILessonType.DICTIONARY) {
        const rawCardsIds = _.map(l.sections, s => {
          if (s.type === SectionType.WORD_CARD) {
            return s.cardId || null
          } else {
            return null
          }
        })
        const ids = _.compact(rawCardsIds)
        dbGetWordCards(ids)
      }
    }
  }, [l?.sections, l?.type])

  const { week, day } = useMemo(() => {
    if (entity && entity.content) {
      let resW: IWeek | undefined
      let resD: IDay | undefined
      for (const w of entity.content) {
        for (const d of w.days) {
          if (indexOf(d.lessons, lessonId) >= 0) {
            resD = d
            resW = w
          }
        }
      }
      return { week: resW, day: resD }
    } else {
      return { week: undefined, day: undefined }
    }
  }, [entity, lessonId])

  const lessonsList = useMemo(() => {
    if (day) {
      return day.lessons
    } else {
      return lessonsIds
    }
  }, [day, lessonsIds])

  const currentTabIndex = useMemo(() => {
    const tab = searchParams.get('tab')
    if (tab) {
      return +tab
    } else {
      return 0
    }
  }, [searchParams])

  const backUrl = useMemo(() => {
    let p = `/${entityType}/${entityId}?tab=1`
    if (week && day) {
      p = `${p}&w=${week.id}&d=${day.id}`
    }
    return p
  }, [week, day, entityId, entityType])

  const onAddSection = (sType: SectionType, i: number) => {
    if (l) {
      const s = createSection(sType)
      const newSections = {
        ...(l.sections || {}),
        [s.id]: s
      }
      const newSectionsOrder = arrayInsertAt(l.sectionsOrder || [], i, s.id)
      const upd = {
        sections: newSections,
        sectionsOrder: newSectionsOrder
      }
      dbUpdateLesson(l.id, upd)
      setEditSectionId(s.id)
    }
  }

  const onMoveSection = (fromIndex: number, toIndex: number) => {
    console.log('onMoveSection', fromIndex, toIndex)
    if (l && l.sectionsOrder) {
      const newSectionsOrder = arrayMove(l.sectionsOrder, fromIndex, toIndex)
      const upd = {
        sectionsOrder: newSectionsOrder
      }
      dbUpdateLesson(l.id, upd)
    }
  }

  const updateSection = (s: ISection) => {
    if (l) {
      const upd = {
        sections: {
          ...(l.sections || {}),
          [s.id]: s
        }
      }
      dbUpdateLesson(l.id, upd)
    }
  }

  const onEditSection = (sId: string) => {
    setEditSectionId(sId)
  }

  const onDeleteSection = () => {
    if (l && editSectionId) {
      const newOrder = filter(l.sectionsOrder, lId => lId !== editSectionId)
      const newSections = { ...l.sections }
      delete newSections[editSectionId]
      setEditSectionId(undefined)
      const upd = { sections: newSections, sectionsOrder: newOrder }
      dbUpdateLesson(l.id, upd)
    }
  }

  const onDuplicateSection = () => {
    if (l && editSectionId && l.sections && l.sectionsOrder) {
      const s = l.sections[editSectionId]
      const index = l.sectionsOrder.indexOf(editSectionId)
      const id = generateId()
      const newS: ISection = {
        ...s,
        id
      }
      const newSections = {
        ...l.sections,
        [newS.id]: newS
      }
      const newSectionsOrder = arrayInsertAt(
        l.sectionsOrder || [],
        index + 1,
        newS.id
      )
      const upd = { sections: newSections, sectionsOrder: newSectionsOrder }
      dbUpdateLesson(l.id, upd)
      setEditSectionId(id)
    }
  }

  const toPrev = () => {
    if (lessonId && lessonsList.indexOf(lessonId) >= 0) {
      const prevLessonId = lessonsList[lessonsList.indexOf(lessonId) - 1]
      navigate(
        `/${entityType}/${entityId}/${prevLessonId}?tab=${currentTabIndex}`
      )
    }
  }

  const toNext = () => {
    if (lessonId && lessonsList.indexOf(lessonId) < lessonsList.length - 1) {
      const nextLessonId = lessonsList[lessonsList.indexOf(lessonId) + 1]
      navigate(
        `/${entityType}/${entityId}/${nextLessonId}?tab=${currentTabIndex}`
      )
    }
  }

  const renderTitle = () => {
    if (l) {
      if (entity && week && day) {
        const num = day.lessons.indexOf(l.id) + 1
        return `${num}. ${l.name}`
      } else {
        return `${lessonId ? lessonsIds.indexOf(lessonId) + 1 + '. ' : ''}${
          l.name
        }`
      }
    }
  }

  const getSubheading = () => {
    if (entity && week && day) {
      const ar = [entity.title, week.title, day.title]
      return ar.join(' / ')
    } else {
      return entity?.title
    }
  }

  const renderTabs = () => {
    return (
      <TabList
        display='flex'
        alignItems='center'
        justifyContent='center'
        borderBottom='none'
        p='4'
        overflow={'hidden'}
      >
        <Tab>Cтраница урока</Tab>
        <Tab>Чат</Tab>
      </TabList>
    )
  }

  if (!l || !entity || !entityType || !user) {
    return <Loading />
  } else {
    return (
      <VStack w='full' h='full' spacing={0} overflow={'hidden'} maxH={'full'}>
        <Tabs
          colorScheme='blackAlpha'
          variant='soft-rounded'
          w='full'
          overflow={'hidden'}
          h='full'
          flex={1}
          display={'flex'}
          flexDirection={'column'}
          index={currentTabIndex}
          onChange={(tabIndex: number) =>
            setSearchParams({ tab: tabIndex.toString() })
          }
        >
          <NavBar
            backUrl={backUrl}
            title={renderTitle()}
            subheading={getSubheading()}
            tabs={renderTabs()}
          />

          <TabPanels overflow={'hidden'} flex={1}>
            <CustomTabPanel bg='black.950'>
              <VStack
                w='full'
                bg={'wood.100'}
                borderBottomRadius={{ base: 'sm', lg: 'lg' }}
                overflowY={'auto'}
              >
                <VStack
                  w='full'
                  justify={'flex-start'}
                  flex={1}
                  spacing={0}
                  h='full'
                  overflowX={'hidden'}
                  overflowY={'auto'}
                  minH='full'
                  bg='black.950'
                >
                  <VStack
                    w='full'
                    bg={'wood.100'}
                    borderBottomRadius={{ base: 'sm', lg: 'lg' }}
                  >
                    <LessonPage
                      lesson={l}
                      storageRef={storageRef}
                      entity={entity}
                      entityType={entityType}
                      toNext={toNext}
                      toPrev={toPrev}
                      backUrl={''}
                      examinationAttempt={null}
                      testResult={null}
                      dbFinishExaminationAttempt={async () => undefined}
                      submitTestAnswers={async () => null}
                      user={user}
                      dbStartExaminationAttempt={async () => undefined}
                      weekId={week?.id || ''}
                      dayId={day?.id || ''}
                      admin={{
                        onAddSection,
                        onMoveSection,
                        onEditSection,
                        onEditHeader: () =>
                          settingsDrawerRef.current?.open(l.id)
                      }}
                      teachers={teachers || {}}
                      partitions={partitions}
                      wordCards={wordCards}
                      cardTags={cardTags}
                      toLearnDictionary={() => null}
                    />
                  </VStack>
                  <Footer />
                </VStack>
              </VStack>
            </CustomTabPanel>
            <TabPanel w='full' h='full' overflow={'hidden'} px={'20'}>
              <LessonChat lessonId={l.id} />
            </TabPanel>
          </TabPanels>
        </Tabs>
        {entityId && (
          <EditSectionDrawer
            s={editSectionId && get(l, ['sections', editSectionId])}
            onChange={updateSection}
            onClose={() => setEditSectionId(undefined)}
            entityId={entityId}
            onDelete={onDeleteSection}
            onDuplicate={onDuplicateSection}
            lessonId={lessonId}
          />
        )}
        <LessonSettingsDrawer ref={settingsDrawerRef} />
      </VStack>
    )
  }
}

export default Lesson
