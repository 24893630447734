import { FC, useEffect, useState } from 'react'
import { IConference } from 'shared/types'
import isEqual from 'lodash/isEqual'
import SettingsCard from 'components/SettingsCard'
import InputField from 'components/formFields/InputField'
import DateTimeField from 'components/formFields/DateTimeField'
import { dbUpdateConference } from 'controllers/conferences'

type Props = {
  c: IConference
}

const ConferenceSettingsCard: FC<Props> = ({ c }) => {
  const [data, setData] = useState(c)

  useEffect(() => {
    setData(c)
  }, [c])

  const onChange = (k: keyof IConference) => (v: any) => {
    console.log('onChange', k, v)
    if (v === undefined) {
      const newData = { ...data }
      delete newData[k]
      setData(newData)
    } else {
      setData({
        ...data,
        [k]: v
      })
    }
  }

  const onSave = () => {
    const upd: Partial<IConference> = {
      title: data.title,
      subTitle: data.subTitle,
      startDate: data.startDate,
      endDate: data.endDate
    }
    dbUpdateConference(c.id, upd)
  }

  return (
    <SettingsCard onSave={onSave} saveDisabled={isEqual(data, c)}>
      <InputField
        label='Название'
        value={data.title || ''}
        onChange={onChange('title')}
      />
      <InputField
        label='Описание'
        value={data.subTitle || ''}
        onChange={onChange('subTitle')}
      />
      <DateTimeField
        label='Дата начала'
        value={data.startDate}
        onChange={onChange('startDate')}
      />
      <DateTimeField
        label='Дата завершения'
        value={data.endDate}
        onChange={onChange('endDate')}
      />
    </SettingsCard>
  )
}

export default ConferenceSettingsCard
