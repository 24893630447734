import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  IconButton,
  useDisclosure,
  Text
} from '@chakra-ui/react'
import {
  Ref,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useState
} from 'react'
import get from 'lodash/get'
import { dbUpdateCoursesPack } from 'controllers/coursesPacks'
import { useSelector } from 'model/hooks'
import { ReactComponent as IconDelete } from 'shared/assets/bsTrash.svg'
import CoursesPackPlanDrawerMain from 'modals/coursesPack/coursesPackPlanDrawer/CoursesPackPlanDrawerMain'
import CoursesPackPlanDrawerPlan from 'modals/coursesPack/coursesPackPlanDrawer/CoursesPackPlanDrawerPlan'
import { ReactComponent as IconBack } from 'shared/assets/bsChevronLeft.svg'

export interface ICoursesPackPlanDrawer {
  open: (cId: string) => void
}
type Props = {}

const CoursesPackPlanDrawer: ForwardRefRenderFunction<
  ICoursesPackPlanDrawer,
  Props
> = (_props, ref: Ref<unknown>) => {
  const courses = useSelector(state => state.courses)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [cId, setCId] = useState<string>()
  const [planNum, setPlanNum] = useState<number | null>(null)
  const c = useSelector(state =>
    cId ? get(state.coursesPacks, cId) : undefined
  )

  useImperativeHandle(ref, () => ({
    open: (cId: string, planId: string) => {
      setCId(cId)
      setPlanNum(null)
      onOpen()
    }
  }))

  const onDeletePlan = (planNum: number) => {
    if (c) {
      const plans = [...(c.plans || [])]
      plans.splice(planNum, 1)
      dbUpdateCoursesPack(c.id, { plans })
      setPlanNum(null)
    }
  }

  const renderContent = () => {
    if (c) {
      if (planNum !== null) {
        const p = c.plans[planNum]
        if (p) {
          return (
            <CoursesPackPlanDrawerPlan
              p={p}
              c={c}
              i={planNum}
              onBack={() => setPlanNum(null)}
            />
          )
        }
      } else {
        return (
          <CoursesPackPlanDrawerMain c={c} onEditPlan={i => setPlanNum(i)} />
        )
      }
    }
  }

  const renderHeader = () => {
    if (planNum !== null && c) {
      const p = c?.plans[planNum]
      if (p) {
        return (
          <HStack>
            <IconButton
              aria-label='back'
              icon={<IconBack />}
              variant={'ghost'}
              colorScheme='black'
              onClick={() => setPlanNum(null)}
            />
            <Text textStyle='small' fontWeight={700}>
              {get(courses, [p.courseId, 'title'])}
            </Text>
          </HStack>
        )
      }
    } else {
      return (
        <Text textStyle='small' fontWeight={700}>
          Тарифные планы
        </Text>
      )
    }
  }

  return (
    <Drawer isOpen={isOpen} placement='right' onClose={onClose} size='sm'>
      <DrawerOverlay bg='transparent' />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader color={'wood.950'}>{renderHeader()}</DrawerHeader>

        <DrawerBody>{renderContent()}</DrawerBody>

        {planNum !== null && (
          <DrawerFooter>
            <HStack w='full' justify={'end'}>
              <Button
                variant='solid'
                onClick={() => onDeletePlan(planNum)}
                colorScheme='red'
                size='sm'
                leftIcon={<IconDelete width={12} />}
              >
                Delete
              </Button>
            </HStack>
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  )
}

export default forwardRef(CoursesPackPlanDrawer)
