import { useRef, useState, FC } from 'react'
import {
  Text,
  VStack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Textarea,
  Checkbox,
  Link,
  Button
} from '@chakra-ui/react'
import isEmpty from 'lodash/isEmpty'
import isEmail from 'validator/lib/isEmail'
import { IFeedback, IUser } from 'shared/types'

type Props = {
  title?: string
  user: IUser | null
  dbSaveFeedback: (feedback: Partial<IFeedback>) => void
}

const ContactForm: FC<Props> = ({
  title = 'Нужна помощь?',
  user,
  dbSaveFeedback
}) => {
  const [data, setData] = useState({
    name: user?.name || '',
    email: user?.email || '',
    phone: user?.phone || '',
    question: ''
  })
  const [errors, setErrors] = useState<Record<string, string | undefined>>({
    name: undefined,
    email: undefined,
    phone: undefined,
    question: undefined
  })
  const nameInputRef = useRef<HTMLInputElement>(null)
  const emailInputRef = useRef<HTMLInputElement>(null)
  const phoneInputRef = useRef<HTMLInputElement>(null)
  const questionInputRef = useRef<HTMLTextAreaElement>(null)
  const [confidentChecked, setConfidentChecked] = useState(false)
  const [contractChecked, setContractChecked] = useState(false)
  const [sent, setSent] = useState(false)

  const onChange = (k: string) => (v: string) => {
    setData({
      ...data,
      [k]: v
    })
  }

  const getErrors = () => {
    const res: Record<string, string> = {}
    if (isEmpty(data.name)) {
      res.name = 'Введите имя и фамилию'
    }
    if (isEmpty(data.email)) {
      res.email = 'Введите email'
    } else if (!isEmail(data.email)) {
      res.email = 'Неверный формат email'
    }
    if (isEmpty(data.phone)) {
      res.phone = 'Укажите контактный номер телефона'
    }
    if (isEmpty(data.question)) {
      res.question = 'Напишите ваш вопрос'
    }
    if (!confidentChecked) {
      res.confident = 'Требуется согласие'
    }
    if (!contractChecked) {
      res.contract = 'Требуется согласие'
    }
    return res
  }

  const resetError = (k: string) => {
    setErrors(errors => ({ ...errors, [k]: undefined }))
  }

  // const checkErrors = () => { }

  const submit = () => {
    const errs = getErrors()
    if (!isEmpty(errs)) {
      setErrors(errs)
    } else {
      dbSaveFeedback({
        name: data.name,
        email: data.email,
        phone: data.phone,
        message: data.question
      })
      setSent(true)
    }
  }

  const nameInput = (
    <FormControl isRequired isInvalid={!isEmpty(errors.name)}>
      <FormLabel textStyle={'body'} m={0}>
        Имя и фамилия
      </FormLabel>
      <Input
        variant={'flushed'}
        size='sm'
        placeholder='Мария Петрова'
        value={data.name}
        h={5}
        onFocus={() => resetError('name')}
        onBlur={() => {
          const errs = getErrors()
          setErrors({ ...errors, name: errs.name })
        }}
        ref={nameInputRef}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            emailInputRef.current?.focus()
          }
        }}
        onChange={e => onChange('name')(e.target.value)}
      />
      <FormErrorMessage fontSize={'xs'}>{errors.name}</FormErrorMessage>
    </FormControl>
  )

  const emailInput = (
    <FormControl isRequired isInvalid={!isEmpty(errors.email)}>
      <FormLabel textStyle={'body'} m={0}>
        Email
      </FormLabel>
      <Input
        type='email'
        variant={'flushed'}
        size='sm'
        h={5}
        placeholder='example@domain.com'
        value={data.email}
        onFocus={() => resetError('email')}
        onBlur={() => {
          const errs = getErrors()
          setErrors(errors => ({ ...errors, email: errs.email }))
        }}
        ref={emailInputRef}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            phoneInputRef.current?.focus()
          }
        }}
        onChange={e => onChange('email')(e.target.value)}
      />
      <FormErrorMessage fontSize={'xs'}>{errors.email}</FormErrorMessage>
    </FormControl>
  )

  const phoneInput = (
    <FormControl isRequired isInvalid={!isEmpty(errors.phone)}>
      <FormLabel textStyle={'body'} m={0}>
        Телефон
      </FormLabel>
      <Input
        type='tel'
        variant={'flushed'}
        size='sm'
        h={5}
        placeholder='+79991234545'
        value={data.phone}
        onFocus={() => resetError('phone')}
        onBlur={() => {
          const errs = getErrors()
          setErrors({ ...errors, phone: errs.phone })
        }}
        ref={phoneInputRef}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            questionInputRef.current?.focus()
          }
        }}
        onChange={e => onChange('phone')(e.target.value)}
      />
      <FormErrorMessage fontSize={'xs'}>{errors.phone}</FormErrorMessage>
    </FormControl>
  )

  const questionInput = (
    <FormControl isRequired isInvalid={!isEmpty(errors.question)}>
      <FormLabel textStyle={'body'} m={0}>
        Вопрос
      </FormLabel>
      <Textarea
        variant={'flushed'}
        size='sm'
        h={5}
        placeholder='Текст'
        value={data.question}
        onFocus={() => resetError('question')}
        onBlur={() => {
          const errs = getErrors()
          setErrors({ ...errors, question: errs.question })
        }}
        ref={questionInputRef}
        onChange={e => onChange('question')(e.target.value)}
      />
      <FormErrorMessage fontSize={'xs'}>{errors.question}</FormErrorMessage>
    </FormControl>
  )

  const confidentCheckbox = (
    <FormControl isRequired isInvalid={!isEmpty(errors.confident)}>
      <Checkbox
        colorScheme='blackAlpha'
        checked={confidentChecked}
        onChange={e => {
          resetError('confident')
          setConfidentChecked(e.target.checked)
        }}
      >
        <Text
          fontSize={'sm'}
          lineHeight={'16px'}
          fontWeight={'450'}
          letterSpacing={'-0.28px'}
          pl={2}
        >
          Отправля данные, вы соглашаетесь с<br />
          <Link href='/privacy' target='_blank' color='wood.500'>
            Положением о конфиденциальности сайта
          </Link>
        </Text>
      </Checkbox>
      <FormErrorMessage fontSize={'xs'}>{errors.confident}</FormErrorMessage>
    </FormControl>
  )

  const contractCheckbox = (
    <FormControl isRequired isInvalid={!isEmpty(errors.contract)}>
      <Checkbox
        colorScheme='blackAlpha'
        checked={contractChecked}
        onChange={e => {
          resetError('contract')
          setContractChecked(e.target.checked)
        }}
      >
        <Text
          fontSize={'sm'}
          lineHeight={'16px'}
          fontWeight={'450'}
          letterSpacing={'-0.28px'}
          pl={2}
        >
          Отправля данные, вы соглашаетесь с{' '}
          <Link href='/agreement' target='_blank' color='wood.500'>
            Договором-офертой
          </Link>
        </Text>
      </Checkbox>
      <FormErrorMessage fontSize={'xs'}>{errors.contract}</FormErrorMessage>
    </FormControl>
  )

  const renderContent = () => {
    if (sent) {
      return (
        <Text textAlign={'start'}>
          Спасибо за обращение. <br />
          Мы свяжемся с вами в ближайщее время.
        </Text>
      )
    } else {
      return (
        <>
          <VStack w='full' color='black.950' align='start' spacing={0}>
            <Text textStyle={'body'} fontWeight={700}>
              {title}
            </Text>
            <Text textStyle={'body'}>Заполните форму и мы вернемся к вам</Text>
          </VStack>

          <VStack w='full' align='start' spacing={2}>
            {nameInput}
            {emailInput}
            {phoneInput}
            {questionInput}
          </VStack>
          <VStack w='full' spacing={4} flexShrink={0}>
            {confidentCheckbox}
            {contractCheckbox}

            <Button variant={'primary'} size='sm' w='full' onClick={submit}>
              Отправить
            </Button>
          </VStack>
        </>
      )
    }
  }

  return (
    <VStack
      w='full'
      // boxShadow={{ base: 'none', lg: '0px 3px 10px 0px rgba(0, 0, 0, 0.20)' }}
      bg='wood.50'
      borderRadius={'xs'}
      p={{ base: 4, lg: 6 }}
      align='flex-start'
      spacing={6}
      maxW='588px'
      // h='lg'
    >
      {renderContent()}
    </VStack>
  )
}

export default ContactForm
