import { FC } from 'react'
import { AspectRatio, Box, VStack, Text, Image, Button } from '@chakra-ui/react'
import FilesPickerButton from 'components/FilesPickerButton'
import FilePlaceholder from 'components/filesPicker/FilePlaceholder'
import { generateId } from 'controllers/db'
import storage from 'controllers/storage'
import Resizer from 'react-image-file-resizer'
import { FileT } from 'shared/types'
import get from 'lodash/get'

type Props = {
  label?: string
  storagePath: string
  value?: string
  onChange: (v: string) => void
}

const InputFild: FC<Props> = ({ label, storagePath, value, onChange }) => {
  const resizeFile = async (file: File, size: number = 1024): Promise<File> =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        size,
        size,
        'PNG',
        100,
        0,
        f => resolve(f as File),
        'file'
      )
    })

  const onImageUploaded = (files: FileT[]) => {
    if (files.length > 0) {
      const imageUrl = get(files, [0, 'url'])
      if (imageUrl) {
        onChange(imageUrl)
      }
    }
  }

  return (
    <VStack w={'full'} spacing={1} align={'flex-start'}>
      {label && (
        <Text textStyle='small' color='black.950'>
          {label}
        </Text>
      )}
      <FilesPickerButton
        generateId={() => generateId() + '.png'}
        userId=''
        storage={storage}
        storagePath={storagePath}
        onComplete={onImageUploaded}
        fileProcessor={resizeFile}
        options={{
          accept: {
            'image/*': ['.png', '.jpeg', '.jpg']
          },
          multiple: false
        }}
      >
        {value ? (
          <Box w='full'>
            <AspectRatio ratio={16 / 9} maxW={'full'} w='full'>
              <Image fit='cover' src={value} />
            </AspectRatio>
          </Box>
        ) : (
          <FilePlaceholder buttonTitle='PNG or JPG file' />
        )}
      </FilesPickerButton>
      {value && (
        <Button
          size='xs'
          colorScheme='red'
          variant={'link'}
          onClick={() => onChange('')}
        >
          reset
        </Button>
      )}
    </VStack>
  )
}

export default InputFild
