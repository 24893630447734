import {
  query,
  collection,
  where,
  getDocs,
  doc,
  getDoc,
  updateDoc
} from 'firebase/firestore'
import { db, dbOmit, functions } from 'controllers/db'
import { IUser } from 'shared/types'
import { httpsCallable } from 'firebase/functions'
import get from 'lodash/get'

export const dbGetUserByEmail = async (email: string) => {
  const q = query(collection(db, 'users'), where('email', '==', email))
  const sn = await getDocs(q)
  if (sn.empty) {
    return null
  } else {
    return sn.docs[0].data() as IUser
  }
}

export const dbGetUser = async (id: string) => {
  const ref = doc(db, 'users', id)
  const sn = await getDoc(ref)
  if (!sn.exists) {
    return null
  } else {
    return sn.data() as IUser
  }
}

export const dbCreateUser = async (
  email: string,
  name: string,
  phone: string
) => {
  try {
    const createUser = httpsCallable(functions, 'createUser')
    const res = await createUser({ email, name, phone })
    console.log('callable function res:', res)
    return res.data as IUser | string
  } catch (e) {
    console.log('createUser error', e)
    return get(e, 'message', '')
  }
}

export const dbUpdateUser = async (userId: string, upd: Partial<IUser>) => {
  try {
    console.log('dbUpdateUser', userId, upd)
    const ref = doc(collection(db, 'users'), userId)
    await updateDoc(ref, dbOmit(upd))
  } catch (e) {
    console.log('dbUpdateUser error', e)
  }
}

export const dbChangeUserEmail = async (userId: string, email: string) => {
  try {
    console.log('dbChangeUserEmail', userId, email)
    const updateUser = httpsCallable(functions, 'updateUser')
    const res = await updateUser({ id: userId, email })
    console.log('callable function res:', res)
    return res.data as string | null
  } catch (e) {
    console.log('dbChangeUserEmail error', e)
    return get(e, 'message', '')
  }
}

export const dbDeleteUser = async (userId: string) => {
  try {
    console.log('dbDeleteUser', userId)
    const updateUser = httpsCallable(functions, 'deleteUser')
    const res = await updateUser({ id: userId })
    console.log('callable function res:', res)
    return res.data as string | null
  } catch (e) {
    console.log('dbChangeUserEmail error', e)
    return get(e, 'message', '')
  }
}
