import { FC } from 'react'
import {
  ISectionCard,
  ISectionColumns,
  ISectionColumnsVideo,
  ISectionHeading,
  IStyledText,
  IVideo
} from 'shared/types'
import {
  Box,
  VStack,
  Show,
  Grid,
  GridItem,
  Divider,
  Circle,
  HStack,
  Text
} from '@chakra-ui/react'
import map from 'lodash/map'
import get from 'lodash/get'
import SectionTextContent from 'shared/components/SectionTextContent'
import VideoPlayer from 'shared/components/VideoPlayer'

type Props = {
  s: ISectionColumnsVideo
}

const SectionColumns: FC<Props> = ({ s }) => {
  const renderCard = (c: IVideo, i: number) => {
    return (
      // <VStack p={0} spacing={6} rounded={'xs'} w='full' h='fit-content'>
      <VideoPlayer v={c} />
      // </VStack>
    )
  }

  const renderCards = () => {
    let amountPerRow = s.columnsAmount || 4
    if (s.columns.length < amountPerRow) amountPerRow = s.columns.length
    return (
      <Grid
        w='full'
        templateColumns={{
          base: `repeat(1, 1fr)`,
          lg: `repeat(${amountPerRow}, 1fr)`
        }}
        rowGap={6}
        columnGap={6}
        justifyItems={'center'}
      >
        {map(s.columns, (c, i) => {
          return (
            <GridItem key={i} h='full' w='full'>
              {renderCard(c, i)}
            </GridItem>
          )
        })}
      </Grid>
    )
  }

  return (
    <VStack
      minH={10}
      // align={s.alignment}
      w='full'
      spacing={2}
    >
      {renderCards()}
    </VStack>
  )
}

export default SectionColumns
