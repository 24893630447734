import { Box, Button, VStack, Text, HStack, Input } from '@chakra-ui/react'
import { FC, useRef, useState } from 'react'
import { IVideoPlatform } from 'shared/types'
import VideoPlayer from 'shared/components/VideoPlayer'

type Props = {
  label: string
  value: string
  platform: IVideoPlatform
  onChange: (v: string) => void
}

const InputFild: FC<Props> = ({ label, value, platform, onChange }) => {
  const [videoValue, setVideoValue] = useState(value || '')
  const videoInputRef = useRef<HTMLInputElement>(null)

  const onBlur = () => {
    if (platform === 'vimeo') {
      onChange(videoValue)
    } else {
      const regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
      const match = videoValue.match(regExp)
      const videoId = match && match[7].length === 11 ? match[7] : false
      console.log('youtube video id extracted', videoId)
      if (videoId) {
        onChange(videoId)
      } else {
        onChange('')
      }
    }
  }

  return (
    <VStack w={'full'} spacing={1} align={'flex-start'}>
      <Text textStyle='small' color='black.950'>
        {label}
      </Text>
      <Box flex={1} w='50%' pt={2}>
        {value && value.length > 0 && (
          <VideoPlayer v={{ platform, url: value }} />
        )}
      </Box>
      <HStack w='full'>
        <Input
          w='full'
          size={'sm'}
          ref={videoInputRef}
          variant={'flushed'}
          placeholder={
            platform === IVideoPlatform.VIMEO
              ? 'ID или url vimeo видео'
              : 'Youtube видео url'
          }
          value={videoValue}
          onChange={e => setVideoValue(e.target.value)}
          onBlur={onBlur}
        />
        <Button
          size='sm'
          onClick={() => videoInputRef.current?.blur()}
          variant={'primary'}
        >
          Apply
        </Button>
      </HStack>
    </VStack>
  )
}

export default InputFild
