import {
  Ref,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useState
} from 'react'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  HStack,
  Button
} from '@chakra-ui/react'
import map from 'lodash/map'

export interface IItem {
  id: string
  label: string
  items?: IItem[]
}

export interface IEntitySelectModal {
  open: (id: string) => void
}

type Props = {
  conf: IItem[]
  onApply: (id: string, pth: string[]) => void
}

const EntitySelectModal: ForwardRefRenderFunction<IEntitySelectModal, Props> = (
  { conf, onApply },
  ref: Ref<unknown>
) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [id, setId] = useState('')

  useImperativeHandle(ref, () => ({
    open: (id: string) => {
      setId(id)
      onOpen()
    }
  }))

  const apply = async (pth: string[]) => {
    onApply(id, pth)
    onClose()
  }

  const renderItem = (item: IItem, pth: string[] = []) => {
    if (item.items) {
      return (
        <AccordionItem>
          <h2>
            <AccordionButton>
              <HStack w='full'>
                <Text textStyle={'small'} color='gray.600'>
                  {item.label}
                </Text>
              </HStack>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Accordion>
              {map(item.items, v => renderItem(v, [...pth, item.id]))}
            </Accordion>
          </AccordionPanel>
        </AccordionItem>
      )
    } else {
      return (
        <HStack
          ml={4}
          as={Button}
          py={1}
          my={1}
          variant={'link'}
          onClick={() => apply([...pth, item.id])}
        >
          <Text textStyle={'small'} color='gray.900' fontWeight={'semibold'}>
            {item.label}
          </Text>
        </HStack>
      )
    }
  }

  const renderBody = () => {
    return (
      <Accordion p={0}>{map(conf, item => renderItem(item, []))}</Accordion>
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xl' scrollBehavior='inside'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Перенести в</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{renderBody()}</ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(EntitySelectModal)
