import { ReactNode } from 'react'
import {
  Box,
  Drawer,
  DrawerContent,
  useDisclosure,
  Flex,
  Show,
  ScaleFade,
  IconButton
} from '@chakra-ui/react'
import SidebarContent from 'components/sidebar/SidebarContent'
import MobileNav from 'components/sidebar/MobileNav'
import HorizontalCollapse from 'shared/components/HorizontalCollapse'
import { ReactComponent as IconMenu } from 'shared/assets/bsMenu.svg'

export default function SimpleSidebar ({ children }: { children?: ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const onOpenDrawerClick = () => {
    onOpen()
  }
  return (
    <Flex
      h={{ base: 'auto', md: 'full' }}
      // w={{ base: 0, md: '60' }}
      overflow={'hidden'}
      flexShrink={0}
    >
      <Show above='md'>
        <HorizontalCollapse isOpen={isOpen} width={240}>
          <SidebarContent onClose={onClose} />
        </HorizontalCollapse>
        {!isOpen && (
          <ScaleFade initialScale={0.9} in={!isOpen}>
            <IconButton
              position={'fixed'}
              variant={'unstyled'}
              top={3}
              left={2}
              aria-label='open drawer'
              icon={<IconMenu width={24} height={24} />}
              size='lg'
              zIndex={100}
              onClick={onOpenDrawerClick}
            />
          </ScaleFade>
        )}
      </Show>
      <Show below='md'>
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement='right'
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size='full'
        >
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />
        <Box ml={{ base: 0, md: 60 }} p='4'>
          {children}
        </Box>
      </Show>
    </Flex>
  )
}
