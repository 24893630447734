import { useMemo } from 'react'
import { Button, HStack, VStack, Wrap, WrapItem } from '@chakra-ui/react'
import NavBar from 'components/NavBar'
import { useSelector } from 'model/hooks'
import map from 'lodash/map'
import { ICourse } from 'shared/types'
import imageDefault from 'shared/assets/course-default.png'
import { AddIcon } from '@chakra-ui/icons'
import { dbCreateCourse } from 'controllers/courses'
import { useNavigate } from 'react-router-dom'
import orderBy from 'lodash/orderBy'
import EntityCard from 'components/EntityCard'
import dayjs from 'dayjs'
import size from 'lodash/size'
import { createLabel } from 'shared/utils/format'

const Courses = () => {
  const navigate = useNavigate()
  const courses = useSelector(state => state.courses)

  const sortedCourses = useMemo(() => {
    return orderBy(courses, 'createdAt', 'desc')
  }, [courses])

  const renderCard = (c: ICourse) => {
    return (
      <WrapItem key={c.id}>
        <EntityCard
          url={`/courses/${c.id}`}
          title={c.title}
          imageUrl={c.imageUrl || imageDefault}
          desc={[
            `${c.studentsAmount || 0} / ${c.studentsLimit || '∞'}`,
            c.startDate ? dayjs(c.startDate).format('MMM D, YYYY') : '-',
            c.endDate ? dayjs(c.endDate).format('MMM D, YYYY') : '-'
          ]}
          lessonsAmount={c.content ? size(c.content) : size(c.lessons)}
          lessonsAmountDesc={
            c.content
              ? createLabel(size(c.content), ['Неделя', 'Недели', 'Недель'])
              : createLabel(size(c.lessons), ['Урок', 'Урока', 'Уроков'])
          }
          closed={c.closed}
          invisible={c.invisible}
        />
      </WrapItem>
    )
  }

  const onCreateCourseClick = async () => {
    const courseId = await dbCreateCourse()
    navigate(`/courses/${courseId}`)
  }

  const navBarActions = (
    <HStack>
      <Button
        colorScheme='teal'
        leftIcon={<AddIcon />}
        onClick={onCreateCourseClick}
      >
        Создать курс
      </Button>
    </HStack>
  )

  return (
    <VStack w='full' h='full' spacing={0}>
      <NavBar hideBackButton title='Курсы'>
        {navBarActions}
      </NavBar>
      <VStack align={'flex-start'} w='full' h='full' p={4} overflowY={'auto'}>
        <Wrap spacing={4}>{map(sortedCourses, renderCard)}</Wrap>
      </VStack>
    </VStack>
  )
}

export default Courses
