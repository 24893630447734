import { FC } from 'react'
import { ISectionColumnsImage } from 'shared/types'
import { VStack, Grid, GridItem, Image, Box } from '@chakra-ui/react'
import map from 'lodash/map'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import CustomButtonRight from 'shared/components/sections/carousel/CustomButtonRight'
import CustomButtonLeft from 'shared/components/sections/carousel/CustomButtonLeft'

type Props = {
  s: ISectionColumnsImage
}

const SectionColumns: FC<Props> = ({ s }) => {
  const renderCard = (c: string, i: number) => {
    return (
      <VStack p={0} spacing={6} rounded={'xs'} w='full' h='fit-content'>
        <Image src={c} />
      </VStack>
    )
  }

  const renderCards = () => {
    let amountPerRow = s.columnsAmount || 4
    if (s.columns.length < amountPerRow) amountPerRow = s.columns.length
    return (
      <Grid
        w='full'
        templateColumns={{
          base: `repeat(1, 1fr)`,
          lg: `repeat(${amountPerRow}, 1fr)`
        }}
        rowGap={6}
        columnGap={6}
        justifyItems={'center'}
      >
        {map(s.columns, (c, i) => {
          return (
            <GridItem key={i} h='full' w='full'>
              {renderCard(c, i)}
            </GridItem>
          )
        })}
      </Grid>
    )
  }

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: s.columnsAmount || 3,
      partialVisibilityGutter: 40
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: s.columnsAmount || 3,
      partialVisibilityGutter: 40
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 20
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 30
    }
  }

  const renderCarousel = () => {
    return (
      <Box
        w='full'
        sx={{
          cursor: 'grab !important',
          li: { 'list-style-type': 'none' },
          'li::before': { content: 'none' }
        }}
      >
        <Carousel
          partialVisible
          key={s.columnsAmount + '_' + (s.columns && s.columns.length)}
          responsive={responsive}
          additionalTransfrom={0}
          centerMode={false}
          infinite
          keyBoardControl
          minimumTouchDrag={80}
          slidesToSlide={1}
          swipeable
          customRightArrow={<CustomButtonRight />}
          customLeftArrow={<CustomButtonLeft />}
        >
          {map(s.columns, (c, i) => {
            return (
              <Box key={i} pr={6} h='full'>
                {renderCard(c, i)}
              </Box>
            )
          })}
        </Carousel>
      </Box>
    )
  }

  return (
    <VStack
      minH={10}
      // align={s.alignment}
      w='full'
      spacing={2}
    >
      {s.showCarousel ? renderCarousel() : renderCards()}
    </VStack>
  )
}

export default SectionColumns
