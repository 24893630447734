import { Card, CardBody, Divider, Text, VStack } from '@chakra-ui/react'
import { useSelector } from 'model/hooks'
import { getChannelsByLesson } from 'model/selectors/channels'
import { FC, useContext, useMemo } from 'react'
import LessonsContext from 'contexts/LessonsContext'
import _ from 'lodash'
import { useSearchParams } from 'react-router-dom'
import Chat from 'components/Chat'

type Props = {
  lessonId: string
}

const LessonChat: FC<Props> = ({ lessonId }) => {
  const channelsByLesson = useSelector(getChannelsByLesson)
  const user = useSelector(state => state.user)
  const [searchParams, setSearchParams] = useSearchParams()
  const { userPayments, entityId, entityType } = useContext(LessonsContext)

  const channelByUserId = useMemo(() => {
    return _.keyBy(_.get(channelsByLesson, lessonId), 'userId')
  }, [channelsByLesson, lessonId])

  const renderUsersList = () => {
    return (
      <VStack
        flex={0.2}
        borderRightWidth={1}
        // divider={<Divider p={0} />}
        spacing={0}
      >
        {_.map(userPayments, p => {
          return (
            <VStack
              key={p.id}
              spacing={0}
              align='flex-start'
              w='full'
              px={4}
              // py={2}
              minH={10}
              justify={'center'}
              _hover={{ bg: 'lightGray', cursor: 'pointer' }}
              onClick={() => setSearchParams({ userId: p.userId, tab: '2' })}
              bg={
                searchParams.get('userId') === p.userId
                  ? 'lightGray'
                  : 'transparent'
              }
            >
              <Text textStyle={'small'} fontWeight={450} color='black'>
                {p.name}
              </Text>
              <Text textStyle={'tag'} color='darkGray'>
                {p.email}
              </Text>
            </VStack>
          )
        })}
      </VStack>
    )
  }

  const renderMessages = () => {
    const userId = searchParams.get('userId')
    if (userId && entityId) {
      return <Chat lessonId={lessonId} userId={userId} entityId={entityId} />
    }
  }

  return (
    <Card w='full' maxW='9xl' p={0} h='full'>
      <CardBody display={'flex'} flexDirection={'row'} p={0} h='full' w='full'>
        {renderUsersList()}
        {renderMessages()}
      </CardBody>
    </Card>
  )
}

export default LessonChat
