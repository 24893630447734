import { createReducer } from '@reduxjs/toolkit'
import { IPartition } from 'shared/types'
import { receivePartitions } from 'src/model/actions'

const initialState = null as Record<string, IPartition> | null

const reducer = createReducer(initialState, builder => {
  builder.addCase(receivePartitions, (state, action) => action.payload)
})

export default reducer
