import { HStack, Show, Text, VStack, Box } from '@chakra-ui/react'
import { ReactComponent as Logo } from 'shared/assets/crown_dark-12.svg'
import { Link } from 'react-router-dom'

const Footer = () => {
  const renderLink = (
    title: string,
    url: string,
    isExternal: boolean = false
  ) => {
    return (
      <Link to={url}>
        <Text fontSize={{ base: 'xs', lg: 'sm' }}>{title}</Text>
      </Link>
    )
  }

  return (
    <HStack
      pt={{ base: 8, lg: 9 }}
      pb={{ base: '72px', lg: 8 }}
      w='full'
      maxW='7xl'
      color='white'
      fontWeight={'450'}
      flexGrow={0}
    >
      <Show below='lg'>
        <VStack w='full' lineHeight={'16px'} spacing={8}>
          <HStack w='full' px={4} align={'flex-start'}>
            <VStack flex={1} align='flex-start'>
              {renderLink('О школе', '/')}
              {/* {renderLink('Контакты', '/contacts')} */}
              {renderLink(
                'Inst: @thequeenenglish',
                'https://instagram.com/thequeenenglish',
                true
              )}
              <Box>
                {renderLink(
                  'info@thequeenenglish.com',
                  'mailto:info@thequeenenglish.com'
                )}
              </Box>
              <Text fontSize={{ base: 'xs', lg: 'sm' }} pt={6}>
                ©2021 thequeenenglish.com
              </Text>
            </VStack>
            <VStack flex={1} align='flex-start' pl={4}>
              {renderLink('Сведения об образовательной организации', '/info')}
              {renderLink('Договор-оферта', '/contract')}
              {renderLink('Пользовательское соглашение', '/agreement')}
              {renderLink('Положение о конфиденциальности', '/privacy')}
            </VStack>
          </HStack>
          <VStack align='center' w='full' px={4} spacing={5}>
            <Logo height={'64'} color='darkGray' />
          </VStack>
        </VStack>
      </Show>
      <Show above='lg'>
        <VStack w='full' spacing={12}>
          <HStack w='full' justify={'space-between'} px={8}>
            <HStack flex={1}>
              <VStack alignItems='flex-start' justify={'flex-start'}>
                {renderLink('О школе', '/')}
                {/* {renderLink('Контакты', '/contacts')} */}
                {renderLink(
                  'Inst: @thequeenenglish',
                  'https://instagram.com/thequeenenglish',
                  true
                )}
                {/* {renderLink('Преподаватели', '/')}
                 */}
              </VStack>
              {/* <VStack alignItems='flex-end'>
                {renderLink(
                  'Facebook',
                  'https://www.facebook.com/thequeenenglish/',
                  true
                )}
                {renderLink(
                  'Youtube',
                  'https://www.instagram.com/thequeenenglish/',
                  true
                )}
              </VStack> */}
            </HStack>
            <HStack align='flex-start' h='full'>
              <Logo />
            </HStack>
            <VStack align='flex-end' flex={1}>
              {renderLink('Сведения об образовательной организации', '/info')}
              {renderLink('Договор-оферта', '/contract')}
              {renderLink('Пользовательское соглашение', '/agreement')}
              {renderLink('Положение о конфиденциальности', '/privacy')}
            </VStack>
          </HStack>

          <HStack
            w='full'
            justify={'space-between'}
            px={8}
            pt='24'
            align={'flex-end'}
          >
            <VStack alignItems='flex-start' justify={'flex-start'}>
              <Text fontSize={{ base: 'xs', lg: 'sm' }}>
                ©2023 thequeenenglish.com
              </Text>
            </VStack>
            <VStack alignItems='flex-end' justify={'flex-start'}>
              {renderLink(
                'info@thequeenenglish.com',
                'mailto:info@thequeenenglish.com'
              )}
            </VStack>
          </HStack>
        </VStack>
      </Show>
    </HStack>
  )
}

export default Footer
