import { Stack, Text, Box } from '@chakra-ui/react'
import { FC } from 'react'
import { IMessage } from 'shared/types'

type Props = {
  m: IMessage
  isLeft: boolean
  hasPrev: boolean
  hasNext: boolean
}

const MessageElement: FC<Props> = ({ m, isLeft, hasPrev, hasNext }) => {
  return (
    <Stack
      w='full'
      pt={0.5}
      align={isLeft ? 'flex-start' : 'flex-end'}
      spacing={0}
    >
      <Box
        maxW={'90%'}
        p={3}
        rounded={'20px'}
        bg='lightGray'
        borderBottomLeftRadius={isLeft && hasNext ? '4px' : undefined}
        borderBottomRightRadius={!isLeft && hasNext ? '4px' : undefined}
        borderTopLeftRadius={isLeft && hasPrev ? '4px' : undefined}
        borderTopRightRadius={!isLeft && hasPrev ? '4px' : undefined}
      >
        <Text textStyle='small'>{m.content.text}</Text>
      </Box>
    </Stack>
  )
}

export default MessageElement
