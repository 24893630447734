import { FC, useRef, useMemo } from 'react'
import PageContentWrapper from 'shared/components/PageContentWrapper'
import { StorageReference } from 'firebase/storage'
import ContentContainer from 'shared/components/ContentContainer'
import { Button } from '@chakra-ui/react'
import PageContent from 'shared/components/PageContent'
import get from 'lodash/get'
import size from 'lodash/size'
import map from 'lodash/map'
import has from 'lodash/has'
import filter from 'lodash/filter'
import {
  ICourse,
  ICoursesPack,
  IFeedback,
  IPartition,
  IPlan,
  ITeacher,
  IUser,
  SectionType
} from 'shared/types'
import courseDefault from 'shared/assets/course-default.png'
import CoursesPackPlansContainer from 'shared/components/CoursesPackPlansContainer'
import CoursesPackPlanCard from 'shared/components/CoursesPackPlanCard'
import PageContentHeader from 'shared/components/PageContentHeader'
import { MAX_WIDTH } from 'shared/constants/main'
import AddSectionButton from 'shared/components/admin/AddSectionButton'
import ContactForm from 'shared/components/ContactForm'

type Props = {
  coursesPack: ICoursesPack
  courses: Record<string, ICourse>
  userCourses: Record<string, ICourse>
  storageRef: (refer: string) => StorageReference
  toCourse: (courseId: string) => void
  onPay: (c: ICourse) => void
  admin?: {
    onAddSection: (sType: SectionType, i: number) => void
    onMoveSection: (fromIndex: number, toIndex: number) => void
    onEditSection: (sid: string) => void
    onEditPlans?: () => void
    onEditHeader?: () => void
  }
  user?: IUser | null
  dbSaveFeedback?: (feedback: Partial<IFeedback>) => void
  teachers: Record<string, ITeacher>
  partitions: Record<string, IPartition> | null
}

const CoursesPackPage: FC<Props> = ({
  storageRef,
  coursesPack,
  courses,
  toCourse,
  onPay,
  userCourses,
  admin,
  user = null,
  dbSaveFeedback,
  teachers,
  partitions
}) => {
  const plansContainerRef = useRef<HTMLDivElement>(null)

  const packCoursesAmount = useMemo(() => {
    return size(filter(coursesPack.plans, p => has(courses, p.courseId)))
  }, [courses, coursesPack.plans])

  const renderPlans = () => {
    if (courses) {
      return (
        <CoursesPackPlansContainer
          amount={packCoursesAmount}
          onEditPlans={admin?.onEditPlans}
        >
          {map(coursesPack.plans, p => {
            const c = get(courses, p.courseId)
            if (c) {
              return (
                <CoursesPackPlanCard
                  key={p.id}
                  p={p}
                  c={c}
                  onPay={() => onPay(c)}
                  toCourse={() => toCourse(c.id)}
                  userCourses={userCourses}
                />
              )
            }
          })}
        </CoursesPackPlansContainer>
      )
    }
  }

  const renderHeader = () => {
    if (coursesPack) {
      return (
        <PageContentHeader
          tags={coursesPack.tags || []}
          backUrl='/courses'
          title={coursesPack.title}
          videoId={coursesPack.vimeoVideoUrl}
          imageUrl={coursesPack.imageUrl || courseDefault}
          onEditHeader={admin?.onEditHeader}
        >
          <Button
            variant='primary'
            w={{ base: 'full', lg: 'auto' }}
            onClick={() =>
              plansContainerRef.current?.scrollIntoView({ behavior: 'smooth' })
            }
          >
            {'Выбрать тариф'}
          </Button>
          {admin && (
            <AddSectionButton
              lt={undefined}
              addSection={(sType: SectionType) => admin.onAddSection(sType, 0)}
            />
          )}
        </PageContentHeader>
      )
    }
  }
  return (
    <PageContentWrapper>
      {renderHeader()}
      <ContentContainer
        bg='white'
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
        py={{ base: 6, lg: 8 }}
      >
        <PageContent
          sections={coursesPack.sections}
          sectionsOrder={coursesPack.sectionsOrder}
          entityId={coursesPack.id}
          storageRef={storageRef}
          isExamination={false}
          admin={admin}
          teachers={teachers}
          partitions={partitions}
        />
      </ContentContainer>
      <ContentContainer
        ref={plansContainerRef}
        borderBottomRadius={{ base: 'sm', lg: 'lg' }}
        py={{ base: 6, lg: 8 }}
        px={0}
        containerProps={{ role: 'group' }}
      >
        {renderPlans()}
      </ContentContainer>
      {dbSaveFeedback && (
        <ContentContainer
          bg='white'
          borderTopRadius={{ base: 'sm', lg: 'lg' }}
          borderBottomRadius={{ base: 'sm', lg: 'lg' }}
          py={{ base: 6, lg: 8 }}
        >
          <ContactForm user={user} dbSaveFeedback={dbSaveFeedback} />
        </ContentContainer>
      )}
    </PageContentWrapper>
  )
}

export default CoursesPackPage
