import { FC, useRef, useState, useEffect } from 'react'
import { Input, InputProps, VStack, Text, HStack, Box } from '@chakra-ui/react'
import { ReactComponent as IconEnter } from 'shared/assets/bsArrowReturnRight.svg'

type Props = InputProps & {
  onChange: (v: string) => void
  clearOnApply?: boolean
  label?: string
}

const SimpleInput: FC<Props> = ({
  onChange,
  value,
  clearOnApply,
  label,
  ...rest
}) => {
  const ref = useRef<HTMLInputElement>(null)
  const [v, setV] = useState<string>(value ? value.toString() : '')

  useEffect(() => {
    setV(value ? value.toString() : '')
  }, [value])

  const apply = () => {
    console.log('onBlur, apply', v)
    onChange(v)
    if (clearOnApply) {
      setV('')
    }
  }

  return (
    <HStack w='full' align={'flex-end'}>
      <VStack w={'full'} spacing={1} align={'flex-start'}>
        {label && (
          <Text textStyle='small' color='black.950'>
            {label}
          </Text>
        )}
        <Input
          ref={ref}
          value={v}
          size='sm'
          textStyle={'small'}
          variant='flushed'
          h='4'
          onChange={e => setV(e.target.value)}
          onBlur={apply}
          color='black.600'
          {...rest}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              ref.current?.blur()
            }
          }}
        />
      </VStack>
      <HStack
        as='button'
        rounded='xs'
        align='center'
        justify='center'
        h={6}
        w={8}
        onClick={() => ref.current?.blur()}
        bg={'black.800'}
        color='white'
      >
        <Box boxSize={{ base: 3, lg: 3 }}>
          <IconEnter width='100%' height='100%' />
        </Box>
      </HStack>
    </HStack>
  )
}

export default SimpleInput
