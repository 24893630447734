import { FC, useRef, useState, useEffect, MouseEvent, useMemo } from 'react'
import {
  Input,
  InputProps,
  Flex,
  HStack,
  VStack,
  Box,
  Text,
  Divider
} from '@chakra-ui/react'
import AutowidthInput from 'react-autowidth-input'
import { ReactComponent as IconEnter } from 'shared/assets/bsArrowReturnRight.svg'
import last from 'lodash/last'
import trim from 'lodash/trim'
import find from 'lodash/find'
import isNil from 'lodash/isNil'
import { cleanupString } from 'shared/utils/tests'
import CorrectText from 'shared/components/sections/tests/CorrectText'
// import SectionDivider from 'shared/components/sections/SectionDivider'

type Props = Omit<InputProps, 'onChange'> & {
  correctValues: string[]
  onWordAdded: (isCorrect: boolean) => void
  highlightCorrectAnswer: boolean
  completed: boolean
}

const WordInput: FC<Props> = ({
  correctValues,
  onWordAdded,
  value,
  highlightCorrectAnswer,
  completed,
  ...rest
}) => {
  const ref = useRef<HTMLInputElement>(null)
  const [attempts, setAttempts] = useState<boolean[]>([])
  const [focused, setFocused] = useState(false)
  const [v, setV] = useState<string>(value ? value.toString() : '')

  useEffect(() => {
    setV(value ? value.toString() : '')
  }, [value])

  const isCorrect = last(attempts) === true

  const correctValue = useMemo(() => {
    return find(correctValues, cv => {
      return cleanupString(v) === cleanupString(cv)
    })
  }, [v, correctValues])

  const apply = () => {
    // console.log('apply')

    if (trim(v) !== '' && attempts.length < 3 && last(attempts) !== true) {
      const isCorrect = !isNil(correctValue)
      const newAttempts = [...attempts, isCorrect]
      setAttempts([...attempts, isCorrect])
      if (isCorrect || newAttempts.length === 3) {
        onWordAdded(isCorrect)
      }
    }
    setFocused(false)

    // onChange(v)
    // setV('')
  }

  // console.log('attempts', attempts)

  // const renderAttempts = () => {
  //   return (
  //     <VStack spacing={'3px'}>
  //       {[0, 1, 2].map((a, i) => {
  //         let color = 'midGray'
  //         if (i < attempts.length) {
  //           color = attempts[i] ? 'green' : 'red'
  //         }
  //         return <Box key={i} rounded='full' w={1} h={1} bg={color} />
  //       })}
  //     </VStack>
  //   )
  // }

  const onRightAddonClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    e.preventDefault()
    if (!focused) {
      ref.current.focus()
    } else {
      ref.current.blur()
    }
  }

  const lastTryFailed = useMemo(
    () =>
      attempts.length < 3 &&
      attempts.length >= 1 &&
      last(attempts) === false &&
      !focused,
    [attempts, focused]
  )

  const inputColor = useMemo(() => {
    return last(attempts) === true
      ? 'green.800'
      : attempts.length === 3 || lastTryFailed
      ? 'red.700'
      : 'black.95'
  }, [attempts, lastTryFailed])

  const readOnly = attempts.length === 3 || last(attempts) === true

  const onFocus = () => {
    if (!readOnly) {
      setFocused(true)
      setV('')
    }
  }

  const renderAttempts = () => {
    const borderColor =
      last(attempts) === true
        ? 'green.800'
        : attempts.length === 3 || lastTryFailed
        ? 'red.700'
        : focused
        ? 'black.950'
        : 'black.300'

    return (
      <HStack spacing={'1px'} w='full'>
        <Divider borderColor={borderColor} />
        {[0, 1, 2].map((a, i) => {
          let color = 'black.200'
          if (i < attempts.length) {
            color = attempts[i] ? 'green.800' : 'red.700'
          }
          return (
            <Box flexShrink={0} key={i} rounded='full' w={1} h={1} bg={color} />
          )
        })}
      </HStack>
    )
  }

  // console.log('correct value', correctValue)

  const renderInput = () => {
    if (isCorrect) {
      return (
        <HStack h={6}>
          <CorrectText answer={v} correctAnswer={correctValue} />
        </HStack>
      )
    } else {
      return (
        <Input
          type='text'
          ref={ref}
          value={v}
          fontSize={{ base: '16px', lg: '18' }}
          fontWeight={450}
          letterSpacing={{ base: '-0.32px', lg: '-0.36px' }}
          lineHeight={{ base: '20px', lg: '24px' }}
          as={AutowidthInput}
          onChange={e => setV(e.target.value)}
          onBlur={apply}
          px={0}
          m={0}
          autoComplete='off'
          // placeholder='Перевод'
          _placeholder={{ color: 'black.300' }}
          // minW={focused || v !== '' ? '16px' : '48px'}
          minW={'48px'}
          h={'18px'}
          onFocus={onFocus}
          // width={'auto'}
          border='none'
          // bg={isCorrect ? GREEN : attempts.length === 3 ? RED : undefined}
          // _disabled={{ opacity: 1 }}
          // disabled={attempts.length === 3 || last(attempts) === true}
          readOnly={readOnly}
          // borderColor={'midGray'}
          // _focus={{
          //   borderColor: 'black'
          // }}
          _focusVisible={{
            outline: 'none'
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              ref.current?.blur()
            }
          }}
          rounded={'0'}
          extraWidth={0}
          {...rest}
        />
      )
    }
  }

  return (
    <HStack spacing={1} pr={1}>
      <HStack
        w='auto'
        role={'group'}
        spacing={1}
        // borderWidth={1}
        // borderColor={
        //   last(attempts) === true
        //     ? 'green'
        //     : attempts.length === 3 || lastTryFailed
        //     ? 'red'
        //     : focused
        //     ? 'black'
        //     : 'midGray'
        // }
        // rounded='4px'
        color={inputColor}
      >
        <VStack spacing={0} justify='flex-end' flexShrink={0}>
          {renderInput()}
          {renderAttempts()}
        </VStack>
        {highlightCorrectAnswer && readOnly && !isCorrect && (
          <VStack align='flex-strt' justify={'flex-start'} px={0} spacing={0}>
            <Text color='green.800'>{correctValues.join(' / ')}</Text>
            <HStack w='full' h={1}>
              <Divider borderColor={'green.800'} />
            </HStack>
          </VStack>
        )}
        {/* <InputRightAddon
          h={'32px'}
          px={0}
          display={focused ? 'block' : 'hidden'}
          border='none'
          opacity={readOnly ? 0 : 1}
          bg='white'
          _hover={{ cursor: readOnly ? 'text' : 'pointer' }}
        > */}
        {!readOnly && focused && !completed && (
          <Flex
            // bg={
            //   completed
            //     ? inputColor === 'red'
            //       ? RED
            //       : GREEN
            //     : focused
            //     ? 'black.800'
            //     : 'white'
            // }
            bg='black.800'
            _hover={{ cursor: readOnly || !focused ? 'text' : 'pointer' }}
            h={6}
            w={6}
            // borderWidth={1}
            // borderColor={'red'}
            // outline={'1px solid black'}
            // rounded={'0px 2px 2px 0px'}
            rounded={'full'}
            color='white'
            align='center'
            justify='center'
            // _hover={{ cursor: 'pointer' }}
            // onClick={onRightAddonClick}
          >
            <IconEnter width={12} height={12} />
          </Flex>
        )}
        {/* </InputRightAddon> */}
      </HStack>
    </HStack>
  )
}

export default WordInput
