import { useSelector } from 'model/hooks'
import { FC, useMemo } from 'react'
import _ from 'lodash'
import { HStack, Radio, RadioGroup } from '@chakra-ui/react'

type Props = {
  value?: string
  onChange: (v: string) => void
}

const LanguageSelector: FC<Props> = ({ value, onChange }) => {
  const langs = useSelector(state => state.languages)
  const sortedLangs = useMemo(() => {
    return _.orderBy(langs, 'title')
  }, [langs])

  return (
    <RadioGroup onChange={onChange} value={value}>
      <HStack>
        {_.map(sortedLangs, l => {
          return (
            <Radio key={l.id} value={l.id} colorScheme='wood' size='sm'>
              {l.title}
            </Radio>
          )
        })}
      </HStack>
    </RadioGroup>
  )
}

export default LanguageSelector
