import { FC } from 'react'
import map from 'lodash/map'
import { VStack, Stack, Box } from '@chakra-ui/react'
import { ISectionSyncTranslate } from 'shared/types'
import SectionTextContent from 'shared/components/SectionTextContent'

type Props = {
  s: ISectionSyncTranslate
}

const SectionText: FC<Props> = ({ s }) => {
  return (
    <VStack w='full' spacing={{ base: 4, lg: 6 }}>
      {map(s.paragraphs, (p, i) => {
        return (
          <Stack
            key={i}
            direction={{ base: 'column', lg: 'row' }}
            w='full'
            spacing={{ base: 2, lg: 6 }}
          >
            <Box flex={1}>
              <SectionTextContent r={{ text: p.left, textStyle: 'body' }} />
            </Box>
            <Box flex={1}>
              <SectionTextContent r={{ text: p.right, textStyle: 'body' }} />
            </Box>
          </Stack>
        )
      })}
    </VStack>
  )
}

export default SectionText
