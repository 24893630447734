import { useRef, useState, useEffect } from 'react'
import {
  VStack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  Alert,
  HStack,
  AlertTitle,
  AlertDescription,
  Box,
  CloseButton,
  useToast
} from '@chakra-ui/react'
import isEmpty from 'lodash/isEmpty'
import isEmail from 'validator/lib/isEmail'
import isNil from 'lodash/isNil'
import get from 'lodash/get'
import { dbSignIn } from 'src/controllers/auth'
import { authErrorToString } from 'shared/utils/stringFirebaseError'
import { useLocation } from 'react-router-dom'

const ContactForm = () => {
  const [data, setData] = useState({
    email: '',
    password: ''
  })
  const [errors, setErrors] = useState<Record<string, string | undefined>>({
    email: undefined,
    password: undefined,
    general: undefined
  })
  const emailInputRef = useRef<HTMLInputElement>(null)
  const passwordInputRef = useRef<HTMLInputElement>(null)
  const [loading, setLoading] = useState(false)
  const location: any = useLocation()
  const notAdmin: boolean = get(location, 'state.notAdmin', false)
  const toast = useToast()

  useEffect(() => {
    if (notAdmin) {
      toast({
        title: 'Доступ запрещен',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notAdmin])

  const onChange = (k: string) => (v: string) => {
    setData({
      ...data,
      [k]: v
    })
  }

  const getErrors = () => {
    const res: Record<string, string> = {}
    if (isEmpty(data.email)) {
      res.email = 'Введите email'
    } else if (!isEmail(data.email)) {
      res.email = 'Неверный формат email'
    }
    if (isEmpty(data.password)) {
      res.phone = 'Введите пароль'
    }
    return res
  }

  const resetError = (k: string) => {
    setErrors(errors => ({ ...errors, [k]: undefined }))
  }

  // const checkErrors = () => { }

  const submit = async (): Promise<void> => {
    const errs = getErrors()

    if (isEmpty(errs)) {
      setLoading(true)
      const errorCode = await dbSignIn(data.email, data.password)
      if (!isNil(errorCode)) {
        switch (errorCode) {
          case 'auth/wrong-password':
            errs.password =
              'The email and password you entered did not match our records. Please double-check and try again.'
            break
          case 'auth/user-not-found':
            errs.email =
              'An account with this email does not exist. To create a new account please sign up.'
            break
          default:
            errs.general = authErrorToString(errorCode)
        }
      }
    }
    setErrors(errs)
    setLoading(false)
  }

  const emailInput = (
    <FormControl isRequired isInvalid={!isEmpty(errors.email)}>
      <FormLabel>Email</FormLabel>
      <Input
        type='email'
        variant={'flushed'}
        size='sm'
        placeholder='example@domain.com'
        value={data.email}
        onFocus={() => resetError('email')}
        onBlur={() => {
          const errs = getErrors()
          setErrors(errors => ({ ...errors, email: errs.email }))
        }}
        ref={emailInputRef}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            passwordInputRef.current?.focus()
          }
        }}
        onChange={e => onChange('email')(e.target.value)}
      />
      <FormErrorMessage fontSize={'xs'}>{errors.email}</FormErrorMessage>
    </FormControl>
  )

  const phoneInput = (
    <FormControl isRequired isInvalid={!isEmpty(errors.password)}>
      <FormLabel>Пароль</FormLabel>
      <Input
        type='password'
        variant={'flushed'}
        size='sm'
        value={data.password}
        onFocus={() => resetError('password')}
        onBlur={() => {
          const errs = getErrors()
          setErrors({ ...errors, password: errs.password })
        }}
        ref={passwordInputRef}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            submit()
          }
        }}
        onChange={e => onChange('password')(e.target.value)}
      />
      <FormErrorMessage fontSize={'xs'}>{errors.password}</FormErrorMessage>
    </FormControl>
  )

  const renderGeneralError = () => {
    if (errors.general) {
      return (
        <Alert status='error' rounded={'md'} mb={8}>
          <HStack color='red' spacing={4} w='full' justify={'space-between'}>
            <VStack align='flex-start' color='gray.700' spacing={1}>
              <AlertTitle>Произошла ошибка</AlertTitle>
              <AlertDescription fontSize={'sm'} lineHeight={1.4}>
                {errors.general}
              </AlertDescription>
            </VStack>
            <Box>
              <CloseButton
                color='gray.600'
                onClick={() => resetError('general')}
              />
            </Box>
          </HStack>
        </Alert>
      )
    }
  }

  return (
    <VStack
      w='full'
      flexShrink={0}
      maxW='420px'
      boxShadow={{ base: 'none', lg: '0px 3px 10px 0px rgba(0, 0, 0, 0.20)' }}
      borderRadius={{ lg: '30px' }}
      p={{ base: 4, lg: 5 }}
      align='flex-start'
      spacing={7}
    >
      {renderGeneralError()}
      {emailInput}
      {phoneInput}
      <VStack w='full' spacing={5}>
        <Button w='full' onClick={submit} isLoading={loading}>
          Вход
        </Button>
      </VStack>
    </VStack>
  )
}

export default ContactForm
